import React from "react";
import { Navigate } from "react-router-dom";
import AuthorizationFragment from "helpers/Unauthorized";

const Authmiddleware = props => {
  // if (!localStorage.getItem("User")) {
  //   return (
  //     <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  //   );
  // }
  if (localStorage.getItem("MasterAuth")) {
    if (localStorage.getItem("User")) {
      if (window.location.pathname === "/") {
        return (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        );
      } else {
        return <AuthorizationFragment>{props.children}</AuthorizationFragment>;
      }
    } else if (localStorage.getItem("session_timeout")) {
      return <React.Fragment>{props.children}</React.Fragment>;
    } else {
      return (
        <Navigate
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }
  } else {
    return (
      <Navigate
        to={{ pathname: "/master_auth", state: { from: props.location } }}
      />
    );
  }
};

export default Authmiddleware;
