import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_EmployeeLeave_DD = (value = 0) => {
  let url = "api/Employee_Leave/Get_EmployeeLeave_DropDown";

  return get(url);
};

const Get_EmployeeLeave_List = body => {
  let url = "api/Employee_Leave/Get_EmployeeLeave_List";

  return post(url, body);
};

const Get_EmployeeLeave__Manager = id => {
  let url = "api/Employee_Leave/Get_EmployeeLeave__Manager?Manager_Id=" + id;

  return get(url);
};

// const Get_EmployeeLeave_List_By_Rows = (PageNumber, PageSize) => {
//   let url = `api/Employee_Leave/Get_EmployeeLeave_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

//   return get(url);
// };
const Get_EmployeeLeaveBy_ID = id => {
  let url = `api/Employee_Leave/Get_EmployeeLeaveBy_ID?EmployeeLeave_Id=${id}`;

  return get(url);
};

const Get_EmployeeLeaveMaster_By_Id = id => {
  let url = `api/Employee_Leave/Get_EmployeeLeaveMaster_By_Id?EmployeeLeave_Id=${id}`;

  return get(url);
};

const Get_EmployeeLeaveTrans_By_Id = id => {
  let url = `api/Employee_Leave/Get_EmployeeLeaveTrans_By_Id?EmployeeLeave_Id=${id}`;

  return get(url);
};

const Save_EmployeeLeave = body => {
  let url = "api/Employee_Leave/Save_EmployeeLeave";
  return post(url, body);
};

const Edit_EmployeeLeave = body => {
  let url = "api/Employee_Leave/Edit_EmployeeLeave";
  return post(url, body);
};

const Delete_EmployeeLeave = body => {
  let url = "api/Employee_Leave/Edit_EmployeeLeave_Status";
  return post(url, body);
};

const MarkLeaveCancel_ByEmp = body => {
  let url = "api/Employee_Leave/MarkLeaveCancel_ByEmp";
  return post(url, body);
};

const ManagerUpdate_Leave = body => {
  let url = "api/Employee_Leave/ManagerUpdate_Leave";
  return post(url, body);
};

const UpdateEmployeeLeave_Workflow = body => {
  let url = "api/Employee_Leave/UpdateEmployeeLeave_Workflow";
  return post(url, body);
};
const EmployeeanchorTable = [
  {
    Header: "S.No",
    accessor: "SrNo",
    hidden: false,
    width: 30,
  },
  {
    Header: "Employee Name",
    accessor: "Employee_Name",
    hidden: false,
    width: 200,
  },
  {
    Header: "Starting Date",
    accessor: "From_Date",
    hidden: false,
    width: 100,
  },
  {
    Header: "Ending Date",
    accessor: "To_Date",
    hidden: false,
    width: 100,
  },
  {
    Header: "Day",
    accessor: "Day",
    hidden: true,
  },
  {
    Header: "Leave Type",
    accessor: "LeaveType_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Approval Status",
    accessor: "ApprovalStatus_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Manager Remarks",
    accessor: "Manager_Remarks",
    hidden: true,
    width: 250,
  },
  {
    Header: "HR Remarks",
    accessor: "HR_Remarks",
    hidden: true,
    width: 250,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: true,
    add: true,
    hidden: false,
    width: 100,
  },
];
const ManageranchorTable = [
  {
    Header: "S.No",
    accessor: "SrNo",
    hidden: false,
    width: 30,
  },
  {
    Header: "Employee Name",
    accessor: "Employee_Name",
    hidden: false,
    width: 250,
  },
  {
    Header: "Starting Date",
    accessor: "From_Date",
    hidden: false,
    width: 150,
  },
  {
    Header: "Ending Date",
    accessor: "To_Date",
    hidden: false,
    width: 150,
  },
  {
    Header: "Day",
    accessor: "Day",
    hidden: true,
    width: 150,
  },
  {
    Header: "Leave Type",
    accessor: "LeaveType_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Reason",
    accessor: "Leave_Justification",
    hidden: false,
    width: 150,
  },
  {
    Header: "Approval Status",
    accessor: "ApprovalStatus_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: true,
    add: true,
    hidden: false,
    width: 100,
  },
];
const HRanchorTable = [
  {
    Header: "S.No",
    accessor: "SrNo",
    hidden: false,
    width: 30,
  },
  {
    Header: "Employee Name",
    accessor: "Employee_Name",
    hidden: false,
    width: 250,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: false,
    width: 200,
  },
  {
    Header: "Designation",
    accessor: "Designation_Name",
    hidden: false,
    width: 200,
  },
  {
    Header: "Starting Date",
    accessor: "From_Date",
    hidden: false,
    width: 150,
  },
  {
    Header: "Ending Date",
    accessor: "To_Date",
    hidden: false,
    width: 150,
  },
  {
    Header: "Leave Type",
    accessor: "LeaveType_Name",
    hidden: true,
    width: 150,
  },
  {
    Header: "Reason",
    accessor: "Leave_Justification",
    hidden: false,
    width: 150,
  },
  {
    Header: "Approval Status",
    accessor: "ApprovalStatus_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: true,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_EmployeeLeave_DD,
  Get_EmployeeLeave_List,
  Get_EmployeeLeave__Manager,
  // Get_EmployeeLeave_List_By_Rows,
  Save_EmployeeLeave,
  Edit_EmployeeLeave,
  Delete_EmployeeLeave,
  MarkLeaveCancel_ByEmp,
  ManagerUpdate_Leave,
  Get_EmployeeLeaveBy_ID,
  Get_EmployeeLeaveMaster_By_Id,
  Get_EmployeeLeaveTrans_By_Id,
  UpdateEmployeeLeave_Workflow,
  EmployeeanchorTable,
  ManageranchorTable,
  HRanchorTable,
};
