import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

const AddMisc = props => {
  retrun(
    <div>
      <Row>
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">
              <span style={{ color: "red" }}>* </span>BloodGroup Name
            </Label>
            <Input
              name="BloodGroup_Name"
              type="text"
              maxLength={255}
              onKeyPressCapture={e => _validation.alphaNumericPlusMinus(e)}
            />
          </div>
        </Col>
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">
              <span style={{ color: "red" }}>* </span>BloodGroup Name
            </Label>
            <Input
              name="BloodGroup_Name"
              type="text"
              maxLength={255}
              onKeyPressCapture={e => _validation.alphaNumericPlusMinus(e)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddMisc;
