import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import background from "assets/images/brands/background.jpg";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import systech from "assets/images/cropped-systechlogic.png";

const ForgetPasswordPage = props => {
  //meta title
  document.title = "HR - Forget Password";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history));
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div
                  className="bg-primary bg-soft"
                  style={{
                    height: 300,
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div
                        className="p-4"
                        style={{
                          color: "white",
                          marginTop: 120,
                          marginLeft: 10,
                          zIndex: 9,
                        }}
                      >
                        <h3>Welcome To,</h3>
                        <h2>SYSTECHLOGIC LLP</h2>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <br />
                <br />
                <CardBody className="pt-0">
                  <br />
                  <div
                    style={{
                      textAlign: "center",
                      letterSpacing: 2,
                      margin: 10,
                    }}
                  >
                    <h5 style={{ fontWeight: "bold" }}>RESET PASSWORD</h5>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <div
                          style={{
                            position: "relative",
                            marginBottom: 10,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "30px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 999,
                            }}
                          >
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </span>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            style={{
                              position: "relative",
                              zIndex: 1,
                              paddingLeft: 80,
                            }}
                          />
                        </div>
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <br />
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          type="submit"
                          style={{
                            width: 100,
                            margin: "auto",
                            backgroundColor: "#004a8d",
                            color: "white",
                          }}
                        >
                          RESET
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Powered by&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ fontWeight: "bold" }}
                    href="https://systechlogic.com/"
                  >
                    Systechlogic
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
