import React, { useState } from "react";
import moment from "moment";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
import { RotatingLines } from "react-loader-spinner";
import FloatingFormHREmp from "components/Forms/Employee_Management/EmployeeLeave/formHREmp";
import FloatingFormManager from "components/Forms/Employee_Management/EmployeeLeave/formManager";
import LeaveCancelationForm from "components/Forms/Employee_Management/EmployeeLeave/LeaveCancelationForm";
//Import Breadcrumb
import Breadcrumbs from "./../../../components/Common/Breadcrumb";
import TableContainer from "./../../../components/Common/TableContainer";
import EmployeeLeaveTableContainer from "components/Table/EmployeeManagement/EmployeeLeaveTableContainer";

import {
  Row,
  Col,
  Container,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

//API Service
import _EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";

//i18n
import { withTranslation } from "react-i18next";
const EmployeeLeave = props => {
  //meta title
  document.title = "HR - Employee Leave";
  const branch = "crudTbEmployeeLeave";
  //Table Column Names
  const EmployeeanchorTable = _EmployeeLeave.EmployeeanchorTable;
  const ManageranchorTable = _EmployeeLeave.ManageranchorTable;
  const HRanchorTable = _EmployeeLeave.HRanchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [Department, setDepartment] = useState(null);
  const [EmployeeList, setEmployeeList] = useState(null);
  const [Date_To, setDate_To] = useState(null);
  const [Date_From, setDate_From] = useState(null);
  const [PageSize, setPageSize] = useState(10);
  const [PageNumber, setPageNumber] = useState(0);
  //------------
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  //--------------

  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(state => state.crudTbEmployeeLeave.dataTable);
  const formOpen = useSelector(state => state.crudTbEmployeeLeave.formOpen);
  const leavecancelformOpen = useSelector(
    state => state.crudTbEmployeeLeave.leavecancelformOpen
  );
  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbEmployeeLeave.popupMessage
  );

  const initialData = async () => {
    try {
      setisLoading(true);
      if (user.Role_Id === 2) {
        const response1 = await _Department.Get_Department_DD();
        setDepartment(response1.Data.Details);
      }
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
    try {
      setisLoading(true);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      // Get the start date of the current month
      let startDate = new Date(currentYear, currentMonth, 1);
      startDate = moment(startDate).format("YYYY-MM-DD");
      setDate_From(startDate);
      // Get the end date of the current month
      let endDate = new Date(currentYear, currentMonth + 1, 0);
      endDate = moment(endDate).format("YYYY-MM-DD");
      setDate_To(endDate);
      setSelectedEmployee(user.Employee_Id);
      if (user.Role_Id === 3) {
        const response = await _EmployeeLeave.Get_EmployeeLeave__Manager(
          user.Employee_Id
        );
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
      } else {
        const data = {
          PageNumber: 1,
          PageSize: 10,
          Employee_Id: user.Employee_Id,
          From_Date: startDate,
          To_Date: endDate,
        };
        const response = await _EmployeeLeave.Get_EmployeeLeave_List(data);
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
        setSelectedDepartment(user.Department_Id);
        handleDepartmentChange(user.Department_Id, true);
        setSelectedEmployee(user.Employee_Id);
      }

      setisLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setInitialList([]);
      setTotalRecords(0);
      dispatch(setData({}, branch));
      setisLoading(false);
    }
  };

  const handleDepartmentChange = async (value, InitialRun) => {
    setisSpinnerLoaderEmployee(true);
    setSelectedDepartment(value);
    setSelectedEmployee(null);
    setSelectedOption("");
    try {
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      const data = response.Data.Details;
      const emp_list = [];
      data.map(item => {
        const obj = {
          value: item.Value,
          label: item.Text + " - " + item.EmpCode,
        };
        emp_list.push(obj);
      });
      if (InitialRun) {
        const index = emp_list.findIndex(item => {
          return item.value == user.Employee_Id;
        });
        if (index !== -1) {
          setSelectedOption(emp_list[index]);
          setSelectedEmployee(user.Employee_Id);
        }
      }
      setEmployeeList(emp_list);
      setisSpinnerLoaderEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderEmployee(false);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const handleSearch = async () => {
    try {
      setisLoading(true);
      setPageSize(10);
      setPageNumber(0);
      const data = {
        PageNumber: 1,
        PageSize: 10,
        Employee_Id: user.Role_Id === 2 ? SelectedEmployee : user.Employee_Id,
        From_Date: Date_From,
        To_Date: Date_To,
      };
      const response = await _EmployeeLeave.Get_EmployeeLeave_List(data);
      setInitialList(response.Data.Details);
      setTotalRecords(response.Data.TotalRecords);
      dispatch(setData(response.Data, branch));
      setisLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setInitialList([]);
      setTotalRecords(0);
      dispatch(setData({}, branch));
      setisLoading(false);
    }
  };

  const renderDepartment = () =>
    Department.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Employee Leave")}
          />
          {/* For Employee*/}
          {user !== null && user !== undefined && user.Role_Id === 4 ? (
            <>
              <Row>
                <Col lg="4" md="6" sm="12" style={{ marginBottom: 20 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Employee Name:
                  </span>
                  <span>{user.First_Name + " " + user.Last_Name}</span>
                </Col>
                <Col lg="8" md="6" sm="12" style={{ marginBottom: 20 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: 27,
                    }}
                  >
                    Report To:
                  </span>
                  <span>{user.Report_To_Name}</span>
                </Col>
                <Col lg="4" md="6" sm="12">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: 40,
                    }}
                  >
                    Department:
                  </span>
                  <span>{user.Department_Name}</span>
                </Col>
                <Col lg="8" md="6" sm="12">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Designation:
                  </span>
                  <span>{user.Designation_Name}</span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Start Date
                    </Label>
                    <Input
                      type="date"
                      name="Start_Date"
                      onChange={e => setDate_From(e.target.value)}
                      required
                      value={Date_From}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>End Date
                    </Label>
                    <Input
                      type="date"
                      name="End_Date"
                      onChange={e => setDate_To(e.target.value)}
                      required
                      value={Date_To}
                    />
                  </div>
                </Col>
                <Col lg="3" style={{ marginTop: 28 }}>
                  <button
                    type="button"
                    className="btn btn-success"
                    id="btn-save-event"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </>
          ) : user !== null && user !== undefined && user.Role_Id === 2 ? (
            <>
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Department
                    </Label>
                    <Input
                      type="select"
                      name="Department_Id"
                      onChange={e => handleDepartmentChange(e.target.value)}
                      required
                      value={SelectedDepartment}
                    >
                      <option key={1} value={0}>
                        Select
                      </option>
                      {Department == null || Department == undefined
                        ? ""
                        : renderDepartment()}
                    </Input>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Employee
                      <span style={{ paddingTop: 10, marginLeft: 10 }}>
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="15"
                          visible={isSpinnerLoaderEmployee}
                        />
                      </span>
                    </Label>
                    <Select
                      defaultValue={EmployeeList !== null && EmployeeList[0]}
                      isDisabled={
                        EmployeeList == null || isSpinnerLoaderEmployee
                      }
                      isSearchable={true}
                      name="color"
                      onChange={e => {
                        setSelectedEmployee(e.value);
                        setSelectedOption(e);
                      }}
                      options={EmployeeList}
                      value={SelectedOption}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          backgroundColor: "white",
                        }),
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Start Date
                    </Label>
                    <Input
                      type="date"
                      name="Start_Date"
                      onChange={e => setDate_From(e.target.value)}
                      required
                      value={Date_From}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>End Date
                    </Label>
                    <Input
                      type="date"
                      name="End_Date"
                      onChange={e => setDate_To(e.target.value)}
                      required
                      value={Date_To}
                    />
                  </div>
                </Col>
                <Col lg="3" style={{ marginTop: 28 }}>
                  <button
                    type="button"
                    className="btn"
                    style={{
                      backgroundColor: "#004a8d",
                      color: "white",
                    }}
                    disabled={SelectedEmployee == null}
                    id="btn-save-event"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          <br />
          {/* <Table columns={columns} data={data} /> */}
          {user !== null && user !== undefined && user.Role_Id === 3 ? (
            ManageranchorTable == null && initialList == null ? (
              ""
            ) : (
              <EmployeeLeaveTableContainer
                columns={ManageranchorTable}
                initialData={initialList}
                totalRecords={TotalRecords}
                customPageSize={10}
                className="custom-header-css"
                branch={branch}
                pageSize={PageSize}
                PageNumber={PageNumber}
                changePageSize={e => setPageSize(e)}
                changePageNumber={e => setPageNumber(e)}
                changeTotalRecords={e => setTotalRecords(e)}
              />
            )
          ) : user !== null && user !== undefined && user.Role_Id === 2 ? (
            HRanchorTable == null && initialList == null ? (
              ""
            ) : (
              <EmployeeLeaveTableContainer
                columns={HRanchorTable}
                initialData={initialList}
                totalRecords={TotalRecords}
                customPageSize={10}
                className="custom-header-css"
                branch={branch}
                Date_To={Date_To}
                Date_From={Date_From}
                EmployeeId={
                  user.Role_Id === 2 ? SelectedEmployee : user.Employee_Id
                }
                pageSize={PageSize}
                PageNumber={PageNumber}
                changePageSize={e => setPageSize(e)}
                changePageNumber={e => setPageNumber(e)}
                changeTotalRecords={e => setTotalRecords(e)}
              />
            )
          ) : user !== null && user !== undefined && user.Role_Id === 4 ? (
            EmployeeanchorTable == null && initialList == null ? (
              ""
            ) : (
              //Import Alert Dialogue
              <EmployeeLeaveTableContainer
                columns={EmployeeanchorTable}
                initialData={initialList}
                totalRecords={TotalRecords}
                customPageSize={10}
                className="custom-header-css"
                branch={branch}
                pageSize={PageSize}
                PageNumber={PageNumber}
                changePageSize={e => setPageSize(e)}
                changePageNumber={e => setPageNumber(e)}
                changeTotalRecords={e => setTotalRecords(e)}
              />
            )
          ) : (
            ""
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingFormHREmp branch={branch} />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {/*Leave Cancelation MODAL */}
      {leavecancelformOpen !== undefined && leavecancelformOpen !== null ? (
        leavecancelformOpen === true ? (
          <LeaveCancelationForm branch={branch} />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {/* MODAL */}
      {/* {user !== null && user !== undefined && user.Role_Id === 11 ? (
        <FloatingFormHREmp branch={branch} />
      ) : (
        <FloatingFormManager branch={branch} />
      )} */}

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EmployeeLeave);
