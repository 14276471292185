import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Leave_Report = body => {
  let url = "api/Reports/GetLeaveDetailsReport";
  return post(url, body);
};

const columns = [
  {
    name: "Employee",
    options: {
      filter: false,
    },
  },
  {
    name: "Department",
    options: {
      filter: false,
    },
  },
  {
    name: "Start Date",
    options: {
      filter: true,
    },
  },
  {
    name: "End Date",
    options: {
      filter: true,
    },
  },
  {
    name: "Leave Justification",
    options: {
      filter: true,
    },
  },
  {
    name: "Leave Type",
    options: {
      filter: true,
    },
  },
  {
    name: "Approved Remarks",
    options: {
      filter: true,
    },
  },
  {
    name: "Approved By",
    options: {
      filter: true,
    },
  },
  {
    name: "Approved On",
    options: {
      filter: true,
    },
  },
  {
    name: "Assigned To",
    options: {
      filter: true,
    },
  },
  {
    name: "Approval Status",
    options: {
      filter: false,
    },
  },
];
// const anchorTable = [
//   {
//     Header: "Date",
//     accessor: "Date",
//     hidden: false,
//   },
//   {
//     Header: "Day",
//     accessor: "Day",
//     hidden: false,
//   },
//   {
//     Header: "Shift",
//     accessor: "Shift",
//     hidden: false,
//   },
//   {
//     Header: "Date In",
//     accessor: "Date_In",
//     hidden: false,
//   },
//   {
//     Header: "Time In",
//     accessor: "Time_In",
//     hidden: false,
//   },
//   {
//     Header: "Date Out",
//     accessor: "Date_Out",
//     hidden: false,
//   },
//   {
//     Header: "Time Out",
//     accessor: "Time_Out",
//     hidden: false,
//   },
//   {
//     Header: "Late Hours",
//     accessor: "Late_Hours",
//     hidden: false,
//   },
//   {
//     Header: "Early Hours",
//     accessor: "Early_Hours",
//     hidden: false,
//   },
//   {
//     Header: "Breaks",
//     accessor: "Breaks",
//     hidden: false,
//   },
//   {
//     Header: "Overtime",
//     accessor: "Overtime",
//     hidden: false,
//   },
//   {
//     Header: "Work Hours",
//     accessor: "Work_Hours",
//     hidden: false,
//   },
//   {
//     Header: "Remarks",
//     accessor: "Remarks",
//     hidden: false,
//   },
// ];
export default {
  getLoggedInUser,
  isUserAuthenticated,
  Leave_Report,
  columns,
};
