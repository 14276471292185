import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ProductTransferHistory from "helpers/AssetManagement/ProductTransferHistory";
import Switch from "@material-ui/core/Switch";
import { RotatingLines } from "react-loader-spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _Employee from "helpers/Employee_Management/Employee";
import Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import Select from "react-select";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isSpinnerLoaderFromEmployee, setisSpinnerLoaderFromEmployee] =
    useState(false);
  const [isSpinnerLoaderToEmployee, setisSpinnerLoaderToEmployee] =
    useState(false);
  const [CurrentDate, setCurrentDate] = useState(null);
  const [TransferredFromEmployee, setTransferredFromEmployee] = useState(null);
  const [TransferredToEmployee, setTransferredToEmployee] = useState(null);
  //Data Lists
  const [EmployeeList1, setEmployeeList1] = useState(null);
  const [EmployeeList2, setEmployeeList2] = useState(null);
  const [DepartmentList, setDepartmentList] = useState([]);

  const formOpen = useSelector(
    state => state.crudTbProductTransferHistory.formOpen
  );
  const EmployeeAssets = useSelector(
    state => state.crudTbProductTransferHistory.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbProductTransferHistory.formValues
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
    setisLoading(true);
    if (formValues != null) {
      ProductTransferHistory.Get_Product_Transfer_History_By_Id(
        formValues.Med_Id
      ).then(response => {
        const data = response.Data.Details[0];
        setIsEdit(true);
        setInitialFormData(data);
        setProcedureDate(data.Procedure_Date.split("T")[0]);
        setIsActiveCheck(data.IsActive);
        setHRApprovalStatus(data.HRStatus_Id);
        setManagerApprovalStatus(data.ManagerStatus_Id);
        setRefundedOnDate(data.Refunded_On.split("T")[0]);
        setisLoading(false);
      });
    } else {
      setIsEdit(false);
      setisLoading(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const initialData = async () => {
    try {
      const departments = await Department.Get_Department_DD();
      setDepartmentList(departments.Data.Details);
    } catch (error) {
      // dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setCurrentDate(currentdate);
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Category_Id: isEdit ? InitialFormData.Emp_Fam_Id : "",
      Product_Name: isEdit ? InitialFormData.Product_Name : "",
      Remarks: isEdit ? InitialFormData.Remarks : "",
      Quantity: isEdit ? InitialFormData.Quantity : "",
      Condition: isEdit ? InitialFormData.Condition : "",
      Cost: isEdit ? InitialFormData.Cost : "",
      Description: isEdit ? InitialFormData.Description : "",
    },
    validationSchema: Yup.object({
      Category_Id: Yup.number()
        .required("Please Select a Category")
        .min(1, "Please Select a Category"),
      Product_Name: Yup.string().required("Please enter product name."),
      Remarks: Yup.string().required("Please enter Remarks."),
      Condition: Yup.string().required("Please enter Condition."),
      Quantity: Yup.number()
        .required("Please enter Quantity")
        .min(1, "Quantity cannot be zero"),
      Cost: Yup.number()
        .required("Please enter Cost")
        .min(1, "Cost cannot be zero"),
      Description: Yup.string().required("Please enter a Description"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body;
        if (user.Role_Id === 2) {
          body = {
            Asset_Id: InitialFormData.Asset_Id,
            IsActive: true,
            User: user.Employee_Id,
          };
        } else {
          body = {
            Asset_Id: InitialFormData.Asset_Id,
            IsActive: true,
            User: user.Employee_Id,
          };
        }

        const response =
          await ProductTransferHistory.Edit_Product_Transfer_History(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Asset_Id: 0,
          IsActive: true,
          User: user.Employee_Id,
        };
        const response =
          await ProductTransferHistory.Save_Product_Transfer_History(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const handleDepartmentChange1 = async value => {
    try {
      setisSpinnerLoaderFromEmployee(true);
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const data = response.Data.Details;
        const emp_list = [];
        data.map(item => {
          const obj = {
            value: item.Value,
            label: item.Text + " - " + item.EmpCode,
          };
          emp_list.push(obj);
        });
        setEmployeeList1(emp_list);
      }
      setisSpinnerLoaderFromEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderFromEmployee(false);
    }
  };

  const handleDepartmentChange2 = async value => {
    try {
      setisSpinnerLoaderToEmployee(true);
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const data = response.Data.Details;
        const emp_list = [];
        data.map(item => {
          const obj = {
            value: item.Value,
            label: item.Text + " - " + item.EmpCode,
          };
          emp_list.push(obj);
        });
        setEmployeeList2(emp_list);
      }
      setisSpinnerLoaderToEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderToEmployee(false);
    }
  };

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit
          ? "Edit Product Transfer History"
          : "Add Product Transfer History"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <h5 style={{ textAlign: "left" }}>Transferred From:</h5>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={e => handleDepartmentChange1(e.target.value)}
                  required
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Department == null || Department == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderFromEmployee}
                    />
                  </span>
                </Label>
                <Select
                  defaultValue={EmployeeList1 !== null && EmployeeList1[0]}
                  isDisabled={
                    EmployeeList1 == null ||
                    isSpinnerLoaderFromEmployee === true
                  }
                  isSearchable={true}
                  onChange={e => setTransferredFromEmployee(e.value)}
                  name="color"
                  options={EmployeeList1}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </div>
            </Col>
            <Col className="col-12">
              <h5 style={{ textAlign: "left" }}>Transferred To:</h5>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={e => handleDepartmentChange2(e.target.value)}
                  required
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Department == null || Department == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderToEmployee}
                    />
                  </span>
                </Label>
                <Select
                  defaultValue={EmployeeList2 !== null && EmployeeList2[0]}
                  isDisabled={
                    EmployeeList2 == null || isSpinnerLoaderToEmployee === true
                  }
                  isSearchable={true}
                  onChange={e => setTransferredToEmployee(e.value)}
                  name="color"
                  options={EmployeeList2}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Product Name
                </Label>
                <Input
                  name="Product_Name"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Product_Name || ""}
                  invalid={
                    validation.touched.Product_Name &&
                    validation.errors.Product_Name
                      ? true
                      : false
                  }
                />
                {validation.touched.Product_Name &&
                validation.errors.Product_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Product_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Quantity
                </Label>
                <Input
                  name="Quantity"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Quantity || ""}
                  invalid={
                    validation.touched.Quantity && validation.errors.Quantity
                      ? true
                      : false
                  }
                />
                {validation.touched.Quantity && validation.errors.Quantity ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Quantity}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Remarks
                </Label>
                <Input
                  name="Remarks"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Remarks || ""}
                  invalid={
                    validation.touched.Remarks && validation.errors.Remarks
                      ? true
                      : false
                  }
                />
                {validation.touched.Remarks && validation.errors.Remarks ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Remarks}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Transfer Date
                </Label>
                <Input
                  type="date"
                  name="Transfer_Date"
                  required
                  // onChange={e => setProcedureDate(e.target.value)}
                  // value={ProcedureDate}
                  max={CurrentDate}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
