import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Encryption from "helpers/Encryption/Encryption";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import Loading from "components/Loading";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { login, loginUser, login_fail } from "../../store/actions";

const Login = props => {
  //meta title
  document.title = "Authentication";
  const [isLoading, setisLoading] = useState(false);
  const [Open, setOpen] = useState(false);
  const [Password, setPassword] = useState(false);
  const { notifMsg } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: Password,
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (Password === "HkI&8r0@u3Fl6TRm") {
        localStorage.setItem("MasterAuth", Encryption.encrypt_url(Password));
        setisLoading(false);
      } else {
        setOpen(true);
        setisLoading(false);
        setPassword("");
      }
    },
  });

  if (localStorage.getItem("MasterAuth") === Encryption.encrypt_url(Password)) {
    console.log("inside");
    window.location.href = "/dashboard";
    // navigate("/login");
  }

  const onCloseClick = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {/* <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to HR Portal.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div> */}
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <h4
                      style={{
                        textAlign: "center",
                        margin: 20,
                      }}
                    >
                      Enter Password To Proceed
                    </h4>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label for="DOB">
                          <span style={{ color: "red" }}>* </span>Enter Password
                        </Label>
                        <div
                          style={{
                            position: "relative",
                            marginBottom: 10,
                            display: "flex",
                          }}
                        >
                          <Input
                            name="password"
                            type="password"
                            value={validation.values.password || Password || ""}
                            placeholder="Enter Password"
                            onChange={e => {
                              validation.handleChange;
                              setPassword(e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          type="submit"
                          style={{
                            backgroundColor: "#004a8d",
                            color: "white",
                          }}
                        >
                          Proceed
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div>
          <Dialog
            open={Open}
            onClose={onCloseClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: 16,
                textDecoration: "underline solid black 2px",
              }}
            >
              <span style={{ color: "black" }}>Authentication Error</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  minWidth: 300,
                  maxWidth: 400,
                  color: "black",
                }}
              >
                Invalid Password
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onCloseClick}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  backgroundColor: "#3f51b5",
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
