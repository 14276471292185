import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageNotFound from "pages/Utility/PageNotFound";
import { check } from "prettier";

function AuthorizationFragment(props) {
  const { children } = props;
  const [isSubmenuFound, setIsSubmenuFound] = React.useState(false);

  const location = useLocation();
  const menu = useSelector(state => state.Login.Menu);
  const unAuthorizedList = ["/master/currency_rate/add_currency_rate"];

  const checkSubmenu = async () => {
    unAuthorizedList.map(item => {
      if (item === location.pathname) {
        setIsSubmenuFound(true);
        return;
      }
    });
    for (const values of menu) {
      for (const { link } of values.child) {
        if (link === location.pathname) {
          setIsSubmenuFound(true);
          return;
        }
      }
    }
  };

  React.useEffect(() => {
    checkSubmenu();
  }, [menu, location.pathname]);

  if (location.pathname === "/dashboard") {
    return (
      <div>
        <React.Fragment>{children}</React.Fragment>
      </div>
    );
  } else {
    return (
      <div>
        {isSubmenuFound === true ? (
          <React.Fragment>{children}</React.Fragment>
        ) : (
          <PageNotFound />
        )}
      </div>
    );
  }
}

export default AuthorizationFragment;
