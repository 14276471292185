import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDashboardAttendanceId } from "store/actions";
import Loading from "components/Loading";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import Attendance from "helpers/Employee_Management/Attendance";

const AttendanceDetailsPopup = props => {
  const { Id } = props;
  const dispatch = useDispatch();

  const [AttendanceData, setAttendanceData] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const getAttendance = async () => {
    setisLoading(true);
    const response = await Attendance.Get_EmployeeAttendance_By_Id(Id);
    if (response.IsSuccess === true && response.Status_Code === 200) {
      setAttendanceData(response.Data.Details[0]);
      // console.log(response.Data.Details[0]);
    } else {
      setErrorMessage("No Data Found.");
    }
    setisLoading(false);
  };

  useEffect(() => {
    getAttendance();
  }, [Id]);

  const onCloseClick = () => {
    dispatch(setDashboardAttendanceId(null));
  };

  return (
    <div>
      {/* <Dialog
        open={Id == null ? false : true}
        onClose={onCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 1 }}
      >
        <DialogTitle
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: 16,
            textDecoration: "underline solid black 2px",
          }}
        >
          <span style={{ color: "black" }}>Attendance Details</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ minWidth: 300, maxWidth: 400, color: "black" }}
          >
            {AttendanceData === true ? (
              renderDetails()
            ) : ErrorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCloseClick}
            style={{
              color: "black",
              fontWeight: "bold",
              backgroundColor: "#3f51b5",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
      <Modal isOpen={Id} centered style={{ zIndex: 1 }}>
        <ModalHeader
          toggle={onCloseClick}
          tag="h5"
          className="py-3 px-4 border-bottom-0"
        >
          Attendance Details
        </ModalHeader>
        <ModalBody className="p-4">
          <Row>
            <Col lg={"12"}>
              <div className="mb-3">
                <Label className="form-label">Employee Name</Label>
                <Input
                  type="text"
                  name="Employee_Family_Id"
                  disabled
                  value={AttendanceData.Employee_Name}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={"6"}>
              <div className="mb-3">
                <Label className="form-label">Date</Label>
                <Input
                  type="text"
                  name="Employee_Family_Id"
                  disabled
                  value={
                    AttendanceData ? AttendanceData.Date.split("T")[0] : ""
                  }
                />
              </div>
            </Col>
            <Col lg={"6"}>
              <div className="mb-3">
                <Label className="form-label">Day</Label>
                <Input
                  type="text"
                  name="Employee_Family_Id"
                  disabled
                  value={AttendanceData.Day}
                />
              </div>
            </Col>
          </Row>

          {AttendanceData.IsOnleave === false &&
          AttendanceData.Holiday === false ? (
            <Row>
              <Col lg={"6"}>
                <div className="mb-3">
                  <Label className="form-label">Time In</Label>
                  <Input
                    type="text"
                    name="Employee_Family_Id"
                    disabled
                    value={
                      AttendanceData
                        ? AttendanceData.Time_In === "00:00:00"
                          ? ""
                          : moment(AttendanceData.Time_In, "HH:mm").format(
                              "hh:mm A"
                            )
                        : ""
                    }
                  />
                </div>
              </Col>
              <Col lg={"6"}>
                <div className="mb-3">
                  <Label className="form-label">Time Out</Label>
                  <Input
                    type="text"
                    name="Employee_Family_Id"
                    disabled
                    value={
                      AttendanceData
                        ? AttendanceData.Time_Out === "00:00:00"
                          ? ""
                          : moment(AttendanceData.Time_Out, "HH:mm").format(
                              "hh:mm A"
                            )
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>
          ) : AttendanceData.IsOnleave === false &&
            AttendanceData.Holiday === true ? (
            <Row>
              <Col lg={"12"}>
                <div className="mb-3">
                  <Input
                    type="text"
                    name="Employee_Family_Id"
                    disabled
                    value={"HOLIDAY"}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={"12"}>
                <div className="mb-3">
                  <Input
                    type="text"
                    name="Employee_Family_Id"
                    disabled
                    value={"LEAVE"}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={onCloseClick}
              >
                Close
              </button>
            </Col>
          </Row>
        </ModalBody>

        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
              zIndex: 999,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

AttendanceDetailsPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AttendanceDetailsPopup;
