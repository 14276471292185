import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import _validation from "helpers/validation";

const floatingForm = props => {
  const { branch } = props;

  const [isLoading, setisLoading] = useState(false);
  const [Start_Date, setStart_Date] = useState(null);
  const [End_Date, setEnd_Date] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);

  const formOpen = useSelector(
    state => state.crudTbEmployeeEmployerRecord.formOpen
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  useEffect(() => {
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // Employer_Name: "",
      // Job_Description: "",
    },
    validationSchema: Yup.object({
      Employer_Name: Yup.string().required("Please Enter Employer Name"),
      Job_Description: Yup.string().required("Please Enter Job Description"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      const value = {
        ...values,
        Start_Date: Start_Date,
        End_Date: End_Date,
      };
      dispatch(addNewItem(value, branch));
      setisLoading(false);
      dispatch(setPopupMessageValues("Added", branch));
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {"Add Employer Record"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Company Name</Label>
                <Input
                  name="Employer_Name"
                  type="text"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Employer_Name || ""}
                  maxLength={255}
                  invalid={
                    validation.touched.Employer_Name &&
                    validation.errors.Employer_Name
                      ? true
                      : false
                  }
                  onKeyPress={event => {
                    _validation.alphaNumeric(event);
                  }}
                />
                {validation.touched.Employer_Name &&
                validation.errors.Employer_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Employer_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Job Description</Label>
                <Input
                  name="Job_Description"
                  type="text"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  maxLength={255}
                  value={validation.values.Job_Description || ""}
                  invalid={
                    validation.touched.Job_Description &&
                    validation.errors.Job_Description
                      ? true
                      : false
                  }
                  onKeyPress={event => {
                    _validation.alphaNumeric(event);
                  }}
                />
                {validation.touched.Job_Description &&
                validation.errors.Job_Description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Job_Description}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Start Date
                </Label>
                <Input
                  type="date"
                  name="Start_Date"
                  required
                  onChange={e => setStart_Date(e.target.value)}
                  value={Start_Date}
                  max={currentDate}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>End Date
                </Label>
                <Input
                  type="date"
                  name="End_Date"
                  required
                  onChange={e => setEnd_Date(e.target.value)}
                  value={End_Date}
                  max={currentDate}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
