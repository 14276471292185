import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MedicalRequests from "helpers/Medical/MedicalRequests";
import _Employee from "helpers/Employee_Management/Employee";
import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import _Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import queryString from "query-string";
import Encryption from "helpers/Encryption/Encryption";
import _validation from "helpers/validation";
import DialogPopup from "components/Common/DialogPopup";
import ApprovalHistoryTable from "./ApprovalHistoryTable";
import MedicalRequestsTable from "./MedicalRequestsTable";
import {
  addNewItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Container,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import { Typography } from "@material-ui/core";

const MedicalRequestView = props => {
  const branch = "crudTbMedicalRequests";
  //meta title
  document.title = "HR - Medical Request Details";

  const [InitialFormData, setInitialFormData] = useState(null);
  const [TransactionHistory, setTransactionHistory] = useState(null);
  const [MedicalTransactions, setMedicalTransactions] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  // const [ProcedureDate, setProcedureDate] = useState(null);
  const [RefundedOnDate, setRefundedOnDate] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);

  const popupMessage = useSelector(
    state => state.crudTbMedicalRequests.popupMessage
  );
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialData = async () => {
    try {
      setisLoading(true);
      await CheckEditScreen();
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const CheckEditScreen = async () => {
    setisLoading(true);
    let params = queryString.parse(location.search);
    const encryupteId = params.q;
    if (encryupteId !== undefined && encryupteId !== null) {
      const leaveId = Encryption.decrypt_url(encryupteId);
      params = queryString.parse(leaveId);
    }
    const Med_Req_Id = params.id;
    if (Med_Req_Id) {
      try {
        const response1 = await MedicalRequests.Get_Medical_Request_By_Id(
          Med_Req_Id
        );
        const data = response1.Data.Details;
        setInitialFormData(data);
        setTransactionHistory(data.Transactions_Approval);
        setMedicalTransactions(data.Transactions);
        // setProcedureDate(data.Procedure_Date.split("T")[0]);
        setRefundedOnDate(
          data.Refunded_On !== null ? data.Refunded_On.split("T")[0] : null
        );
      } catch (error) {
        dispatch(setPopupMessageValues("Opps! Server is down.", branch));
        console.log(error);
      }
    }
    setisLoading(false);
  };

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Medical Request Details
        </Typography>
        <Container fluid>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Employee Name</Label>
                <Input
                  type="text"
                  name="employee_name"
                  required
                  disabled
                  value={
                    InitialFormData == null ? "" : InitialFormData.EmployeeName
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Employee Code</Label>
                <Input
                  type="text"
                  name="employee_Code"
                  required
                  disabled
                  value={
                    InitialFormData == null ? "" : InitialFormData.EmployeeCode
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Department</Label>
                <Input
                  type="text"
                  name="employee_department"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Employee_department
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Designation</Label>
                <Input
                  type="text"
                  name="employee_Designation"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Employee_designation
                  }
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  Employee Family
                </Label>
                <Input
                  type="text"
                  name="Employee_Family_Name"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Employee_Family_Name
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  Medical Procedure
                </Label>
                <Input
                  type="text"
                  name="Medical_Procedure_Name"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Medical_Procedure_Name
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  Date
                </Label>
                <Input
                  type="date"
                  name="ProcedureDate"
                  required
                  disabled
                  onChange={e => setProcedureDate(e.target.value)}
                  value={ProcedureDate}
                  max={currentDate}
                />
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Total Claim Amount</Label>
                <Input
                  type="text"
                  name="Claim_Amount"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Claim_Amount.toLocaleString()
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Total Refunded Amount</Label>
                <Input
                  type="text"
                  name="Refunded_Amount"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Refunded_Amount.toLocaleString()
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Refund Date</Label>
                <Input
                  type="text"
                  name="Refunded_Amount"
                  required
                  disabled
                  value={
                    RefundedOnDate == null
                      ? ""
                      : Moment(RefundedOnDate).format("DD-MMM-YYYY")
                  }
                />
              </div>
            </Col>
          </Row>

          {InitialFormData !== null &&
          InitialFormData.Status_Name !== "Completed" ? (
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Currently Assigned To</Label>
                  <Input
                    type="text"
                    name="assigned_to_name"
                    required
                    disabled
                    value={
                      InitialFormData == null ? "" : InitialFormData.AssignedTo
                    }
                  />
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <br />
          <br />
          {/* Medical Requests Table */}
          <Typography variant="h6">Medical Requests</Typography>
          <MedicalRequestsTable DataList={MedicalTransactions} />

          <br />
          <br />
          {/* Approval History Table */}
          <Typography variant="h6">Approval History</Typography>
          <ApprovalHistoryTable DataList={TransactionHistory} />
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

MedicalRequestView.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default MedicalRequestView;
