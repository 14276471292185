import React, { Fragment, useState, useCallback, useEffect } from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Encryption from "helpers/Encryption/Encryption";
import user1 from "../../assets/images/users/avatar-1.jpg";

import {
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setPopupMessageValues,
  openLeaveCancelationForm,
  updateItem,
} from "store/actions";
import del from "helpers/del";
import changeActiveStatus from "helpers/changeActiveStatus";
import tableData from "helpers/tableData";
import DeleteModal from "./DeleteModal";
import StatusChangeModal from "./StatusChangeModal";

const TableContainer = props => {
  const {
    columns,
    initialData,
    totalRecords,
    className,
    branch,
    Date_To,
    Date_From,
    EmployeeId,
  } = props;
  const navigate = useNavigate();

  // const findSort = () => {
  //   let sort = "";
  //   for (const ini of columns) {
  //     if (ini.IsSort === true) {
  //       sort = ini.Header;
  //       break;
  //     }
  //   }
  //   return sort;
  // };

  const [DataList, setDataList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [DataToDelete, setDataToDelete] = useState(null);
  const [changeStatusModal, setchangeStatusModal] = useState(false);
  const [StatusChangeItem, setStatusChangeItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [TotalNoOfRows, setTotalNoOfRows] = React.useState(0);
  const [IsAddButton, setIsAddButton] = React.useState(false);

  //Search and Sort
  const [rows, setRows] = React.useState([]);
  const [search, setsearch] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const checkAddButton = () => {
    const add = columns.filter(item => {
      return item.accessor === "Action" && item.add === true;
    });
    if (add.length > 0) {
      setIsAddButton(true);
    } else {
      setIsAddButton(false);
    }
  };

  //Search and Sort
  const requestSearch = async searchedVal => {
    const searchWord = searchedVal;
    let filterData = [];
    const newFilter = Object.keys(DataList).reduce((result, key) => {
      let checkData = false;
      Object.keys(DataList[key]).forEach(function (keys) {
        let textdata = DataList[key][keys] + "";
        if (textdata.toLowerCase().includes(searchWord.toLowerCase())) {
          checkData = true;
        }
      });

      if (checkData) {
        filterData.push(DataList[key]);
      }
    }, []);

    setRows(filterData);
    // setDataList(filterData);
  };

  React.useEffect(() => {
    requestSearch("");
    setsearch("");
  }, [DataList, initialData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };
  //Search and Sort

  React.useEffect(() => {
    if (initialData != null) {
      checkAddButton();
      setDataList(initialData);
      setTotalNoOfRows(totalRecords);
    }
  }, [initialData]);

  const EditButtons = item => {
    switch (branch) {
      case "crudTbProfile":
        return (
          <Link
            to={`/profile_management/add_profile?q=${Encryption.encrypt_url(
              "id=" + item.Profile_Id
            )}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      case "crudTbEmployeeList":
        return (
          <Link
            to={`/employee_management/employee_addition?q=${Encryption.encrypt_url(
              "id=" + item.Employee_Id
            )}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      case "crudTbSalaryProfileList":
        return (
          <Link
            to={`/salary_management/salary_profile_addition?q=${Encryption.encrypt_url(
              "id=" + item.Salary_Profile_Id
            )}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      case "crudTbEmployeeLeave":
        return (
          <Link
            to={`/employee_management/employee_leave_view?q=${Encryption.encrypt_url(
              "id=" + item.EmployeeLeave_Id
            )}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      case "crudTbCurrencyRate":
        return (
          <Link
            to={`/master/currency_rate/add_currency_rate?q=${Encryption.encrypt_url(
              "id=" + item.CurrencyRate_Id
            )}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      default:
        return (
          <Button
            color="red"
            size="sm"
            onClick={e => {
              handleEdit(item);
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>
        );
    }
  };

  const handleChangeRowsPerPage = event => {
    const totalRows = event.target.value;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onPageRowChange(totalRows, 0);
  };
  const handleChangePage = (event, newPage) => {
    onPageRowChange(rowsPerPage, newPage);
    setPage(newPage);
  };
  const onPageRowChange = async (totalRows, currentPage) => {
    currentPage++;
    setisLoading(true);
    let TableData_response = null;
    if (branch === "crudTbEmployeeLeave") {
      TableData_response = await tableData.get_EmployeeLeave_data(
        currentPage,
        totalRows,
        EmployeeId,
        Date_From,
        Date_To
      );
    } else if (branch === "crudTbHREmployeeAttendance") {
      TableData_response = await tableData.get_HREmployeeAttendance_data(
        currentPage,
        totalRows,
        EmployeeId,
        Date_From,
        Date_To
      );
    } else if (branch === "crudTbEmployeeFamily") {
      TableData_response = await tableData.get_employee_family_data(
        currentPage,
        totalRows,
        EmployeeId
      );
    } else {
      TableData_response = await tableData.tableData(
        branch,
        currentPage,
        totalRows
      );
    }

    if (
      TableData_response.IsSuccess === true &&
      TableData_response.Status_Code === 200
    ) {
      // dispatch(setData(TableData_response.Data));
      dispatch(setData(TableData_response.Data, branch));
      setDataList(TableData_response.Data.Details);
      setTotalNoOfRows(TableData_response.Data.TotalRecords);
      setisLoading(false);
    } else {
      console.log(TableData_response.Status_Message);
      setisLoading(false);
    }
  };
  const addNewItem = () => {
    if (branch === "crudTbEmployeeList") {
      navigate("/employee_management/employee_addition");
    } else if (branch === "crudTbProfile") {
      navigate("/profile_management/add_profile");
    } else if (branch === "crudTbSalaryProfileList") {
      navigate("/salary_management/salary_profile_addition");
    } else if (branch === "crudTbCurrencyRate") {
      navigate("/master/currency_rate/add_currency_rate");
    } else {
      dispatch(toggleForm(true, branch));
    }
  };

  const handleView = item => {
    // dispatch(toggleForm(true, branch));
  };

  const handleEdit = item => {
    dispatch(setFormValues(item, branch));
    dispatch(toggleForm(true, branch));
  };

  const eventDel = useCallback(
    async item => {
      setisLoading(true);
      const del_response = await del.delAction(branch, item, user.Employee_Id);
      if (del_response.IsSuccess === true && del_response.Status_Code === 200) {
        setdeleteModal(false);
        dispatch(removeItem(item, branch));
        setisLoading(false);
        dispatch(setPopupMessageValues(del_response.Status_Message, branch));
      } else {
        setdeleteModal(false);
        dispatch(setPopupMessageValues(del_response.Status_Message, branch));
        setisLoading(false);
      }
    },
    [branch]
  );

  const eventChangeStatus = useCallback(
    async item => {
      setisLoading(true);
      setchangeStatusModal(false);
      const response = await changeActiveStatus.changeActiveStatus(
        branch,
        item,
        user.Employee_Id
      );
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setdeleteModal(false);
        dispatch(updateItem(response.Data.Details[0], branch));
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        setdeleteModal(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
        setisLoading(false);
      }
    },
    [branch]
  );

  const handleRowDelete = row => {
    if (branch === "crudTbEmployeeLeave") {
      dispatch(openLeaveCancelationForm(true, branch));
      dispatch(setFormValues(row, branch));
    } else {
      setdeleteModal(true);
      setDataToDelete(row);
    }
  };

  const renderRows = () =>
    // stableSort(rows, getComparator(order, orderBy))
    DataList.map((row, index1) => {
      return (
        <tr key={index1}>
          {columns.map((cell, index) => {
            return (
              cell.hidden === false && (
                <td
                  key={index}
                  // width={cell.width !== undefined && cell.width}
                  style={{
                    margin: 0,
                    width: cell.width !== undefined ? cell.width : 300,
                  }}
                >
                  {cell.accessor === "Action" ? (
                    <div>
                      {cell.view === true ? (
                        <Button
                          color="red"
                          size="sm"
                          onClick={e => {
                            handleView(row);
                          }}
                        >
                          <i className="fas fa-eye"></i>
                        </Button>
                      ) : (
                        " "
                      )}
                      {cell.edit === true ? EditButtons(row) : ""}
                      {/* {cell.edit === true && row["IsEditable"] === true
                        ? EditButtons(row)
                        : ""} */}
                      {cell.delete === true ? (
                        branch === "crudTbEmployeeLeave" &&
                        row["ApprovalStatus_Name"] === "PENDING" ? (
                          <Button
                            color="red"
                            size="sm"
                            onClick={e => {
                              // eventDel(row);
                              handleRowDelete(row);
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        ) : branch === "crudTbEmployeeLeave" &&
                          row["ApprovalStatus_Name"] !== "Pending" ? (
                          ""
                        ) : (
                          <Button
                            color="red"
                            size="sm"
                            onClick={e => {
                              // eventDel(row);
                              handleRowDelete(row);
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        )
                      ) : (
                        ""
                      )}
                      {/* {cell.delete === true && row["IsEditable"] === true ? (
                        <Button
                          color="red"
                          size="sm"
                          onClick={e => {
                            // eventDel(row);
                            handleRowDelete(row);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      ) : (
                        ""
                      )} */}
                    </div>
                  ) : cell.accessor === "IsActive" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          width: 80,
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                        onClick={() => {
                          setchangeStatusModal(true);
                          setStatusChangeItem(row);
                        }}
                      >
                        ACTIVE
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          width: 80,
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setchangeStatusModal(true);
                          setStatusChangeItem(row);
                        }}
                      >
                        In Active
                      </Button>
                    )
                  ) : cell.accessor === "IsDependent" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 40,
                          margin: "auto",
                          borderRadius: 5,
                          padding: 0,
                          zIndex: 9999,
                        }}
                      >
                        Yes
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          width: 40,
                          margin: "auto",
                          pointerEvents: "none",
                          borderRadius: 5,
                          padding: 0,
                        }}
                      >
                        No
                      </Button>
                    )
                  ) : cell.accessor === "ApprovalStatus_Name" ? (
                    row[cell.accessor] == "Approved" ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        APPROVED
                      </Button>
                    ) : row[cell.accessor] == "Completed" ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        COMPLETED
                      </Button>
                    ) : row[cell.accessor] == "Cancelled" ? (
                      <Button
                        type="button"
                        color="secondary"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        CANCELLED
                      </Button>
                    ) : row[cell.accessor] == "Pending" ? (
                      <Button
                        type="button"
                        color="warning"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        PENDING
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        REJECTED
                      </Button>
                    )
                  ) : cell.accessor === "Full_Name" ? (
                    <span style={{ textAlign: "center" }}>
                      <img
                        className="rounded-circle header-profile-user"
                        src={user1}
                        alt="Header Avatar"
                        style={{ marginRight: 5 }}
                      />
                      {row[cell.accessor]}
                    </span>
                  ) : cell.accessor === "Time_In" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(
                        row[cell.accessor].split(".")[0],
                        "HH:mm:ss"
                      ).format("h:mm A")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "Time_Out" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(
                        row[cell.accessor].split(".")[0],
                        "HH:mm:ss"
                      ).format("h:mm A")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "Date" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(row[cell.accessor]).format("MMM DD, YYYY")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "From_Date" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(row[cell.accessor]).format("MMM DD, YYYY")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "To_Date" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(row[cell.accessor]).format("MMM DD, YYYY")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "indexing" ? (
                    index1 + 1 + page * rowsPerPage
                  ) : cell.accessor === "Holiday" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{ pointerEvents: "none" }}
                      >
                        Holiday
                      </Button>
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "Icon" ? (
                    <Button
                      type="button"
                      className="btn-rounded  mb-1 me-1"
                      style={{
                        pointerEvents: "none",
                        backgroundColor: "#004a8d",
                        color: "white",
                      }}
                    >
                      <i className={row[cell.accessor]}></i>
                    </Button>
                  ) : cell.accessor === "SrNo" ? (
                    index1 + 1 + page * rowsPerPage
                  ) : (
                    row[cell.accessor]
                  )}
                </td>
              )
            );
          })}
        </tr>
      );
    });

  //-----------
  return (
    <Fragment>
      <Row className="mb-2">
        <Col lg="3" sm="4" xs="7">
          <div
            className="position-relative"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* <span className="bx bx-search-alt" style={{ fontSize: 30 }} /> */}
            {/* <input
              type="text"
              className="form-control"
              placeholder={"Search on page" + "..."}
              // onChange={e => handleSearch(e)}
              value={search}
              onChange={searchVal => {
                requestSearch(searchVal.target.value);
                setsearch(searchVal.target.value);
              }}
            /> */}
          </div>
        </Col>

        {IsAddButton === true ? (
          <Col lg="9" sm="8" xs="5">
            <div className="text-sm-end" style={{ marginTop: 5 }}>
              <Button
                type="button"
                // color="success"
                style={{
                  backgroundColor: "#004a8d",
                }}
                className="btn-rounded  mb-2 me-2"
                onClick={addNewItem}
              >
                <i className="mdi mdi-plus me-1" />
                Add New
              </Button>
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
          <TableHead className="table-light table-nowrap">
            <tr>
              {columns.map((headerGroup, index) =>
                headerGroup.hidden === false ? (
                  <th key={index}>
                    {/* <TableSortLabel 
                     active={orderBy === headerGroup.Header}
                     direction={orderBy === headerGroup.Header ? order : "asc"}
                   onClick={createSortHandler(headerGroup.Header)}
                    >
                  {headerGroup.Header}
                    </TableSortLabel> */}
                    {headerGroup.Header}
                  </th>
                ) : (
                  ""
                )
              )}
            </tr>
          </TableHead>

          <tbody>
            {DataList !== null ? (
              renderRows()
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {DataList != null && TotalNoOfRows != null ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={TotalNoOfRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => eventDel(DataToDelete)}
        onCloseClick={() => setdeleteModal(false)}
        isLoading={isLoading}
      />
      <StatusChangeModal
        show={changeStatusModal}
        onConfirmClick={() => eventChangeStatus(StatusChangeItem)}
        onCloseClick={() => setchangeStatusModal(false)}
        isLoading={isLoading}
      />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
