import {
  SET_DATA,
  ADD_NEW_ITEM,
  UPDATE_ITEM,
  REMOVE_ITEM,
  TOGGLE_FORM,
  SET_FORM_VALUES,
  SET_POP_MESSAGE_VALUES,
} from "../../crudTbFrmConstant";

const Initial_State = {
  dataTable: [],
  error: {},
  formOpen: false,
  formValues: null,
  popupMessage: null,
};

const EmployeeAddition = (state = Initial_State, action) => {
  const { branch } = action;
  switch (action.type) {
    case `${branch}/${SET_DATA}`:
      return {
        ...state,
        dataTable: action.payload,
      };

    case `${branch}/${TOGGLE_FORM}`:
      return {
        ...state,
        formOpen: action.payload,
      };

    case `${branch}/${SET_FORM_VALUES}`: {
      return {
        ...state,
        formValues: action.payload,
      };
    }

    case `${branch}/${SET_POP_MESSAGE_VALUES}`: {
      return {
        ...state,
        popupMessage: action.payload,
      };
    }

    case `${branch}/${ADD_NEW_ITEM}`: {
      let newTable = state.dataTable;
      newTable.push(action.payload);
      return {
        ...state,
        dataTable: newTable,
        formOpen: false,
        formValues: null,
      };
    }

    // case `${branch}/${UPDATE_ITEM}`: {
    //   let InstitutionName = state.dataTable;
    //   const index = InstitutionName.findIndex(
    //     item => item.Institution_Name === action.payload.Institution_Name
    //   );
    //   if (index !== -1) {
    //     InstitutionName[index] = action.payload;
    //     return {
    //       ...state,
    //       dataTable: InstitutionName,
    //       formOpen: false,
    //       formValues: null,
    //     };
    //   }
    //   break;
    // }

    case `${branch}/${REMOVE_ITEM}`: {
      const EmployerRecordList = state.dataTable;
      const index = EmployerRecordList.findIndex(
        item => item.Employer_Name === action.payload.Employer_Name
      );
      if (index !== -1) {
        return {
          ...state,
          dataTable: EmployerRecordList.filter(item => {
            return item.Employer_Name !== action.payload.Employer_Name;
          }),
        };
      }
      break;
    }
    default:
      return state;
  }
};

export default EmployeeAddition;
