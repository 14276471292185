import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _SubMenu from "helpers/Profile_Management/SubMenu";
import _Menu from "helpers/Profile_Management/Menu";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [MenuList, setMenuList] = useState(null);

  //Icon Search
  // const [openDetail, setOpenDetail] = useState(false);
  // const [iconName, setIconName] = useState("");
  // const [filterText, setFilterText] = useState("");

  const formOpen = useSelector(state => state.crudTbSubMenu.formOpen);
  const SubMenuList = useSelector(state => state.crudTbSubMenu.dataTable);
  const formValues = useSelector(state => state.crudTbSubMenu.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      const data = await _Menu.Get_Menu_DD();
      setMenuList(data.Data.Details);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  //Icon Search
  // const handleOpenDetail = name => {
  //   // setOpenDetail(true);
  //   setIconName(name);
  //   addNew(addIcon(name, branch));
  // };

  // const handleCloseDetail = () => {
  //   setOpenDetail(false);
  // };

  // const handleSearch = event => {
  //   event.persist();
  //   // Show result base on keyword
  //   setFilterText(event.target.value.toLowerCase());
  // };

  useEffect(() => {
    if (formValues != null) {
      const index = SubMenuList.findIndex(
        item => item.SubMenu_Id == formValues.SubMenu_Id
      );
      setIsEdit(true);
      setInitialFormData(SubMenuList[index]);
      setIsActiveCheck(SubMenuList[index].IsActive);
    } else {
      setIsEdit(false);
      setIsActiveCheck(true);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      SubMenu_Name: isEdit ? InitialFormData.SubMenu_Name : "",
      Menu_Id: isEdit ? InitialFormData.Menu_Id : "",
      Link: isEdit ? InitialFormData.Link : "",
      Sort: isEdit ? InitialFormData.Sort : 0,
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      SubMenu_Name: Yup.string().required("Please Enter SubMenu Name"),
      Link: Yup.string().required("Please Enter a link"),
      Sort: Yup.number().required("Please define sorting").min(1),
      Menu_Id: Yup.number()
        .required("Please select a Menu")
        .min(1, "Please select a Menu"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          SubMenu_Id: InitialFormData.SubMenu_Id,
          SubMenu_Name: values.SubMenu_Name,
          Menu_Id: values.Menu_Id,
          Link: values.Link,
          Sort: values.Sort,
          Icon: "fas fa-bars",
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _SubMenu.Edit_SubMenu(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          SubMenu_Id: 0,
          SubMenu_Name: values.SubMenu_Name,
          Menu_Id: values.Menu_Id,
          Link: values.Link,
          Sort: values.Sort,
          Icon: "fas fa-bars",
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _SubMenu.Save_SubMenu(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderMenu = () =>
    MenuList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Sub Menu" : "Add Sub Menu"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Menu
                </Label>
                <Input
                  type="select"
                  name="Menu_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Menu_Id || ""}
                  invalid={
                    validation.touched.Menu_Id && validation.errors.Menu_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {MenuList == null || MenuList == undefined
                    ? ""
                    : renderMenu()}
                </Input>
                {validation.touched.Menu_Id && validation.errors.Menu_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Menu_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>SubMenu Name
                </Label>
                <Input
                  name="SubMenu_Name"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric2(e)}
                  // value={isEdit ? InitialFormData.SubMenu_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.SubMenu_Name || ""}
                  invalid={
                    validation.touched.SubMenu_Name &&
                    validation.errors.SubMenu_Name
                      ? true
                      : false
                  }
                />
                {validation.touched.SubMenu_Name &&
                validation.errors.SubMenu_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.SubMenu_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Link
                </Label>
                <Input
                  name="Link"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric2(e)}
                  // value={isEdit ? InitialFormData.Link : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Link || ""}
                  invalid={
                    validation.touched.Link && validation.errors.Link
                      ? true
                      : false
                  }
                />
                {validation.touched.Link && validation.errors.Link ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Link}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Sort
                </Label>
                <Input
                  name="Sort"
                  type="number"
                  maxLength={3}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.Sort : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Sort || ""}
                  invalid={
                    validation.touched.Sort && validation.errors.Sort
                      ? true
                      : false
                  }
                />
                {validation.touched.Sort && validation.errors.Sort ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Sort}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
