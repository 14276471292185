import React, { useState, useEffect } from "react";
import moment from "moment";
import Loading from "components/Loading";
import { RotatingLines } from "react-loader-spinner";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
import FloatingForm from "components/Forms/Employee_Management/HREmployeeAttendance/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "../../../components/Common/TableContainer";
import HREmployeeAttendanceTableContainer from "components/Table/EmployeeManagement/HREmployeeAttendanceTableContainer";
import {
  Row,
  Col,
  Container,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

//API Service
import _EmployeeAttendance from "helpers/Employee_Management/HREmployeeAttendance";
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";

//i18n
import { withTranslation } from "react-i18next";
const EmployeeAttendance = props => {
  //meta title
  document.title = "HR - Employee Attendance";
  const branch = "crudTbHREmployeeAttendance";
  //Table Column Names
  const anchorTable = _EmployeeAttendance.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [Department, setDepartment] = useState(null);
  const [EmployeeList, setEmployeeList] = useState(null);
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [Date_To, setDate_To] = useState(null);
  const [Date_From, setDate_From] = useState(null);
  const [PageSize, setPageSize] = useState(30);
  const [PageNumber, setPageNumber] = useState(0);
  const [currentDate, setcurrentDate] = useState("");
  //------------
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  //--------------

  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);

  const dispatch = useDispatch();
  //Redux States
  const user = useSelector(state => state.Login.User);
  const initData = useSelector(
    state => state.crudTbHREmployeeAttendance.dataTable
  );
  const formOpen = useSelector(
    state => state.crudTbHREmployeeAttendance.formOpen
  );
  const popupMessage = useSelector(
    state => state.crudTbHREmployeeAttendance.popupMessage
  );

  const initialData = async () => {
    try {
      setisLoading(true);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      // Get the start date of the current month
      let startDate = new Date(currentYear, currentMonth, 1);
      startDate = moment(startDate).format("YYYY-MM-DD");
      setDate_From(startDate);
      // Set the end date to the current date
      const endDate = moment(currentDate).format("YYYY-MM-DD");
      setDate_To(endDate);
      const response1 = await _Department.Get_Department_DD();
      setDepartment(response1.Data.Details);
      handleDepartmentChange(user.Department_Id, true);
      const data = {
        PageNumber: 1,
        PageSize: 30,
        Employee_Id: user.Employee_Id,
        Department_Id: user.Department_Id,
        From_Date: startDate,
        To_Date: endDate,
      };
      const response = await _EmployeeAttendance.Get_EmployeeAttendance_List(
        data
      );
      setInitialList(response.Data.Details);
      setTotalRecords(response.Data.TotalRecords);
      dispatch(setData(response.Data, branch));
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const handleDepartmentChange = async (value, InitialRun) => {
    setisSpinnerLoaderEmployee(true);
    setSelectedDepartment(value);
    try {
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      const data = response.Data.Details;
      const emp_list = [];
      setSelectedOption("");
      setSelectedEmployee(null);
      data.map(item => {
        const obj = {
          value: item.Value,
          label: item.Text + " - " + item.EmpCode,
        };
        emp_list.push(obj);
      });
      if (InitialRun) {
        const index = emp_list.findIndex(item => {
          return item.value == user.Employee_Id;
        });
        if (index !== -1) {
          setSelectedOption(emp_list[index]);
          setSelectedEmployee(user.Employee_Id);
        }
      }
      setEmployeeList(emp_list);
      setisSpinnerLoaderEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderEmployee(false);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const handleSearch = async () => {
    try {
      setisLoading(true);
      setPageNumber(0);
      setInitialList(null);
      let data;
      if (SelectedEmployee !== null) {
        data = {
          PageNumber: 1,
          PageSize: PageSize,
          Employee_Id: SelectedEmployee,
          Department_Id: SelectedDepartment,
          From_Date: Date_From,
          To_Date: Date_To,
        };
      } else {
        data = {
          PageNumber: 1,
          PageSize: PageSize,
          Department_Id: SelectedDepartment,
          From_Date: Date_From,
          To_Date: Date_To,
        };
      }

      const response = await _EmployeeAttendance.Get_EmployeeAttendance_List(
        data
      );
      setInitialList(response.Data.Details);
      setTotalRecords(response.Data.TotalRecords);
      dispatch(setData(response.Data, branch));
      setisLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setInitialList([]);
      setTotalRecords(0);
      dispatch(setData({}, branch));
      setisLoading(false);
    }
  };

  const renderDepartment = () =>
    Department.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Employee Attendance")}
          />
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={e => handleDepartmentChange(e.target.value)}
                  required
                  value={SelectedDepartment}
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Department == null || Department == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  Employee
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderEmployee}
                    />
                  </span>
                </Label>
                <Select
                  defaultValue={EmployeeList !== null && EmployeeList[0]}
                  isDisabled={EmployeeList == null || isSpinnerLoaderEmployee}
                  isSearchable={true}
                  name="color"
                  onChange={e => {
                    setSelectedEmployee(e.value);
                    setSelectedOption(e);
                  }}
                  options={EmployeeList}
                  value={SelectedOption}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Start Date
                </Label>
                <Input
                  type="date"
                  name="Start_Date"
                  onChange={e => setDate_From(e.target.value)}
                  required
                  value={Date_From}
                  max={currentDate}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>End Date
                </Label>
                <Input
                  type="date"
                  name="End_Date"
                  onChange={e => setDate_To(e.target.value)}
                  required
                  value={Date_To}
                  max={currentDate}
                />
              </div>
            </Col>
            <Col lg="3" style={{ marginTop: 28 }}>
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#004a8d",
                  color: "white",
                }}
                id="btn-save-event"
                onClick={handleSearch}
                // disabled={SelectedEmployee == null}
              >
                Search
              </button>
            </Col>
          </Row>
          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            //HR Attendance Table
            <HREmployeeAttendanceTableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={30}
              className="custom-header-css"
              branch={branch}
              Date_To={Date_To}
              Date_From={Date_From}
              EmployeeId={SelectedEmployee}
              SelectedDepartment={
                SelectedDepartment !== null
                  ? SelectedDepartment
                  : user.Department_Id
              }
              pageSize={PageSize}
              PageNumber={PageNumber}
              changePageSize={e => setPageSize(e)}
              changePageNumber={e => setPageNumber(e)}
              changeTotalRecords={e => setTotalRecords(e)}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm
            branch={branch}
            SelectedDepartment={SelectedDepartment}
            SelectedEmployee={SelectedEmployee}
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EmployeeAttendance);
