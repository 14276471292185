import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_SalaryDisbursement_DD = (value = 0) => {
  let url = "api/Salary_Disbursement/Get_SalaryDisbursement_DropDown";

  return get(url);
};

const Get_SalaryDisbursement_List = body => {
  let url = "api/Salary_Disbursement/Get_SalaryDisbursement_List?id=0";

  return get(url, body);
};

const Get_SalaryDisbursement_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Salary_Disbursement/Get_SalaryDisbursement_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_SalaryDisbursement = body => {
  let url = "api/Salary_Disbursement/Save_SalaryDisbursement";
  return post(url, body);
};

const Edit_SalaryDisbursement = body => {
  let url = "api/Salary_Disbursement/Edit_SalaryDisbursement";
  return post(url, body);
};

const Delete_SalaryDisbursement = body => {
  let url = "api/Salary_Disbursement/Edit_SalaryDisbursement_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Srno",
    accessor: "Srno",
    hidden: false,
  },
  {
    Header: "Year",
    accessor: "Year",
    hidden: false,
  },
  {
    Header: "Month",
    accessor: "Month",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "Department",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_SalaryDisbursement_DD,
  Get_SalaryDisbursement_List,
  Get_SalaryDisbursement_List_By_Rows,
  Save_SalaryDisbursement,
  Edit_SalaryDisbursement,
  Delete_SalaryDisbursement,
  anchorTable,
};
