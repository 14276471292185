import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Project_DD = (value = 0) => {
  let url = "api/Project/Get_Project_DropDown";

  return get(url);
};

const Get_Project_List = body => {
  let url = "api/Project/Get_Project_List?id=0";

  return get(url, body);
};

const Get_Project_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Project/Get_Project_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Project = body => {
  let url = "api/Project/Save_Project";
  return post(url, body);
};

const Edit_Project = body => {
  let url = "api/Project/Edit_Project";
  return post(url, body);
};

const Delete_Project = body => {
  let url = "api/Project/Edit_Project_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
  },
  {
    Header: "Name",
    accessor: "Project_Name",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Project_DD,
  Get_Project_List,
  Get_Project_List_By_Rows,
  Save_Project,
  Edit_Project,
  Delete_Project,
  anchorTable,
};
