import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _SalaryDisbursement from "helpers/Salary_Management/SalaryDisbursement";
import _Calendar from "helpers/MasterData_Management/Calendar";
import _Department from "helpers/MasterData_Management/Department";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import { RotatingLines } from "react-loader-spinner";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [YearList, setYearList] = useState(null);
  const [MonthList, setMonthList] = useState(null);
  const [DepartmentList, setDepartmentList] = useState(null);
  const [isSpinnerLoaderMonth, setisSpinnerLoaderMonth] = useState(false);
  const [isSpinnerLoaderYear, setisSpinnerLoaderYear] = useState(false);

  const formOpen = useSelector(
    state => state.crudTbSalaryDisbursement.formOpen
  );
  const SalaryDisbursementList = useSelector(
    state => state.crudTbSalaryDisbursement.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbSalaryDisbursement.formValues
  );
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      setisSpinnerLoaderYear(true);
      const years = await _Calendar.Get_Calendar_Years();
      const yearsList = years.Data.Details;
      setYearList(yearsList);

      const response1 = await _Department.Get_Department_DD();
      setDepartmentList(response1.Data.Details);

      setisSpinnerLoaderYear(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisSpinnerLoaderYear(false);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  const getMonths = async e => {
    setisSpinnerLoaderMonth(true);
    const year = e.target.value;
    const months = await _Calendar.Get_Calendar_Months_By_Year(year);
    if (months.IsSuccess === true && months.Status_Code === 200) {
      setMonthList(months.Data.Details);
      setisSpinnerLoaderMonth(false);
    } else {
      setMonthList(null);
      setisSpinnerLoaderMonth(false);
    }
  };

  useEffect(() => {
    if (formValues != null) {
      const index = SalaryDisbursementList.findIndex(
        item => item.SalaryDisbursement_Id == formValues.SalaryDisbursement_Id
      );
      setIsEdit(true);
      setInitialFormData(SalaryDisbursementList[index]);
      setIsActiveCheck(SalaryDisbursementList[index].IsActive);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Year_Id: isEdit ? InitialFormData.Year_Id : "",
      Month_Id: isEdit ? InitialFormData.Month_Id : "",
      Department_Id: isEdit ? InitialFormData.Department_Id : "",
    },
    validationSchema: Yup.object({
      Year_Id: Yup.number()
        .required("Please select a year")
        .min(1, "Please select a year"),
      Month_Id: Yup.number()
        .required("Please select a month")
        .min(1, "Please select a month"),
      Department_Id: Yup.number()
        .required("Please select a department")
        .min(1, "Please select a department"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          SalaryDisbursement_Id: InitialFormData.SalaryDisbursement_Id,
          Year_Id: values.Year_Id,
          Month_Id: values.Month_Id,
          Department_Id: values.Department_Id,
          User: user.Employee_Id,
        };
        const response = await _SalaryDisbursement.Edit_SalaryDisbursement(
          body
        );
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          SalaryDisbursement_Id: 0,
          Year_Id: values.Year_Id,
          Month_Id: values.Month_Id,
          Department_Id: values.Department_Id,
          User: user.Employee_Id,
        };
        const response = await _SalaryDisbursement.Save_SalaryDisbursement(
          body
        );
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderYear = () =>
    YearList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderMonth = () =>
    MonthList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Salary Disbursement" : "Add Salary Disbursement"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Year{" "}
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderYear}
                    />
                  </span>
                </Label>
                <Input
                  type="select"
                  name="Year_Id"
                  onChange={e => {
                    validation.handleChange(e);
                    getMonths(e);
                  }}
                  onBlur={validation.handleBlur}
                  visible={isSpinnerLoaderYear}
                  value={validation.values.Year_Id || ""}
                  invalid={
                    validation.touched.Year_Id && validation.errors.Year_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {YearList == null || YearList == undefined
                    ? ""
                    : renderYear()}
                </Input>
                {validation.touched.Year_Id && validation.errors.Year_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Year_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Month{" "}
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderMonth}
                    />
                  </span>
                </Label>
                <Input
                  type="select"
                  name="Month_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={isSpinnerLoaderMonth}
                  value={validation.values.Month_Id || ""}
                  invalid={
                    validation.touched.Month_Id && validation.errors.Month_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {MonthList == null || MonthList == undefined
                    ? ""
                    : renderMonth()}
                </Input>
                {validation.touched.Month_Id && validation.errors.Month_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Month_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department{" "}
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderYear}
                    />
                  </span>
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={isSpinnerLoaderYear}
                  value={validation.values.Department_Id || ""}
                  invalid={
                    validation.touched.Department_Id &&
                    validation.errors.Department_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {DepartmentList == null || DepartmentList == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
                {validation.touched.Department_Id &&
                validation.errors.Department_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Department_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
