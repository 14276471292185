import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            {/* <Col md={6}></Col> */}
            <Col md={12}>
              <div className="text-sm-end d-none d-sm-block">
                HRMS V 1.0 | © {new Date().getFullYear()} Powered by&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: "bold", color: "#004a8d" }}
                  href="https://systechlogic.com/"
                >
                  SYSTECHLOGIC
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
