import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Employee_DD = () => {
  let url = `api/Employee/Get_Employee_DropDown`;
  return get(url);
};

const Get_Employee_By_Department_DD = Department => {
  let url = `api/Employee/Get_Employee_By_Department_DropDown?Department=${Department}`;
  return get(url);
};

const Get_Employee_By_Function_DD = Function => {
  let url = `api/Employee/Get_Employee_By_Function_DropDown?Function=${Function}`;
  return get(url);
};

const Get_Employee_By_LineManager_DD = LineManager => {
  let url = `api/Employee/Get_Employee_By_LineManager_DropDown?LineManager=${LineManager}`;
  return get(url);
};

const Get_Employee_List_By_Rows = body => {
  let url = `api/Employee/Get_Employee_List`;
  return post(url, body);
};

const Get_HOD_By_Department = Department => {
  let url = `api/Employee/Get_HOD_By_Department?Department=${Department}`;
  return get(url);
};

const Get_Employee_By_Id = id => {
  let url = `api/Employee/Get_Employee_By_Id?Id=${id}`;

  return get(url);
};

const Save_Employee = body => {
  let url = "api/Employee/Save_Employee";
  return post(url, body);
};

const Save_Employee_Academic_Information = body => {
  let url = "api/Employee/Save_Employee_Academic_Information";
  return post(url, body);
};

const Save_Employee_Function_Information = body => {
  let url = "api/Employee/Save_Employee_Function_Information";
  return post(url, body);
};

const Save_Employee_Record_Information = body => {
  let url = "api/Employee/Save_Employee_Record_Information";
  return post(url, body);
};

const Edit_Employee = body => {
  let url = "api/Employee/Edit_Employee";
  return post(url, body);
};

const Delete_Employee = body => {
  let url = "api/Employee/Edit_Employee_Status";
  return post(url, body);
};

const Update_Employee_Status = body => {
  let url = "api/Employee/Update_Employee_Status";
  return post(url, body);
};

const Update_ProfilePic = body => {
  let url = "api/Employee/Update_ProfilePic";
  return post(url, body);
};

//S.No	Employee Name	Department	Designation	Location	DOB	DOJ	Active Status	Action
const anchorTable = [
  {
    Header: "S.No",
    accessor: "SrNo",
    hidden: true,
    width: 30,
  },
  {
    Header: "Employee Code",
    accessor: "Employee_Code",
    hidden: false,
    width: 80,
  },
  {
    Header: "Employee_Id",
    accessor: "Employee_Id",
    hidden: true,
  },
  {
    Header: "Employee_Code",
    accessor: "Employee_Code",
    hidden: true,
  },
  {
    Header: "Employee Name",
    accessor: "Full_Name",
    hidden: false,
    width: 300,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: false,
    width: 200,
  },
  {
    Header: "Designation",
    accessor: "Designation_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Location",
    accessor: "Branch_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "Email",
    accessor: "Email",
    hidden: false,
  },
  {
    Header: "Shift",
    accessor: "Shift_Name",
    hidden: false,
    width: 150,
  },
  {
    Header: "DOB",
    accessor: "DOB",
    hidden: false,
    width: 150,
  },
  {
    Header: "DOJ",
    accessor: "DOJ",
    hidden: false,
    width: 150,
  },
  {
    Header: "Status",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
    width: 50,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Employee_DD,
  Get_Employee_By_Id,
  Save_Employee_Academic_Information,
  Save_Employee_Function_Information,
  Save_Employee_Record_Information,
  Get_Employee_By_Department_DD,
  Get_Employee_By_Function_DD,
  Get_Employee_By_LineManager_DD,
  Get_HOD_By_Department,
  Get_Employee_List_By_Rows,
  Save_Employee,
  Edit_Employee,
  Delete_Employee,
  Update_Employee_Status,
  Update_ProfilePic,
  anchorTable,
};
