import React, { useState } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

import _EmployeeBulkUploader from "helpers/Employee_Management/EmployeeBulkUploader";
import validation from "helpers/validation";

//i18n
import { withTranslation } from "react-i18next";
const EmployeeBulkUploader = props => {
  //meta title
  document.title = "HR - Employee Bulk Uploader";
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [IsDisabled, setIsDisabled] = useState(false);
  const branch = "crudTbEmployeeBulkUploader";

  const dispatch = useDispatch();

  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbEmployeeBulkUploader.popupMessage
  );

  function handleAcceptedFiles(files) {
    if (files.length > 1) {
      dispatch(
        setPopupMessageValues(
          "Can not upload more than one file at a time.",
          branch
        )
      );
    } else {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      const type = files[0].type;
      // if (type.includes("image")) {
      if (type.includes("csv")) {
        setselectedFiles(files);
        setIsDisabled(false);
      } else {
        dispatch(setPopupMessageValues('Only "CSV" format is allowed', branch));
        setselectedFiles([]);
        setIsDisabled(true);
      }
    }
  }

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadFile = async () => {
    setisLoading(true);
    let newItem;
    convertBase64(selectedFiles[0], result => {
      newItem = {
        User_Id: user.Employee_Id,
        Attachment: {
          Name: selectedFiles[0].name,
          File: result,
        },
        User: user.Employee_Id,
      };
      _EmployeeBulkUploader
        .Save_Bulk_Employee(newItem)
        .then(response => {
          if (response.Status_Code === 200 && response.IsSuccess === true) {
            dispatch(
              setPopupMessageValues("File Uploaded Successfully", branch)
            );
            setselectedFiles([]);
            setIsDisabled(false);
            setisLoading(false);
          } else {
            dispatch(setPopupMessageValues(response.Status_Message, branch));
            setselectedFiles([]);
            setisLoading(false);
          }
        })
        .catch(error => {
          dispatch(setPopupMessageValues(error, branch));
          setisLoading(false);
        });
    });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // convert data to worksheet format
    worksheet.addRow([
      "Employee_Code",
      "CNIC",
      "Email",
      "Mobile",
      "Password",
      "First_Name",
      "Last_Name",
      "DOJ",
      "DOB",
      "Gender_Id",
      "Country_Id",
      "State_Id",
      "City_Id",
      "Branch_Id",
      "Address",
      "Report_To",
      "Designation_Id",
      "Department_Id",
      "Role_Id",
      "BloodGroup_Id",
      "ContractType_Id",
      "InsurancePlan_Id",
    ]);
    worksheet.addRow([
      "000000",
      "0000000000000",
      "xxxx@outlook.com",
      "00000000000",
      "Password",
      "First_Name",
      "Last_Name",
      "year-month-day",
      "year-month-day",
      "0",
      "0",
      "0",
      "0",
      "0",
      "Address",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ]);

    // save workbook and download file
    workbook.csv
      .writeBuffer()
      .then(buffer => {
        saveAs(new Blob([buffer]), "Employee Data Format.csv");
      })
      .catch(error => {
        console.error(error.message);
      });
  };

  const handleFileChange = async files => {
    const file = files[0];
    setIsDisabled(false);
    const reader = new FileReader();
    reader.onload = async e => {
      const arrayBuffer = e.target.result;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("CSV Data");

      const dataView = new DataView(arrayBuffer);
      const decoder = new TextDecoder("utf-8");
      const csvText = decoder.decode(dataView);

      const lines = csvText.split("\n");

      lines.forEach(line => {
        const rowData = line.split(",");
        worksheet.addRow(rowData);
      });

      const csvData = worksheet.getSheetValues();
      //custom validations
      csvData.map((item, index) => {
        if (index < 2 || index === csvData.length - 1) {
        } else {
          item.map(value => {
            if (value === "") {
              dispatch(
                setPopupMessageValues("Empty Cells at Row # " + index, branch)
              );
              setIsDisabled(true);
              setselectedFiles([]);
              return;
            }
          });
          if (!/^\d{5}-\d{7}-\d$/i.test(item[2])) {
            dispatch(
              setPopupMessageValues("Invalid CNIC at Row # " + index, branch)
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item[3])
          ) {
            dispatch(
              setPopupMessageValues(
                "Invalid Email Address at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (!/^0[1-9]\d{1,3}-\d{7}$/i.test(item[4])) {
            dispatch(
              setPopupMessageValues(
                "Invalid Phone Number at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (!item[8].match(/^\d{4}-\d{2}-\d{2}$/)) {
            dispatch(
              setPopupMessageValues(
                "Invalid Date of Joining at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (!item[9].match(/^\d{4}-\d{2}-\d{2}$/)) {
            dispatch(
              setPopupMessageValues(
                "Invalid Date of Birth at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[10] == 0) {
            dispatch(
              setPopupMessageValues(
                "Gender_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[11] == 0) {
            dispatch(
              setPopupMessageValues(
                "Country_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[12] == 0) {
            dispatch(
              setPopupMessageValues(
                "State_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[13] == 0) {
            dispatch(
              setPopupMessageValues(
                "City_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[14] == 0) {
            dispatch(
              setPopupMessageValues(
                "Branch_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[16] == 0) {
            dispatch(
              setPopupMessageValues(
                "Report_To can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[17] == 0) {
            dispatch(
              setPopupMessageValues(
                "Designation_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[18] == 0) {
            dispatch(
              setPopupMessageValues(
                "Department_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[19] == 0) {
            dispatch(
              setPopupMessageValues(
                "Role_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[20] == 0) {
            dispatch(
              setPopupMessageValues(
                "BloodGroup_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[21] == 0) {
            dispatch(
              setPopupMessageValues(
                "ContractType_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else if (item[22] == 0) {
            dispatch(
              setPopupMessageValues(
                "InsurancePlan_Id can not be zero at Row # " + index,
                branch
              )
            );
            setIsDisabled(true);
            setselectedFiles([]);
            return;
          } else {
            handleAcceptedFiles(files);
          }
        }
      });
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Employee Mngt")}
            breadcrumbItem={props.t("Employee Bulk Uploader")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div style={{ display: "flex" }}>
                    <div className="col-10">
                      <h6 className="card-title">Upload Employees in Bulk</h6>
                      <CardSubtitle className="mb-3">
                        Select or Drop CSV file to upload Employees in bulk.
                      </CardSubtitle>
                    </div>
                    <div className="col-2">
                      <h6 className="card-title">Download CSV Format</h6>
                      <CardSubtitle className="mb-3">
                        <Button
                          onClick={handleExport}
                          style={{
                            backgroundColor: "#004a8d",
                            color: "white",
                          }}
                        >
                          Download
                        </Button>
                      </CardSubtitle>
                    </div>
                  </div>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleFileChange(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                {/* <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col> */}
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Form>

                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#004a8d",
                        color: "white",
                      }}
                      onClick={handleUploadFile}
                      disabled={selectedFiles.length == 0 || IsDisabled}
                    >
                      Send Files
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EmployeeBulkUploader);
