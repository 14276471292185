import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Image from "@material-ui/icons/Image";
import MedicalRequests from "helpers/Medical/MedicalRequests";
import _Employee from "helpers/Employee_Management/Employee";
import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import _Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import queryString from "query-string";
import Encryption from "helpers/Encryption/Encryption";
import _validation from "helpers/validation";
import DialogPopup from "components/Common/DialogPopup";
import MedicalRequestsTable from "./MedicalRequestsTable";
import ApprovalHistoryTable from "./ApprovalHistoryTable";
import {
  addNewItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Container,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@material-ui/core";

const MedicalRequestView = props => {
  const branch = "crudTbMedicalRequests";
  //meta title
  document.title = "HR - Medical Request Details";

  const [InitialFormData, setInitialFormData] = useState(null);
  const [TransactionHistory, setTransactionHistory] = useState(null);
  const [MedicalTransactions, setMedicalTransactions] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const [ApprovalStatusList, setApprovalStatusList] = useState(null);
  const [SelectedApprovalStatus, setSelectedApprovalStatus] = useState(null);

  const [ProcedureDate, setProcedureDate] = useState(null);
  const [RefundedOnDate, setRefundedOnDate] = useState(null);
  const [newRefundedOnDate, setnewRefundedOnDate] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);

  const popupMessage = useSelector(
    state => state.crudTbMedicalRequests.popupMessage
  );
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialData = async () => {
    try {
      setisLoading(true);
      const response2 = await _ApprovalStatus.Get_ApprovalStatus_DD();
      const data = response2.Data.Details;
      const filtered = data.filter(item => {
        return item.Value === 2 || item.Value === 4;
      });
      setApprovalStatusList(filtered);
      await CheckEditScreen();
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const CheckEditScreen = async () => {
    setisLoading(true);
    let params = queryString.parse(location.search);
    const encryupteId = params.q;
    if (encryupteId !== undefined && encryupteId !== null) {
      const leaveId = Encryption.decrypt_url(encryupteId);
      params = queryString.parse(leaveId);
    }
    const Med_Req_Id = params.id;
    if (Med_Req_Id) {
      try {
        const response1 = await MedicalRequests.Get_Medical_Request_By_Id(
          Med_Req_Id
        );
        const data = response1.Data.Details;
        setInitialFormData(data);
        setTransactionHistory(data.Transactions_Approval);
        setMedicalTransactions(data.Transactions);
        // setProcedureDate(data.Procedure_Date.split("T")[0]);
        setRefundedOnDate(
          data.Refunded_On !== null ? data.Refunded_On.split("T")[0] : null
        );
      } catch (error) {
        dispatch(setPopupMessageValues("Opps! Server is down.", branch));
        console.log(error);
      }
    }
    setisLoading(false);
  };

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Approver_Remarks: "",
      ApprovalStatus_Id: "",
      Refunded_Amount: 0,
    },
    validationSchema: Yup.object({
      Approver_Remarks: Yup.string().required("Please enter remarks"),
      ApprovalStatus_Id: Yup.number().min(
        1,
        "Please select an approval status"
      ),
      Refunded_Amount: Yup.number().required("Please enter Refunded Amount"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      let body;
      //only employee can add new leave
      // if (user.Role_Id === 3) {
      //   body = {
      //     Med_Id: InitialFormData.Med_Id,
      //     ApprovalStatus_Id: values.ApprovalStatus_Id,
      //     ApprovedById: user.Employee_Id,
      //     ApprovedRemarks: values.Approver_Remarks,
      //     DOP_User_Id: 0,
      //   };
      // } else {
      //   body = {
      //     Med_Id: InitialFormData.Med_Id,
      //     ApprovalStatus_Id: values.ApprovalStatus_Id,
      //     ApprovedById: user.Employee_Id,
      //     ApprovedRemarks: values.Approver_Remarks,
      //     DOP_User_Id: 0,
      //     Refunded_Amount: values.Refunded_Amount,
      //     Refunded_On: newRefundedOnDate,
      //   };
      // }
      body = {
        Med_Id: InitialFormData.Med_Id,
        ApprovalStatus_Id: values.ApprovalStatus_Id,
        ApprovedById: user.Employee_Id,
        ApprovedRemarks: values.Approver_Remarks,
        DOP_User_Id: 0,
        Refunded_Amount: values.Refunded_Amount,
        Refunded_On: newRefundedOnDate,
      };

      const response = await MedicalRequests.UpdateMR_Workflow(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        let data = response.Data;
        setisLoading(false);
        navigate("/medical/medical_requests");
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }
      toggle();
    },
  });

  const renderApprovalStatus = () =>
    ApprovalStatusList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Medical Request Details
        </Typography>
        <Container fluid>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Employee Name</Label>
                  <Input
                    type="text"
                    name="employee_name"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.EmployeeName
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Employee Code</Label>
                  <Input
                    type="text"
                    name="employee_Code"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.EmployeeCode
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Department</Label>
                  <Input
                    type="text"
                    name="employee_department"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.Employee_department
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Designation</Label>
                  <Input
                    type="text"
                    name="employee_Designation"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.Employee_designation
                    }
                  />
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    Employee Family
                  </Label>
                  <Input
                    type="text"
                    name="Employee_Family_Name"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.Employee_Family_Name
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    Medical Procedure
                  </Label>
                  <Input
                    type="text"
                    name="Medical_Procedure_Name"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.Medical_Procedure_Name
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    Date
                  </Label>
                  <Input
                    type="date"
                    name="ProcedureDate"
                    required
                    disabled
                    onChange={e => setProcedureDate(e.target.value)}
                    value={ProcedureDate}
                    max={currentDate}
                  />
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Total Claim Amount</Label>
                  <Input
                    type="text"
                    name="Claim_Amount"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.Claim_Amount.toLocaleString()
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Total Refunded Amount</Label>
                  <Input
                    type="text"
                    name="Refunded_Amount"
                    required
                    disabled
                    value={
                      InitialFormData == null
                        ? ""
                        : InitialFormData.Refunded_Amount.toLocaleString()
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Refund Date</Label>
                  <Input
                    type="text"
                    name="Refunded_Amount"
                    required
                    disabled
                    value={
                      RefundedOnDate == null
                        ? ""
                        : Moment(RefundedOnDate).format("DD-MMM-YYYY")
                    }
                  />
                </div>
              </Col>
            </Row>
            <br />
            {/* Medical Requests Table */}
            <Typography variant="h6">Medical Requests</Typography>
            <MedicalRequestsTable DataList={MedicalTransactions} />
            <br />
            <Row>
              <Col className="col-12">
                <Typography variant="h6">Add Approval</Typography>
              </Col>
              <br />
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Remarks
                  </Label>
                  <Input
                    name="Approver_Remarks"
                    type="text"
                    maxLength={255}
                    onKeyPress={_validation.alphaNumeric}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Approver_Remarks || ""}
                    invalid={
                      validation.touched.Approver_Remarks &&
                      validation.errors.Approver_Remarks
                        ? true
                        : false
                    }
                  />
                  {validation.touched.Approver_Remarks &&
                  validation.errors.Approver_Remarks ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Approver_Remarks}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Approval Status
                  </Label>
                  <Input
                    type="select"
                    name="ApprovalStatus_Id"
                    onChange={e => {
                      validation.handleChange(e);
                      setSelectedApprovalStatus(e.target.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.ApprovalStatus_Id || ""}
                    invalid={
                      validation.touched.ApprovalStatus_Id &&
                      validation.errors.ApprovalStatus_Id
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {ApprovalStatusList == null ||
                    ApprovalStatusList == undefined
                      ? ""
                      : renderApprovalStatus()}
                  </Input>
                  {validation.touched.ApprovalStatus_Id &&
                  validation.errors.ApprovalStatus_Id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ApprovalStatus_Id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            {SelectedApprovalStatus == 2 ? (
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Refunded Amount
                    </Label>
                    <Input
                      name="Refunded_Amount"
                      type="text"
                      maxLength={255}
                      onKeyPress={_validation.wholeNumber}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.Refunded_Amount || ""}
                      invalid={
                        validation.touched.Refunded_Amount &&
                        validation.errors.Refunded_Amount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.Refunded_Amount &&
                    validation.errors.Refunded_Amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Refunded_Amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Refunded Date
                    </Label>
                    <Input
                      type="date"
                      name="RefundedDate"
                      required
                      onChange={e => setnewRefundedOnDate(e.target.value)}
                      value={newRefundedOnDate}
                      min={currentDate}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row className="mt-2">
              <Col className="col-12 text-start">
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
          <br />
          <br />
          {/* Approval History Table */}
          <Typography variant="h6">Approval History</Typography>
          <ApprovalHistoryTable DataList={TransactionHistory} />
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

MedicalRequestView.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default MedicalRequestView;
