import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import _Employee from "helpers/Employee_Management/Employee";
import _LeaveType from "helpers/MasterData_Management/LeaveType";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import _Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import queryString from "query-string";
import Encryption from "helpers/Encryption/Encryption";
import _validation from "helpers/validation";
import DialogPopup from "components/Common/DialogPopup";
import ApprovalHistoryTable from "./ApprovalHistoryTable";
import {
  addNewItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Container,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@material-ui/core";

const EmployeeLeaveView = props => {
  const branch = "crudTbEmployeeLeave";
  //meta title
  document.title = "HR - Employee Leave Details";

  const [InitialFormData, setInitialFormData] = useState(null);
  const [TransactionHistory, setTransactionHistory] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [IsHalfDay, setIsHalfDay] = useState(false);

  const [LeaveTypeList, setLeaveTypeList] = useState(null);
  const [ApprovalStatusList, setApprovalStatusList] = useState(null);

  const [From_Date, setFrom_Date] = useState(null);
  const [To_Date, setTo_Date] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);

  const popupMessage = useSelector(
    state => state.crudTbEmployeeLeave.popupMessage
  );
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialData = async () => {
    try {
      setisLoading(true);
      const response1 = await _LeaveType.Get_LeaveType_DD();
      setLeaveTypeList(response1.Data.Details);
      const response2 = await _ApprovalStatus.Get_ApprovalStatus_DD();
      setApprovalStatusList(response2.Data.Details);
      await CheckEditScreen();
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const CheckEditScreen = async () => {
    setisLoading(true);
    let params = queryString.parse(location.search);
    const encryupteId = params.q;
    if (encryupteId !== undefined && encryupteId !== null) {
      const leaveId = Encryption.decrypt_url(encryupteId);
      params = queryString.parse(leaveId);
    }
    const Emp_Leave_Id = params.id;
    if (Emp_Leave_Id) {
      try {
        const response = await _EmployeeLeave.Get_EmployeeLeaveBy_ID(
          Emp_Leave_Id
        );
        const data = response.Data.Details;
        setInitialFormData(data);
        setIsHalfDay(data.isHalfDay);
        const startDate = Moment(data.From_Date).format("YYYY-MM-DD");
        const endDate = Moment(data.To_Date).format("YYYY-MM-DD");
        setFrom_Date(startDate);
        setTo_Date(endDate);
        setTransactionHistory(data.Transactions);
      } catch (error) {
        dispatch(setPopupMessageValues("Opps! Server is down.", branch));
        console.log(error);
      }
    }
    setisLoading(false);
  };

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Employee_Code:
        user.Role_Id === 4
          ? user.Employee_Code
          : InitialFormData !== null
          ? InitialFormData.Employee_Code
          : "",
      From_Date: InitialFormData !== null ? InitialFormData.From_Date : "",
      To_Date: InitialFormData !== null ? InitialFormData.To_Date : "",
      LeaveType_Id:
        InitialFormData !== null ? InitialFormData.LeaveType_Id : "",
      ApprovalStatus_Id: "",
      Leave_Justification:
        InitialFormData !== null ? InitialFormData.Leave_Justification : "",
      Approver_Remarks: "",
    },
    validationSchema: Yup.object({
      Approver_Remarks: Yup.string().required("Please enter remarks"),
      ApprovalStatus_Id: Yup.number().min(
        1,
        "Please select an approval status"
      ),
    }),
    onSubmit: async values => {
      setisLoading(true);
      let body;
      //only employee can add new leave
      body = {
        Employee_Leave_Id: InitialFormData.EmployeeLeave_Id,
        ApprovalStatus_Id: values.ApprovalStatus_Id,
        ApprovedById: user.Employee_Id,
        ApprovedRemarks: values.Approver_Remarks,
        DOP_User_Id: 0,
      };

      const response = await _EmployeeLeave.UpdateEmployeeLeave_Workflow(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        let data = response.Data;
        setisLoading(false);
        navigate("/employee_management/employee_leave");
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }
      toggle();
    },
  });

  const renderLeaveType = () =>
    LeaveTypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderApprovalStatus = () =>
    ApprovalStatusList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Employee Leave Details
        </Typography>
        <Container fluid>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Employee Name</Label>
                <Input
                  type="text"
                  name="employee_name"
                  required
                  disabled
                  value={
                    InitialFormData == null ? "" : InitialFormData.Employee_Name
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Employee Code</Label>
                <Input
                  type="text"
                  name="employee_Code"
                  required
                  disabled
                  value={
                    InitialFormData == null ? "" : InitialFormData.Employee_Code
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Department</Label>
                <Input
                  type="text"
                  name="employee_department"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Department_Name
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Designation</Label>
                <Input
                  type="text"
                  name="employee_Designation"
                  required
                  disabled
                  value={
                    InitialFormData == null
                      ? ""
                      : InitialFormData.Designation_Name
                  }
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Leave Type</Label>
                <Input
                  type="select"
                  name="LeaveType_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  disabled
                  value={validation.values.LeaveType_Id || ""}
                  invalid={
                    validation.touched.LeaveType_Id &&
                    validation.errors.LeaveType_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {LeaveTypeList == null || LeaveTypeList == undefined
                    ? ""
                    : renderLeaveType()}
                </Input>
                {validation.touched.LeaveType_Id &&
                validation.errors.LeaveType_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.LeaveType_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Start Date</Label>
                <Input
                  type="date"
                  name="From_Date"
                  disabled
                  required
                  onChange={e => setFrom_Date(e.target.value)}
                  value={From_Date}
                  min={user.Role_Id === 4 ? currentDate : ""}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">End Date</Label>
                <Input
                  type="date"
                  name="To_Date"
                  required
                  disabled
                  onChange={e => setTo_Date(e.target.value)}
                  value={To_Date}
                  min={user.Role_Id === 4 ? currentDate : ""}
                />
              </div>
            </Col>
            <Col xl="3">
              {/* <FormControlLabel
                control={
                  <Switch name="IsHalfDay" checked={IsHalfDay} disabled />
                }
                label="Is Half Day"
                style={{ marginTop: 25 }}
              /> */}
              <div className="mb-3">
                <Label className="form-label">Full-Day/Half-Day</Label>
                <Input
                  type="text"
                  name="Is_HalfDay"
                  disabled
                  value={IsHalfDay ? "Half-Day" : "Full-Day"}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label className="form-label">Leave Justification</Label>
                <Input
                  name="Leave_Justification"
                  type="textarea"
                  maxLength={2000}
                  style={{ height: 100 }}
                  onKeyPress={_validation.alphabetic}
                  // value={isEdit ? InitialFormData.Leave_Justification : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled
                  value={validation.values.Leave_Justification || ""}
                  invalid={
                    validation.touched.Leave_Justification &&
                    validation.errors.Leave_Justification
                      ? true
                      : false
                  }
                />
                {validation.touched.Leave_Justification &&
                validation.errors.Leave_Justification ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Leave_Justification}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          {InitialFormData !== null &&
          InitialFormData.ApprovalStatus_Id !== 3 ? (
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Currently Assigned To</Label>
                  <Input
                    type="text"
                    name="assigned_to_name"
                    required
                    disabled
                    value={
                      InitialFormData == null ? "" : InitialFormData.AssignedTo
                    }
                  />
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <br />
          <br />
          {/* Approval History Table */}
          <Typography variant="h6">Approval History</Typography>
          <ApprovalHistoryTable DataList={TransactionHistory} />
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

EmployeeLeaveView.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default EmployeeLeaveView;
