import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Medical_Request_DD = (value = 0) => {
  let url = "api/Medical_Request/Get_Medical_Request_DropDown";

  return get(url);
};

const Get_Medical_Request_By_Id = id => {
  let url = "api/Medical_Request/Get_Medical_Request_By_Id?Id=" + id;

  return get(url);
};

const Get_Medical_Request_List = body => {
  let url = "api/Medical_Request/Get_Medical_Request_List";

  return post(url, body);
};

const Get_Medical_Request_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Medical_Request/Get_Medical_Request_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Medical_Request = body => {
  let url = "api/Medical_Request/Save_Medical_Request";
  return post(url, body);
};

const Edit_Medical_Request = body => {
  let url = "api/Medical_Request/Edit_Medical_Request";
  return post(url, body);
};

const Delete_Medical_Request = body => {
  let url = "api/Medical_Request/Edit_Medical_Request_Status";
  return post(url, body);
};
const UpdateMR_Workflow = body => {
  let url = "api/Medical_Request/UpdateMR_Workflow";
  return post(url, body);
};
const EmployeeanchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Employee Name",
    accessor: "EmployeeName",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: true,
  },
  {
    Header: "Designation",
    accessor: "Designation_Name",
    hidden: true,
  },
  {
    Header: "Family Name",
    accessor: "Employee_Family_Name",
    hidden: true,
  },
  {
    Header: "Total Claim Amount",
    accessor: "Total_Claim_Amount",
    hidden: false,
  },
  {
    Header: "Total Amount Paid",
    accessor: "Total_Refunded_Amount",
    hidden: false,
  },
  {
    Header: "Approval Status",
    accessor: "ApprovalStatus_Name",
    hidden: false,
  },
  {
    Header: "Month",
    accessor: "MonthName",
    hidden: false,
  },
  {
    Header: "Request Date",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: false,
    delete: false,
    view: true,
    add: true,
    hidden: false,
    width: 50,
  },
];

const HrManageranchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Employee Name",
    accessor: "EmployeeName",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "Designation",
    accessor: "Designation_Name",
    hidden: false,
  },
  {
    Header: "Family Name",
    accessor: "Employee_Family_Name",
    hidden: true,
  },
  {
    Header: "Total Claim Amount",
    accessor: "Total_Claim_Amount",
    hidden: false,
  },
  {
    Header: "Total Amount Paid",
    accessor: "Total_Refunded_Amount",
    hidden: false,
  },
  {
    Header: "Month",
    accessor: "MonthName",
    hidden: false,
  },
  {
    Header: "Request Date",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Approval Status",
    accessor: "ApprovalStatus_Name",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: true,
    add: true,
    hidden: false,
    width: 150,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Medical_Request_DD,
  Get_Medical_Request_By_Id,
  Get_Medical_Request_List,
  Get_Medical_Request_List_By_Rows,
  Save_Medical_Request,
  Edit_Medical_Request,
  Delete_Medical_Request,
  UpdateMR_Workflow,
  EmployeeanchorTable,
  HrManageranchorTable,
};
