import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _Attendance from "helpers/Employee_Management/Attendance";
import _LeaveType from "helpers/MasterData_Management/LeaveType";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [currentDate, setcurrentDate] = useState(false);

  const [LeaveTypeList, setLeaveTypeList] = useState(null);
  const [TypeList, setTypeList] = useState(null);

  const [Date_From, setDate_From] = useState(null);
  const [Date_To, setDate_To] = useState(null);

  const formOpen = useSelector(state => state.crudTbAttendance.formOpen);
  const AttendanceList = useSelector(state => state.crudTbAttendance.dataTable);
  const formValues = useSelector(state => state.crudTbAttendance.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      const response2 = await _LeaveType.Get_LeaveType_DD();
      setLeaveTypeList(response2.Data.Details);
      setTypeList([
        {
          Text: "Type 1",
          Value: 1,
        },
        {
          Text: "Type 2",
          Value: 2,
        },
        {
          Text: "Type 3",
          Value: 3,
        },
      ]);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  useEffect(() => {
    if (formValues != null) {
      const index = AttendanceList.findIndex(
        item => item.Attendance_Id == formValues.Attendance_Id
      );
      setIsEdit(true);
      setInitialFormData(AttendanceList[index]);
      setDate_From(AttendanceList[index].Date_From);
      setDate_To(AttendanceList[index].Date_To);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Date_From: isEdit ? InitialFormData.Date_From : "",
      Date_To: isEdit ? InitialFormData.Date_To : "",
      LeaveType_Id: isEdit ? InitialFormData.LeaveType_Id : "",
      Type_Id: isEdit ? InitialFormData.Type_Id : "",
    },
    validationSchema: Yup.object({
      LeaveType_Id: Yup.number()
        .required("Please Select an Attendance Type")
        .min(1, "Please Select an Attendance Type"),
      Type_Id: Yup.number()
        .required("Please Select a Type")
        .min(1, "Please Select a Type"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          Attendance_Id: InitialFormData.Attendance_Id,
          Date_From: Date_From,
          Date_To: Date_To,
          LeaveType_Id: values.LeaveType_Id,
          Type_Id: values.Type_Id,
          User: user.Employee_Id,
        };
        const response = await _Attendance.Edit_EmployeeAttendance(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Attendance_Id: 0,
          Date_From: Date_From,
          Date_To: Date_To,
          LeaveType_Id: values.LeaveType_Id,
          Type_Id: values.Type_Id,
          User: user.Employee_Id,
        };
        const response = await _Attendance.Save_EmployeeAttendance(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderLeaveType = () =>
    LeaveTypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderType = () =>
    TypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Attendance " : "Add Attendance"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date From
                </Label>
                <Input
                  type="date"
                  name="Date_From"
                  max={currentDate}
                  onChange={e => setDate_From(e.target.value)}
                  value={Date_From}
                  required
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date To
                </Label>
                <Input
                  type="date"
                  name="Date_To"
                  max={currentDate}
                  onChange={e => setDate_To(e.target.value)}
                  value={Date_To}
                  required
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Leave Type
                </Label>
                <Input
                  type="select"
                  name="LeaveType_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.LeaveType_Id || ""}
                  invalid={
                    validation.touched.LeaveType_Id &&
                    validation.errors.LeaveType_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {LeaveTypeList == null || LeaveTypeList == undefined
                    ? ""
                    : renderLeaveType()}
                </Input>
                {validation.touched.LeaveType_Id &&
                validation.errors.LeaveType_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.LeaveType_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span> Type
                </Label>
                <Input
                  type="select"
                  name="Type_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Type_Id || ""}
                  invalid={
                    validation.touched.Type_Id && validation.errors.Type_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {TypeList == null || TypeList == undefined
                    ? ""
                    : renderType()}
                </Input>
                {validation.touched.Type_Id && validation.errors.Type_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Type_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
