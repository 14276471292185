import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
  openLeaveCancelationForm,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@material-ui/core";

const floatingForm = props => {
  const { branch } = props;

  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const leavecancelformOpen = useSelector(
    state => state.crudTbEmployeeLeave.leavecancelformOpen
  );
  const LeaveList = useSelector(state => state.crudTbEmployeeLeave.dataTable);
  const formValues = useSelector(state => state.crudTbEmployeeLeave.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();
  useEffect(() => {
    if (formValues !== undefined && formValues !== null) {
      setInitialFormData(formValues);
    }
  }, [leavecancelformOpen]);
  const toggle = () => {
    dispatch(openLeaveCancelationForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // Leave_Justification: "",
    },
    validationSchema: Yup.object({
      // Leave_Justification: Yup.string().required("Please Enter Justification"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      let body = {
        Employee_Leave_Id: InitialFormData.EmployeeLeave_Id,
        ApprovalStatus_Id: 5,
        User: user.Employee_Id,
        DOP_User_Id: 0,
      };
      const response = await _EmployeeLeave.MarkLeaveCancel_ByEmp(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        let data = response.Data;
        dispatch(updateItem(data.Details, branch));
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
        toggle();
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
        toggle();
      }
    },
  });
  return (
    <Modal isOpen={leavecancelformOpen} centered>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i
                  className="mdi mdi-alert-circle-outline"
                  style={{ fontSize: "4em", color: "orange" }}
                />
                <h2>Are you sure?</h2>
                <h4>{"You won't be able to revert this!"}</h4>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light btn-lg me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-danger btn-lg ms-2"
                id="btn-save-event"
              >
                Yes
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
