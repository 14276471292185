import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import {
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setPopupMessageValues,
} from "store/actions";

const CrudTable = props => {
  const { HeaderData, BodyData, branch, className } = props;

  const dispatch = useDispatch();

  const eventDel = item => {
    dispatch(removeItem(item, branch));
    dispatch(setPopupMessageValues("Deleted", branch));
  };

  const renderHeader = () =>
    HeaderData.map((row, index) => <th key={index + 1}>{row.Header}</th>);

  const renderBody = () =>
    BodyData.map((row, index) => {
      return (
        <tr key={index}>
          <td key={0}>{index + 1}</td>
          {HeaderData.map((cell, index) => {
            return (
              cell.hidden === false && (
                <td key={index + 1} style={{ width: 80 }}>
                  {cell.accessor === "Action" ? (
                    <Button
                      color="red"
                      size="sm"
                      onClick={e => {
                        eventDel(row);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  ) : (
                    row[cell.accessor]
                  )}
                </td>
              )
            );
          })}
        </tr>
      );
    });

  return (
    <div className="table-responsive react-table">
      <Table bordered hover className={className}>
        <thead className="table-light table-nowrap">
          {HeaderData !== null ? (
            <tr>
              <th style={{ width: "10%" }}>Sr.No</th>
              {renderHeader()}
            </tr>
          ) : (
            <tr></tr>
          )}
        </thead>

        <tbody>
          {HeaderData != null && BodyData !== null ? renderBody() : ""}
        </tbody>
      </Table>
    </div>
  );
};

CrudTable.propTypes = {
  HeaderData: PropTypes.array,
  BodyData: PropTypes.array,
  branch: PropTypes.string,
};

export default CrudTable;
