import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//authentication
import authservice from "../../helpers/auth.service";
import userService from "../../helpers/user.service";
import background from "assets/images/brands/background.jpg";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { login, loginUser, login_fail } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import systech from "assets/images/cropped-systechlogic.png";

const Login = props => {
  //meta title
  document.title = "HR - Login";
  const [isLoading, setisLoading] = useState(false);
  const [IsPasswordVisible, setIsPasswordVisible] = useState(false);
  const { isLoggedIn, notifMsg } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      const response = await authservice.login(values);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const now = new Date();
        // 7200000
        userService.Set_User(response.Data, now.getTime() + 7200000);
        const data = {
          ...response.Data,
          notification: [],
        };
        dispatch(login(data));
        setisLoading(false);
      } else if (response.IsSuccess === false && response.Status_Code === 401) {
        setisLoading(false);
        dispatch(login_fail(response.Status_Message));
      } else {
        console.log(response.message);
        dispatch(login_fail(response.message));
        setisLoading(false);
      }
    },
  });

  if (isLoggedIn) {
    // window.location.href = "/dashboard";
    navigate("/dashboard");
  }

  if (!localStorage.getItem("MasterAuth")) {
    // window.location.href = "/master_auth";
    navigate("/master_auth");
  }

  const showPassword = () => {
    setIsPasswordVisible(!IsPasswordVisible);
  };

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      // dispatch(socialLogin(postData, props.router.navigate, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      // dispatch(socialLogin(postData, props.router.navigate, type))
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div
                  className="bg-primary bg-soft"
                  style={{
                    height: 300,
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div
                        className="p-4"
                        style={{
                          color: "white",
                          marginTop: 120,
                          marginLeft: 10,
                          zIndex: 9,
                        }}
                      >
                        <h3>Welcome To,</h3>
                        <h2>SYSTECHLOGIC LLP</h2>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={systech}
                            alt=""
                            // className="rounded-circle"
                            height="20"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={systech}
                            alt=""
                            // className="rounded-circle"
                            height="20"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <br />
                  <br />
                  <div
                    style={{
                      textAlign: "center",
                      letterSpacing: 2,
                      margin: 10,
                    }}
                  >
                    <h5 style={{ fontWeight: "bold" }}>USER LOGIN</h5>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <div
                          style={{
                            position: "relative",
                            marginBottom: 10,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "30px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 999,
                            }}
                          >
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </span>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            style={{
                              position: "relative",
                              zIndex: 1,
                              paddingLeft: 80,
                            }}
                          />
                        </div>
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div
                          style={{
                            position: "relative",
                            marginBottom: 10,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "30px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 999,
                            }}
                          >
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </span>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={IsPasswordVisible ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                            style={{
                              position: "relative",
                              zIndex: 1,
                              paddingLeft: 80,
                            }}
                          />
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}
                      <div
                        className="mt-4 text-center"
                        style={{
                          position: "absolute",
                          right: 27,
                          top: "78%",
                        }}
                      >
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot password?
                        </Link>
                      </div>
                      <br />
                      <br />
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          type="submit"
                          style={{
                            width: 100,
                            margin: "auto",
                            backgroundColor: "#004a8d",
                            color: "white",
                          }}
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                © {new Date().getFullYear()} Powered by&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: "bold" }}
                  href="https://systechlogic.com/"
                >
                  Systechlogic
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <DialogPopup branch={"Login"} message={notifMsg} />
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
              zIndex: 999,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

// <div className="mt-4 text-center">
//                         <h5 className="font-size-14 mb-3">Sign in with</h5>

//                         <ul className="list-inline">
//                           <li className="list-inline-item">
//                             <FacebookLogin
//                               appId={facebook.APP_ID}
//                               autoLoad={false}
//                               callback={facebookResponse}
//                               render={renderProps => (
//                                 <Link
//                                   to="#"
//                                   className="social-list-item bg-primary text-white border-primary"
//                                   onClick={renderProps.onClick}
//                                 >
//                                   <i className="mdi mdi-facebook" />
//                                 </Link>
//                               )}
//                             />
//                           </li>
//                           {/*<li className="list-inline-item">*/}
//                           {/*  <TwitterLogin*/}
//                           {/*    loginUrl={*/}
//                           {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
//                           {/*    }*/}
//                           {/*    onSuccess={this.twitterResponse}*/}
//                           {/*    onFailure={this.onFailure}*/}
//                           {/*    requestTokenUrl={*/}
//                           {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
//                           {/*    }*/}
//                           {/*    showIcon={false}*/}
//                           {/*    tag={"div"}*/}
//                           {/*  >*/}
//                           {/*    <a*/}
//                           {/*      href=""*/}
//                           {/*      className="social-list-item bg-info text-white border-info"*/}
//                           {/*    >*/}
//                           {/*      <i className="mdi mdi-twitter"/>*/}
//                           {/*    </a>*/}
//                           {/*  </TwitterLogin>*/}
//                           {/*</li>*/}
//                           <li className="list-inline-item">
//                             <GoogleLogin
//                               clientId={google.CLIENT_ID}
//                               render={renderProps => (
//                                 <Link
//                                   to="#"
//                                   className="social-list-item bg-danger text-white border-danger"
//                                   onClick={renderProps.onClick}
//                                 >
//                                   <i className="mdi mdi-google" />
//                                 </Link>
//                               )}
//                               onSuccess={googleResponse}
//                               onFailure={() => {}}
//                             />
//                           </li>
//                         </ul>
//                       </div>
