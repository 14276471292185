import React, { Fragment, useState, useCallback, useEffect } from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Encryption from "helpers/Encryption/Encryption";
import user1 from "assets/images/users/avatar-1.jpg";

import {
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setPopupMessageValues,
  openLeaveCancelationForm,
  updateItem,
} from "store/actions";
import del from "helpers/del";
import changeActiveStatus from "helpers/changeActiveStatus";
import tableData from "helpers/tableData";

const TableContainer = props => {
  const {
    columns,
    initialData,
    totalRecords,
    className,
    branch,
    EmployeeId,
    PageNumber,
    pageSize,
    changePageSize,
    changePageNumber,
    changeTotalRecords,
  } = props;
  const navigate = useNavigate();

  // const findSort = () => {
  //   let sort = "";
  //   for (const ini of columns) {
  //     if (ini.IsSort === true) {
  //       sort = ini.Header;
  //       break;
  //     }
  //   }
  //   return sort;
  // };

  const [DataList, setDataList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [DataToDelete, setDataToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [TotalNoOfRows, setTotalNoOfRows] = React.useState(0);
  const [IsAddButton, setIsAddButton] = React.useState(false);

  //Search and Sort
  const [rows, setRows] = React.useState([]);
  const [search, setsearch] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const checkAddButton = () => {
    const add = columns.filter(item => {
      return item.accessor === "Action" && item.add === true;
    });
    if (add.length > 0) {
      setIsAddButton(true);
    } else {
      setIsAddButton(false);
    }
  };

  //Search and Sort
  const requestSearch = async searchedVal => {
    const searchWord = searchedVal;
    let filterData = [];
    const newFilter = Object.keys(DataList).reduce((result, key) => {
      let checkData = false;
      Object.keys(DataList[key]).forEach(function (keys) {
        let textdata = DataList[key][keys] + "";
        if (textdata.toLowerCase().includes(searchWord.toLowerCase())) {
          checkData = true;
        }
      });

      if (checkData) {
        filterData.push(DataList[key]);
      }
    }, []);

    setRows(filterData);
    // setDataList(filterData);
  };

  React.useEffect(() => {
    requestSearch("");
    setsearch("");
  }, [DataList, initialData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };
  //Search and Sort

  React.useEffect(() => {
    if (initialData != null) {
      checkAddButton();
      setDataList(initialData);
      //   setTotalNoOfRows(totalRecords);
    }
  }, [initialData]);

  const EditButtons = item => {
    return (
      <Button
        color="red"
        size="sm"
        onClick={e => {
          handleEdit(item);
        }}
      >
        <i className="fas fa-pen"></i>
      </Button>
    );
  };

  const handleChangeRowsPerPage = event => {
    const totalRows = event.target.value;
    changePageSize(parseInt(event.target.value, 10));
    changePageNumber(0);
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    onPageRowChange(totalRows, 0);
  };
  const handleChangePage = (event, newPage) => {
    changePageNumber(newPage);
    onPageRowChange(pageSize, newPage);
    // setPage(newPage);
  };
  const onPageRowChange = async (totalRows, currentPage) => {
    currentPage++;
    setisLoading(true);
    let TableData_response = null;
    TableData_response = await tableData.get_asset_management_list_data(
      currentPage,
      totalRows,
      EmployeeId
    );

    if (
      TableData_response.IsSuccess === true &&
      TableData_response.Status_Code === 200
    ) {
      // dispatch(setData(TableData_response.Data));
      dispatch(setData(TableData_response.Data, branch));
      setDataList(TableData_response.Data.Details);
      //   setTotalNoOfRows(TableData_response.Data.TotalRecords);
      changeTotalRecords(TableData_response.Data.TotalRecords);
      setisLoading(false);
    } else {
      console.log(TableData_response.Status_Message);
      setisLoading(false);
    }
  };
  const addNewItem = () => {
    dispatch(toggleForm(true, branch));
  };

  const handleView = item => {
    // dispatch(toggleForm(true, branch));
  };

  const handleEdit = item => {
    dispatch(setFormValues(item, branch));
    dispatch(toggleForm(true, branch));
  };

  const renderRows = () =>
    // stableSort(rows, getComparator(order, orderBy))
    DataList.map((row, index1) => {
      return (
        <tr key={index1}>
          {columns.map((cell, index) => {
            return (
              cell.hidden === false && (
                <td
                  key={index}
                  style={{
                    margin: 0,
                    width: cell.width !== undefined ? cell.width : 300,
                  }}
                >
                  {cell.accessor === "Action" ? (
                    <div>
                      {cell.view === true ? (
                        <Button
                          color="red"
                          size="sm"
                          onClick={e => {
                            handleView(row);
                          }}
                        >
                          <i className="fas fa-eye"></i>
                        </Button>
                      ) : (
                        " "
                      )}
                      {cell.edit === true ? EditButtons(row) : ""}
                    </div>
                  ) : cell.accessor === "IsActive" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                      >
                        ACTIVE
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                        }}
                      >
                        In Active
                      </Button>
                    )
                  ) : cell.accessor === "Full_Name" ? (
                    <span style={{ textAlign: "center" }}>
                      <img
                        className="rounded-circle header-profile-user"
                        src={user1}
                        alt="Header Avatar"
                        style={{ marginRight: 5 }}
                      />
                      {row[cell.accessor]}
                    </span>
                  ) : cell.accessor === "Procedure_Date" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(row[cell.accessor]).format("MMM DD, YYYY")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "indexing" ? (
                    index1 + 1
                  ) : cell.accessor === "Holiday" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{ pointerEvents: "none" }}
                      >
                        Holiday
                      </Button>
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "Icon" ? (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-1 me-1"
                      style={{ pointerEvents: "none" }}
                    >
                      <i className={row[cell.accessor]}></i>
                    </Button>
                  ) : cell.accessor === "SrNo" ? (
                    index1 + 1 + page * rowsPerPage
                  ) : (
                    row[cell.accessor]
                  )}
                </td>
              )
            );
          })}
        </tr>
      );
    });

  //-----------
  return (
    <Fragment>
      <Row className="mb-2">
        <Col lg="3" sm="4" xs="7">
          <div
            className="position-relative"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* <span className="bx bx-search-alt" style={{ fontSize: 30 }} /> */}
            {/* <input
              type="text"
              className="form-control"
              placeholder={"Search on page" + "..."}
              // onChange={e => handleSearch(e)}
              value={search}
              onChange={searchVal => {
                requestSearch(searchVal.target.value);
                setsearch(searchVal.target.value);
              }}
            /> */}
          </div>
        </Col>

        {IsAddButton === true ? (
          <Col lg="9" sm="8" xs="5">
            <div className="text-sm-end" style={{ marginTop: 5 }}>
              <Button
                type="button"
                // color="success"
                style={{
                  backgroundColor: "#004a8d",
                }}
                className="btn-rounded  mb-2 me-2"
                onClick={addNewItem}
              >
                <i className="mdi mdi-plus me-1" />
                Add New
              </Button>
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
          <TableHead className="table-light table-nowrap">
            <tr>
              {columns.map((headerGroup, index) =>
                headerGroup.hidden === false ? (
                  <th key={index}>{headerGroup.Header}</th>
                ) : (
                  ""
                )
              )}
            </tr>
          </TableHead>

          <tbody>
            {DataList !== null ? (
              renderRows()
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {DataList != null && TotalNoOfRows != null ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={pageSize}
          page={PageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
