import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Floating Form
import FloatingForm from "components/Forms/MasterData_Management/ApprovalStatus/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../../../components/Common/TableContainer";
//Import Table Component
import EmployeeListTableContainer from "components/Table/EmployeeManagement/EmployeeListTableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

//API Service
import _Employee from "helpers/Employee_Management/Employee";
import Department from "helpers/MasterData_Management/Department";
import Branch from "helpers/MasterData_Management/Branch";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { withTranslation } from "react-i18next";

const ApprovalStatus = props => {
  //meta title
  document.title = "Employee Management- Employees";
  //Table Column Names
  const anchorTable = _Employee.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);

  //Data Lists
  const [BranchList, setBranchList] = useState(null);
  const [DepartmentList, setDepartmentList] = useState([]);

  //Filter Values
  const [SelectedBranch, setSelectedBranch] = useState(null);
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [SearchString, setSearchString] = useState("");
  const [ActiveFilterValue, setActiveFilterValue] = useState(true);
  const [PageSize, setPageSize] = useState(10);
  const [PageNumber, setPageNumber] = useState(0);
  const branch = "crudTbEmployeeList";

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(state => state.crudTbEmployeeList.dataTable);
  const popupMessage = useSelector(
    state => state.crudTbEmployeeList.popupMessage
  );

  const initialData = async () => {
    try {
      setisLoading(true);
      const departments = await Department.Get_Department_DD();
      setDepartmentList(departments.Data.Details);
      const branches = await Branch.Get_All_Branch_DropDown();
      setBranchList(branches.Data.Details);
      // const response = await _Employee.Get_Employee_List_By_Rows(1, 10);
      // setInitialList(response.Data.Details);
      // setTotalRecords(response.Data.TotalRecords);
      // dispatch(setData(response.Data, branch));
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Department_Id: 0,
      Branch_Id: 0,
      Status_Id: true,
      Payroll_Id: "",
    },
    validationSchema: Yup.object({
      Department_Id: Yup.number()
        .required("Please Select a Department")
        .min(1, "Please Select a Department"),
      Branch_Id: Yup.number()
        .required("Please Select a Branch")
        .min(1, "Please Select a Branch"),
      Status_Id: Yup.boolean().required("Please Select a Status"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      setPageSize(10);
      setPageNumber(0);
      setInitialList(null);
      let body = {
        IsActive: values.Status_Id,
        PageNumber: 1,
        PageSize: 10,
        BranchId: values.Branch_Id,
        Department: values.Department_Id,
        Emp_Payrol: values.Payroll_Id === "" ? null : values.Payroll_Id,
      };
      const response = await _Employee.Get_Employee_List_By_Rows(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
        setisLoading(false);
        // dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }
    },
  });

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderBranch = () =>
    BranchList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Employee Management")}
            breadcrumbItem={props.t("Employees")}
          />
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Department
                  </Label>
                  <Input
                    type="select"
                    name="Department_Id"
                    onChange={e => {
                      validation.handleChange(e);
                      setSelectedDepartment(e.target.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.Department_Id || ""}
                    invalid={
                      validation.touched.Department_Id &&
                      validation.errors.Department_Id
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {DepartmentList == null || DepartmentList == undefined
                      ? ""
                      : renderDepartment()}
                  </Input>
                  {validation.touched.Department_Id &&
                  validation.errors.Department_Id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Department_Id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Branch
                  </Label>
                  <Input
                    type="select"
                    name="Branch_Id"
                    onChange={e => {
                      validation.handleChange(e);
                      setSelectedBranch(e.target.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.Branch_Id || ""}
                    invalid={
                      validation.touched.Branch_Id &&
                      validation.errors.Branch_Id
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {BranchList == null || BranchList == undefined
                      ? ""
                      : renderBranch()}
                  </Input>
                  {validation.touched.Branch_Id &&
                  validation.errors.Branch_Id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Branch_Id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Status
                  </Label>
                  <Input
                    type="select"
                    name="Status_Id"
                    onChange={e => {
                      validation.handleChange(e);
                      setActiveFilterValue(e.target.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.Status_Id || ""}
                    invalid={
                      validation.touched.Status_Id &&
                      validation.errors.Status_Id
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={true}>
                      Active
                    </option>
                    <option key={1} value={false}>
                      InActive
                    </option>
                  </Input>
                  {validation.touched.Status_Id &&
                  validation.errors.Status_Id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Status_Id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Payroll ID</Label>
                  <Input
                    name="Payroll_Id"
                    type="text"
                    placeholder="Enter Employee Payroll ID"
                    maxLength={255}
                    onKeyPressCapture={e => _validation.alphabetic(e)}
                    onChange={e => {
                      validation.handleChange(e);
                      setSearchString(e.target.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.Payroll_Id || ""}
                  />
                </div>
              </Col>
              <Col lg="3">
                <button
                  type="submit"
                  className="btn"
                  id="btn-save-event"
                  style={{
                    marginTop: 28,
                    backgroundColor: "#004a8d",
                    color: "white",
                  }}
                >
                  Search
                </button>
              </Col>
            </Row>
          </Form>

          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            //Employee List Table
            <EmployeeListTableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
              EmployeeListBranchId={SelectedBranch}
              EmployeeListDepartmentId={SelectedDepartment}
              EmployeeListIsAcitve={ActiveFilterValue}
              EmployeeListSearchString={SearchString}
              pageSize={PageSize}
              PageNumber={PageNumber}
              changePageSize={e => setPageSize(e)}
              changePageNumber={e => setPageNumber(e)}
              changeTotalRecords={e => setTotalRecords(e)}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      <FloatingForm branch={branch} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(ApprovalStatus);
