import React, { useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Encryption from "helpers/Encryption/Encryption";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Button,
  FormFeedback,
} from "reactstrap";
import classnames from "classnames";
//Import Floating Form
import FloatingForm from "components/Forms/Salary_Management/SalaryDetail/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";
//Redux Actions
import {
  addNewItem,
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setPopupMessageValues,
} from "store/actions";

import DynamicDataTable from "./DynamicDataTable";
//API Service
import _validation from "helpers/validation";
import _HeadCategory from "helpers/MasterData_Management/HeadCategory";
import _SalaryProfile from "helpers/Salary_Management/SalaryProfile";

const SalaryListAnchor = [
  // {
  //   Header: "Sr No.",
  //   accessor: "SrNo",
  //   hidden: false,
  // },
  {
    Header: "Salary Head",
    accessor: "Head_Name",
    hidden: false,
  },
  {
    Header: "Percentage",
    accessor: "Percentage",
    hidden: false,
  },
  {
    Header: "Amount",
    accessor: "Amount",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    hidden: false,
  },
];

//i18n
import { withTranslation } from "react-i18next";
const SalaryProfile = props => {
  const { className } = props;
  //meta title
  document.title = "Salary Management - Salary Profile";
  const branch = "crudTbSalaryProfile";
  const branchSalaryDetail = "crudTbSalaryDetail";
  const [IsEdit, setIsEdit] = useState(false);
  const [ProfileDetailData, setProfileDetailData] = useState(null);
  const [ProfileData, setProfileData] = useState(null);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [isLoading, setisLoading] = useState(false);
  // const [HeadCategoryList, setHeadCategoryList] = useState(null);
  const [SalaryDetailList, setSalaryDetailList] = useState([]);
  const [SalaryProfileResponse, setSalaryProfileResponse] = useState([]);

  const dispatch = useDispatch();

  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbSalaryProfile.popupMessage
  );
  const popupMessageSalaryDetail = useSelector(
    state => state.crudTbSalaryDetail.popupMessage
  );
  const SalaryDetails = useSelector(
    state => state.crudTbSalaryDetail.dataTable
  );
  const formOpen = useSelector(state => state.crudTbSalaryDetail.formOpen);

  const CheckEditScreen = async () => {
    setisLoading(true);
    let params = queryString.parse(location.search);
    const encryupteId = params.q;
    if (encryupteId !== undefined && encryupteId !== null) {
      const SalaryProfileId = Encryption.decrypt_url(encryupteId);
      params = queryString.parse(SalaryProfileId);
    }
    const __salaryProfileId = params.id;
    if (__salaryProfileId) {
      const response = await _SalaryProfile.Get_Salary_Profile_Detail_By_Id(
        __salaryProfileId
      );

      const profileDetailData = response.Data.Details.Salary_Profile_Detail;

      // await OnDepartmentChange(response.Data.Details[0].Department_Id);
      await setProfileData(response.Data.Details.SalaryProfile);
      await setProfileDetailData(profileDetailData);

      profileDetailData.map(item => {
        dispatch(addNewItem(item, branchSalaryDetail));
      });

      await setIsEdit(true);
    }

    setisLoading(false);
  };

  React.useEffect(() => {
    if (SalaryDetails !== null && SalaryDetails !== undefined) {
      SalaryDetails.map(item => {
        dispatch(removeItem(item, branchSalaryDetail));
      });
    }
    CheckEditScreen();
  }, []);

  React.useEffect(() => {
    setSalaryDetailList(SalaryDetails);
    dispatch(setPopupMessageValues(null, branchSalaryDetail));
  }, [popupMessageSalaryDetail]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const addNewItemSalaryDetail = () => {
    dispatch(toggleForm(true, branchSalaryDetail));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Salary_Profile_Name: ProfileData ? ProfileData.Salary_Profile_Name : 0,
    },
    validationSchema: Yup.object({
      Salary_Profile_Name: Yup.string().required(
        "Please Enter Salary Profile Name"
      ),
    }),
    onSubmit: async values => {
      setisLoading(true);
      //While Adding new Data
      if (IsEdit) {
        //While updating an existing data
        let body = {
          Salary_Profile_Id: ProfileData.Salary_Profile_Id,
          Salary_Profile_Name: values.Salary_Profile_Name,
          User: user.Employee_Id,
        };
        const response = await _SalaryProfile.Edit_Salary_Profile_Master(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          setactiveTab(2);
          setSalaryProfileResponse(data.Details[0]);
          // dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Salary_Profile_Id: 0,
          Salary_Profile_Name: values.Salary_Profile_Name,
          IsActive: true,
          User: user.Employee_Id,
        };
        const response = await _SalaryProfile.Save_Salary_Profile_Master(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          setactiveTab(2);
          setSalaryProfileResponse(data.Details[0]);
          //dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
        setisLoading(false);
        setactiveTab(2);
      }

      setisLoading(false);
    },
  });

  const handleSubmitSalaryDetails = async () => {
    setisLoading(true);

    if (SalaryDetails.length === 0) {
      dispatch(setPopupMessageValues("Please add Salary Detail", branch));
      setisLoading(false);
      return;
    }
    const Records = [];

    await SalaryDetails.map((item, index) =>
      Records.push({
        Head_Id: parseInt(item.Head_Id),
        Percentage: item.Percentage || 0,
        Amount: item.Amount || 0,
      })
    );

    let body = {
      Salary_Profile_Id: SalaryProfileResponse.Salary_Profile_Id,
      Records: Records,
      User: user.Employee_Id,
    };
    const response = await _SalaryProfile.Save_Salary_Profile_Detail(body);
    if (response.IsSuccess === true && response.Status_Code === 200) {
      let data = response.Data;
      //dispatch(addNewItem(data.Details[0], branchAcademicInformation));
      setisLoading(false);
      setactiveTab(3);
      //dispatch(setPopupMessageValues(response.Status_Message, branch));
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues(response.Status_Message, branch));
    }
    setisLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Salary Management")}
            breadcrumbItem={props.t("Salary Profile")}
          />
          {/* <Table columns={columns} data={data} /> */}
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Salary Profile Wizard</h4>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1);
                          }}
                          disabled={
                            !(passedSteps || []).includes(1) && IsEdit === false
                          }
                        >
                          <span className="number">1.</span> Salary Profile
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2);
                          }}
                          disabled={
                            !(passedSteps || []).includes(2) && IsEdit === false
                          }
                        >
                          <span className="number">2.</span> Salary Details
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 3 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3);
                          }}
                          disabled={
                            !(passedSteps || []).includes(3) && IsEdit === false
                          }
                        >
                          <span className="number">3.</span> Confirm Detail
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label for="Salary_Profile_Name">
                                  Salary Profile Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="Salary_Profile_Name"
                                  name="Salary_Profile_Name"
                                  placeholder="Enter Salary Profile Name"
                                  required
                                  onKeyPress={_validation.alphabetic}
                                  maxLength={50}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.Salary_Profile_Name || ""
                                  }
                                  invalid={
                                    validation.touched.Salary_Profile_Name &&
                                    validation.errors.Salary_Profile_Name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.Salary_Profile_Name &&
                                validation.errors.Salary_Profile_Name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Salary_Profile_Name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <Button
                                type="button"
                                onClick={() => {
                                  // toggleTab(activeTab - 1);
                                }}
                              >
                                Previous
                              </Button>
                              <Button
                                type="submit"
                                className="btn btn-success"
                                style={{ marginLeft: 20 }}
                              >
                                Next
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div>
                          <Form>
                            <Row className="mb-2">
                              <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={addNewItemSalaryDetail}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <DynamicDataTable
                                  HeaderData={SalaryListAnchor}
                                  BodyData={SalaryDetailList}
                                  branch={branchSalaryDetail}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{ marginLeft: 20 }}
                                  onClick={handleSubmitSalaryDetails}
                                >
                                  Next
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId={3}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-muted">
                                  Salary Profile has been successfully created.
                                </p>
                                <Link
                                  to="/salary_management/salary_profiles"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button
                                    type="button"
                                    className="btn btn-success"
                                    style={{ marginLeft: 20 }}
                                  >
                                    Proceed
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm branch={branchSalaryDetail} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(SalaryProfile);
