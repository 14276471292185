import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Calendar_Years = () => {
  let url = "api/Calendar/Get_Calendar_Years";

  return get(url);
};

const Get_Calendar_Months_By_Year = year => {
  let url = `api/Calendar/Get_Calendar_Months_By_Year?year=${year}`;

  return get(url);
};

const Get_Calendar_DD = (value = 0) => {
  let url = "api/Calendar/Get_Calendar_DropDown";

  return get(url);
};

const Get_Calendar_List = body => {
  let url = "api/Calendar/Get_Calendar_List?id=0";

  return get(url, body);
};

const Get_Calendar_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Calendar/Get_Calendar_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Calendar = body => {
  let url = "api/Calendar/Save_Calendar";
  return post(url, body);
};

const Edit_Calendar = body => {
  let url = "api/Calendar/Edit_Calendar";
  return post(url, body);
};

const Delete_Calendar = body => {
  let url = "api/Calendar/Edit_Calendar_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
  },
  {
    Header: "Date",
    accessor: "Date",
    hidden: false,
  },
  {
    Header: "Day",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Month",
    accessor: "Month",
    hidden: false,
  },
  {
    Header: "Year",
    accessor: "Year",
    hidden: false,
  },
  {
    Header: "Holiday",
    accessor: "Holiday",
    hidden: false,
  },
  {
    Header: "Remarks",
    accessor: "Remarks",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Calendar_Years,
  Get_Calendar_Months_By_Year,
  Get_Calendar_DD,
  Get_Calendar_List,
  Get_Calendar_List_By_Rows,
  Save_Calendar,
  Edit_Calendar,
  Delete_Calendar,
  anchorTable,
};
