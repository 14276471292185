import React, { useState } from "react";
import Moment from "moment";
import InputMask from "react-input-mask";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Encryption from "helpers/Encryption/Encryption";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
import { Modal, ModalBody } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Button,
  FormFeedback,
} from "reactstrap";
import classnames from "classnames";
import FloatingForm from "components/Forms/Employee_Management/Academic_Information/form";
import FloatingForm1 from "components/Forms/Employee_Management/Function_Information/form";
import FloatingForm2 from "components/Forms/Employee_Management/Employer_Record/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import user1 from "assets/images/users/avatar-1.jpg";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  addNewItem,
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setFiles,
  setPopupMessageValues,
} from "store/actions";

import DynamicDataTable from "./DynamicDataTable";
import _validation from "helpers/validation";
import _Gender from "helpers/MasterData_Management/Gender";
import _BloodGroup from "helpers/MasterData_Management/BloodGroup";
import _InsurancePlan from "helpers/MasterData_Management/InsurancePlan";
import _ContractType from "helpers/MasterData_Management/ContractType";
import _Department from "helpers/MasterData_Management/Department";
import _Designation from "helpers/MasterData_Management/Designation";
import _Employee from "helpers/Employee_Management/Employee";
import _Shift from "helpers/Shift_Management/Shift";
import _Role from "helpers/MasterData_Management/Role";
import _Profile from "helpers/Profile_Management/Profile";
import _Country from "helpers/MasterData_Management/Country";
import _State from "helpers/MasterData_Management/State";
import _City from "helpers/MasterData_Management/City";
import _Branch from "helpers/MasterData_Management/Branch";
import _SalaryProfile from "helpers/Salary_Management/SalaryProfile";

const EmployeeAcademicListAnchor = [
  {
    Header: "Institution Name",
    accessor: "Institute_Name",
    hidden: false,
  },
  {
    Header: "Degree Name",
    accessor: "Degree_Name",
    hidden: false,
  },
  {
    Header: "GPA",
    accessor: "GPA",
    hidden: false,
  },
  {
    Header: "Grade",
    accessor: "Grade",
    hidden: false,
  },
  {
    Header: "Year",
    accessor: "Awarding_Year",
    hidden: false,
  },
  {
    Header: "City Name",
    accessor: "City_Name",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    hidden: false,
  },
];
const EmployeRecordListAnchor = [
  {
    Header: "Employer Name",
    accessor: "Employer_Name",
    hidden: false,
  },
  {
    Header: "Job Description",
    accessor: "Job_Description",
    hidden: false,
  },
  {
    Header: "Start Date",
    accessor: "Start_Date",
    hidden: false,
  },
  {
    Header: "End Date",
    accessor: "End_Date",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    hidden: false,
  },
];
const EmployeeFunctionListAnchor = [
  {
    Header: "Function Name",
    accessor: "Function_Name",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    hidden: false,
  },
];
//i18n
import { withTranslation } from "react-i18next";

function CNICInput(props) {
  return (
    <InputMask
      mask="99999-9999999-9"
      value={props.value}
      onChange={props.onChange}
    >
      <Input required placeholder="Enter CNIC No." />
    </InputMask>
  );
}
function PhoneInput(props) {
  return (
    <InputMask
      mask="9999-9999999"
      value={props.value}
      onChange={props.onChange}
    >
      <Input required placeholder="Enter Phone No." />
    </InputMask>
  );
}

const ApprovalStatus = props => {
  const { className } = props;
  //meta title
  document.title = "Employee Management - Employee Addition";
  const branch = "crudTbEmployee";
  const branchAcademicInformation = "crudTbEmployeeAcademicInformation";
  const branchFunctionInformation = "crudTbEmployeeFunctionInformation";
  const branchEmployerRecord = "crudTbEmployeeEmployerRecord";
  const [IsEdit, setIsEdit] = useState(false);
  const [EditData, setEditData] = useState(null);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [isLoading, setisLoading] = useState(false);
  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);
  const [isSpinnerLoaderState, setisSpinnerLoaderState] = useState(false);
  const [isSpinnerLoaderCity, setisSpinnerLoaderCity] = useState(false);
  const [isSpinnerLoaderBranch, setisSpinnerLoaderBranch] = useState(false);
  const [isSpinnerLoaderDesignation, setisSpinnerLoaderDesignation] =
    useState(false);
  const [isSpinnerLoaderReportTo, setisSpinnerLoaderReportTo] = useState(false);
  const [DisableSubmit, setDisableSubmit] = useState(false);
  const [ShowDialoguePopup, setShowDialoguePopup] = useState(false);
  const [EmployeePhotoPath, setEmployeePhotoPath] = useState(null);
  const [EmployeeIDByCode, setEmployeeIDByCode] = useState(null);
  const [GenderList, setGenderList] = useState(null);
  const [BloodGroupList, setBloodGroupList] = useState(null);
  const [InsurancePlanList, setInsurancePlanList] = useState(null);
  const [ContractTypeList, setContractTypeList] = useState(null);
  const [DepartmentList, setDepartmentList] = useState(null);
  const [DesignationList, setDesignationList] = useState(null);
  const [SalaryProfileList, setSalaryProfileList] = useState(null);
  const [SelectedDesignation, setSelectedDesignation] = useState(false);
  const [SelectedDepartment, setSelectedDepartment] = useState(false);
  const [HODAlreadyExist, setHODAlreadyExist] = useState(false);
  const [ShiftList, setShiftList] = useState(null);
  const [RoleList, setRoleList] = useState(null);
  const [ProfileList, setProfileList] = useState(null);
  const [CountryList, setCountryList] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);
  const [StateList, setStateList] = useState(null);
  const [CityList, setCityList] = useState(null);
  const [BranchList, setBranchList] = useState(null);
  const [NewEmployeeData, setNewEmployeeData] = useState(null);
  const [Password, setPassword] = useState("");
  const [IsActiveCheck, setIsActiveCheck] = useState(false);
  const [EmployeeList, setEmployeeList] = useState(null);
  const [EmployeeAcademicList, setEmployeeAcademicList] = useState([]);
  const [EmployeeFunctionList, setEmployeeFunctionList] = useState([]);
  const [EmployeeEmployerRecordList, setEmployeeEmployerRecordList] = useState(
    []
  );

  const [CNIC, setCNIC] = useState("");
  const [PHONE, setPHONE] = useState("");
  const handleInputCNIC = ({ target: { value } }) => setCNIC(value);
  const handleInputPHONE = ({ target: { value } }) => setPHONE(value);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(state => state.crudTbEmployee.popupMessage);
  const Files = useSelector(state => state.crudTbEmployee.Files);
  const popupMessageAcademic = useSelector(
    state => state.crudTbEmployeeAcademicInformation.popupMessage
  );
  const popupMessageFunction = useSelector(
    state => state.crudTbEmployeeFunctionInformation.popupMessage
  );
  const popupMessageEmployerRecord = useSelector(
    state => state.crudTbEmployeeEmployerRecord.popupMessage
  );
  const AcademicList = useSelector(
    state => state.crudTbEmployeeAcademicInformation.dataTable
  );
  const FunctionList = useSelector(
    state => state.crudTbEmployeeFunctionInformation.dataTable
  );
  const EmployerRecordList = useSelector(
    state => state.crudTbEmployeeEmployerRecord.dataTable
  );
  const formOpen1 = useSelector(
    state => state.crudTbEmployeeAcademicInformation.formOpen
  );
  const formOpen2 = useSelector(
    state => state.crudTbEmployeeFunctionInformation.formOpen
  );
  const formOpen3 = useSelector(
    state => state.crudTbEmployeeEmployerRecord.formOpen
  );
  const EmployeeListt = useSelector(state => state.crudTbEmployee.dataTable);

  const initialData = async () => {
    setisLoading(true);
    dispatch(setFiles(null, branch));
    try {
      const response_gender = await _Gender.Get_Gender_DD();
      setGenderList(response_gender.Data.Details);

      const response_bloodgroup = await _BloodGroup.Get_BloodGroup_DD();
      setBloodGroupList(response_bloodgroup.Data.Details);

      const response_insuranceplan =
        await _InsurancePlan.Get_InsurancePlans_DD();
      setInsurancePlanList(response_insuranceplan.Data.Details);

      const response_contacttype = await _ContractType.Get_ContractType_DD();
      setContractTypeList(response_contacttype.Data.Details);

      const response_salaryprofile =
        await _SalaryProfile.Get_Salary_Profile_Master_DD();
      setSalaryProfileList(response_salaryprofile.Data.Details);

      const response_department = await _Department.Get_Department_DD();
      setDepartmentList(response_department.Data.Details);

      const response_role = await _Role.Get_Role_DD();
      setRoleList(response_role.Data.Details);

      const response_shift = await _Shift.Get_Shift_DD();
      setShiftList(response_shift.Data.Details);

      const response_country = await _Country.Get_Country_DD();
      setCountryList(response_country.Data.Details);

      dispatch(setData([], branchAcademicInformation));
      dispatch(setData([], branchFunctionInformation));
      dispatch(setData([], branchEmployerRecord));
      dispatch(setFiles(null, branch));
      await CheckEditScreen();

      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      console.log(error);
      setisLoading(false);
    }
  };

  const CheckEditScreen = async () => {
    setisLoading(true);
    let params = queryString.parse(location.search);
    const encryupteId = params.q;
    if (encryupteId !== undefined && encryupteId !== null) {
      const employeeId = Encryption.decrypt_url(encryupteId);
      params = queryString.parse(employeeId);
    }
    const __employee = params.id;
    if (__employee) {
      try {
        const response = await _Employee.Get_Employee_By_Id(__employee);
        await setEditData(response.Data.Details.Employee);
        await setIsEdit(true);
        await setIsActiveCheck(response.Data.Details.Employee.IsActive);
        setEmployeePhotoPath(
          response.Data.Details.Employee_Pic.User_Photo_Path
        );
        setCNIC(response.Data.Details.Employee.CNIC);
        setPHONE(response.Data.Details.Employee.Mobile);
        if (response.Data.Details.Employee.Report_To === "")
          setSelectedDesignation(true);
        dispatch(
          setData(
            response.Data.Details.Employee_Academics,
            branchAcademicInformation
          )
        );
        dispatch(setPopupMessageValues("Added", branchAcademicInformation));
        dispatch(
          setData(
            response.Data.Details.Employee_Functions,
            branchFunctionInformation
          )
        );
        dispatch(setPopupMessageValues("Added", branchFunctionInformation));
        dispatch(
          setData(response.Data.Details.Employee_Record, branchEmployerRecord)
        );
        dispatch(setPopupMessageValues("Added", branchEmployerRecord));
        if (response.Data.Details.Employee.Designation_Id == "1") {
          setSelectedDesignation(true);
        } else {
          setSelectedDesignation(false);
        }
        await OnDepartmentChange(response.Data.Details.Employee.Department_Id);
        await OnConutryChange(response.Data.Details.Employee.Country_Id);
        await OnStateChange(response.Data.Details.Employee.State_Id);
        await OnCityChange(response.Data.Details.Employee.City_Id);

        if (response.Data.Details.Employee.Designation_Id === "6") {
          setSelectedDesignation(true);
        }
      } catch (error) {
        dispatch(setPopupMessageValues("Opps! Server is down.", branch));
        console.log(error);
      }
    }
    setisLoading(false);
  };

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadFiles = (files, previewUrl) => {
    if (files.length === 1) {
      files.map(async file => {
        const fileSize = files[0].size;
        if (fileSize > 2097152) {
          alert(`Maximum file size is 2 Mb.`);
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          await convertBase64(file, result => {
            const newItem = {
              Name: file.name,
              fileType: file.type,
              File: result,
              previewUrl: previewUrl,
            };
            dispatch(setFiles(newItem, branch));
          });
        } else {
          alert(`Only Images are allowed.`);
        }
      });
    }
  };

  const handleFileEvent = e => {
    // const chosenFiles = Array.prototype.slice.call(e.target.files);
    // dispatch(setFiles(null, branch));
    // handleUploadFiles(chosenFiles);

    const chosenFiles = Array.prototype.slice.call(e.target.files);
    dispatch(setFiles(null, branch));
    const file = e.target.files[0];
    const reader = new FileReader();
    let previewUrl;
    reader.onload = () => {
      previewUrl = reader.result;
      handleUploadFiles(chosenFiles, previewUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //-------------------
  const handleActiveStatus = async value => {
    setisLoading(true);
    console.log(!IsActiveCheck);
    const body = {
      Employee_Id: EditData.Employee_Id,
      IsActive: !IsActiveCheck,
      User: user.Employee_Id,
    };
    const response = await _Employee.Update_Employee_Status(body);
    console.log(response);
    if (response.IsSuccess === true && response.Status_Code === 200) {
      await setIsActiveCheck(!IsActiveCheck);
      dispatch(setPopupMessageValues(response.Status_Message, branch));
    } else {
      dispatch(
        setPopupMessageValues("Could not update the employee status", branch)
      );
    }
    setisLoading(false);
  };
  const OnDepartmentChange = async e => {
    const department = e;
    setSelectedDepartment(e);
    try {
      setisSpinnerLoaderDesignation(true);
      //const department = e.target.value;
      const response_designation = await _Designation.Get_Designation_DD(
        department
      );
      setDesignationList(response_designation.Data.Details);

      setisSpinnerLoaderDesignation(false);
      setisSpinnerLoaderReportTo(true);

      const response_employee = await _Employee.Get_Employee_By_Department_DD(
        department
      );
      const data = response_employee.Data.Details;
      setEmployeeList(data);
      setisSpinnerLoaderReportTo(false);
      // }
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      console.log(error);
    }
  };

  const OnConutryChange = async e => {
    const value = e;
    try {
      setisSpinnerLoaderState(true);
      const response_state = await _State.Get_State_DD(value);
      setStateList(response_state.Data.Details);
      setisSpinnerLoaderState(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      console.log(error);
    }
  };

  const OnStateChange = async e => {
    try {
      const value = e;
      setisSpinnerLoaderCity(true);
      const response_city = await _City.Get_City_DD(value);
      setCityList(response_city.Data.Details);
      setisSpinnerLoaderCity(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      console.log(error);
    }
  };

  const OnCityChange = async e => {
    const value = e;
    try {
      setisSpinnerLoaderBranch(true);
      const response_branch = await _Branch.Get_Branch_DD(value);
      setBranchList(response_branch.Data.Details);
      setisSpinnerLoaderBranch(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      console.log(error);
    }
  };

  const OnEmployeeCodeChange = async e => {
    try {
      setisSpinnerLoaderEmployee(true);
      let eomployeeCode = e.target.value;
      eomployeeCode = eomployeeCode.toString();
      const response = await _Employee.Get_Employee_List_By_Rows(1, 99999999);
      const data = response.Data.Details;
      const index = await data.findIndex(item => {
        return item.Employee_Code == eomployeeCode;
      });

      if (index !== -1) {
        setEmployeeIDByCode(data[index].Employee_Id);
        setShowDialoguePopup(true);
        setDisableSubmit(true);
        setisSpinnerLoaderEmployee(false);
      } else {
        setShowDialoguePopup(false);
        setDisableSubmit(false);
        setisSpinnerLoaderEmployee(false);
      }
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      console.log(error);
    }
  };

  const OnDesignationChange = async e => {
    const value = e;
    if (value == 1) {
      setSelectedDesignation(true);
    } else {
      setSelectedDesignation(false);
    }
  };

  const onCloseClick = () => {
    setShowDialoguePopup(false);
  };

  React.useEffect(() => {
    initialData();
  }, []);

  const handleNavigate = () => {
    setShowDialoguePopup(false);
    navigate(
      `/employee_management/employee_addition?q=${Encryption.encrypt_url(
        "id=" + EmployeeIDByCode
      )}`
    );
    CheckEditScreen();

    setDisableSubmit(false);
  };

  React.useEffect(() => {
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  React.useEffect(() => {
    setEmployeeAcademicList(AcademicList);
    dispatch(setPopupMessageValues(null, branchAcademicInformation));
  }, [popupMessageAcademic, AcademicList]);

  React.useEffect(() => {
    setEmployeeFunctionList(FunctionList);
    dispatch(setPopupMessageValues(null, branchFunctionInformation));
  }, [popupMessageFunction, FunctionList]);

  React.useEffect(() => {
    setEmployeeEmployerRecordList(EmployerRecordList);
    dispatch(setPopupMessageValues(null, branchEmployerRecord));
  }, [popupMessageEmployerRecord, EmployeRecordListAnchor]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const renderGender = () =>
    GenderList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderBloodGroup = () =>
    BloodGroupList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderInsurancePlan = () =>
    InsurancePlanList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderContractType = () =>
    ContractTypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderDesignation = () =>
    DesignationList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderEmployee = () =>
    EmployeeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderShift = () =>
    ShiftList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderRole = () =>
    RoleList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderProfile = () =>
    ProfileList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderCountry = () =>
    CountryList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderState = () =>
    StateList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderCity = () =>
    CityList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderBranch = () =>
    BranchList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderSalaryProfile = () =>
    SalaryProfileList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const addNewItemAcademic = () => {
    dispatch(toggleForm(true, branchAcademicInformation));
  };

  const addNewItemFunction = () => {
    dispatch(toggleForm(true, branchFunctionInformation));
  };

  const addNewItemEmployerRecord = () => {
    dispatch(toggleForm(true, branchEmployerRecord));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Employee_Id: IsEdit ? EditData.Employee_Id : 0,
      Employee_Code: IsEdit ? EditData.Employee_Code : "",
      First_Name: IsEdit ? EditData.First_Name : "",
      Last_Name: IsEdit ? EditData.Last_Name : "",
      Gender_Id: IsEdit ? EditData.Gender_Id : "",
      DOB: IsEdit ? EditData.DOB : "",
      // CNIC: IsEdit ? CNIC : "",
      // Mobile: IsEdit ? PHONE : "",
      BloodGroup_Id: IsEdit ? EditData.BloodGroup_Id : "",
      DOJ: IsEdit ? EditData.DOJ : "",
      Email: IsEdit ? EditData.Email : "",
      // Password: "",
      InsurancePlan_Id: IsEdit ? EditData.InsurancePlan_Id : "",
      ContractType_Id: IsEdit ? EditData.ContractType_Id : "",
      Department_Id: IsEdit ? EditData.Department_Id : "",
      Designation_Id: IsEdit ? EditData.Designation_Id : "",
      Report_To: IsEdit
        ? EditData.Report_To === ""
          ? "self"
          : EditData.Report_To
        : "",
      Role_Id: IsEdit ? EditData.Role_Id : "",
      Shift_Id: IsEdit ? EditData.Shift_Id : "",
      Gross_Salary_Amount: IsEdit ? EditData.Gross_Salary_Amount : "",
      Device_Id: IsEdit ? EditData.Device_Id : "",
      Country_Id: IsEdit ? EditData.Country_Id : "",
      State_Id: IsEdit ? EditData.State_Id : "",
      City_Id: IsEdit ? EditData.City_Id : "",
      Branch_Id: IsEdit ? EditData.Branch_Id : "",
      Salary_Profile_Id: IsEdit ? EditData.Salary_Profile_Id : "",
      Address: IsEdit ? EditData.Address : "",
    },
    validationSchema: Yup.object({
      Employee_Code: Yup.string().required("Please Enter Employee Code"),
      First_Name: Yup.string().required("Please Enter First Name"),
      Last_Name: Yup.string().required("Please Enter Last Name"),
      Gender_Id: Yup.string().required("Please Select Gender"),
      DOB: Yup.string().required("Please Enter DOB"),
      // CNIC: Yup.string().required("Please Enter CNIC"),
      // Mobile: Yup.string().required("Please Enter Mobile"),
      BloodGroup_Id: Yup.string().required("Please Select BloodGroup_Id"),
      DOJ: Yup.string().required("Please Enter DOJ"),
      Email: Yup.string().required("Please Enter Email"),
      // Password: Yup.string().required("Please Enter Password"),
      InsurancePlan_Id: Yup.string().required("Please Select Insurance Plan"),
      ContractType_Id: Yup.string().required("Please Select Contract Type"),
      Department_Id: Yup.string().required("Please Select Department"),
      Designation_Id: Yup.string().required("Please Select Designation"),
      Report_To: Yup.string().required("Please Select Report To"),
      Role_Id: Yup.string().required("Please Select Role"),
      Shift_Id: Yup.string().required("Please Select Shift"),
      Salary_Profile_Id: Yup.string().required("Please Select Salary Profile"),
      Device_Id: Yup.number().required("Please Enter Device ID"),
      Gross_Salary_Amount: Yup.number()
        .required("Please Enter a Gross Salary Amount")
        .min(1),
      Country_Id: Yup.string().required("Please Select Country"),
      State_Id: Yup.string().required("Please Select State"),
      City_Id: Yup.string().required("Please Select City"),
      Branch_Id: Yup.string().required("Please Select Branch"),
      Address: Yup.string().required("Please Enter Address"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      //While Adding new Data
      if (SelectedDesignation === true) {
        const response = await _Employee.Get_HOD_By_Department(
          SelectedDepartment
        );
        if (
          response.IsSuccess === true &&
          response.Status_Code === 200 &&
          response.Data.Details.length > 0
        ) {
          const data = response.Data.Details;
          if (
            IsEdit === true &&
            EditData.Employee_Code !== data[0].Employee_Code
          ) {
            setHODAlreadyExist(true);
            dispatch(
              setPopupMessageValues(
                "Selected department already has an active HOD.",
                branch
              )
            );
            setisLoading(false);
            return;
          } else if (
            IsEdit === true &&
            EditData.Employee_Code === data[0].Employee_Code
          ) {
            setHODAlreadyExist(true);
            setisLoading(false);
          } else if (IsEdit === false && data.length > 0) {
            setHODAlreadyExist(true);
            dispatch(
              setPopupMessageValues(
                "Selected department already has an active HOD.",
                branch
              )
            );
            setisLoading(false);
            return;
          }
        } else {
          setHODAlreadyExist(false);
        }
      }
      let body = {
        Employee_Id: values.Employee_Id,
        Employee_Code: values.Employee_Code,
        CNIC: CNIC,
        Email: values.Email,
        Mobile: PHONE,
        First_Name: values.First_Name,
        Last_Name: values.Last_Name,
        DOJ: values.DOJ,
        DOB: values.DOB,
        Gender_Id: values.Gender_Id,
        Country_Id: values.Country_Id,
        Shift_Id: values.Shift_Id,
        State_Id: values.State_Id,
        City_Id: values.City_Id,
        Branch_Id: values.Branch_Id,
        Address: values.Address,
        Report_To: SelectedDesignation
          ? IsEdit
            ? values.Employee_Id
            : 1
          : values.Report_To,
        Designation_Id: values.Designation_Id,
        Department_Id: values.Department_Id,
        Role_Id: values.Role_Id,
        BloodGroup_Id: values.BloodGroup_Id,
        ContractType_Id: values.ContractType_Id,
        Salary_Profile_Id: values.Salary_Profile_Id,
        InsurancePlan_Id: values.InsurancePlan_Id,
        User_Photo_Path: 0,
        DOP_User_Id: 0,
        Device_Id: values.Device_Id,
        Gross_Salary_Amount: values.Gross_Salary_Amount,
        User_Photo_Path: {
          Name: Files.Name,
          File: Files.File,
        },
        User: user.Employee_Id,
      };

      if (IsEdit !== true) {
        body = { ...body, Password: Password };
      }

      const response =
        body.Employee_Id === 0
          ? await _Employee.Save_Employee(body)
          : await _Employee.Edit_Employee(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        let data = response.Data;
        setNewEmployeeData(data.Details.Employee);
        setactiveTab(2);
        dispatch(addNewItem(data.Details.Employee, branch));
        setisLoading(false);
        //dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }

      setisLoading(false);
    },
  });

  const handleSubmitAcademicInformation = async () => {
    setisLoading(true);

    if (EmployeeAcademicList.length === 0) {
      dispatch(
        setPopupMessageValues(
          "Please add Employee Academic Information",
          branch
        )
      );
      setisLoading(false);
      return;
    }
    const Records = [];

    await EmployeeAcademicList.map((item, index) =>
      Records.push({
        Degree_Id: item.Degree_Id,
        EmployeeAttachments_Id: 0,
        Grade: item.Grade,
        GPA: item.GPA,
        Institute_Name: item.Institute_Name,
        Institute_City_Id: item.City_Id,
        Awarding_Year: item.Awarding_Year,
      })
    );

    let body = {
      Employee_Id: IsEdit ? EditData.Employee_Id : NewEmployeeData.Employee_Id,
      Records: Records,
      User: user.Employee_Id,
    };
    const response = await _Employee.Save_Employee_Academic_Information(body);

    if (response.IsSuccess === true && response.Status_Code === 200) {
      let data = response.Data;
      //dispatch(addNewItem(data.Details[0], branchAcademicInformation));
      setisLoading(false);
      setactiveTab(3);
      //dispatch(setPopupMessageValues(response.Status_Message, branch));
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues(response.Status_Message, branch));
    }

    setisLoading(false);
  };

  const SkipAcedamicInformation = () => {
    passedSteps.push(3);
    setactiveTab(3);
  };

  const SkipEmployerRecord = () => {
    passedSteps.push(5);
    setactiveTab(5);
  };

  const handleSubmitFunctionInformation = async () => {
    setisLoading(true);

    if (EmployeeFunctionList.length === 0) {
      dispatch(
        setPopupMessageValues(
          "Please add Employee Function Information",
          branch
        )
      );
      setisLoading(false);
      return;
    }
    const Records = [];

    await EmployeeFunctionList.map((item, index) =>
      Records.push({
        Function_Id: item.Function_Id,
      })
    );

    let body = {
      Employee_Id: IsEdit ? EditData.Employee_Id : NewEmployeeData.Employee_Id,
      Records: Records,
      User: user.Employee_Id,
    };
    const response = await _Employee.Save_Employee_Function_Information(body);

    if (response.IsSuccess === true && response.Status_Code === 200) {
      let data = response.Data;
      //dispatch(addNewItem(data.Details[0], branchAcademicInformation));
      setisLoading(false);
      setactiveTab(4);
      //dispatch(setPopupMessageValues(response.Status_Message, branch));
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues(response.Status_Message, branch));
    }

    setisLoading(false);
  };

  const handleSubmitEmployerRecord = async () => {
    setisLoading(true);
    if (EmployeeEmployerRecordList.length === 0) {
      dispatch(setPopupMessageValues("Please add Employer Record", branch));
      setisLoading(false);
      return;
    }
    const Records = [];

    await EmployeeEmployerRecordList.map((item, index) =>
      Records.push({
        EmployeeEmployer_Id: 0,
        Employer_Name: item.Employer_Name,
        Job_Description: item.Job_Description,
        Start_Date: item.Start_Date,
        End_Date: item.End_Date,
      })
    );

    let body = {
      Employee_Id: IsEdit ? EditData.Employee_Id : NewEmployeeData.Employee_Id,
      Records: Records,
      User: user.Employee_Id,
    };
    const response = await _Employee.Save_Employee_Record_Information(body);

    if (response.IsSuccess === true && response.Status_Code === 200) {
      let data = response.Data;
      // dispatch(addNewItem(data.Details[0], branchAcademicInformation));
      setisLoading(false);
      setactiveTab(5);
      // dispatch(setPopupMessageValues(response.Status_Message, branch));
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues(response.Status_Message, branch));
    }

    setisLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Employee Management")}
            breadcrumbItem={props.t("Employee Addition")}
          />
          {/* <Table columns={columns} data={data} /> */}
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Employee Wizard</h4>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1);
                          }}
                          disabled={
                            !(passedSteps || []).includes(1) && IsEdit === false
                          }
                        >
                          <span className="number">1.</span> Employee Info
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2);
                          }}
                          disabled={
                            !(passedSteps || []).includes(2) && IsEdit === false
                          }
                        >
                          <span className="number">2.</span> Academic Info
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 3 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3);
                          }}
                          disabled={
                            !(passedSteps || []).includes(3) && IsEdit === false
                          }
                        >
                          <span className="number">3.</span> Function Info
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 4 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 4 })}
                          onClick={() => {
                            setactiveTab(4);
                          }}
                          disabled={
                            !(passedSteps || []).includes(4) && IsEdit === false
                          }
                        >
                          <span className="number">4.</span> Employer Info
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 5 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 5 })}
                          onClick={() => {
                            setactiveTab(5);
                          }}
                          disabled={
                            !(passedSteps || []).includes(5) && IsEdit === false
                          }
                        >
                          <span className="number">5.</span> Confirm Detail
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col lg="3">
                              <div className="mb-3"></div>
                              <h3>Personal Information</h3>
                            </Col>
                          </Row>

                          <Row style={{ margin: "5px 0px" }}>
                            <Col lg="3" md="4">
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center", // Added property
                                }}
                              >
                                <img
                                  src={
                                    Files !== null
                                      ? Files.previewUrl
                                      : EmployeePhotoPath !== null
                                      ? EmployeePhotoPath
                                      : user1
                                  }
                                  style={{
                                    width: "150px",
                                    border: "1px solid black",
                                    margin: "auto",
                                  }}
                                />
                                <label
                                  htmlFor="fileUpload"
                                  style={{
                                    margin: "5px auto",
                                    padding: "3px 0px",
                                    cursor: "pointer",
                                    display: "block",
                                    backgroundColor: "#556ee6",
                                    borderRadius: 5,
                                    width: 150,
                                    color: "white",
                                  }}
                                >
                                  Upload Photo
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={handleFileEvent}
                                  id="fileUpload"
                                  multiple={false}
                                  style={{ display: "none" }}
                                  accept="image/jpeg, image/png"
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Employee_Code">
                                  <span style={{ color: "red" }}>* </span>
                                  Employee Code{" "}
                                </Label>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Col lg="9">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="Employee_Code"
                                      name="Employee_Code"
                                      placeholder="Enter Employee Code"
                                      onKeyPress={_validation.Number2}
                                      // disabled={IsEdit}
                                      maxLength={10}
                                      onChange={validation.handleChange}
                                      onBlur={e => OnEmployeeCodeChange(e)}
                                      value={
                                        validation.values.Employee_Code || ""
                                      }
                                      invalid={
                                        validation.touched.Employee_Code &&
                                        validation.errors.Employee_Code
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  <Col lg="1"></Col>
                                  <Col lg="2">
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="30"
                                      visible={isSpinnerLoaderEmployee}
                                    />
                                  </Col>
                                </div>
                                {validation.touched.Employee_Code &&
                                validation.errors.Employee_Code ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Employee_Code}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {IsEdit ? (
                              <Col>
                                <div className="mb-3">
                                  <Label for="Employee_Code">
                                    InActive/Active
                                  </Label>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name="IsActive"
                                          onClick={e =>
                                            handleActiveStatus(e.target.checked)
                                          }
                                          checked={IsActiveCheck}
                                          color="primary"
                                        />
                                      }
                                      label=""
                                    />
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>

                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="First_Name">
                                  <span style={{ color: "red" }}>* </span>First
                                  Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="First_Name"
                                  name="First_Name"
                                  placeholder="Enter First Name"
                                  onKeyPress={_validation.alphabetic}
                                  maxLength={255}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.First_Name || ""}
                                  invalid={
                                    validation.touched.First_Name &&
                                    validation.errors.First_Name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.First_Name &&
                                validation.errors.First_Name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.First_Name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Last_Name">
                                  <span style={{ color: "red" }}>* </span>Last
                                  Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="Last_Name"
                                  name="Last_Name"
                                  placeholder="Enter Last Name"
                                  onKeyPress={_validation.alphabetic}
                                  maxLength={255}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Last_Name || ""}
                                  invalid={
                                    validation.touched.Last_Name &&
                                    validation.errors.Last_Name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.Last_Name &&
                                validation.errors.Last_Name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Last_Name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Gender_Id">
                                  <span style={{ color: "red" }}>* </span>Gender
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Gender_Id"
                                  name="Gender_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Gender_Id || ""}
                                  invalid={
                                    validation.touched.Gender_Id &&
                                    validation.errors.Gender_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {GenderList == null || GenderList == undefined
                                    ? ""
                                    : renderGender()}
                                </Input>
                                {validation.touched.Gender_Id &&
                                validation.errors.Gender_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Gender_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="DOB">
                                  <span style={{ color: "red" }}>* </span>Date
                                  of Birth
                                </Label>

                                <Input
                                  type="date"
                                  className="form-control"
                                  id="DOB"
                                  name="DOB"
                                  placeholder="Enter DOB"
                                  onKeyPress={_validation.Number2}
                                  maxLength={10}
                                  max={currentDate}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.DOB || ""}
                                  invalid={
                                    validation.touched.DOB &&
                                    validation.errors.DOB
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.DOB &&
                                validation.errors.DOB ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.DOB}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="CNIC">
                                  <span style={{ color: "red" }}>* </span>CNIC
                                </Label>
                                {/* <Input
                                  type="text"
                                  className="form-control"
                                  id="CNIC"
                                  name="CNIC"
                                  placeholder="Enter CNIC No."
                                  onKeyPress={_validation.alphaNumeric2}
                                  maxLength={13}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.CNIC || ""}
                                  invalid={
                                    validation.touched.CNIC &&
                                    validation.errors.CNIC
                                      ? true
                                      : false
                                  }
                                /> */}
                                <div>
                                  <CNICInput
                                    value={CNIC}
                                    onChange={handleInputCNIC}
                                  ></CNICInput>
                                </div>
                                {/* {validation.touched.CNIC &&
                                validation.errors.CNIC ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.CNIC}
                                  </FormFeedback>
                                ) : null} */}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Mobile">
                                  <span style={{ color: "red" }}>* </span>Mobile
                                </Label>
                                {/* <Input
                                  type="text"
                                  className="form-control"
                                  id="Mobile"
                                  name="Mobile"
                                  placeholder="Enter Mobile No."
                                  onKeyPress={_validation.alphaNumeric2}
                                  maxLength={11}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Mobile || ""}
                                  invalid={
                                    validation.touched.Mobile &&
                                    validation.errors.Mobile
                                      ? true
                                      : false
                                  }
                                /> */}
                                <div>
                                  <PhoneInput
                                    value={PHONE}
                                    onChange={handleInputPHONE}
                                  ></PhoneInput>
                                </div>
                                {/* {validation.touched.Mobile &&
                                validation.errors.Mobile ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Mobile}
                                  </FormFeedback>
                                ) : null} */}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="BloodGroup_Id">
                                  <span style={{ color: "red" }}>* </span>Blood
                                  Group
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="BloodGroup_Id"
                                  name="BloodGroup_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.BloodGroup_Id || ""}
                                  invalid={
                                    validation.touched.BloodGroup_Id &&
                                    validation.errors.BloodGroup_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {BloodGroupList == null ||
                                  BloodGroupList == undefined
                                    ? ""
                                    : renderBloodGroup()}
                                </Input>
                                {validation.touched.BloodGroup_Id &&
                                validation.errors.BloodGroup_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.BloodGroup_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="DOJ">
                                  <span style={{ color: "red" }}>* </span>Date
                                  Of Joining
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="DOJ"
                                  name="DOJ"
                                  placeholder="Enter DOJ"
                                  onKeyPress={_validation.Number2}
                                  maxLength={10}
                                  max={currentDate}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.DOJ || ""}
                                  invalid={
                                    validation.touched.DOJ &&
                                    validation.errors.DOJ
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.DOJ &&
                                validation.errors.DOJ ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.DOJ}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Email">
                                  <span style={{ color: "red" }}>* </span>Email
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="Email"
                                  name="Email"
                                  placeholder="Enter Email"
                                  maxLength={40}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Email || ""}
                                  invalid={
                                    validation.touched.Email &&
                                    validation.errors.Email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.Email &&
                                validation.errors.Email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {!IsEdit ? (
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="Password">
                                    <span style={{ color: "red" }}>* </span>
                                    Password
                                  </Label>
                                  {/* <Input
                                  type="Password"
                                  className="form-control"
                                  id="Password"
                                  name="Password"
                                  placeholder="Enter Password"
                                  maxLength={40}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Password || ""}
                                  invalid={
                                    validation.touched.Password &&
                                    validation.errors.Password
                                      ? true
                                      : false
                                  }
                                />  */}
                                  <Input
                                    type="password"
                                    value={Password}
                                    onChange={e => setPassword(e.target.value)}
                                    id="Password"
                                    name="Password"
                                    placeholder="Enter Password"
                                    required
                                  />
                                  {/* {validation.touched.Password && *
                                  validation.errors.Password ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.Password}
                                    </FormFeedback>
                                  ) : null}  */}
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="InsurancePlan_Id">
                                  <span style={{ color: "red" }}>* </span>
                                  Insurance Plan
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="InsurancePlan_Id"
                                  name="InsurancePlan_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.InsurancePlan_Id || ""
                                  }
                                  invalid={
                                    validation.touched.InsurancePlan_Id &&
                                    validation.errors.InsurancePlan_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {InsurancePlanList == null ||
                                  InsurancePlanList == undefined
                                    ? ""
                                    : renderInsurancePlan()}
                                </Input>
                                {validation.touched.InsurancePlan_Id &&
                                validation.errors.InsurancePlan_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.InsurancePlan_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="ContractType_Id">
                                  <span style={{ color: "red" }}>* </span>
                                  Contract Type
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="ContractType_Id"
                                  name="ContractType_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.ContractType_Id || ""
                                  }
                                  invalid={
                                    validation.touched.ContractType_Id &&
                                    validation.errors.ContractType_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {ContractTypeList == null ||
                                  ContractTypeList == undefined
                                    ? ""
                                    : renderContractType()}
                                </Input>
                                {validation.touched.ContractType_Id &&
                                validation.errors.ContractType_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.ContractType_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Department_Id">
                                  <span style={{ color: "red" }}>* </span>
                                  Department
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Department_Id"
                                  name="Department_Id"
                                  onChange={e => {
                                    OnDepartmentChange(e.target.value);
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Department_Id || ""}
                                  invalid={
                                    validation.touched.Department_Id &&
                                    validation.errors.Department_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {DepartmentList == null ||
                                  DepartmentList == undefined
                                    ? ""
                                    : renderDepartment()}
                                </Input>
                                {validation.touched.Department_Id &&
                                validation.errors.Department_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Department_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Designation_Id">
                                  <span style={{ color: "red" }}>* </span>
                                  Designation
                                  <span
                                    style={{ paddingTop: 10, marginLeft: 10 }}
                                  >
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="15"
                                      visible={isSpinnerLoaderDesignation}
                                    />
                                  </span>
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Designation_Id"
                                  name="Designation_Id"
                                  onChange={e => {
                                    validation.handleChange(e);
                                    OnDesignationChange(e.target.value);
                                  }}
                                  onBlur={validation.handleBlur}
                                  disabled={isSpinnerLoaderDesignation}
                                  value={validation.values.Designation_Id || ""}
                                  invalid={
                                    validation.touched.Designation_Id &&
                                    validation.errors.Designation_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {DesignationList == null ||
                                  DesignationList == undefined
                                    ? ""
                                    : renderDesignation()}
                                </Input>
                                {validation.touched.Designation_Id &&
                                validation.errors.Designation_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Designation_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Report_To">
                                  <span style={{ color: "red" }}>* </span>Report
                                  To
                                  <span
                                    style={{ paddingTop: 10, marginLeft: 10 }}
                                  >
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="15"
                                      visible={isSpinnerLoaderReportTo}
                                    />
                                  </span>
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Report_To"
                                  name="Report_To"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  disabled={isSpinnerLoaderReportTo}
                                  value={validation.values.Report_To || ""}
                                  invalid={
                                    validation.touched.Report_To &&
                                    validation.errors.Report_To
                                      ? true
                                      : false
                                  }
                                >
                                  {SelectedDesignation ? (
                                    <>
                                      <option key={1} value={0}>
                                        Select
                                      </option>
                                      <option key={2} value={"self"}>
                                        Self
                                      </option>
                                    </>
                                  ) : (
                                    <>
                                      <option key={1} value={0}>
                                        Select
                                      </option>
                                      {EmployeeList == null ||
                                      EmployeeList == undefined
                                        ? ""
                                        : renderEmployee()}
                                    </>
                                  )}
                                </Input>
                                {validation.touched.Report_To &&
                                validation.errors.Report_To ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Report_To}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Role_Id">
                                  <span style={{ color: "red" }}>* </span>Role
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Role_Id"
                                  name="Role_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Role_Id || ""}
                                  invalid={
                                    validation.touched.Role_Id &&
                                    validation.errors.Role_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {RoleList == null || RoleList == undefined
                                    ? ""
                                    : renderRole()}
                                </Input>
                                {validation.touched.Role_Id &&
                                validation.errors.Role_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Role_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Shift_Id">
                                  <span style={{ color: "red" }}>* </span>Shift
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Shift_Id"
                                  name="Shift_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Shift_Id || ""}
                                  invalid={
                                    validation.touched.Shift_Id &&
                                    validation.errors.Shift_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {ShiftList == null || ShiftList == undefined
                                    ? ""
                                    : renderShift()}
                                </Input>
                                {validation.touched.Shift_Id &&
                                validation.errors.Shift_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Shift_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="First_Name">
                                  <span style={{ color: "red" }}>* </span>Gross
                                  Salary Amount
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="Gross_Salary_Amount"
                                  name="Gross_Salary_Amount"
                                  placeholder="Enter Gross Salary Amount"
                                  onKeyPress={_validation.Number}
                                  maxLength={8}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.Gross_Salary_Amount || ""
                                  }
                                  invalid={
                                    validation.touched.Gross_Salary_Amount &&
                                    validation.errors.Gross_Salary_Amount
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.Gross_Salary_Amount &&
                                validation.errors.Gross_Salary_Amount ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Gross_Salary_Amount}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Salary_Profile_Id">
                                  <span style={{ color: "red" }}>* </span>Salary
                                  Profile
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Salary_Profile_Id"
                                  name="Salary_Profile_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.Salary_Profile_Id || ""
                                  }
                                  invalid={
                                    validation.touched.Salary_Profile_Id &&
                                    validation.errors.Salary_Profile_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {SalaryProfileList == null ||
                                  SalaryProfileList == undefined
                                    ? ""
                                    : renderSalaryProfile()}
                                </Input>
                                {validation.touched.Salary_Profile_Id &&
                                validation.errors.Salary_Profile_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Salary_Profile_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Device_Id">
                                  <span style={{ color: "red" }}>* </span>Device
                                  ID
                                </Label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="Device_Id"
                                  name="Device_Id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Device_Id || ""}
                                  invalid={
                                    validation.touched.Device_Id &&
                                    validation.errors.Device_Id
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.Device_Id &&
                                validation.errors.Device_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Device_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <div className="mb-3"></div>
                              <h3>Address Information</h3>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Country_Id">
                                  <span style={{ color: "red" }}>* </span>
                                  Country
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Country_Id"
                                  name="Country_Id"
                                  onChange={e => {
                                    OnConutryChange(e.target.value);
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Country_Id || ""}
                                  invalid={
                                    validation.touched.Country_Id &&
                                    validation.errors.Country_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {CountryList == null ||
                                  CountryList == undefined
                                    ? ""
                                    : renderCountry()}
                                </Input>
                                {validation.touched.Country_Id &&
                                validation.errors.Country_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Country_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="State_Id">
                                  <span style={{ color: "red" }}>* </span>
                                  State/Province
                                  <span
                                    style={{ paddingTop: 10, marginLeft: 10 }}
                                  >
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="15"
                                      visible={isSpinnerLoaderState}
                                    />
                                  </span>
                                </Label>

                                <Input
                                  type="select"
                                  className="form-control"
                                  id="State_Id"
                                  name="State_Id"
                                  disabled={isSpinnerLoaderState}
                                  onChange={e => {
                                    OnStateChange(e.target.value);
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.State_Id || ""}
                                  invalid={
                                    validation.touched.State_Id &&
                                    validation.errors.State_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {StateList == null || StateList == undefined
                                    ? ""
                                    : renderState()}
                                </Input>
                                {validation.touched.State_Id &&
                                validation.errors.State_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.State_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="City_Id">
                                  <span style={{ color: "red" }}>* </span>City
                                  <span
                                    style={{ paddingTop: 10, marginLeft: 10 }}
                                  >
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="15"
                                      visible={isSpinnerLoaderCity}
                                    />
                                  </span>
                                </Label>

                                <Input
                                  type="select"
                                  className="form-control"
                                  id="City_Id"
                                  name="City_Id"
                                  disabled={isSpinnerLoaderCity}
                                  onChange={e => {
                                    OnCityChange(e.target.value);
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.City_Id || ""}
                                  invalid={
                                    validation.touched.City_Id &&
                                    validation.errors.City_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {CityList == null || CityList == undefined
                                    ? ""
                                    : renderCity()}
                                </Input>
                                {validation.touched.City_Id &&
                                validation.errors.City_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.City_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="Branch_Id">
                                  <span style={{ color: "red" }}>* </span>Branch
                                  <span
                                    style={{ paddingTop: 10, marginLeft: 10 }}
                                  >
                                    <RotatingLines
                                      strokeColor="grey"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="15"
                                      visible={isSpinnerLoaderBranch}
                                    />
                                  </span>
                                </Label>

                                <Input
                                  type="select"
                                  className="form-control"
                                  id="Branch_Id"
                                  name="Branch_Id"
                                  disabled={isSpinnerLoaderBranch}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Branch_Id || ""}
                                  invalid={
                                    validation.touched.Branch_Id &&
                                    validation.errors.Branch_Id
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={1} value={0}>
                                    Select
                                  </option>
                                  {BranchList == null || BranchList == undefined
                                    ? ""
                                    : renderBranch()}
                                </Input>
                                {validation.touched.Branch_Id &&
                                validation.errors.Branch_Id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Branch_Id}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="Address">
                                  <span style={{ color: "red" }}>* </span>
                                  Address
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="Address"
                                  name="Address"
                                  placeholder="Enter Address"
                                  maxLength={255}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Address || ""}
                                  invalid={
                                    validation.touched.Address &&
                                    validation.errors.Address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.Address &&
                                validation.errors.Address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Address}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="5">
                              <div className="mb-3"></div>
                              <h3>Attachment/Documents</h3>
                            </Col>
                          </Row>

                          <Row>
                            {/* <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Employee Photo
                                </Label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={handleFileEvent}
                                  id="fileUpload"
                                  multiple
                                  // disabled={IsTicketClose}
                                  accept="image/jpeg, image/png,"
                                />
                              </div>
                            </Col> */}
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Contract/Documents
                                </Label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="Contract"
                                  name="Contract"
                                  placeholder="Upload resume"
                                  accept="image/jpeg, image/png,"
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              {/* <Button
                                type="button"
                                onClick={() => {
                                  // toggleTab(activeTab - 1);
                                }}
                              >
                                Previous
                              </Button> */}
                              <Button
                                type="submit"
                                className="btn btn-success"
                                style={{ marginLeft: 20 }}
                                disabled={DisableSubmit}
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div>
                          <Form>
                            <Row className="mb-2">
                              <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={addNewItemAcademic}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <DynamicDataTable
                                  HeaderData={EmployeeAcademicListAnchor}
                                  BodyData={EmployeeAcademicList}
                                  branch={branchAcademicInformation}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{ marginLeft: 20 }}
                                  onClick={handleSubmitAcademicInformation}
                                >
                                  Save
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{ marginLeft: 20 }}
                                  onClick={SkipAcedamicInformation}
                                >
                                  Skip
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div>
                          <Form>
                            <Row className="mb-2">
                              <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={addNewItemFunction}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <DynamicDataTable
                                  HeaderData={EmployeeFunctionListAnchor}
                                  BodyData={EmployeeFunctionList}
                                  branch={branchFunctionInformation}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{ marginLeft: 20 }}
                                  onClick={handleSubmitFunctionInformation}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={4}>
                        <div>
                          <Form>
                            <Row className="mb-2">
                              <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={addNewItemEmployerRecord}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <DynamicDataTable
                                  HeaderData={EmployeRecordListAnchor}
                                  BodyData={EmployeeEmployerRecordList}
                                  branch={branchEmployerRecord}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{ marginLeft: 20 }}
                                  onClick={handleSubmitEmployerRecord}
                                >
                                  Save
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-success"
                                  style={{ marginLeft: 20 }}
                                  onClick={SkipEmployerRecord}
                                >
                                  Skip
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={5}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-muted">
                                  Employee has been successfully created.
                                </p>
                                <Link
                                  to="/employee_management/employees"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button
                                    type="button"
                                    className="btn btn-success"
                                    style={{ marginLeft: 20 }}
                                  >
                                    Proceed
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                  {/* <div className="actions clearfix">
                    <ul>
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={activeTab === 4 ? "next disabled" : "next"}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab + 1);
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      <Modal
        size="sm"
        isOpen={ShowDialoguePopup}
        toggle={onCloseClick}
        centered={true}
      >
        <div className="modal-content">
          <ModalBody
            className="px-4 py-5 text-center"
            style={{ border: "2px solid gray" }}
          >
            {/* <button
            type="button"
            onClick={onDeleteClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button> */}
            {/* <div className="avatar-sm mb-6 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div> */}
            <p
              className="text-muted font-size-16 mb-8"
              style={{ fontWeight: "bold" }}
            >
              Employee already exist.
            </p>
            <p className="text-muted font-size-16 mb-8">
              Kindly edit employee details or use different Employee Code.
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              {/* <button type="button" className="btn btn-danger" onClick={onDeleteClick}>Delete Now</button> */}

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleNavigate}
                style={{ width: 80, backgroundColor: "#3DCD49" }}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCloseClick}
                style={{ width: 80 }}
              >
                Close
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      {/* MODAL */}
      {formOpen1 !== undefined && formOpen1 !== null ? (
        formOpen1 === true ? (
          <FloatingForm branch={branchAcademicInformation} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {formOpen2 !== undefined && formOpen2 !== null ? (
        formOpen2 === true ? (
          <FloatingForm1 branch={branchFunctionInformation} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {formOpen3 !== undefined && formOpen3 !== null ? (
        formOpen3 === true ? (
          <FloatingForm2 branch={branchEmployerRecord} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(ApprovalStatus);
