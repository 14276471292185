import Encryption from "../common/Encryption";

const User_Id = () => {
  return Get_User() != null ? Get_User().User_Id : null;
};

const Get_Token_old = () => {
  const itemStr = localStorage.getItem("User");
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    //localStorage.removeItem("User");
    localStorage.clear();
    return null;
  }
  return item.Token;
};

const Get_Login_old = () => {
  const itemStr = localStorage.getItem("User");
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    //localStorage.removeItem("User");
    localStorage.clear();
    return null;
  }
  return item;
};

const Get_User = () => {
  const item = Get_Current_User();

  // if the item doesn't exist, return null
  if (item == null) {
    return item;
  }

  return item.UserInfo;
};

const Get_User_Menu = () => {
  const item = Get_Current_User();

  // if the item doesn't exist, return null
  if (item == null) {
    return item;
  }

  return item.Menu;
};

const Get_Token = () => {
  const item = Get_Current_User();
  // if the item doesn't exist, return null
  if (item == null) {
    return item;
  }

  return item.Token;
};

const Get_Login = () => {
  return Get_Current_User();
};

const Set_User = (value, ttl) => {
  //only for session time test
  const now = new Date();
  now.setMinutes(now.getMinutes() + 1);
  const now_expiry = now * 1;
  //only for session time test

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire

  const decodedJwt = parseJwt(value.Token);
  const item = {
    UserInfo: value.User,
    Token: value.Token,
    Menu: value.Menu,
    expiry: ttl, //decodedJwt.exp * 1000,
  };
  if (localStorage.getItem("session_timeout")) {
    localStorage.clear();
  }

  localStorage.setItem("User", JSON.stringify(item));
};

const Get_Current_User = () => {
  const itemStr = localStorage.getItem("User");
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const EncryptStr = Encryption.encrypt_url(itemStr);

  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.clear();
    // window.location.href = "/login";
    if (localStorage.getItem("MasterAuth")) {
      localStorage.setItem("session_timeout", "Your Session is Timed Out");
      window.location.href = "/session_timeout?q=" + EncryptStr;
    } else {
      window.location.href = "/master_auth";
    }

    return false;
  }
  return item;
};

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export default {
  User_Id,
  Get_User,
  Get_User_Menu,
  Get_Token,
  Get_Login,
  Set_User,
};
