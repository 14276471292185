import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _Menu from "helpers/Profile_Management/Menu";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import SearchIcons from "pages/Profile_Management/SearchIcon/SearchIcons";
// import dataIcon from "helpers/icons/ion-icon";
import dataIcon from "helpers/icons/fontawesome-icon";

import icons from "helpers/icons/font_awesome_icons";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  // Icon Search
  const [loading, setLoading] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [iconName, setIconName] = useState("");
  const [filterText, setFilterText] = useState("");

  const formOpen = useSelector(state => state.crudTbMenu.formOpen);
  const MenuList = useSelector(state => state.crudTbMenu.dataTable);
  const formValues = useSelector(state => state.crudTbMenu.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const handleOpenDetail = name => {
    // setOpenDetail(true);
    const data = `fas ${name}`;
    setIconName(data);
    // addNew(addIcon(name, branch));
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleSearch = event => {
    setLoading(true);
    event.persist();
    // Show result base on keyword
    setFilterText(event.target.value.toLowerCase());
    setLoading(false);
  };

  useEffect(() => {
    if (formValues != null) {
      const index = MenuList.findIndex(
        item => item.Menu_Id == formValues.Menu_Id
      );
      setIsEdit(true);
      setInitialFormData(MenuList[index]);
      setIsActiveCheck(MenuList[index].IsActive);
      setIconName(MenuList[index].Icon);
    } else {
      setIsEdit(false);
      setIsActiveCheck(true);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Menu_Name: isEdit ? InitialFormData.Menu_Name : "",
      Link: isEdit ? InitialFormData.Link : "",
      Sort: isEdit ? InitialFormData.Sort : 0,
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      Menu_Name: Yup.string().required("Please Enter Menu Name"),
      Sort: Yup.number().required("Please define sorting").min(1),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          Menu_Id: InitialFormData.Menu_Id,
          Menu_Name: values.Menu_Name,
          Link: values.Link,
          Sort: values.Sort,
          Icon: iconName,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _Menu.Edit_Menu(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Menu_Id: 0,
          Menu_Name: values.Menu_Name,
          Link: values.Link,
          Sort: values.Sort,
          Icon: iconName,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _Menu.Save_Menu(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Menu" : "Add Menu"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Menu Name
                </Label>
                <Input
                  name="Menu_Name"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphabetic(e)}
                  // value={isEdit ? InitialFormData.Menu_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Menu_Name || ""}
                  required
                  invalid={
                    validation.touched.Menu_Name && validation.errors.Menu_Name
                      ? true
                      : false
                  }
                />
                {validation.touched.Menu_Name && validation.errors.Menu_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Menu_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Link</Label>
                <Input
                  name="Link"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric2(e)}
                  // value={isEdit ? InitialFormData.Link : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Link || ""}
                  invalid={
                    validation.touched.Link && validation.errors.Link
                      ? true
                      : false
                  }
                />
                {validation.touched.Link && validation.errors.Link ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Link}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Sort
                </Label>
                <Input
                  name="Sort"
                  type="number"
                  maxLength={3}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.Sort : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Sort || ""}
                  invalid={
                    validation.touched.Sort && validation.errors.Sort
                      ? true
                      : false
                  }
                />
                {validation.touched.Sort && validation.errors.Sort ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Sort}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Icon
                </Label>
                <Input
                  name="Icon"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={iconName || ""}
                  placeholder="Select an Icon"
                  required
                  disabled
                  invalid={
                    validation.touched.Icon && validation.errors.Icon
                      ? true
                      : false
                  }
                />
                {validation.touched.Icon && validation.errors.Icon ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Icon}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div>
                {loading && (
                  <LinearProgress
                    color="secondary"
                    // className={classes.preloader}
                  />
                )}
                <SearchIcons
                  filterText={filterText}
                  handleSearch={event => handleSearch(event)}
                />
                <div style={{ height: 130, overflowY: "scroll" }}>
                  <div
                  // className={classes.iconsList}
                  >
                    {dataIcon.map((raw, index) => {
                      if (raw.toLowerCase().indexOf(filterText) === -1) {
                        return false;
                      }
                      return (
                        <div
                          // className={classes.inlineWrap}
                          key={index.toString()}
                        >
                          <IconButton
                            // variant="contained"
                            color="black"
                            title={"Click to add " + raw}
                            onClick={() => handleOpenDetail(raw)}
                            // className={classes.btn}
                          >
                            <Typography
                              gutterBottom
                              noWrap
                              style={{ margin: 10 }}
                            >
                              <i className={"fas " + raw} />
                              &nbsp;&nbsp;{raw}
                            </Typography>
                          </IconButton>
                        </div>
                      );
                    })}
                    {/* <DetailIonIcon
                      closeDetail={() => handleCloseDetail()}
                      isOpenDetail={openDetail}
                      iconName={iconName}
                    /> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
