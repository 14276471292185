import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _DemandDetails from "helpers/AssetManagement/DemandDetails";
import Switch from "@material-ui/core/Switch";
import { RotatingLines } from "react-loader-spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import DemandMaster from "helpers/AssetManagement/DemandMaster";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [DemandDate, setDemandDate] = useState(null);
  const [Demand, setDemand] = useState(null);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);

  const formOpen = useSelector(state => state.crudTbDemandDetails.formOpen);
  const DemandDetailsList = useSelector(
    state => state.crudTbDemandDetails.dataTable
  );
  const formValues = useSelector(state => state.crudTbDemandDetails.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      const response2 = await DemandMaster.Get_DemandMaster_DD();
      setDemand(response2.Data.Details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    if (formValues != null) {
      const index = DemandDetailsList.findIndex(
        item => item.DemandDetails_Id == formValues.DemandDetails_Id
      );
      setIsEdit(true);
      setInitialFormData(DemandDetailsList[index]);
      setIsActiveCheck(DemandDetailsList[index].IsActive);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Item_Id: isEdit ? InitialFormData.Item_Id : "",
      Item_Quantity: isEdit ? InitialFormData.Item_Quantity : "",
      Item_Unit_Price: isEdit ? InitialFormData.Item_Unit_Price : "",
      Item_Origin: isEdit ? InitialFormData.Item_Origin : "",
      Item_Remarks: isEdit ? InitialFormData.Item_Remarks : "",
      Demand_Id: isEdit ? InitialFormData.Demand_Id : "",
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      Item_Id: Yup.string().required("Please Enter Item ID"),
      Item_Quantity: Yup.string().required("Please Enter Item Quantity"),
      Item_Unit_Price: Yup.string().required("Please Enter Item Unit Price"),
      Item_Origin: Yup.string().required("Please Enter Item Origin"),
      Item_Remarks: Yup.string().required("Please Enter Item Remarks"),
      Demand_Id: Yup.number().required("Please select a Demand"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          Dem_Item_ID: InitialFormData.Dem_Item_ID,
          Item_ID: values.Item_Id,
          Item_Qty: values.Item_Qty,
          Item_Unit_Price: values.Item_Unit_Price,
          Item_Origin: values.Item_Origin,
          Item_Remarks: values.Item_Remarks,
          Demand_ID: values.Demand_Id,
          User: user.Employee_Id,
        };
        const response = await _DemandDetails.Edit_DemandDetails(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Dem_Item_ID: 0,
          Item_ID: values.Item_Id,
          Item_Qty: values.Item_Qty,
          Item_Unit_Price: values.Item_Unit_Price,
          Item_Origin: values.Item_Origin,
          Item_Remarks: values.Item_Remarks,
          Demand_ID: values.Demand_Id,
          User: user.Employee_Id,
        };
        const response = await _DemandDetails.Save_DemandDetails(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderDemand = () =>
    Demand.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Demand Details" : "Add Demand Details"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Item ID
                </Label>
                <Input
                  name="Item_Id"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.Item_Id : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Item_Id || ""}
                  invalid={
                    validation.touched.Item_Id && validation.errors.Item_Id
                      ? true
                      : false
                  }
                />
                {validation.touched.Item_Id && validation.errors.Item_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Item_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Item Quantity
                </Label>
                <Input
                  name="Item_Quantity"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.Item_Quantity : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Item_Quantity || ""}
                  invalid={
                    validation.touched.Item_Quantity &&
                    validation.errors.Item_Quantity
                      ? true
                      : false
                  }
                />
                {validation.touched.Item_Quantity &&
                validation.errors.Item_Quantity ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Item_Quantity}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Item Unit Price
                </Label>
                <Input
                  name="Item_Unit_Price"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.Number2(e)}
                  // value={isEdit ? InitialFormData.Item_Unit_Price : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Item_Unit_Price || ""}
                  invalid={
                    validation.touched.Item_Unit_Price &&
                    validation.errors.Item_Unit_Price
                      ? true
                      : false
                  }
                />
                {validation.touched.Item_Unit_Price &&
                validation.errors.Item_Unit_Price ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Item_Unit_Price}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Item Origin
                </Label>
                <Input
                  name="Item_Origin"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.Item_Origin : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Item_Origin || ""}
                  invalid={
                    validation.touched.Item_Origin &&
                    validation.errors.Item_Origin
                      ? true
                      : false
                  }
                />
                {validation.touched.Item_Origin &&
                validation.errors.Item_Origin ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Item_Origin}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Demand
                </Label>
                <Input
                  name="Demand"
                  type="select"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumericPlusMinus(e)}
                  // value={isEdit ? InitialFormData.Demand : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Demand || ""}
                  invalid={
                    validation.touched.Demand && validation.errors.Demand
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Demand == null || Demand == undefined ? "" : renderDemand()}
                </Input>
                {validation.touched.Demand && validation.errors.Demand ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Demand}
                  </FormFeedback>
                ) : null}
              </div>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Item Remarks
                  </Label>
                  <Input
                    name="Item_Remarks"
                    type="text"
                    maxLength={255}
                    // value={isEdit ? InitialFormData.Item_Remarks : ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Item_Remarks || ""}
                    invalid={
                      validation.touched.Item_Remarks &&
                      validation.errors.Item_Remarks
                        ? true
                        : false
                    }
                  />
                  {validation.touched.Item_Remarks &&
                  validation.errors.Item_Remarks ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Item_Remarks}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Col>

            <Col className="col-12">
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
