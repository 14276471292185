import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_EmployeeAttendance_DD = (value = 0) => {
  let url = "api/Employee_Attendance/Get_EmployeeAttendance_DropDown";

  return get(url);
};

const Get_EmployeeAttendance_By_Id = id => {
  let url = "api/Employee_Attendance/Get_EmployeeAttendance_By_Id?Id=" + id;

  return get(url);
};

const Get_EmployeeAttendance_List = body => {
  let url = "api/Employee_Attendance/Get_EmployeeAttendance_List?id=0";

  return get(url, body);
};

const Get_EmployeeAttendance_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Employee_Attendance/Get_EmployeeAttendance_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_EmployeeAttendance = body => {
  let url = "api/Employee_Attendance/Save_EmployeeAttendance";
  return post(url, body);
};

const Edit_EmployeeAttendance = body => {
  let url = "api/Employee_Attendance/Edit_EmployeeAttendance";
  return post(url, body);
};

const Delete_EmployeeAttendance = body => {
  let url = "api/Employee_Attendance/Edit_EmployeeAttendance_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "SrNo",
    accessor: "SrNo",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "Emplloyee",
    accessor: "Employee_Name",
    hidden: false,
  },
  {
    Header: "Date",
    accessor: "Date",
    hidden: false,
  },
  {
    Header: "Day",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Time In",
    accessor: "Time_In",
    hidden: false,
  },
  {
    Header: "Time Out",
    accessor: "Time_Out",
    hidden: false,
  },
  {
    Header: "Attendance Type",
    accessor: "Attendance_Type",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_EmployeeAttendance_DD,
  Get_EmployeeAttendance_By_Id,
  Get_EmployeeAttendance_List,
  Get_EmployeeAttendance_List_By_Rows,
  Save_EmployeeAttendance,
  Edit_EmployeeAttendance,
  Delete_EmployeeAttendance,
  anchorTable,
};
