import React, { useState } from "react";
import moment from "moment";
import { Card, CardBody, CardTitle, Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import {
  openLeaveApprovalForm,
  setDashboardFormValues,
  setLeaveApprovalStatusValues,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const ActivityComp = props => {
  const { RequestData } = props;

  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const onCloseClick = () => {
    setMessage(null);
  };

  const handleApprove = async index => {
    // setMessage("Request approved Successfully.");
    dispatch(setDashboardFormValues(RequestData[index]));
    dispatch(setLeaveApprovalStatusValues(2));
    dispatch(openLeaveApprovalForm(true));
  };

  const handleReject = async index => {
    // setMessage("Request rejected Successfully.");
    dispatch(setDashboardFormValues(RequestData[index]));
    dispatch(setLeaveApprovalStatusValues(4));
    dispatch(openLeaveApprovalForm(true));
  };
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Leave Requests</CardTitle>
          <ul
            className="verti-timeline list-unstyled"
            style={{
              height: RequestData !== null ? 350 : 50,
              overflowY:
                RequestData !== null && RequestData.length > 2
                  ? "scroll"
                  : "none",
              overflowX: "hidden",
            }}
          >
            {RequestData !== null && RequestData !== undefined ? (
              RequestData.map((item, index) => {
                return (
                  <li className="event-list" key={index}>
                    <Row>
                      <Col xl="9" md="12">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="me-3">
                          <h5>{"Employee Name: " + item[2].toUpperCase()}</h5>
                          <h5 className="font-size-14">
                            {moment(item[5].split("T")[0]).format(
                              "DD-MMM-YYYY"
                            ) +
                              " - To - " +
                              moment(item[6].split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                          </h5>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <h5 className="font-size-12">
                              {"Leave Type: " + item[8]}
                            </h5>
                            <h5
                              className="font-size-12"
                              style={{ margin: "0px 10px" }}
                            >
                              {"Approval Status: " + item[9]}
                            </h5>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <span style={{ fontWeight: "600" }}>Reason: </span>
                            <span>{item[7]}</span>
                            {/* {"Reason: " + item[6]} */}
                          </div>
                        </div>
                        <div className="flex-shrink-0 d-flex"></div>
                      </Col>
                      <Col xl="3" md="12">
                        <div className="flex-grow-1">
                          <Button
                            className="btn btn-primary waves-effect waves-light btn-sm"
                            style={{ margin: 2, width: 70 }}
                            onClick={e => handleApprove(index)}
                          >
                            Approve
                          </Button>
                          <Button
                            className="btn btn-primary waves-effect waves-light btn-sm"
                            style={{ margin: 2, width: 70 }}
                            onClick={e => handleReject(index)}
                          >
                            Reject
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </li>
                );
              })
            ) : (
              <li className="event-list">
                <Row>
                  <Col xl="9" md="12">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18" />
                    </div>
                    <div className="me-3">
                      <h5>No Pending Request.</h5>
                      {/* <h5 className="font-size-14">
                        {moment(item[4].split("T")[0]).format("DD-MMM-YYYY") +
                          " - To - " +
                          moment(item[5].split("T")[0]).format("DD-MMM-YYYY")}
                      </h5>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <h5 className="font-size-12">
                          {"Leave Type: " + item[8]}
                        </h5>
                        <h5
                          className="font-size-12"
                          style={{ margin: "0px 10px" }}
                        >
                          {"Approval Status: " + item[9]}
                        </h5> 
                      </div>*/}
                    </div>
                    {/* <div className="flex-grow-1">
                      <div>
                        <span style={{ fontWeight: "600" }}>Reason: </span>
                        <span>{item[6]}</span>
                      </div>
                    </div>
                    <div className="flex-shrink-0 d-flex"></div> */}
                  </Col>
                  {/* <Col xl="3" md="12">
                    <div className="flex-grow-1">
                      <Button
                        className="btn btn-primary waves-effect waves-light btn-sm"
                        style={{ margin: 2, width: 70 }}
                        onClick={e => handleApprove(index)}
                      >
                        Approve
                      </Button>
                      <Button
                        className="btn btn-primary waves-effect waves-light btn-sm"
                        style={{ margin: 2, width: 70 }}
                        onClick={e => handleReject(index)}
                      >
                        Reject
                      </Button>
                    </div>
                  </Col> */}
                </Row>
              </li>
            )}
            {/* <li className="event-list">
              <Row>
                <Col xl="9">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18" />
                  </div>
                  <div className="me-3">
                    <h5 className="font-size-14">
                      22 April{" "}
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Applied for Annual Leaves 25-July to 10-August</div>
                  </div>
                  <div className="flex-shrink-0 d-flex"></div>
                </Col>
                <Col xl="3">
                  <div className="flex-grow-1">
                    <Button
                      className="btn btn-primary waves-effect waves-light btn-sm"
                      style={{ margin: 2, width: 70 }}
                      onClick={e => handleApprove(0)}
                    >
                      Approve
                    </Button>
                    <Button
                      className="btn btn-primary waves-effect waves-light btn-sm"
                      style={{ margin: 2, width: 70 }}
                      onClick={e => handleReject(0)}
                    >
                      Reject
                    </Button>
                  </div>
                </Col>
              </Row>
            </li>
            */}
          </ul>
          <div className="text-center mt-4">
            <Link
              to="/employee_management/employee_leave"
              className="btn btn-primary waves-effect waves-light btn-sm"
              style={{ backgroundColor: "#004a8d" }}
            >
              View <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>

      {/* modal */}
      <div>
        <Dialog
          open={message}
          onClose={onCloseClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: 16,
              textDecoration: "underline solid black 2px",
            }}
          >
            <span style={{ color: "black" }}>Confirmation</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ maxWidth: 300, color: "black" }}
            >
              {/* {message} */}
              {message === null ? "" : message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <NavLink to={path} style={{ textDecoration: "none" }}>
           
          </NavLink> */}
            <Button
              onClick={onCloseClick}
              style={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: "#3f51b5",
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default ActivityComp;
