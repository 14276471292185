import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import EmployeeAssets from "helpers/AssetManagement/EmployeeAssets";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _Employee from "helpers/Employee_Management/Employee";
import Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import Select from "react-select";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [CurrentDate, setCurrentDate] = useState(null);
  const [EmployeeFamilyList, setEmployeeFamilyList] = useState(null);
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  //Data Lists
  const [EmployeeList, setEmployeeList] = useState(null);
  const [DepartmentList, setDepartmentList] = useState([]);

  const formOpen = useSelector(state => state.crudTbEmployeeAssets.formOpen);
  const EmployeeAssets = useSelector(
    state => state.crudTbEmployeeAssets.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbEmployeeAssets.formValues
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
    setisLoading(true);
    if (formValues != null) {
      EmployeeAssets.Get_Asset_Management_By_Id(formValues.Med_Id).then(
        response => {
          const data = response.Data.Details[0];
          setIsEdit(true);
          setInitialFormData(data);
          setProcedureDate(data.Procedure_Date.split("T")[0]);
          setIsActiveCheck(data.IsActive);
          setHRApprovalStatus(data.HRStatus_Id);
          setManagerApprovalStatus(data.ManagerStatus_Id);
          setRefundedOnDate(data.Refunded_On.split("T")[0]);
          setisLoading(false);
        }
      );
    } else {
      setIsEdit(false);
      setisLoading(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const initialData = async () => {
    try {
      const departments = await Department.Get_Department_DD();
      setDepartmentList(departments.Data.Details);
    } catch (error) {
      // dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setCurrentDate(currentdate);
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Category_Id: isEdit ? InitialFormData.Emp_Fam_Id : "",
      Product_Name: isEdit ? InitialFormData.Product_Name : "",
      Remarks: isEdit ? InitialFormData.Remarks : "",
      Quantity: isEdit ? InitialFormData.Quantity : "",
      Condition: isEdit ? InitialFormData.Condition : "",
      Cost: isEdit ? InitialFormData.Cost : "",
      Description: isEdit ? InitialFormData.Description : "",
    },
    validationSchema: Yup.object({
      Category_Id: Yup.number()
        .required("Please Select a Category")
        .min(1, "Please Select a Category"),
      Product_Name: Yup.string().required("Please enter product name."),
      Remarks: Yup.string().required("Please enter Remarks."),
      Condition: Yup.string().required("Please enter Condition."),
      Quantity: Yup.number()
        .required("Please enter Quantity")
        .min(1, "Quantity cannot be zero"),
      Cost: Yup.number()
        .required("Please enter Cost")
        .min(1, "Cost cannot be zero"),
      Description: Yup.string().required("Please enter a Description"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body;
        if (user.Role_Id === 2) {
          body = {
            Asset_Id: InitialFormData.Asset_Id,
            IsActive: true,
            User: user.Employee_Id,
          };
        } else {
          body = {
            Asset_Id: InitialFormData.Asset_Id,
            IsActive: true,
            User: user.Employee_Id,
          };
        }

        const response = await EmployeeAssets.Edit_Asset_Management(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Asset_Id: 0,
          IsActive: true,
          User: user.Employee_Id,
        };
        const response = await EmployeeAssets.Save_Asset_Management(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const handleDepartmentChange = async value => {
    try {
      setisLoading(true);
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const data = response.Data.Details;
        const emp_list = [];
        data.map(item => {
          const obj = {
            value: item.Value,
            label: item.Text + " - " + item.EmpCode,
          };
          emp_list.push(obj);
        });
        setEmployeeList(emp_list);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Asset" : "Add Asset"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Category
                </Label>
                <Input
                  type="select"
                  name="Category_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.Category_Id || ""}
                  invalid={
                    validation.touched.Category_Id &&
                    validation.errors.Category_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {EmployeeFamilyList == null || EmployeeFamilyList == undefined
                    ? ""
                    : renderEmployeeFamily()}
                </Input>
                {validation.touched.Category_Id &&
                validation.errors.Category_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Category_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Product Name
                </Label>
                <Input
                  name="Product_Name"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Product_Name || ""}
                  invalid={
                    validation.touched.Product_Name &&
                    validation.errors.Product_Name
                      ? true
                      : false
                  }
                />
                {validation.touched.Product_Name &&
                validation.errors.Product_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Product_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={e => handleDepartmentChange(e.target.value)}
                  required
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Department == null || Department == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee
                </Label>
                <Select
                  defaultValue={EmployeeList !== null && EmployeeList[0]}
                  isDisabled={EmployeeList == null}
                  isSearchable={true}
                  onChange={e => setSelectedEmployee(e.value)}
                  name="color"
                  options={EmployeeList}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Remarks
                </Label>
                <Input
                  name="Remarks"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Remarks || ""}
                  invalid={
                    validation.touched.Remarks && validation.errors.Remarks
                      ? true
                      : false
                  }
                />
                {validation.touched.Remarks && validation.errors.Remarks ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Remarks}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Quantity
                </Label>
                <Input
                  name="Quantity"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Quantity || ""}
                  invalid={
                    validation.touched.Quantity && validation.errors.Quantity
                      ? true
                      : false
                  }
                />
                {validation.touched.Quantity && validation.errors.Quantity ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Quantity}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Condition
                </Label>
                <Input
                  name="Condition"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Condition || ""}
                  invalid={
                    validation.touched.Condition && validation.errors.Condition
                      ? true
                      : false
                  }
                />
                {validation.touched.Condition && validation.errors.Condition ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Condition}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Assign Date
                </Label>
                <Input
                  type="date"
                  name="Assign_Date"
                  required
                  // onChange={e => setProcedureDate(e.target.value)}
                  // value={ProcedureDate}
                  max={CurrentDate}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Item Picture</Label>
                <input
                  type="file"
                  className="form-control"
                  // onChange={handleFileEvent}
                  id="fileUpload"
                  multiple
                  // disabled={IsTicketClose}
                  accept="image/jpeg, image/png,"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
