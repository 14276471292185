import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Salary_Profile_Master_DD = () => {
  let url = `api/Salary_Profile_Master/Get_Salary_Profile_Master_DropDown`;
  return get(url);
};

const Get_Salary_Profile_Master_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Salary_Profile_Master/Get_Salary_Profile_Master_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Get_Salary_Profile_Master_By_Id = id => {
  let url = `api/Salary_Profile_Master/Get_Salary_Profile_Master_By_Id?Id=${id}`;

  return get(url);
};

const Get_Salary_Profile_Detail_By_Id = id => {
  let url = `api/Salary_Profile_Detail/Get_Salary_Profile_Detail_By_Id?Id=${id}`;

  return get(url);
};

const Save_Salary_Profile_Detail = body => {
  let url = "api/Salary_Profile_Detail/Save_Salary_Profile_Detail";
  return post(url, body);
};

const Save_Salary_Profile_Master = body => {
  let url = "api/Salary_Profile_Master/Save_Salary_Profile_Master";
  return post(url, body);
};

const Edit_Salary_Profile_Master = body => {
  let url = "api/Salary_Profile_Master/Edit_Salary_Profile_Master";
  return post(url, body);
};

const Delete_Salary_Profile_Master = body => {
  let url = "api/Salary_Profile_Master/Edit_Salary_Profile_Master_Status";
  return post(url, body);
};

//S.No	Salary_Profile_Master Name	Department	Designation	Location	DOB	DOJ	Active Status	Action
const anchorTable = [
  {
    Header: "Salary Profile  Name",
    accessor: "Salary_Profile_Name",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Modified By",
    accessor: "ModifiedBy",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Salary_Profile_Master_DD,
  Get_Salary_Profile_Master_By_Id,
  Get_Salary_Profile_Master_List_By_Rows,
  Get_Salary_Profile_Detail_By_Id,
  Save_Salary_Profile_Master,
  Save_Salary_Profile_Detail,
  Edit_Salary_Profile_Master,
  Delete_Salary_Profile_Master,
  anchorTable,
};
