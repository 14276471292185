import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Function_DD = (value = 0) => {
  let url = "api/Functions/Get_Functions_DropDown";

  return get(url);
};

const Get_Function_List = body => {
  let url = "api/Functions/Get_Functions_List?id=0";

  return get(url, body);
};

const Get_Function_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Functions/Get_Functions_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Function = body => {
  let url = "api/Functions/Save_Functions";
  return post(url, body);
};

const Edit_Function = body => {
  let url = "api/Functions/Edit_Functions";
  return post(url, body);
};

const Delete_Function = body => {
  let url = "api/Functions/Edit_Functions_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
  },
  {
    Header: "Function Name",
    accessor: "Function_Name",
    hidden: false,
  },
  {
    Header: "Department Name",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "Profile Name",
    accessor: "Profile_Name",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Function_DD,
  Get_Function_List,
  Get_Function_List_By_Rows,
  Save_Function,
  Edit_Function,
  Delete_Function,
  anchorTable,
};
