import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import _Function from "helpers/MasterData_Management/Function";

const floatingForm = props => {
  const { branch } = props;

  const [FunctionList, setFunctionList] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const formOpen = useSelector(
    state => state.crudTbEmployeeFunctionInformation.formOpen
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  React.useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    try {
      setisLoading(true);

      const response_function = await _Function.Get_Function_DD();
      setFunctionList(response_function.Data.Details);

      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  const renderFunction = () =>
    FunctionList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Function_Id: "",
    },
    validationSchema: Yup.object({
      Function_Id: Yup.number()
        .required("Please Select a Function")
        .min(1, "Please Select a Function"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      let Function_Name = "";

      const findFunctionData = await FunctionList.findIndex(submenu => {
        return submenu.Value + "" === values.Function_Id + "";
      });

      if (findFunctionData !== -1) {
        Function_Name = FunctionList[findFunctionData].Text;
      }

      values = { ...values, Function_Name: Function_Name };

      dispatch(addNewItem(values, branch));
      setisLoading(false);
      dispatch(setPopupMessageValues("Added", branch));
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {"Add Function Information"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Function Name</Label>
                <Input
                  name="Function_Id"
                  type="select"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Function_Id || ""}
                  invalid={
                    validation.touched.Function_Id &&
                    validation.errors.Function_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {FunctionList == null || FunctionList == undefined
                    ? ""
                    : renderFunction()}
                </Input>
                {validation.touched.Function_Id &&
                validation.errors.Function_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Function_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
