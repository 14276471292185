import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CurrencyRate from "helpers/Account/CurrencyRate";
import Loading from "components/Loading";
import _validation from "helpers/validation";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
import DynamicDataTable from "./DynamicDataTable";
//Import Floating Form
import FloatingForm from "components/Forms/Account/NewCurrencyRate/form";
//Redux Actions
import {
  addNewItem,
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setFiles,
  setPopupMessageValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Container,
  Button,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@material-ui/core";

const NewCurrencyRateListAnchor = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Rate",
    accessor: "Currency_Rate",
    hidden: false,
  },
  {
    Header: "Date",
    accessor: "Assigned_Date",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "IsActive",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    hidden: false,
    width: 100,
  },
];

const AddCurrencyRate = props => {
  const branch = "crudTbNewCurrencyRate";
  //meta title
  document.title = "HR - Add Currency Rate";

  const [isLoading, setisLoading] = useState(false);
  const [NewCurrencyRateList, setNewCurrencyRateList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);

  const [currentDate, setcurrentDate] = useState(null);

  //Redux States
  const popupMessage = useSelector(
    state => state.crudTbNewCurrencyRate.popupMessage
  );
  const formOpen = useSelector(state => state.crudTbNewCurrencyRate.formOpen);
  const dataTable = useSelector(state => state.crudTbNewCurrencyRate.dataTable);
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const initialData = async () => {
  //   try {
  //     setisLoading(true);
  //     dataTable !== null &&
  //       dataTable.map(item => {
  //         dispatch(removeItem(item, branch));
  //       });
  //     const response1 = await MedicalProcedure.Get_Medical_Procedure_DD();
  //     setMedicalProcedureList(response1.Data.Details);
  //     const response2 =
  //       await EmployeeFamily.Get_Employee_Family_DropDown_ByEmpID(
  //         user.Employee_Id
  //       );
  //     setEmployeeFamilyList(response2.Data.Details);
  //     const response4 =
  //       await EmployeeFamily.Get_Employee_Family_Relation_DropDown_ByEmpID(
  //         user.Employee_Id
  //       );
  //     setEmployeeRelationList(response4.Data.Details);

  //     setMonthList(monthsList);
  //     setisLoading(false);
  //   } catch (error) {
  //     dispatch(setPopupMessageValues("Opps! Server is down.", branch));
  //   }
  // };

  useEffect(() => {
    // initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  useEffect(() => {
    setNewCurrencyRateList(dataTable);
  }, [dataTable, formOpen]);

  const addNewItem = () => {
    dispatch(toggleForm(true, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Currency: "",
    },
    validationSchema: Yup.object({
      Currency: Yup.number().min(1, "Please select a currency symbol"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      const currencyRates = [];
      NewCurrencyRateList.map(item => {
        const newObj = {
          NewCurrencyRate_Id: item.NewCurrencyRate_Id,
          Currency_Rate: item.Currency_Rate,
          Assigned_Date: item.Assigned_Date,
          IsActive: item.IsActive,
          User: item.User,
        };
        currencyRates.push(newObj);
      });
      const body = {
        Currency_Rate_Id: 0,
        Currency: values.Currency,
        CurrencyRates: NewCurrencyRateList,
        User: user.Employee_Id,
      };
      if (NewCurrencyRateList.length == 0) {
        dispatch(
          setPopupMessageValues("At least add one medical detail.", branch)
        );
      } else {
        const response = await CurrencyRate.Save_CurrencyRate(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          setisLoading(false);
          navigate("/master/currency_rate");
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }

      setisLoading(false);
    },
  });

  const renderCurrencyList = () =>
    CurrencyList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Add Currency Rate
        </Typography>
        <Container fluid>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">Currency </Label>
                  <Input
                    type="select"
                    name="Currency"
                    // value={event ? event.Currency : "bg-primary"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Currency || ""}
                    invalid={
                      validation.touched.Currency && validation.errors.Currency
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {CurrencyList === null ? "" : renderCurrencyList()}
                  </Input>
                  {validation.touched.Currency && validation.errors.Currency ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Currency}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <br />
            <br />
            {/* Medical Request Tables */}
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={addNewItem}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <DynamicDataTable
                  HeaderData={NewCurrencyRateListAnchor}
                  BodyData={NewCurrencyRateList}
                  branch={branch}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="col-12 text-start">
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm branch={branch} />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

AddCurrencyRate.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default AddCurrencyRate;
