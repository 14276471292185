import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
// import Description from "@material-ui/icons/Description";
import { Image, PictureAsPdf } from "@material-ui/icons";
import { Table, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import {
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setPopupMessageValues,
} from "store/actions";

const CrudTable = props => {
  const {
    HeaderData,
    BodyData,
    branch,
    EmployeeFamilyList,
    EmployeeRelationList,
    MedicalProcedureList,
    className,
  } = props;

  const dispatch = useDispatch();

  const eventDel = item => {
    dispatch(removeItem(item, branch));
    // dispatch(setPopupMessageValues("Deleted", branch));
  };

  const eventEdit = item => {
    dispatch(setFormValues(item, branch));
    dispatch(toggleForm(true, branch));
  };

  const renderFamilyName = value => {
    let index = -1;
    if (EmployeeFamilyList !== null) {
      index = EmployeeFamilyList.findIndex(item => {
        return item.Value === value;
      });
    }
    if (index !== -1) {
      return EmployeeFamilyList[index].Text;
    } else {
      return "";
    }
  };

  const renderRelationName = value => {
    let index = -1;
    if (EmployeeRelationList !== null) {
      index = EmployeeRelationList.findIndex(item => {
        return item.Value === value;
      });
    }
    if (index !== -1) {
      return EmployeeRelationList[index].Text;
    } else {
      return "";
    }
  };

  const renderProcedureName = value => {
    let index = -1;
    if (MedicalProcedureList !== null) {
      index = MedicalProcedureList.findIndex(item => {
        return item.Value === value;
      });
    }
    if (index !== -1) {
      return MedicalProcedureList[index].Text;
    } else {
      return "";
    }
  };

  const renderHeader = () =>
    HeaderData.map((row, index) => <th key={index + 1}>{row.Header}</th>);

  const renderBody = () =>
    BodyData.map((row, index) => {
      return (
        <tr key={index}>
          {/* <td key={0}>{index + 1}</td> */}
          {HeaderData.map((cell, indexx) => {
            return (
              cell.hidden === false && (
                <td
                  key={indexx + 1}
                  style={{
                    margin: 0,
                    width: cell.width !== undefined ? cell.width : 300,
                  }}
                >
                  {cell.accessor === "Action" ? (
                    <div>
                      <Button
                        color="red"
                        size="sm"
                        onClick={e => {
                          eventEdit(row);
                        }}
                      >
                        <i className="fas fa-pen"></i>
                      </Button>
                      <Button
                        color="red"
                        size="sm"
                        onClick={e => {
                          eventDel(row);
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </div>
                  ) : cell.accessor === "indexing" ? (
                    index + 1
                  ) : cell.accessor === "Employee_Family_Id" ? (
                    renderFamilyName(parseInt(row[cell.accessor]))
                  ) : cell.accessor === "Claim_Amount" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor].toLocaleString()
                  ) : cell.accessor === "Medical_Procedure_Id" ? (
                    renderProcedureName(parseInt(row[cell.accessor]))
                  ) : cell.accessor === "File" ? (
                    row[cell.accessor] !== null ? (
                      <Tooltip title="Preview">
                        <IconButton
                          aria-label="View"
                          onClick={e => {
                            openImageInNewTab(row[cell.accessor]);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {row[cell.accessor].fileType.startsWith("image/") ? (
                            <Image
                              style={{ fontSize: 22, padding: 0, margin: -20 }}
                            />
                          ) : row[cell.accessor].fileType ===
                            "application/pdf" ? (
                            <PictureAsPdf
                              style={{ fontSize: 22, padding: 0, margin: -20 }}
                            />
                          ) : (
                            ""
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )
                  ) : (
                    row[cell.accessor]
                  )}
                </td>
              )
            );
          })}
        </tr>
      );
    });

  // const openImageInNewTab = previewUrl => {
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<html><body><img src="${previewUrl}" /></body></html>`
  //   );
  // };

  const openImageInNewTab = data => {
    const url = data.previewUrl;
    const type = data.fileType;
    console.log(type);
    if (type.startsWith("image/")) {
      // const newWindow = window.open();
      // const image = new Image();

      // image.onload = () => {
      //   const newDocument = newWindow.document;
      //   newDocument.write("<html><body></body></html>");
      //   newDocument.body.appendChild(image);
      //   newWindow.document.close();
      // };

      // image.src = url;

      const newWindow = window.open();
      newWindow.document.write(
        `<html><body><img src="${url}" /></body></html>`
      );
      newWindow.document.close();
    } else if (type === "application/pdf") {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          const newWindow = window.open(objectUrl, "_blank");
          newWindow.opener = null;
        })
        .catch(error => {
          console.log("Error fetching file:", error);
        });
    }
  };

  return (
    <div className="table-responsive react-table">
      <Table bordered hover className={className}>
        <thead className="table-light table-nowrap">
          {HeaderData !== null ? (
            <tr>
              {/* <th style={{ width: "10%" }}>Sr.No</th> */}
              {renderHeader()}
            </tr>
          ) : (
            <tr></tr>
          )}
        </thead>

        <tbody>
          {HeaderData != null && BodyData !== null ? renderBody() : ""}
        </tbody>
      </Table>
    </div>
  );
};

CrudTable.propTypes = {
  HeaderData: PropTypes.array,
  BodyData: PropTypes.array,
  branch: PropTypes.string,
};

export default CrudTable;
