import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { FileCopyTwoTone } from "@material-ui/icons";
import avatar from "../../assets/images/users/avatar-1.jpg";
import Avatar from "@material-ui/core/Avatar";
import LocalPhone from "@material-ui/icons/LocalPhone";
import LocationOn from "@material-ui/icons/LocationOn";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Timer from "@material-ui/icons/Timer";
import WatchLater from "@material-ui/icons/WatchLater";
import Person from "@material-ui/icons/Person";
import NaturePeople from "@material-ui/icons/NaturePeople";
import CreditCard from "@material-ui/icons/CreditCard";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
import Email from "@material-ui/icons/Email";
import Business from "@material-ui/icons/Business";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import { useSelector, useDispatch } from "react-redux";
import { setDashboardIsCopied } from "store/actions";
import user1 from "assets/images/users/avatar-1.jpg";
import { changeProfilePicture, setPopMessage } from "store/actions";
import Employee from "helpers/Employee_Management/Employee";

// import PasswordChange from "./PasswordChange";

function About(props) {
  const { classes, dataa, User_Photo } = props;
  const data = dataa;
  const location = useLocation();
  const dispatch = useDispatch();

  const { User } = useSelector(state => state.Login);
  const Files = useSelector(state => state.Profile.Files);
  const popupMessage = useSelector(state => state.Profile.PopupMessage);

  const [EmployeePhotoPath, setEmployeePhotoPath] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    dispatch(changeProfilePicture(null));
    setEmployeePhotoPath(User_Photo.User_Photo_Path);
  }, []);

  const handleCopy = value => {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, textarea.value.length);

    try {
      document.execCommand("copy");
      dispatch(setDashboardIsCopied(true));
      setTimeout(() => dispatch(setDashboardIsCopied(false)), 2000); // Reset the "Text Copied" message after 2 seconds
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }

    document.body.removeChild(textarea);
  };

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadFiles = (files, previewUrl) => {
    if (files.length === 1) {
      files.map(async file => {
        const fileSize = files[0].size;
        if (fileSize > 2097152) {
          alert(`Maximum file size is 2 Mb.`);
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          await convertBase64(file, result => {
            const newItem = {
              Name: file.name,
              // fileType: file.type,
              File: result,
              previewUrl: previewUrl,
            };
            dispatch(changeProfilePicture(newItem));
          });
        } else {
          alert(`Only Images are allowed.`);
        }
      });
    }
  };

  const handleFileEvent = e => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    dispatch(changeProfilePicture(null));
    const file = e.target.files[0];
    const reader = new FileReader();
    let previewUrl;
    reader.onload = () => {
      previewUrl = reader.result;
      handleUploadFiles(chosenFiles, previewUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  //-------------------

  const handleChangeProfilePicture = async () => {
    setisLoading(true);
    const body = {
      Employee_Id: User.Employee_Id,
      User_Photo_Path: {
        Name: Files.Name,
        File: Files.File,
      },
    };
    const response = await Employee.Update_ProfilePic(body);
    if (response.IsSuccess === true && response.Status_Code === 200) {
      console.log(response.Status_Message);
      dispatch(setPopMessage(response.Status_Message));
    } else {
      dispatch(changeProfilePicture(null));
    }
    setisLoading(false);
  };

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      direction="row"
      spacing={3}
    >
      <Grid
        item
        lg={2}
        md={4}
        sm={5}
        xs={12}
        style={{
          // backgroundColor: "#2a3042f0",
          borderRadius: 30,
          color: "#004a8d",
        }}
      >
        <div style={{ margin: "auto", marginTop: 5 }}>
          {/* <div style={{ display: "flex", marginBottom: 10 }}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.title}
              style={{ marginTop: -3, fontSize: 22 }}
            >
              PROFILE IMAGE
            </Typography>
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <img
              alt={name}
              src={
                User_Photo.User_Photo_Path !== null &&
                User_Photo.User_Photo_Path !== ""
                  ? User_Photo.User_Photo_Path
                  : avatar
              }
              className="avatar-lg"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "70%",
                height: "70%",
                borderRadius: 10,
                marginTop: 5,
              }}
            /> */}
            <div
              style={{
                width: "100%",
                textAlign: "center", // Added property
              }}
            >
              <img
                src={
                  Files !== null
                    ? Files.previewUrl
                    : EmployeePhotoPath !== null
                    ? EmployeePhotoPath
                    : user1
                }
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "70%",
                  height: "70%",
                  borderRadius: 10,
                  marginTop: 5,
                  boxShadow: "5px 5px 5px grey",
                }}
              />
              <label
                htmlFor="fileUpload"
                style={{
                  margin: "15px auto",
                  padding: "3px 0px",
                  cursor: "pointer",
                  display: "block",
                  backgroundColor: "#004a8d",
                  borderRadius: 5,
                  width: "70%",
                  color: "white",
                }}
              >
                Upload Photo
              </label>
              <input
                type="file"
                className="form-control"
                onChange={handleFileEvent}
                id="fileUpload"
                multiple={false}
                style={{ display: "none" }}
                accept="image/jpeg, image/png"
              />
              <button
                style={{
                  margin: "auto",
                  marginTop: -10,
                  padding: "3px 0px",
                  cursor: Files === null ? "default" : "pointer",
                  display: "block",
                  backgroundColor: Files === null ? "grey" : "#004a8d",
                  border: "1px solid #004a8d",
                  borderRadius: 5,
                  width: "70%",
                  color: "white",
                }}
                onClick={e => handleChangeProfilePicture()}
                disabled={Files === null}
              >
                Save
              </button>
            </div>
          </div>
          <Typography
            gutterBottom
            style={{
              textAlign: "center",
              fontWeight: "inherit",
              fontSize: 20,
              marginTop: 10,
              // textDecoration: "underline",
            }}
          >
            {data.Full_Name}
          </Typography>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={7} xs={12}>
        <Grid
          container
          // className={classes.colList}
          style={{
            // backgroundColor: "#2a3042f0",
            borderRadius: 30,
            color: "white",
            marginTop: -10,
          }}
        >
          <Grid item md={12}>
            <div style={{ display: "flex", color: "black" }}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.title}
                style={{ marginTop: 12, fontSize: 22 }}
              >
                EMPLOYEE DETAILS
              </Typography>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <CreditCard />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Employee Code
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Employee_Code}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <CreditCard />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    CNIC
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.CNIC}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <NaturePeople />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Role
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Role_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <Business />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Department
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Department_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                color: "black",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <LocalPhone />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Phone
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      style={{ fontSize: 15, marginRight: 5, color: "black" }}
                    >
                      <a style={{ color: "black" }} href={`tel:${data.Mobile}`}>
                        {data.Mobile}
                      </a>
                    </Typography>
                    <a
                      style={{
                        width: 25,
                        top: 25,
                        right: 5,
                        position: "absolute",
                      }}
                      onClick={() => {
                        handleCopy(data.Mobile);
                      }}
                    >
                      <FileCopyTwoTone />
                    </a>
                  </div>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                color: "black",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem style={{ overflow: "hidden" }}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    User Email
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 15,
                        marginRight: 5,
                      }}
                    >
                      <a
                        style={{ color: "black" }}
                        href={`mailto:${data.Email}`}
                      >
                        {data.Email}
                      </a>
                    </Typography>
                    <a
                      style={{
                        width: 30,
                        top: 25,
                        right: 0,
                        position: "absolute",
                        zIndex: 99,
                      }}
                      onClick={() => {
                        handleCopy(data.Email);
                      }}
                    >
                      <FileCopyTwoTone />
                    </a>
                  </div>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <LocationOn />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Branch
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Branch_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <BusinessCenter />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Designation
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Designation_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        {/* Manager Information */}
        <Grid
          container
          className={classes.colList}
          style={{
            // backgroundColor: "#2a3042f0",
            borderRadius: 30,
            color: "white",
            marginTop: -10,
          }}
        >
          <Grid md={12}>
            <div style={{ display: "flex", color: "black" }}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.title}
                style={{ marginTop: 12, fontSize: 22 }}
              >
                MANAGER INFORMATION
              </Typography>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Name
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Report_To_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <BusinessCenter />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Designation
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Manager_Designation_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Email
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Manager_Email}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#004a8d" }}>
                    <LocationOn />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Location
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Manager_Branch_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        {/* Shift Card */}
        <Grid
          container
          className={classes.colList}
          style={{
            // backgroundColor: "#2a3042f0",
            borderRadius: 30,
            color: "white",
            marginTop: -10,
          }}
        >
          <Grid md={12}>
            <div style={{ display: "flex", color: "black" }}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.title}
                style={{ marginTop: 12, fontSize: 22 }}
              >
                SHIFT INFORMATION
              </Typography>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={classNames(classes.avatar, classes.purpleAvatar)}
                    style={{ backgroundColor: "#004a8d" }}
                  >
                    <WatchLater />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Shift Name
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {data.Shift_Name}
                  </Typography>
                </div>
              </ListItem>
            </List>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={classNames(classes.avatar, classes.orangeAvatar)}
                    style={{ backgroundColor: "#004a8d" }}
                  >
                    <Timer />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Break Timing
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {moment(data.Shift_Breaktime, "HH:mm:ss").format("h:mm A")}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={classNames(classes.avatar, classes.pinkAvatar)}
                    style={{ backgroundColor: "#004a8d" }}
                  >
                    <Timer />
                  </Avatar>
                </ListItemAvatar>
                <div>
                  <Typography style={{ fontWeight: "bold", color: "#004a8d" }}>
                    Shift Timing
                  </Typography>
                  <Typography style={{ fontSize: 15, color: "black" }}>
                    {moment(data.Shift_TimeStart, "HH:mm:ss").format("h:mm A") +
                      " - " +
                      moment(data.Shift_TimeEnd, "HH:mm:ss").format("h:mm A")}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      {/* <PasswordChange /> */}
      {/* <Grid item md={12} xs={12}>
        {location.pathname.toLowerCase().replace(/[/]/g, "_") ===
        "_app_my_profile" ? (
          <PasswordChange onSubmit={values => handleSubmit(values)} />
        ) : (
          ""
        )}
        <AlertDialog />
      </Grid> */}

      {/* DialogPopup */}
      <DialogPopup branch={"Profile"} message={popupMessage} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Grid>
  );
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
  // data: PropTypes.array.isRequired,
};

export default withStyles()(About);
