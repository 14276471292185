import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_EmployeeAttendance_DD = (value = 0) => {
  let url = "api/Employee_Attendance/Get_EmployeeAttendance_DropDown";

  return get(url);
};

const Get_EmployeeAttendance_List = body => {
  let url = "api/Employee_Attendance/Get_EmployeeAttendance_List";

  return post(url, body);
};

const Save_EmployeeAttendance = body => {
  let url = "api/Employee_Attendance/Save_EmployeeAttendance";
  return post(url, body);
};

const Edit_EmployeeAttendance = body => {
  let url = "api/Employee_Attendance/Edit_EmployeeAttendance";
  return post(url, body);
};

const Delete_EmployeeAttendance = body => {
  let url = "api/Employee_Attendance/Edit_EmployeeAttendance_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "SrNo",
    accessor: "SrNo",
    hidden: false,
    width: 30,
  },
  {
    Header: "Employee",
    accessor: "EmployeeName",
    hidden: false,
    width: 200,
  },
  {
    Header: "Department",
    accessor: "DepartmentName",
    hidden: false,
    width: 200,
  },
  {
    Header: "Date",
    accessor: "Date",
    hidden: false,
    width: 100,
  },
  {
    Header: "Day",
    accessor: "Day",
    hidden: false,
    width: 100,
  },
  {
    Header: "Time In",
    accessor: "TimeIn",
    hidden: false,
    width: 100,
  },
  {
    Header: "Time Out",
    accessor: "TimeOut",
    hidden: false,
    width: 100,
  },
  {
    Header: "Working Hrs.",
    accessor: "WorkingHours",
    hidden: false,
    width: 100,
  },
  {
    Header: "Status",
    accessor: "Status",
    hidden: false,
    width: 100,
  },
  {
    Header: "Attendance Type",
    accessor: "AttendanceTypeName",
    hidden: false,
    width: 150,
  },
  {
    Header: "AttendanceType Id",
    accessor: "AttendanceTypeId",
    hidden: true,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
    width: 50,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_EmployeeAttendance_DD,
  Get_EmployeeAttendance_List,
  Save_EmployeeAttendance,
  Edit_EmployeeAttendance,
  Delete_EmployeeAttendance,
  anchorTable,
};
