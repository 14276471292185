import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Floating Form
import FloatingForm from "components/Forms/MasterData_Management/EmployeeFamily/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "../../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
//API Service
import _EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import _Employee from "helpers/Employee_Management/Employee";
import Department from "helpers/MasterData_Management/Department";

//i18n
import { withTranslation } from "react-i18next";
const EmployeeFamily = props => {
  //meta title
  document.title = "HR - Employee Family";
  //Table Column Names
  const anchorTable = _EmployeeFamily.anchorTable;

  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  //Data Lists
  const [EmployeeList, setEmployeeList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  //Filter Values
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedEmployeeName, setSelectedEmployeeName] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  const branch = "crudTbEmployeeFamily";

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(state => state.crudTbEmployeeFamily.dataTable);
  const formOpen = useSelector(state => state.crudTbEmployeeFamily.formOpen);
  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbEmployeeFamily.popupMessage
  );

  const initialData = async () => {
    try {
      setisLoading(true);
      const departments = await Department.Get_Department_DD();
      setDepartmentList(departments.Data.Details);
      handleDepartmentChange(user.Department_Id, true);
      setSelectedEmployee(user.Employee_Id);

      let body = {
        PageNumber: 1,
        PageSize: 10,
        EmployeeID: user.Employee_Id,
      };
      const response = await _EmployeeFamily.Get_Employee_Family_List(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
        setisLoading(false);
        // dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }

      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Department_Id: user.Department_Id,
    },
    validationSchema: Yup.object({
      Department_Id: Yup.number()
        .required("Please Select a Department")
        .min(1, "Please Select a Department"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      setInitialList(null);
      let body = {
        PageNumber: 1,
        PageSize: 10,
        EmployeeID: SelectedEmployee,
      };
      const response = await _EmployeeFamily.Get_Employee_Family_List(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
        setisLoading(false);
        // dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }
    },
  });

  const handleDepartmentChange = async (value, InitialRun) => {
    try {
      setisLoading(true);
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const data = response.Data.Details;
        setEmployeeList([]);
        setSelectedOption("");
        const emp_list = [];
        data.map(item => {
          const obj = {
            value: item.Value,
            label: item.Text + " - " + item.EmpCode,
          };
          emp_list.push(obj);
        });
        if (InitialRun) {
          const index = emp_list.findIndex(item => {
            return item.value == user.Employee_Id;
          });
          if (index !== -1) {
            setSelectedOption(emp_list[index]);
          }
        }

        setEmployeeList(emp_list);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };
  // console.log(InitialEmployeeIndex);
  // console.log(EmployeeList[InitialEmploxyeeIndex].label);
  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Employee Family")}
          />

          {user.Role_Id !== 4 ? (
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Department
                    </Label>
                    <Input
                      type="select"
                      name="Department_Id"
                      onChange={e => {
                        validation.handleChange(e);
                        handleDepartmentChange(e.target.value);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.Department_Id || ""}
                      invalid={
                        validation.touched.Department_Id &&
                        validation.errors.Department_Id
                          ? true
                          : false
                      }
                    >
                      <option key={1} value={0}>
                        Select
                      </option>
                      {DepartmentList == null || DepartmentList == undefined
                        ? ""
                        : renderDepartment()}
                    </Input>
                    {validation.touched.Department_Id &&
                    validation.errors.Department_Id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Department_Id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Employee
                    </Label>
                    <Select
                      isDisabled={EmployeeList == []}
                      isSearchable={true}
                      value={SelectedOption}
                      onChange={e => {
                        setSelectedEmployee(e.value);
                        setSelectedEmployeeName(e.label);
                        setSelectedOption(e);
                      }}
                      name="color"
                      options={EmployeeList}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          backgroundColor: "white",
                        }),
                      }}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="btn-save-event"
                    style={{
                      marginTop: 28,
                    }}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}

          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            //Dynamic Table
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
              EmployeeId={SelectedEmployee}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm
            branch={branch}
            SelectedEmployee={SelectedEmployee}
            SelectedEmployeeName={SelectedEmployeeName}
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EmployeeFamily);
