import axios from "axios";
import userService from "./user.service";

//pass new generated access token here
let Authorization = userService.Get_Token();
Authorization = "Bearer " + Authorization;

//apply base url for axios
const API_URL = "http://43.231.61.149/Systech_HR_Test/";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = Authorization;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

// export async function get_(url, config = {}) {
//   return await axiosApi.get(url, { ...config }).then(response => response.data)
// }

// export async function post_(url, data, config = {}) {
//   return axiosApi
//     .post(url, { ...data }, { ...config })
//     .then(response => response.data)
// }

export async function put(url, data, config = {}) {
  return axiosApi
    .put(API_URL + url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

//------------------------------
export async function get(url, config = {}) {
  return await axiosApi
    .get(API_URL + url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      return err;
    });
  // .catch(err => {
  //   var message
  //   if (err.response && err.response.status) {
  //     switch (err.response.status) {
  //       case 404:
  //         message = "Sorry! the page you are looking for could not be found"
  //         break
  //       case 500:
  //         message =
  //           "Sorry! something went wrong, please contact our support team"
  //         break
  //       case 401:
  //         message = "Invalid credentials"
  //         break
  //       default:
  //         message = err[1]
  //         break
  //     }
  //   }
  //   throw message
  // })
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(API_URL + url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      return err;
    });
  // .catch(err => {
  //   var message
  //   if (err.response && err.response.status) {
  //     switch (err.response.status) {
  //       case 404:
  //         message = "Sorry! the page you are looking for could not be found"
  //         break
  //       case 500:
  //         message =
  //           "Sorry! something went wrong, please contact our support team"
  //         break
  //       case 401:
  //         message = "Invalid credentials"
  //         break
  //       default:
  //         message = err[1]
  //         break
  //     }
  //   }
  //   throw message
  // })
}
