import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _Calendar from "helpers/MasterData_Management/Calendar";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";
import { reset } from "redux-form";
import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isHolidayCheck, setisHolidayCheck] = useState(false);

  const formOpen = useSelector(state => state.crudTbCalendar.formOpen);
  const CalendarList = useSelector(state => state.crudTbCalendar.dataTable);
  const formValues = useSelector(state => state.crudTbCalendar.formValues);
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formValues != null) {
      const index = CalendarList.findIndex(
        item => item.Calendar_Id == formValues.Calendar_Id
      );
      setIsEdit(true);
      setInitialFormData(CalendarList[index]);
      setIsActiveCheck(CalendarList[index].IsActive);
      setisHolidayCheck(CalendarList[index].Holiday);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
    reset();
  };

  let validation;

  if (
    InitialFormData !== null &&
    InitialFormData !== undefined &&
    isEdit === true
  ) {
    validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
      initialValues: {
        Remarks: isEdit ? InitialFormData.Remarks : "",
        Holiday: isEdit ? isHolidayCheck : false,
      },
      validationSchema: Yup.object({
        Remarks: Yup.string().required("Please Enter Remarks"),
      }),
      onSubmit: async values => {
        setisLoading(true);
        //While updating an existing data
        let body = {
          Calendar_Id: InitialFormData.Calendar_Id,
          Date: InitialFormData.Date,
          DayId: InitialFormData.DayId,
          Day: InitialFormData.Day,
          MonthKey: InitialFormData.MonthKey,
          Month: InitialFormData.Month,
          Year: InitialFormData.Year,
          Holiday: isHolidayCheck,
          Remarks: values.Remarks,
          IsActive: IsActiveCheck,
          User: InitialFormData.User,
        };
        const response = await _Calendar.Edit_Calendar(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
        toggle();
      },
    });
  } else {
    validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
      initialValues: {
        Year: "",
      },
      validationSchema: Yup.object({
        Year: Yup.number().required("Please Enter Year"),
      }),
      onSubmit: async values => {
        setisLoading(true);

        let body = {
          Year: values.Year,
          User: user.Employee_Id,
        };
        const response = await _Calendar.Save_Calendar(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
        toggle();
      },
    });
  }

  const handleValidate = e => {
    _validation.Number(e);
    _validation.maxLength4;
  };

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Calendar" : "Add Calendar"}
      </ModalHeader>
      <ModalBody className="p-4">
        {InitialFormData !== null && InitialFormData !== undefined ? (
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Remarks
                  </Label>
                  <Input
                    name="Remarks"
                    type="text"
                    maxLength={255}
                    // onKeyPressCapture={e => _validation.alphabetic(e)}
                    // value={isEdit ? InitialFormData.Remarks : ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Remarks || ""}
                    invalid={
                      validation.touched.Remarks && validation.errors.Remarks
                        ? true
                        : false
                    }
                  />
                  {validation.touched.Remarks && validation.errors.Remarks ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Remarks}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label" style={{ marginRight: 20 }}>
                    <span style={{ color: "red" }}>* </span> Holiday
                  </Label>
                  <Input
                    name="Holiday"
                    type="checkbox"
                    // value={isEdit ? InitialFormData.Holiday : ""}
                    onChange={e => setisHolidayCheck(e.target.checked)}
                    onBlur={validation.handleBlur}
                    value={validation.values.Holiday || ""}
                    checked={isHolidayCheck}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="col-6 text-end">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  onClick={toggle}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Year</Label>
                  <Input
                    name="Year"
                    type="number"
                    onKeyPressCapture={e => handleValidate(e)}
                    // value={isEdit ? InitialFormData.Year : ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Year || ""}
                    invalid={
                      validation.touched.Year && validation.errors.Year
                        ? true
                        : false
                    }
                  />
                  {validation.touched.Year && validation.errors.Year ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Year}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="col-12 text-end">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  onClick={toggle}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </ModalBody>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
