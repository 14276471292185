import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Attendance_Report = body => {
  let url = "api/Reports/GetMedicalReport";
  return post(url, body);
};

// const Attendance_Report_ByHR = body => {
//   let url = "api/Reports/Attendance_Report_ByHR";
//   return post(url, body);
// };

const EmployeeColumns = [
  {
    name: "S.No.",
    options: {
      filter: false,
    },
  },
  {
    name: "Employee Name",
    options: {
      filter: false,
    },
  },
  {
    name: "Month",
    options: {
      filter: true,
    },
  },
  {
    name: "Total Claim Amount",
    options: {
      filter: true,
    },
  },
  {
    name: "Total Refunded Amount",
    options: {
      filter: true,
    },
  },
  {
    name: "Approval Date",
    options: {
      filter: true,
    },
  },
  {
    name: "Approval Status",
    options: {
      filter: true,
    },
  },
];
const ManagerHRColumns = [
  {
    name: "S.No.",
    options: {
      filter: false,
    },
  },
  {
    name: "Employee Name",
    options: {
      filter: false,
    },
  },
  {
    name: "Month",
    options: {
      filter: true,
    },
  },
  {
    name: "Total Claim Amount",
    options: {
      filter: true,
    },
  },
  {
    name: "Total Refunded Amount",
    options: {
      filter: true,
    },
  },
  {
    name: "Approval Date",
    options: {
      filter: true,
    },
  },
  {
    name: "Approval Status",
    options: {
      filter: true,
    },
  },
];
export default {
  getLoggedInUser,
  isUserAuthenticated,
  Attendance_Report,
  // Attendance_Report_ByHR,
  EmployeeColumns,
  ManagerHRColumns,
};
