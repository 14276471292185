import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";

const styles = theme => ({
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
});
/*
  It uses npm mui-datatables. It's easy to use, you just describe columns and data collection.
  Checkout full documentation here :
  https://github.com/gregnb/mui-datatables/blob/master/README.md
*/
function AdvFilter(props) {
  const { columns, data, fileName } = props;

  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    selectableRows: false,
    print: false,
    rowsPerPage: 50,
    download: data.length > 0 ? true : false,
    rowsPerPageOptions: [50, 100, 150],
    page: 0,
    downloadOptions: { filename: fileName },
  };

  const { classes } = props;

  return (
    <div className={classes.table}>
      <MUIDataTable title="" data={data} columns={columns} options={options} />
    </div>
  );
}

AdvFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdvFilter);
