import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { FileCopyTwoTone } from "@material-ui/icons";
import { List } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Phone, Email } from "@material-ui/icons";
import { PhoneAndroidTwoTone } from "@material-ui/icons";
import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { Row } from "reactstrap";
import { setDashboardIsCopied } from "store/actions";
import "./style.css";
// import datas from "dan-api/apps/connectionData";

function Connection(props) {
  const { classes, Connections } = props;
  const user = useSelector(state => state.Login.User);
  const data = Connections;

  const dispatch = useDispatch();

  const [HOD, setHOD] = useState([]);
  const [Manager, setManager] = useState([]);
  const [Employee, setEmployee] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const Hods = data.filter(item => {
        return (
          item.Designation_Name === "HOD" &&
          item.Employee_Code !== user.Employee_Code
        );
      });
      setHOD(Hods);
      const managers = data.filter(item => {
        return item.Designation_Name === "Manager";
      });
      setManager(managers);
      const employees = data.filter(item => {
        return (
          item.Designation_Name !== "HOD" && item.Designation_Name !== "Manager"
        );
      });
      setEmployee(employees);
    }
  }, []);

  const handleCopyEmail = value => {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, textarea.value.length);

    try {
      document.execCommand("copy");
      dispatch(setDashboardIsCopied(true));
      setTimeout(() => dispatch(setDashboardIsCopied(false)), 2000); // Reset the "Text Copied" message after 2 seconds
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }

    document.body.removeChild(textarea);
  };
  const handleCopyPhoneNumber = value => {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, textarea.value.length);

    try {
      document.execCommand("copy");
      dispatch(setDashboardIsCopied(true));
      setTimeout(() => dispatch(setDashboardIsCopied(false)), 2000); // Reset the "Text Copied" message after 2 seconds
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }

    document.body.removeChild(textarea);
  };
  if (data.length > 0) {
    return (
      <Row style={{ flexDirection: "row", display: "flex" }}>
        {HOD.length > 0 ? (
          <Row style={{ flexDirection: "row", display: "flex" }}>
            <Grid item md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  HODs
                </Typography>
              </div>
            </Grid>
            {HOD.map((data, index) => (
              <Card
                style={{
                  width: 240,
                  margin: "10px 10px",
                  boxShadow: "2px 2px 10px grey",
                  padding: 0,
                }}
                key={index}
                className="connect-card"
              >
                <CardContent
                  style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    display: "grid",
                    padding: 0,
                    color: "black",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      alignItems: "center",
                      alignSelf: "center",
                      margin: 0,
                    }}
                  >
                    <img
                      alt={name}
                      src={avatar}
                      className="avatar-lg"
                      style={{
                        display: "block",
                        margin: "auto",
                        width: "70%",
                        height: "70%",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#e3ebf5f0",
                        padding: "5px 5px",
                        width: 240,
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                        gutterBottom
                        color="white"
                      >
                        {data.Full_Name}
                      </Typography>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: -10,
                          color: "#809fc5",
                          marginBottom: 0,
                        }}
                      >
                        {data.Designation_Name}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: -5,
                          color: "black",
                          marginBottom: 5,
                        }}
                      >
                        {"Department: " + data.Department_Name}
                      </p>
                      <div style={{ display: "flex" }}>
                        <a href={`tel:${data.Mobile}`}>
                          <PhoneAndroidTwoTone
                            style={{
                              fontSize: 36,
                              margin: "0px 4px 0px 0px",
                              padding: 3,
                              borderRadius: 5,
                              color: "#2c2f42",
                              border: "2px solid #2c2f42",
                              backgroundColor: "white",
                            }}
                          />
                        </a>
                        <div
                          style={{
                            backgroundColor: "white",
                            width: 190,
                            borderRadius: 5,
                            textAlign: "center",
                            border: "2px solid #2c2f42",
                          }}
                        >
                          <a
                            onClick={() =>
                              (window.location = `mailto:${data.Email}`)
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <Email
                              style={{
                                fontSize: 32,
                                margin: "0px 4px 0px 0px",
                                padding: 3,
                                borderRadius: 5,
                                color: "#2c2f42",
                                backgroundColor: "white",
                              }}
                            />
                            Email
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </Row>
        ) : (
          ""
        )}

        {/* ----Managers----- */}
        {Manager.length > 0 ? (
          <Row style={{ flexDirection: "row", display: "flex" }}>
            <Grid item md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  Managers
                </Typography>
              </div>
            </Grid>
            {Manager.map((data, index) => (
              <Card
                style={{
                  boxShadow: "2px 2px 10px grey",
                  width: 240,
                  margin: "10px 10px",
                  padding: 0,
                }}
                key={index}
                className="connect-card"
              >
                <CardContent
                  style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    display: "grid",
                    padding: 0,
                    color: "black",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      alignItems: "center",
                      alignSelf: "center",
                      margin: 0,
                    }}
                  >
                    <img
                      alt={name}
                      src={avatar}
                      className="avatar-lg"
                      style={{
                        display: "block",
                        margin: "auto",
                        width: "70%",
                        height: "70%",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#e3ebf5f0",
                        padding: "5px 5px",
                        width: 240,
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                        gutterBottom
                        color="white"
                      >
                        {data.Full_Name}
                      </Typography>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: -10,
                          color: "#809fc5",
                          marginBottom: 0,
                        }}
                      >
                        {data.Designation_Name}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: -5,
                          color: "black",
                          marginBottom: 5,
                        }}
                      >
                        {"Department: " + data.Department_Name}
                      </p>
                      <div style={{ display: "flex" }}>
                        <a href={`tel:${data.Mobile}`}>
                          <PhoneAndroidTwoTone
                            style={{
                              fontSize: 36,
                              margin: "0px 4px 0px 0px",
                              padding: 3,
                              borderRadius: 5,
                              color: "#2c2f42",
                              backgroundColor: "white",
                              border: "2px solid #2c2f42",
                            }}
                          />
                        </a>
                        <div
                          style={{
                            backgroundColor: "white",
                            width: 190,
                            borderRadius: 5,
                            textAlign: "center",
                            border: "2px solid #2c2f42",
                          }}
                        >
                          <a
                            onClick={() =>
                              (window.location = `mailto:${data.Email}`)
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <Email
                              style={{
                                fontSize: 32,
                                margin: "0px 4px 0px 0px",
                                padding: 3,
                                borderRadius: 5,
                                color: "#2c2f42",
                                backgroundColor: "white",
                              }}
                            />
                            Email
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </Row>
        ) : (
          ""
        )}

        {/* -----Employees---- */}
        {Employee.length > 0 ? (
          <Row style={{ flexDirection: "row", display: "flex" }}>
            <Grid item md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  Employees
                </Typography>
              </div>
            </Grid>
            {Employee.map((data, index) => (
              <Card
                style={{
                  boxShadow: "2px 2px 10px grey",
                  width: 240,
                  margin: "10px 10px",
                  padding: 0,
                }}
                key={index}
                className="connect-card"
              >
                <CardContent
                  style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    display: "grid",
                    padding: 0,
                    color: "black",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      alignItems: "center",
                      alignSelf: "center",
                      margin: 0,
                    }}
                  >
                    <img
                      alt={name}
                      src={avatar}
                      className="avatar-lg"
                      style={{
                        display: "block",
                        margin: "auto",
                        width: "70%",
                        height: "70%",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#e3ebf5f0",
                        padding: "5px 5px",
                        width: 240,
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                        gutterBottom
                        color="white"
                      >
                        {data.Full_Name}
                      </Typography>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: -5,
                          color: "#809fc5",
                          marginBottom: 0,
                        }}
                      >
                        {data.Designation_Name}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          color: "black",
                          marginBottom: 10,
                        }}
                      >
                        {"Department: " + data.Department_Name}
                      </p>
                      <div style={{ display: "flex" }}>
                        <a href={`tel:${data.Mobile}`}>
                          <PhoneAndroidTwoTone
                            style={{
                              fontSize: 36,
                              margin: "0px 4px 0px 0px",
                              padding: 3,
                              borderRadius: 5,
                              border: "2px solid #2c2f42",
                              color: "#2c2f42",
                              backgroundColor: "white",
                            }}
                          />
                        </a>
                        <div
                          style={{
                            backgroundColor: "white",
                            width: 190,
                            borderRadius: 5,
                            textAlign: "center",
                            border: "2px solid #2c2f42",
                          }}
                        >
                          <a
                            onClick={() =>
                              (window.location = `mailto:${data.Email}`)
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <Email
                              style={{
                                fontSize: 32,
                                margin: "0px 4px 0px 0px",
                                padding: 3,
                                borderRadius: 5,
                                color: "#2c2f42",
                                backgroundColor: "white",
                              }}
                            />
                            Email
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </Row>
        ) : (
          ""
        )}
      </Row>
    );
  }
  return (
    <Grid item md={3} xs={12}>
      <Grid md={12}>
        <div style={{ display: "flex", color: "black" }}>
          <Typography
            variant="h6"
            component="h2"
            className={classes.title}
            style={{ marginTop: 12, fontSize: 22 }}
          >
            SUBORDINATES
          </Typography>
        </div>
      </Grid>
      <List
        dense
        className={classes.profileList}
        style={{
          backgroundColor: "white",
          boxShadow: "2px 2px 10px grey",
          borderRadius: 10,
          margin: "10px 0px",
          padding: 10,
        }}
      >
        <Typography
          gutterBottom
          style={{ fontWeight: "bold", margin: 5, color: "#2c2f42" }}
        >
          Unfortunately you don't have any subordinate.
        </Typography>
      </List>
    </Grid>
  );
}

Connection.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles()(Connection);

{
  /* <div
  key={index}
  style={{
    // backgroundColor: "#2a3042f0",
    borderRadius: 10,
    display: "block",
    color: "white",
    width: 252,
    margin: 10,
  }}
>
  <Card style={{ backgroundColor: "#373c4d00", boxShadow: "none" }}>
    <CardContent
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        display: "grid",
        padding: 10,
        color: "black",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      <div
        style={{
          display: "block",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <img
          alt={name}
          src={avatar}
          className="avatar-lg rounded-circle"
          style={{
            display: "block",
            margin: "auto",
            marginTop: 5,
            marginBottom: 10,
            width: "40%",
            height: "40%",
          }}
        />
        <div
          style={{
            backgroundColor: "#e3ebf5f0",
            padding: "2px 5px",
            width: 210,
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: 16,
              fontWeight: "bold",
            }}
            gutterBottom
            color="white"
          >
            {data.Full_Name}
          </Typography>
          <p
            style={{
              textAlign: "center",
              fontSize: 14,
              // fontWeight: "bold",
              marginTop: -10,
              color: "#809fc5",
              marginBottom: 0,
            }}
          >
            {data.Designation_Name}
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: 14,
              // fontWeight: "bold",
              marginTop: -5,
              color: "black",
              marginBottom: 5,
            }}
          >
            {"Department: " + data.Department_Name}
          </p>
          <p style={{ margin: 0 }}>
            <span>
              <Phone
                style={{
                  fontSize: 14,
                  margin: "0px 4px 0px 0px",
                }}
              />
            </span>
            <span data-tooltip="Copy">{data.Mobile}</span>
            <span>
              <a onClick={e => handleCopyPhoneNumber("03310311300")}>
                <FileCopyTwoTone style={{ fontSize: 18, marginLeft: 3 }} />
              </a>
            </span>
          </p>
          <div style={{ margin: 0, display: "flex" }}>
            <span>
              <Email
                style={{
                  fontSize: 14,
                  margin: "0px 4px 0px 0px",
                }}
              />
            </span>
            <span
              data-tooltip="Copy"
              style={{ width: 150, overflow: "hidden" }}
            >
              {data.Email}
            </span>
            <span>...</span>
            <span>
              <a
                onClick={e =>
                  handleCopyEmail("example.example@systechlogic.com")
                }
              >
                <FileCopyTwoTone style={{ fontSize: 18, marginLeft: 3 }} />
              </a>
            </span>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
</div>; */
}
