import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _SalaryHeads from "helpers/MasterData_Management/SalaryHeads";
import Switch from "@material-ui/core/Switch";
import { RotatingLines } from "react-loader-spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import _Degree from "helpers/MasterData_Management/Degree";
import _City from "helpers/MasterData_Management/City";
import _validation from "helpers/validation";

const floatingForm = props => {
  const { branch } = props;

  const [SalaryHeadList, setSalaryHeadList] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [selectedOption, setselectedOption] = useState(false);
  const [selectedSalaryHead, setselectedSalaryHead] = useState(false);
  const [isSpinnerLoaderHeads, setisSpinnerLoaderHeads] = useState(false);
  const [Amounts, setAmount] = useState(0);
  const [Percentages, setPercentage] = useState(0);

  const formOpen = useSelector(state => state.crudTbSalaryDetail.formOpen);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  React.useEffect(() => {
    initialData();
    setisLoading(false);
  }, []);

  const initialData = async () => {
    try {
      setisLoading(true);
      setisSpinnerLoaderHeads(true);

      const response = await _SalaryHeads.Get_SalaryHeads_DD();
      setSalaryHeadList(response.Data.Details);

      setisLoading(false);
      setisSpinnerLoaderHeads(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  const selectedSalaryHeadFind = id => {
    if (SalaryHeadList !== null) {
      const index = SalaryHeadList.findIndex(item => {
        return item.Value == id;
      });
      if (index !== -1) setselectedSalaryHead(SalaryHeadList[index].Text);
    }
  };

  const renderSalaryHeads = () =>
    SalaryHeadList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Head_Id: "",
      // Percentage: "",
      // Amount: "",
    },
    validationSchema: Yup.object({
      Head_Id: Yup.number()
        .required("Please Select a Salary Head")
        .min(1, "Please Select a Salary Head"),
      Percentage: Yup.number().max(100),
      Amount: Yup.number(),
    }),
    onSubmit: async values => {
      let data;
      if (selectedOption == 1) {
        data = {
          ...values,
          Percentage: parseFloat(Percentages),
          Amount: 0,
          Head_Name: selectedSalaryHead,
        };
      } else {
        data = {
          ...values,
          Percentage: 0,
          Amount: parseFloat(Amounts),
          Head_Name: selectedSalaryHead,
        };
      }
      setisLoading(true);
      dispatch(addNewItem(data, branch));
      setisLoading(false);
      dispatch(setPopupMessageValues("Added", branch));
      useFormik.resetForm();
      toggle();
    },
  });

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {"Add Salary Details"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Salary Head
                    <span style={{ paddingTop: 10, marginLeft: 10 }}>
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="15"
                        visible={isSpinnerLoaderHeads}
                      />
                    </span>
                  </Label>
                  <Input
                    name="Head_Id"
                    type="select"
                    // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                    onChange={e => {
                      validation.handleChange(e);
                      selectedSalaryHeadFind(e.target.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.Head_Id || ""}
                    disabled={isSpinnerLoaderHeads}
                    invalid={
                      validation.touched.Head_Id && validation.errors.Head_Id
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {SalaryHeadList == null || SalaryHeadList == undefined
                      ? ""
                      : renderSalaryHeads()}
                  </Input>

                  {validation.touched.Head_Id && validation.errors.Head_Id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Head_Id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Select an option
                  </Label>
                  <Input
                    type="select"
                    onChange={e => setselectedOption(e.target.value)}
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    <option key={2} value={1}>
                      Percentage
                    </option>
                    <option key={3} value={2}>
                      Amount
                    </option>
                  </Input>
                </div>
              </Col>
              {selectedOption == 1 ? (
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Percentage</Label>
                    <Input
                      name="Percentage"
                      type="text"
                      // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                      onChange={e => {
                        validation.handleChange(e);
                        setPercentage(e.target.value);
                      }}
                      onBlur={validation.handleBlur}
                      // value={validation.values.Percentage || ""}
                      required
                      maxLength={4}
                      disabled={selectedOption === 2}
                      invalid={
                        validation.touched.Percentage &&
                        validation.errors.Percentage
                          ? true
                          : false
                      }
                      onKeyPress={event => {
                        _validation.Number2(event);
                      }}
                    />
                    {validation.touched.Percentage &&
                    validation.errors.Percentage ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Percentage}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              ) : selectedOption == 2 ? (
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Amount</Label>
                    <Input
                      name="Amount"
                      type="text"
                      required
                      maxLength={9}
                      // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                      onChange={e => {
                        validation.handleChange(e);
                        setAmount(e.target.value);
                      }}
                      onBlur={validation.handleBlur}
                      // value={validation.values.Amount || ""}
                      disabled={selectedOption === 1}
                      invalid={
                        validation.touched.Amount && validation.errors.Amount
                          ? true
                          : false
                      }
                      onKeyPress={event => {
                        _validation.Number2(event);
                      }}
                    />
                    {validation.touched.Amount && validation.errors.Amount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Amount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
