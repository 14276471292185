import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { RotatingLines } from "react-loader-spinner";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import _Employee from "helpers/Employee_Management/Employee";
import _LeaveType from "helpers/MasterData_Management/LeaveType";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import _Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import _validation from "helpers/validation";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isSpinnerLoader, setisSpinnerLoader] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);
  const [Disable, setDisable] = useState(false);
  const [IsHalfDay, setIsHalfDay] = useState(false);

  const [LeaveTypeList, setLeaveTypeList] = useState(null);
  const [ApprovalStatusList, setApprovalStatusList] = useState(null);

  const [From_Date, setFrom_Date] = useState(null);
  const [To_Date, setTo_Date] = useState(null);
  const [currentDate, setcurrentDate] = useState(null);

  const formOpen = useSelector(state => state.crudTbEmployeeLeave.formOpen);
  const EmployeeLeaveList = useSelector(
    state => state.crudTbEmployeeLeave.dataTable
  );
  const formValues = useSelector(state => state.crudTbEmployeeLeave.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      setisLoading(true);
      const response1 = await _LeaveType.Get_LeaveType_DD();
      setLeaveTypeList(response1.Data.Details);
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  useEffect(() => {
    if (formValues != null) {
      setInitialFormData(formValues);
      setIsEdit(true);
      setIsHalfDay(formValues.isHalfDay);
      setFrom_Date(formValues.From_Date.split("T")[0]);
      setTo_Date(formValues.To_Date.split("T")[0]);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Employee_Code: user.Employee_Code,
      From_Date: isEdit ? InitialFormData.From_Date : "",
      To_Date: isEdit ? InitialFormData.To_Date : "",
      LeaveType_Id: isEdit ? InitialFormData.LeaveType_Id : "",
      Leave_Justification: isEdit ? InitialFormData.Leave_Justification : "",
    },
    validationSchema: Yup.object({
      Leave_Justification: Yup.string().required(
        "Please Enter Leave Justification"
      ),
      LeaveType_Id: Yup.number()
        .required("Please Select a Leave Type")
        .min(1, "Please Select a Leave Type"),
      // Leave_Remarks: Yup.string().required("Please Enter Leave Remarks"),
      // ApprovalStatus_Id: Yup.number().min(1),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        let body;
        //While updating an existing data
        body = {
          EmployeeLeave_Id: InitialFormData.EmployeeLeave_Id,
          Employee_Id: InitialFormData.Employee_Id,
          From_Date: From_Date,
          To_Date: To_Date,
          LeaveType_Id: values.LeaveType_Id,
          Leave_Justification: values.Leave_Justification,
          DOP_User_Id: 0,
          IsActive: true,
          isHalfDay: IsHalfDay ? 1 : 0,
          User: user.Employee_Id,
          Report_to: user.Report_To_ID,
        };

        const response = await _EmployeeLeave.Edit_EmployeeLeave(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body;
        body = {
          Employee_Leave_Id: 0,
          Employee_Code: user.Employee_Code,
          LeaveType_Id: values.LeaveType_Id,
          From_Date: From_Date,
          To_Date: To_Date,
          ApprovalStatus_Id: 1,
          Leave_Justification: values.Leave_Justification,
          DOP_User_Id: 0,
          isHalfDay: IsHalfDay,
          IsActive: true,
          User: user.Employee_Id,
          Report_to: user.Report_To_ID,
        };

        const response = await _EmployeeLeave.Save_EmployeeLeave(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderLeaveType = () =>
    LeaveTypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  // const renderApprovalStatus = () =>
  //   ApprovalStatusList.map((item, index) => (
  //     <option key={index} value={item.Value}>
  //       {item.Text}
  //     </option>
  //   ));

  // const handleEmployeeCode = async value => {
  //   setisSpinnerLoader(true);
  //   const response = await _Employee.Get_Employee_List_By_Rows(1, 999999);
  //   if (response.IsSuccess === true && response.Status_Code === 200) {
  //     const data = response.Data.Details;
  //     const index = data.findIndex(item => {
  //       return item.Employee_Code == value;
  //     });
  //     if (index == -1) {
  //       setErrorMessage("User Does not Exist");
  //       setDisable(true);
  //     } else {
  //       setDisable(false);
  //     }
  //   }
  //   setisSpinnerLoader(false);
  // };

  const onCloseClick = () => {
    setErrorMessage(null);
  };
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit " : "Add New"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee Code
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoader}
                    />
                  </span>
                </Label>
                <Input
                  name="Employee_Code"
                  type="text"
                  maxLength={10}
                  disabled
                  onKeyPress={_validation.Number2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    user.Role_Id == 4
                      ? user.Employee_Code
                      : validation.values.Employee_Code || ""
                  }
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Start Date
                </Label>
                <Input
                  type="date"
                  name="From_Date"
                  required
                  onChange={e => setFrom_Date(e.target.value)}
                  value={From_Date}
                  // min={currentDate}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>End Date
                </Label>
                <Input
                  type="date"
                  name="To_Date"
                  required
                  onChange={e => setTo_Date(e.target.value)}
                  value={To_Date}
                  // min={currentDate}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Leave Justification
                </Label>
                <Input
                  name="Leave_Justification"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.alphabetic}
                  // value={isEdit ? InitialFormData.Leave_Justification : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Leave_Justification || ""}
                  invalid={
                    validation.touched.Leave_Justification &&
                    validation.errors.Leave_Justification
                      ? true
                      : false
                  }
                />
                {validation.touched.Leave_Justification &&
                validation.errors.Leave_Justification ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Leave_Justification}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Leave Type
                </Label>
                <Input
                  type="select"
                  name="LeaveType_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.LeaveType_Id || ""}
                  invalid={
                    validation.touched.LeaveType_Id &&
                    validation.errors.LeaveType_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {LeaveTypeList == null || LeaveTypeList == undefined
                    ? ""
                    : renderLeaveType()}
                </Input>
                {validation.touched.LeaveType_Id &&
                validation.errors.LeaveType_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.LeaveType_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <FormControlLabel
                control={
                  <Switch
                    name="IsHalfDay"
                    onChange={e => setIsHalfDay(e.target.checked)}
                    checked={IsHalfDay}
                    color="primary"
                  />
                }
                label="Is Half Day"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
                disabled={Disable}
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <div>
        <Dialog
          open={ErrorMessage}
          onClose={onCloseClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: 16,
              textDecoration: "underline solid black 3px",
            }}
          >
            <span style={{ color: "black" }}>Error</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ maxWidth: 300, color: "black" }}
            >
              {/* {ErrorMessage} */}
              {ErrorMessage === null ? "" : ErrorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onCloseClick}
              style={{ color: "black", fontWeight: "bold" }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
