import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _HREmployeeAttendance from "helpers/Employee_Management/HREmployeeAttendance";
import _Department from "helpers/MasterData_Management/Department";
import _AttendanceType from "helpers/MasterData_Management/AttendanceType";
import _Employee from "helpers/Employee_Management/Employee";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch, SelectedDepartment, SelectedEmployee } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [currentDate, setcurrentDate] = useState(false);

  const [DepartmentList, setDepartmentList] = useState(null);
  const [AttendanceTypeList, setAttendanceTypeList] = useState(null);
  const [EmployeeList, setEmployeeList] = useState(null);

  const [DateIn, setDateIn] = useState(null);
  const [DateOut, setDateOut] = useState(null);
  const [Time_In, setTime_In] = useState(null);
  const [Time_Out, setTime_Out] = useState(null);
  const [CheckStatus, setCheckStatus] = useState(false);

  const formOpen = useSelector(
    state => state.crudTbHREmployeeAttendance.formOpen
  );
  const HREmployeeAttendanceList = useSelector(
    state => state.crudTbHREmployeeAttendance.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbHREmployeeAttendance.formValues
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      setisLoading(true);
      const response1 = await _Department.Get_Department_DD();
      setDepartmentList(response1.Data.Details);
      const response2 = await _AttendanceType.Get_AttendanceType_DD();
      setAttendanceTypeList(response2.Data.Details);
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const fetchEmployeeList = async value => {
    setisLoading(true);
    const response = await _Employee.Get_Employee_By_Department_DD(value);
    setEmployeeList(response.Data.Details);
    setisLoading(false);
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  useEffect(() => {
    if (formValues != null) {
      const index = HREmployeeAttendanceList.findIndex(
        item => item.EmployeeAttendanceId == formValues.EmployeeAttendanceId
      );
      if (index !== -1) {
        setIsEdit(true);
        setInitialFormData(HREmployeeAttendanceList[index]);
        setDateIn(HREmployeeAttendanceList[index].DateIn.split("T")[0]);
        setDateOut(HREmployeeAttendanceList[index].DateOut.split("T")[0]);
        setTime_In(HREmployeeAttendanceList[index].TimeIn);
        setTime_Out(HREmployeeAttendanceList[index].TimeOut);
        fetchEmployeeList(SelectedDepartment);
        // setCheckStatus(HREmployeeAttendanceList[index].Check_Status);
      }
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Employee_Id: isEdit ? SelectedEmployee : "",
      Department_Id: isEdit ? SelectedDepartment : "",
      Date: isEdit ? Date : "",
      Time_In: isEdit ? InitialFormData.TimeIn : "",
      Time_Out: isEdit ? InitialFormData.TimeOut : "",
      AttendanceType_Id: isEdit ? InitialFormData.AttendanceTypeId : "",
    },
    validationSchema: Yup.object({
      // Department_Id: Yup.number()
      //   .required("Please Select an Department")
      //   .min(1),
      // Employee_Id: Yup.number().required("Please Select an Employee").min(1),
      // Date: Yup.string().required("Please Select a Date"),
      // Time_In: Yup.string().required("Please Select a Time In"),
      // Time_Out: Yup.string().required("Please Select a Time Out"),
      AttendanceType_Id: Yup.number()
        .required("Please Select an Attendance Type")
        .min(1, "Please Select an Attendance Type"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (
        Time_In === null &&
        DateIn === null &&
        Time_Out === null &&
        DateOut === null
      ) {
        setisLoading(false);
        dispatch(
          setPopupMessageValues(
            'Enter "Date In & Time IN" or "Date Out & Time Out".',
            branch
          )
        );
        return;
      }
      if (isEdit) {
        //While updating an existing data
        let body = {
          EmployeeAttendance_Id: InitialFormData.EmployeeAttendanceId,
          Employee_Id: values.Employee_Id,
          Date_In: DateIn,
          Date_Out: DateOut,
          Time_In: Time_In,
          Time_Out: Time_Out,
          AttendanceType_Id: values.AttendanceType_Id,
          Check_Status: CheckStatus === true ? 1 : 0,
          User: user.Employee_Id,
        };
        const response = await _HREmployeeAttendance.Edit_EmployeeAttendance(
          body
        );
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          EmployeeAttendance_Id: 0,
          Employee_Id: values.Employee_Id,
          Date_In: DateIn,
          Date_Out: DateOut,
          Time_In: Time_In,
          Time_Out: Time_Out,
          AttendanceType_Id: values.AttendanceType_Id,
          Check_Status: CheckStatus === true ? 1 : 0,
          User: user.Employee_Id,
        };
        const response = await _HREmployeeAttendance.Save_EmployeeAttendance(
          body
        );
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          // dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderEmployee = () =>
    EmployeeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text + " / " + item.EmpCode}
      </option>
    ));
  const renderAttendanceType = () =>
    AttendanceTypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Attendance" : "Add New Attendance"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={e => {
                    validation.handleChange(e);
                    fetchEmployeeList(e.target.value);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.Department_Id || ""}
                  invalid={
                    validation.touched.Department_Id &&
                    validation.errors.Department_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {DepartmentList == null || DepartmentList == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
                {validation.touched.Department_Id &&
                validation.errors.Department_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Department_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee
                </Label>
                <Input
                  type="select"
                  name="Employee_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={EmployeeList == null}
                  value={validation.values.Employee_Id || ""}
                  invalid={
                    validation.touched.Employee_Id &&
                    validation.errors.Employee_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {EmployeeList == null || EmployeeList == undefined
                    ? ""
                    : renderEmployee()}
                </Input>
                {validation.touched.Employee_Id &&
                validation.errors.Employee_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Employee_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date In
                </Label>
                <Input
                  type="date"
                  name="Date"
                  max={currentDate}
                  onChange={e => setDateIn(e.target.value)}
                  value={DateIn}
                  disabled={isEdit}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Time In
                </Label>
                <Input
                  type="time"
                  name="Time_In"
                  step="1"
                  onChange={e => setTime_In(e.target.value)}
                  value={Time_In}
                  required={DateIn !== null ? true : false}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date Out
                </Label>
                <Input
                  type="date"
                  name="Date"
                  // min={currentDate}
                  onChange={e => setDateOut(e.target.value)}
                  value={DateOut}
                  disabled={DateIn === null}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Time Out
                </Label>
                <Input
                  type="time"
                  name="Time_Out"
                  step="1"
                  onChange={e => setTime_Out(e.target.value)}
                  value={Time_Out}
                  required={DateOut !== null ? true : false}
                  disabled={Time_In === null}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Attendance Type
                </Label>
                <Input
                  type="select"
                  name="AttendanceType_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.AttendanceType_Id || ""}
                  invalid={
                    validation.touched.AttendanceType_Id &&
                    validation.errors.AttendanceType_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {AttendanceTypeList == null || AttendanceTypeList == undefined
                    ? ""
                    : renderAttendanceType()}
                </Input>
                {validation.touched.AttendanceType_Id &&
                validation.errors.AttendanceType_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.AttendanceType_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <FormControlLabel
                control={
                  <Switch
                    name="CheckStatus"
                    onChange={e => setCheckStatus(e.target.checked)}
                    checked={CheckStatus}
                    color="primary"
                  />
                }
                label="Check Status"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
