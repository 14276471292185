import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Medical Report Page internal component
import MedicalReportDetails from "./EmployeeMedicalReport";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "store/actions";

//i18n
import { withTranslation } from "react-i18next";
const Attendance = props => {
  //meta title
  document.title = "HR -  Medical Report";
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbMedicalReport";

  //Redux States
  const popupMessage = useSelector(
    state => state.crudTbMedicalReport.popupMessage
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Medical Report")}
          />
          <MedicalReportDetails branch={branch} />
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {/* <FloatingForm branch={branch} /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Attendance);
