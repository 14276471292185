import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PermScanWifi from "@material-ui/icons/PermScanWifi";

//Import Images
import error from "../../assets/images/error-img.png";

const Pages404 = props => {
  //meta title
  // document.title = "HR - No Internet Connection";

  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return <React.Fragment>{props.children}</React.Fragment>;
  } else {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  {/* <h1 className="display-2 fw-medium">
                    4<i className="bx bx-buoy bx-spin text-primary display-3" />
                    4
                  </h1> */}
                  <PermScanWifi
                    fontSize="large"
                    className="text-primary display-3"
                  />
                  <h4 className="text-uppercase" style={{ marginTop: 20 }}>
                    Unable to connect to the internet
                  </h4>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8" xl="6">
                <div>
                  <img src={error} alt="" className="img-fluid" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5" style={{ marginTop: 30 }}>
                  <h4 className="text-uppercase">
                    If your internet connection is working, Try Reloading this
                    page
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
};

export default Pages404;
