import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ServicesDetails from "helpers/AssetManagement/ServicesDetails";
import Switch from "@material-ui/core/Switch";
import { RotatingLines } from "react-loader-spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import ServicesMaster from "helpers/AssetManagement/ServicesMaster";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [ServicesDate, setServicesDate] = useState(null);
  const [Services, setServices] = useState(null);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);

  const formOpen = useSelector(state => state.crudTbServicesDetails.formOpen);
  const ServicesDetailsList = useSelector(
    state => state.crudTbServicesDetails.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbServicesDetails.formValues
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      const response2 = await ServicesMaster.Get_ServicesMaster_DD();
      setServices(response2.Data.Details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    if (formValues != null) {
      const index = ServicesDetailsList.findIndex(
        item => item.ServicesDetails_Id == formValues.ServicesDetails_Id
      );
      setIsEdit(true);
      setInitialFormData(ServicesDetailsList[index]);
      setIsActiveCheck(ServicesDetailsList[index].IsActive);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Est_Cost: isEdit ? InitialFormData.Est_Cost : "",
      Service_Brief: isEdit ? InitialFormData.Service_Brief : "",
      Justification: isEdit ? InitialFormData.Justification : "",
      Service_ID: isEdit ? InitialFormData.Service_ID : "",
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      Est_Cost: Yup.string().required("Please Enter Estimated Cost"),
      Service_Brief: Yup.string().required("Please Enter Service Brief"),
      Justification: Yup.string().required("Please Enter Justification"),
      Service_ID: Yup.number().required("Please select a Service"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          Serv_Det_ID: InitialFormData.Serv_Det_ID,
          Est_Cost: values.Est_Cost,
          Service_Brief: values.Service_Brief,
          Justification: values.Justification,
          Services_ID: values.Service_ID,
          User: user.Employee_Id,
        };
        const response = await _ServicesDetails.Edit_ServicesDetails(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Serv_Det_ID: 0,
          Est_Cost: values.Est_Cost,
          Service_Brief: values.Service_Brief,
          Justification: values.Justification,
          Services_ID: values.Service_ID,
          User: user.Employee_Id,
        };
        const response = await _ServicesDetails.Save_ServicesDetails(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  const renderServices = () =>
    Services.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Services Details" : "Add Services Details"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Service Brief
                </Label>
                <Input
                  name="Service_Brief"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.Service_Brief : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Service_Brief || ""}
                  invalid={
                    validation.touched.Service_Brief &&
                    validation.errors.Service_Brief
                      ? true
                      : false
                  }
                />
                {validation.touched.Service_Brief &&
                validation.errors.Service_Brief ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Service_Brief}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Estimated Cost
                </Label>
                <Input
                  name="Est_Cost"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.Number2(e)}
                  // value={isEdit ? InitialFormData.Est_Cost : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Est_Cost || ""}
                  invalid={
                    validation.touched.Est_Cost && validation.errors.Est_Cost
                      ? true
                      : false
                  }
                />
                {validation.touched.Est_Cost && validation.errors.Est_Cost ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Est_Cost}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Services
                </Label>
                <Input
                  name="Service_ID"
                  type="select"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumericPlusMinus(e)}
                  // value={isEdit ? InitialFormData.Service_ID : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Service_ID || ""}
                  invalid={
                    validation.touched.Service_ID &&
                    validation.errors.Service_ID
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Services == null || Services == undefined
                    ? ""
                    : renderServices()}
                </Input>
                {validation.touched.Service_ID &&
                validation.errors.Service_ID ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Service_ID}
                  </FormFeedback>
                ) : null}
              </div>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Justification
                  </Label>
                  <Input
                    name="Justification"
                    type="text"
                    maxLength={255}
                    // value={isEdit ? InitialFormData.Justification : ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Justification || ""}
                    invalid={
                      validation.touched.Justification &&
                      validation.errors.Justification
                        ? true
                        : false
                    }
                  />
                  {validation.touched.Justification &&
                  validation.errors.Justification ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Justification}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Col>

            <Col className="col-12">
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
