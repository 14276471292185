import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _Client from "helpers/ClientProfiles/Client";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [CountryList, setCountryList] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const formOpen = useSelector(state => state.crudTbClient.formOpen);
  const ApprovalStatusList = useSelector(state => state.crudTbClient.dataTable);
  const formValues = useSelector(state => state.crudTbClient.formValues);
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formValues != null) {
      const index = ApprovalStatusList.findIndex(
        item => item.Client_Id == formValues.Client_Id
      );
      setIsEdit(true);
      setInitialFormData(ApprovalStatusList[index]);
      setIsActiveCheck(ApprovalStatusList[index].IsActive);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  // const initialData = async () => {
  //   try {
  //     const response = await _Employee.Get_Employee_DD();
  //     setCountryList(response.Data.Details);
  //   } catch (error) {
  //     dispatch(setPopupMessageValues("Opps! Server is down.", branch));
  //   }
  // };

  // useEffect(() => {
  //   initialData();
  // }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Client_Name: isEdit ? InitialFormData.Client_Name : "",
      Country_Id: isEdit ? InitialFormData.Country_Id : "",
      Official_Email: isEdit ? InitialFormData.Official_Email : "",
      Register_Email: isEdit ? InitialFormData.Register_Email : "",
      Address: isEdit ? InitialFormData.Address : "",
      NTN: isEdit ? InitialFormData.NTN : "",
      Contact1: isEdit ? InitialFormData.Contact1 : "",
      Contact2: isEdit ? InitialFormData.Contact2 : "",
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      Client_Name: Yup.string().required("Please Enter Client Name"),
      Country_Id: Yup.number().min(1, "Kindly Select a Country"),
      Official_Email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Official Email"),
      Register_Email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Register Email"),
      Address: Yup.string().required("Please Enter Address"),
      NTN: Yup.number().min(1, "Kindly add NTN"),
      Contact1: Yup.number().min(1, "Kindly Enter ContactNo 1"),
      Contact2: Yup.number().min(1, "Kindly Enter ContactNo 2"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          Client_Id: InitialFormData.Client_Id,
          Client_Name: values.Client_Name,
          Country_Id: parseInt(values.Country_Id),
          Official_Email: values.Official_Email,
          Register_Email: values.Register_Email,
          Address: values.Address,
          NTN: values.NTN,
          Contact1: values.Contact1,
          Contact2: values.Contact2,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        dispatch(updateItem(body, branch));
        setisLoading(false);
        dispatch(setPopupMessageValues("Client Edited Successfully.", branch));
        // const response = await _Client.Edit_Client(body);
        // if (response.IsSuccess === true && response.Status_Code === 200) {
        //   let data = response.Data;
        //   dispatch(updateItem(data.Details[0], branch));
        //   setisLoading(false);
        //   dispatch(setPopupMessageValues(response.Status_Message, branch));
        // } else {
        //   console.log(response.Status_Message);
        //   setisLoading(false);
        //   dispatch(setPopupMessageValues(response.Status_Message, branch));
        // }
      } else {
        //While Adding new Data
        let body = {
          Client_Id: Date.now(),
          Client_Name: values.Client_Name,
          Country_Id: parseInt(values.Country_Id),
          Official_Email: values.Official_Email,
          Register_Email: values.Register_Email,
          Address: values.Address,
          NTN: values.NTN,
          Contact1: values.Contact1,
          Contact2: values.Contact2,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        dispatch(addNewItem(body, branch));
        setisLoading(false);
        dispatch(setPopupMessageValues("Client Added Successfully.", branch));
        // const response = await _Client.Save_Client(body);
        // if (response.IsSuccess === true && response.Status_Code === 200) {
        //   let data = response.Data;
        //   dispatch(addNewItem(data.Details[0], branch));
        //   setisLoading(false);
        //   dispatch(setPopupMessageValues(response.Status_Message, branch));
        // } else {
        //   console.log(response.Status_Message);
        //   setisLoading(false);
        //   dispatch(setPopupMessageValues(response.Status_Message, branch));
        // }
      }
      toggle();
    },
  });

  const renderCountryList = () =>
    CountryList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Client" : "Add Client"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Country</Label>
                <Input
                  type="select"
                  name="Country_Id"
                  // value={event ? event.Country_Id : "bg-primary"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Country_Id || ""}
                  invalid={
                    validation.touched.Country_Id &&
                    validation.errors.Country_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {CountryList === null ? "" : renderCountryList()}
                </Input>
                {validation.touched.Country_Id &&
                validation.errors.Country_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Country_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Client Name
                </Label>
                <Input
                  name="Client_Name"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphabetic(e)}
                  // value={isEdit ? InitialFormData.Client_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Client_Name || ""}
                  invalid={
                    validation.touched.Client_Name &&
                    validation.errors.Client_Name
                      ? true
                      : false
                  }
                />
                {validation.touched.Client_Name &&
                validation.errors.Client_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Client_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Official Email
                </Label>
                <Input
                  name="Official_Email"
                  type="text"
                  maxLength={255}
                  // onKeyPressCapture={e => _validation.email(e)}
                  // value={isEdit ? InitialFormData.Official_Email : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Official_Email || ""}
                  invalid={
                    validation.touched.Official_Email &&
                    validation.errors.Official_Email
                      ? true
                      : false
                  }
                />
                {validation.touched.Official_Email &&
                validation.errors.Official_Email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Official_Email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Register Email
                </Label>
                <Input
                  name="Register_Email"
                  type="text"
                  maxLength={255}
                  // onKeyPressCapture={e => _validation.email(e)}
                  // value={isEdit ? InitialFormData.Register_Email : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Register_Email || ""}
                  invalid={
                    validation.touched.Register_Email &&
                    validation.errors.Register_Email
                      ? true
                      : false
                  }
                />
                {validation.touched.Register_Email &&
                validation.errors.Register_Email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Register_Email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Address
                </Label>
                <Input
                  name="Address"
                  type="text"
                  maxLength={255}
                  // value={isEdit ? InitialFormData.Address : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Address || ""}
                  invalid={
                    validation.touched.Address && validation.errors.Address
                      ? true
                      : false
                  }
                />
                {validation.touched.Address && validation.errors.Address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Address}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>NTN
                </Label>
                <Input
                  name="NTN"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.NTN : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.NTN || ""}
                  invalid={
                    validation.touched.NTN && validation.errors.NTN
                      ? true
                      : false
                  }
                />
                {validation.touched.NTN && validation.errors.NTN ? (
                  <FormFeedback type="invalid">
                    {validation.errors.NTN}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Contact No 1
                </Label>
                <Input
                  name="Contact1"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.Contact1 : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Contact1 || ""}
                  invalid={
                    validation.touched.Contact1 && validation.errors.Contact1
                      ? true
                      : false
                  }
                />
                {validation.touched.Contact1 && validation.errors.Contact1 ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Contact1}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Contact No 2
                </Label>
                <Input
                  name="Contact2"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.wholeNumber(e)}
                  // value={isEdit ? InitialFormData.Contact2 : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Contact2 || ""}
                  invalid={
                    validation.touched.Contact2 && validation.errors.Contact2
                      ? true
                      : false
                  }
                />
                {validation.touched.Contact2 && validation.errors.Contact2 ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Contact2}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
