import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_SubMenu_DD = (value = 0) => {
  let url = "api/Sub_Menu/Get_SubMenu_DropDown";

  return get(url);
};

const Get_SubMenu_List = body => {
  let url = "api/Sub_Menu/Get_SubMenu_List?id=0";

  return get(url, body);
};

const Get_SubMenu_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Sub_Menu/Get_SubMenu_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_SubMenu = body => {
  let url = "api/Sub_Menu/Save_SubMenu";
  return post(url, body);
};

const Edit_SubMenu = body => {
  let url = "api/Sub_Menu/Edit_SubMenu";
  return post(url, body);
};

const Delete_SubMenu = body => {
  let url = "api/Sub_Menu/Edit_SubMenu_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Sub Menu Name",
    accessor: "SubMenu_Name",
    hidden: false,
  },
  {
    Header: "Menu Name",
    accessor: "Menu_Name",
    hidden: false,
  },
  {
    Header: "Icon",
    accessor: "Icon",
    hidden: false,
    width: 150,
  },
  {
    Header: "Link",
    accessor: "Link",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  // {
  //   Header: "Created By",
  //   accessor: "CreatedBy",
  //   hidden: false,
  // },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 150,
  },
];
export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_SubMenu_DD,
  Get_SubMenu_List,
  Get_SubMenu_List_By_Rows,
  Save_SubMenu,
  Edit_SubMenu,
  Delete_SubMenu,
  anchorTable,
};
