import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login__ from "../pages/Authentication/Login__";
import LockScreen from "../pages/Authentication/LockScreen";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import MasterAuth from "pages/Authentication/MasterAuth";
import PageNotFound from "pages/Utility/PageNotFound";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserProfile from "../pages/Authentication/user-profile";
import ChangePassword from "pages/ChangePassword";

//Master Data Management
import Department from "pages/MasterData_Management/Department";
import Designation from "pages/MasterData_Management/Designation";
import Gender from "pages/MasterData_Management/Gender";
import LeaveType from "pages/MasterData_Management/LeaveType";
import HeadCategory from "pages/MasterData_Management/HeadCategory";
import SalaryHeads from "pages/MasterData_Management/SalaryHeads";
import Country from "pages/MasterData_Management/Country";
import State from "pages/MasterData_Management/State";
import City from "pages/MasterData_Management/City";
import ContractType from "pages/MasterData_Management/ContractType";
import Degree from "pages/MasterData_Management/Degree";
import Days from "pages/MasterData_Management/Days";
import BloodGroup from "pages/MasterData_Management/BloodGroup";
import Relationship from "pages/MasterData_Management/Relationship";
import MedicalProcedure from "pages/MasterData_Management/MedicalProcedure";
import EmployeeFamily from "pages/MasterData_Management/EmployeeFamily";
import Branch from "pages/MasterData_Management/Branch";
import AttendanceType from "pages/MasterData_Management/AttendanceType";
import ApprovalStatus from "pages/MasterData_Management/ApprovalStatus";
import InsurancePlan from "pages/MasterData_Management/InsurancePlan";
import MappingDepartmentWithDesignation from "pages/MasterData_Management/MappingDepartmentWithDesignation";
import BranchHoliday from "pages/MasterData_Management/BranchHoliday";
import Calendar_ from "pages/MasterData_Management/Calendar";
import Role from "pages/MasterData_Management/Role";
import Function from "pages/MasterData_Management/Function";

//Profile Management
import Menu from "pages/Profile_Management/Menu";
import SubMenu from "pages/Profile_Management/SubMenu";
import Profile from "pages/Profile_Management/Profile";
import AddProfile from "pages/Profile_Management/AddProfile";

//Shift Management
import Shift from "pages/Shift_Management/Shift";
import ShiftOffDay from "pages/Shift_Management/ShiftOffDay";

//Employee Management
import EmployeeList from "pages/Employee_Management/Employee_List";
import EmployeeAddition from "pages/Employee_Management/Employee_Addition";
import HREmployeeAttendance from "pages/Employee_Management/HREmployeeAttendance";
import Attendance from "pages/Employee_Management/Attendance";
import AttendanceReport from "pages/Employee_Management/AttendanceReport";
import LeaveReport from "pages/Employee_Management/LeaveReport";
import EmployeeLeave from "pages/Employee_Management/EmployeeLeave";
import EmployeeLeaveEdit from "pages/Employee_Management/EmployeeLeave/EmployeeLeaveEdit";
import EmployeeLeaveView from "pages/Employee_Management/EmployeeLeave/EmployeeLeaveView";
import DailyAttendance from "pages/Employee_Management/DailyAttendance";
import EmployeeBulkUploader from "pages/Employee_Management/EmployeeBulkUploader";

//Salary Management
import SalaryProfile from "pages/Salary_Management/SalaryProfile";
import SalaryProfile_Addition from "pages/Salary_Management/SalaryProfile_Addition";
import SalaryDisbursement from "pages/Salary_Management/SalaryDisbursement";

//Account Management
import LedgerHead from "pages/Account/LedgerHead";
import PaymentMode from "pages/Account/PaymentMode";
import Transaction from "pages/Account/Transaction";
import Tax from "pages/Account/Tax";
import Currency from "pages/Account/Currency";
import CurrencyRate from "pages/Account/CurrencyRate";
import AddCurrencyRate from "pages/Account/CurrencyRateAddition";

//Travel and Medical
import AddMisc from "pages/TravelAndMedical/AddMisc";

//Travel and Medical
import MedicalRequests from "pages/Medical/MedicalRequests";
import MedicalReport from "pages/Medical/MedicalReport";
import MedicalRequestView from "pages/Medical/MedicalRequests/MedicalRequestView";
import MedicalRequestEdit from "pages/Medical/MedicalRequests/MedicalRequestEdit";
import MedicalRequestionAddition from "pages/Medical/MedicalRequests/MedicalRequestAddition";

//Asset Management
import AssetManagementList from "pages/AssetManagement/AssetManagementList";
import EmployeeAssets from "pages/AssetManagement/EmployeeAssets";
import ProductTransferHistory from "pages/AssetManagement/ProductTransferHistory";
import AssetCategory from "pages/AssetManagement/AssetCategory";
import DemandMaster from "pages/AssetManagement/DemandMaster";
import ServicesMaster from "pages/AssetManagement/ServicesMaster";
import ServicesDetails from "pages/AssetManagement/ServicesDetails";
import DemandDetails from "pages/AssetManagement/DemandDetails";

//Client Profiles
import Company from "pages/ClientProfiles/Company";
import Client from "pages/ClientProfiles/Client";
import Project from "pages/ClientProfiles/Project";

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/change_password", component: <ChangePassword /> },

  //Master Data Management
  { path: "/master/department", component: <Department /> },
  { path: "/master/designation", component: <Designation /> },
  { path: "/master/gender", component: <Gender /> },
  { path: "/master/leaveType", component: <LeaveType /> },
  { path: "/master/country", component: <Country /> },
  { path: "/master/head_category", component: <HeadCategory /> },
  { path: "/master/salary_heads", component: <SalaryHeads /> },
  { path: "/master/state", component: <State /> },
  { path: "/master/city", component: <City /> },
  { path: "/master/contract_type", component: <ContractType /> },
  { path: "/master/degree", component: <Degree /> },
  { path: "/master/days", component: <Days /> },
  { path: "/master/blood_group", component: <BloodGroup /> },
  { path: "/master/relationship", component: <Relationship /> },
  { path: "/master/medical_procedure", component: <MedicalProcedure /> },
  { path: "/master/employee_family", component: <EmployeeFamily /> },
  { path: "/master/branch", component: <Branch /> },
  { path: "/master/attendance_type", component: <AttendanceType /> },
  { path: "/master/approval_status", component: <ApprovalStatus /> },
  { path: "/master/insurance_plan", component: <InsurancePlan /> },
  { path: "/master/role", component: <Role /> },
  {
    path: "/master/department_designation",
    component: <MappingDepartmentWithDesignation />,
  },
  { path: "/master/branch_holiday", component: <BranchHoliday /> },
  { path: "/master/calendar", component: <Calendar_ /> },
  { path: "/master/function", component: <Function /> },

  //Profile Management
  { path: "/profile_management/main_menu", component: <Menu /> },
  { path: "/profile_management/sub_menu", component: <SubMenu /> },
  { path: "/profile_management/profile", component: <Profile /> },
  { path: "/profile_management/add_profile", component: <AddProfile /> },

  //Shift Management
  { path: "/shift_management/shift", component: <Shift /> },
  { path: "/shift_management/shift_off_day", component: <ShiftOffDay /> },

  //Employee Management
  { path: "/employee_management/employees", component: <EmployeeList /> },
  {
    path: "/employee_management/employee_addition",
    component: <EmployeeAddition />,
  },
  {
    path: "/employee_management/hr_employee_attendance",
    component: <HREmployeeAttendance />,
  },
  {
    path: "/employee_management/attendance_report",
    component: <AttendanceReport />,
  },
  {
    path: "/employee_management/leave_report",
    component: <LeaveReport />,
  },
  {
    path: "/employee_management/employee_attendance",
    component: <Attendance />,
  },
  {
    path: "/employee_management/daily_attendance",
    component: <DailyAttendance />,
  },
  {
    path: "/employee_management/employee_bulk_uploader",
    component: <EmployeeBulkUploader />,
  },
  {
    path: "/employee_management/employee_leave",
    component: <EmployeeLeave />,
  },
  {
    path: "/employee_management/employee_leave_edit",
    component: <EmployeeLeaveEdit />,
  },
  {
    path: "/employee_management/employee_leave_view",
    component: <EmployeeLeaveView />,
  },

  //Salary Management
  {
    path: "/salary_management/salary_profiles",
    component: <SalaryProfile />,
  },
  {
    path: "/salary_management/salary_profile_addition",
    component: <SalaryProfile_Addition />,
  },
  {
    path: "/salary_management/salary_disbursement",
    component: <SalaryDisbursement />,
  },

  //Account Management
  {
    path: "/account/ledger_head",
    component: <LedgerHead />,
  },
  {
    path: "/account/payment_mode",
    component: <PaymentMode />,
  },
  {
    path: "/account/transaction",
    component: <Transaction />,
  },
  { path: "/master/tax", component: <Tax /> },
  { path: "/master/currency", component: <Currency /> },
  { path: "/master/currency_rate", component: <CurrencyRate /> },
  {
    path: "/master/currency_rate/add_currency_rate",
    component: <AddCurrencyRate />,
  },

  //Travel and Medical
  { path: "/travel_medical/add_misc", component: <AddMisc /> },

  //Medical
  { path: "/medical/medical_requests", component: <MedicalRequests /> },
  { path: "/medical/medical_report", component: <MedicalReport /> },
  {
    path: "/medical/medical_requests_view",
    component: <MedicalRequestView />,
  },
  {
    path: "/medical/medical_requests_edit",
    component: <MedicalRequestEdit />,
  },
  {
    path: "/medical/add_medical_requests",
    component: <MedicalRequestionAddition />,
  },

  //Asset Management
  {
    path: "/asset_management/asset_managemet_list",
    component: <AssetManagementList />,
  },
  {
    path: "/asset_management/employee_assets",
    component: <EmployeeAssets />,
  },
  {
    path: "/asset_management/product_transfer_history",
    component: <ProductTransferHistory />,
  },
  {
    path: "/asset_management/asset_category",
    component: <AssetCategory />,
  },
  {
    path: "/asset_management/demand_master",
    component: <DemandMaster />,
  },
  {
    path: "/asset_management/services_master",
    component: <ServicesMaster />,
  },
  {
    path: "/asset_management/services_details",
    component: <ServicesDetails />,
  },
  {
    path: "/asset_management/demand_details",
    component: <DemandDetails />,
  },
  {
    path: "*",
    component: <PageNotFound />,
  },

  //Client Profiles
  { path: "/client_profiles/company", component: <Company /> },
  { path: "/client_profiles/client", component: <Client /> },
  { path: "/client_profiles/project", component: <Project /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/session_timeout", component: <LockScreen /> },
  { path: "/login", component: <Login__ /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/master_auth", component: <MasterAuth /> },
  {
    path: "/page_not_found",
    component: <PageNotFound />,
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
];

export { authProtectedRoutes, publicRoutes };
