import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_LedgerHead_DD = (value = 0) => {
  let url = "api/Employee_Attendance/Get_LedgerHead_DropDown";

  return get(url);
};

const Get_LedgerHead_List = body => {
  let url = "api/Employee_Attendance/Get_LedgerHead_List?id=0";

  return get(url, body);
};

const Get_LedgerHead_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Employee_Attendance/Get_LedgerHead_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_LedgerHead = body => {
  let url = "api/Employee_Attendance/Save_LedgerHead";
  return post(url, body);
};

const Edit_LedgerHead = body => {
  let url = "api/Employee_Attendance/Edit_LedgerHead";
  return post(url, body);
};

const Delete_LedgerHead = body => {
  let url = "api/Employee_Attendance/Edit_LedgerHead_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Ledger Head ID",
    accessor: "SrNo",
    hidden: false,
  },
  {
    Header: "Name",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "Previous Balance",
    accessor: "Employee_Name",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "Date",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "Time_In",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Modified By",
    accessor: "Time_In",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_LedgerHead_DD,
  Get_LedgerHead_List,
  Get_LedgerHead_List_By_Rows,
  Save_LedgerHead,
  Edit_LedgerHead,
  Delete_LedgerHead,
  anchorTable,
};
