import Department from "helpers/MasterData_Management/Department";
import Designation from "helpers/MasterData_Management/Designation";
import Gender from "helpers/MasterData_Management/Gender";
import LeaveType from "helpers/MasterData_Management/LeaveType";
import HeadCategory from "helpers/MasterData_Management/HeadCategory";
import SalaryHeads from "helpers/MasterData_Management/SalaryHeads";
import Country from "helpers/MasterData_Management/Country";
import State from "helpers/MasterData_Management/State";
import City from "helpers/MasterData_Management/City";
import ContractType from "helpers/MasterData_Management/ContractType";
import Degree from "helpers/MasterData_Management/Degree";
import Days from "helpers/MasterData_Management/Days";
import BloodGroup from "helpers/MasterData_Management/BloodGroup";
import Relationship from "helpers/MasterData_Management/Relationship";
import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import Branch from "helpers/MasterData_Management/Branch";
import AttendanceType from "helpers/MasterData_Management/AttendanceType";
import ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import InsurancePlan from "helpers/MasterData_Management/InsurancePlan";
import MappingDepartmentWithDesignation from "helpers/MasterData_Management/MappingDepartmentWithDesignation";
import BranchHoliday from "helpers/MasterData_Management/BranchHoliday";
import Calendar from "helpers/MasterData_Management/Calendar";
import Role from "helpers/MasterData_Management/Role";
import Function from "helpers/MasterData_Management/Function";

//Profile Management
import Menu from "helpers/Profile_Management/Menu";
import SubMenu from "helpers/Profile_Management/SubMenu";
import Profile from "helpers/Profile_Management/Profile";

//Shift Management
import Shift from "helpers/Shift_Management/Shift";
import ShiftOffDay from "helpers/Shift_Management/ShiftOffDay";

//Employee Management
import EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";

//Salary Management
import SalaryProfile from "helpers/Salary_Management/SalaryProfile";

let User_Id;

const Active_department = async (data, user) => {
  try {
    const body = {
      Department_Id: data.Department_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Department.Edit_Department(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_designation = async (data, user) => {
  try {
    const body = {
      Designation_Id: data.Designation_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Designation.Edit_Designation(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_gender = async (data, user) => {
  try {
    const body = {
      ...data,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Gender.Edit_Gender(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_leaveType = async (data, user) => {
  try {
    const body = {
      LeaveType_Id: data.LeaveType_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await LeaveType.Edit_LeaveType(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_head_category = async (data, user) => {
  try {
    const body = {
      Head_Cat_Id: data.Head_Cat_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await HeadCategory.Edit_HeadCategory(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_salary_head = async (data, user) => {
  try {
    const body = {
      Head_Id: data.Head_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await SalaryHeads.Edit_SalaryHeads(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_country = async (data, user) => {
  try {
    const body = {
      Country_Id: data.Country_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Country.Edit_Country(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_state = async (data, user) => {
  try {
    const body = {
      State_Id: data.State_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await State.Edit_State(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_city = async (data, user) => {
  try {
    const body = {
      City_Id: data.City_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await City.Edit_City(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_contractType = async (data, user) => {
  try {
    const body = {
      ContractType_Id: data.ContractType_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await ContractType.Edit_ContractType(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_degree = async (data, user) => {
  try {
    const body = {
      Degree_Id: data.Degree_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Degree.Edit_Degree(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_days = async (data, user) => {
  try {
    const body = {
      Day_Id: data.Day_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Days.Edit_Day(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_bloodGroup = async (data, user) => {
  try {
    const body = {
      BloodGroup_Id: data.BloodGroup_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await BloodGroup.Edit_BloodGroup(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_relationship = async (data, user) => {
  try {
    const body = {
      Relationship_Id: data.Relationship_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Relationship.Edit_Relationship(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_medical_procedure = async (data, user) => {
  try {
    const body = {
      Medical_Procedure_Id: data.Medical_Procedure_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await MedicalProcedure.Edit_Medical_Procedure(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_employee_family = async (data, user) => {
  try {
    const body = {
      Emp_Fam_Id: data.Emp_Fam_Id,
      Relationship_Id: data.Relationship_Id,
      Employee_Id: data.Employee_Id,
      Employee_Family_Name: data.Employee_Family_Name,
      IsActive: !data.IsActive,
      IsDependent: data.IsDependent,
      User: user,
    };

    console.log(body);
    const responseee = await EmployeeFamily.Edit_Employee_Family(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_branch = async (data, user) => {
  try {
    const body = {
      Branch_Id: data.Branch_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Branch.Edit_Branch(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_attendanceType = async (data, user) => {
  try {
    const body = {
      AttendanceType_Id: data.AttendanceType_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await AttendanceType.Edit_AttendanceType(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_approvalStatus = async (data, user) => {
  try {
    const body = {
      ApprovalStatus_Id: data.ApprovalStatus_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await ApprovalStatus.Edit_ApprovalStatus(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_insurancePlan = async (data, user) => {
  try {
    const body = {
      InsurancePlan_Id: data.InsurancePlan_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await InsurancePlan.Edit_InsurancePlans(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_mappingDepartmentDesignaiton = async (data, user) => {
  try {
    const body = {
      Dep_Des_Id: data.Dep_Des_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee =
      await MappingDepartmentWithDesignation.Edit_MappingDepartmentDesignation(
        body
      );
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_branchHoliday = async (data, user) => {
  try {
    const body = {
      BranchHoliday_Id: data.BranchHoliday_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await BranchHoliday.Edit_BranchHoliday(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_calendar = async (data, user) => {
  try {
    const body = {
      Calendar_Id: data.Calendar_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Calendar.Edit_Calendar(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_role = async (data, user) => {
  try {
    const body = {
      Role_Id: data.Role_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Role.Edit_Role(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_function = async (data, user) => {
  try {
    const body = {
      Function_Id: data.Function_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Function.Edit_Function(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Profile Management------------------------------------
const Active_menu = async (data, user) => {
  try {
    const body = {
      Menu_Id: data.Menu_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Menu.Edit_Menu(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_subMenu = async (data, user) => {
  try {
    const body = {
      SubMenu_Id: data.SubMenu_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await SubMenu.Edit_SubMenu(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Shift Management------------------------------------------------------------------------
const Active_shift = async (data, user) => {
  try {
    const body = {
      Shift_Id: data.Shift_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await Shift.Edit_Shift(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const Active_shiftOffDay = async (data, user) => {
  try {
    const body = {
      ShiftOffDay_Id: data.ShiftOffDay_Id,
      IsActive: !data.IsActive,
      User: user,
    };

    const responseee = await ShiftOffDay.Edit_ShiftOffDay(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const changeActiveStatus = async (branch, item, user) => {
  let save_response = null;

  switch (branch) {
    case "crudTbDepartment": {
      save_response = await Active_department(item, user);
      break;
    }
    case "crudTbDesignation": {
      save_response = await Active_designation(item, user);
      break;
    }
    case "crudTbGender": {
      save_response = await Active_gender(item, user);
      break;
    }
    case "crudTbLeaveType": {
      save_response = await Active_leaveType(item, user);
      break;
    }
    case "crudTbHeadCategory": {
      save_response = await Active_head_category(item, user);
      break;
    }
    case "crudTbSalaryHeads": {
      save_response = await Active_salary_head(item, user);
      break;
    }
    case "crudTbCountry": {
      save_response = await Active_country(item, user);
      break;
    }
    case "crudTbStates": {
      save_response = await Active_state(item, user);
      break;
    }
    case "crudTbCity": {
      save_response = await Active_city(item, user);
      break;
    }
    case "crudTbContractType": {
      save_response = await Active_contractType(item, user);
      break;
    }
    case "crudTbDegree": {
      save_response = await Active_degree(item, user);
      break;
    }
    case "crudTbDays": {
      save_response = await Active_days(item, user);
      break;
    }
    case "crudTbBloodGroup": {
      save_response = await Active_bloodGroup(item, user);
      break;
    }
    case "crudTbRelationship": {
      save_response = await Active_relationship(item, user);
      break;
    }
    case "crudTbMedicalProcedure": {
      save_response = await Active_medical_procedure(item, user);
      break;
    }
    case "crudTbEmployeeFamily": {
      save_response = await Active_employee_family(item, user);
      break;
    }
    case "crudTbBranch": {
      save_response = await Active_branch(item, user);
      break;
    }
    case "crudTbAttendanceType": {
      save_response = await Active_attendanceType(item, user);
      break;
    }
    case "crudTbApprovalStatus": {
      save_response = await Active_approvalStatus(item, user);
      break;
    }
    case "crudTbInsurancePlan": {
      save_response = await Active_insurancePlan(item, user);
      break;
    }
    case "crudTbMappingDepartmentWithDesignation": {
      save_response = await Active_mappingDepartmentDesignaiton(item, user);
      break;
    }
    case "crudTbBranchHoliday": {
      save_response = await Active_branchHoliday(item, user);
      break;
    }
    case "crudTbCalendar": {
      save_response = await Active_calendar(item, user);
      break;
    }
    case "crudTbRole": {
      save_response = await Active_role(item, user);
      break;
    }
    case "crudTbFunction": {
      save_response = await Active_function(item, user);
      break;
    }

    //Profile Management------------------------------------
    case "crudTbMenu": {
      save_response = await Active_menu(item, user);
      break;
    }
    case "crudTbSubMenu": {
      save_response = await Active_subMenu(item, user);
      break;
    }

    //Shift Management------------------------------------
    case "crudTbShift": {
      save_response = await Active_shift(item, user);
      break;
    }
    case "crudTbShiftOffDay": {
      save_response = await Active_shiftOffDay(item, user);
      break;
    }
    default:
      break;
  }

  return save_response;
};

export default {
  changeActiveStatus,
};
