import React, { useEffect, useState } from "react";
import moment from "moment";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Floating Form
import FloatingForm from "components/Forms/Medical/MedicalRequests/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "../../../components/Common/TableContainer";
import MedicalRequestTableContainer from "components/Table/Medical/MedicalRequestTableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues, setFormValues } from "store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

//API Service
import _Employee from "helpers/Employee_Management/Employee";
import Department from "helpers/MasterData_Management/Department";
import MedicalRequests from "helpers/Medical/MedicalRequests";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";

const MedicalRequest = props => {
  //meta title
  document.title = "HR- Medical Requests";
  //Table Column Names
  const EmployeeanchorTable = MedicalRequests.EmployeeanchorTable;
  const HrManageranchorTable = MedicalRequests.HrManageranchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);

  //Data Lists
  const [EmployeeList, setEmployeeList] = useState(null);
  const [DepartmentList, setDepartmentList] = useState([]);

  //Filter Values
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const [PageNumber, setPageNumber] = useState(0);
  const [Date_To, setDate_To] = useState(null);
  const [Date_From, setDate_From] = useState(null);
  const branch = "crudTbMedicalRequests";

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(state => state.crudTbMedicalRequests.dataTable);
  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbMedicalRequests.popupMessage
  );
  const formValues = useSelector(
    state => state.crudTbMedicalRequests.formValues
  );

  const initialData = async () => {
    try {
      setisLoading(true);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      // Get the start date of the current month
      let startDate = new Date(currentYear, currentMonth, 1);
      startDate = moment(startDate).format("YYYY-MM-DD");
      setDate_From(startDate);
      // Get the end date of the current month
      let endDate = new Date(currentYear, currentMonth + 1, 0);
      endDate = moment(endDate).format("YYYY-MM-DD");
      setDate_To(endDate);
      if (user.Role_Id === 3) {
        const response = await _Employee.Get_Employee_By_LineManager_DD(
          user.Employee_Id
        );
        if (response.IsSuccess === true && response.Status_Code === 200) {
          const data = response.Data.Details;
          const emp_list = [];
          data.map(item => {
            const obj = {
              value: item.Value,
              label: item.Text + " - " + item.EmpCode,
            };
            emp_list.push(obj);
          });
          setEmployeeList(emp_list);
        }
      } else {
        const departments = await Department.Get_Department_DD();
        setDepartmentList(departments.Data.Details);
        setInitialList([].Details);
        setTotalRecords(0);
        dispatch(setData([], branch));
        handleDepartmentChange(user.Department_Id, true);
        setSelectedEmployee(user.Employee_Id);

        const body = {
          PageNumber: 1,
          PageSize: 10,
          Employee_Id: user.Employee_Id,
          From_Date: startDate,
          To_Date: endDate,
        };
        const medicalRequests = await MedicalRequests.Get_Medical_Request_List(
          body
        );

        setInitialList(medicalRequests.Data.Details);
        setTotalRecords(medicalRequests.Data.TotalRecords);
        dispatch(setData(medicalRequests.Data, branch));
      }
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Department_Id: user.Department_Id,
    },
    validationSchema: Yup.object({
      // Department_Id: Yup.number()
      //   .required("Please Select a Department")
      //   .min(1, "Please Select a Department"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      // setPageSize(10);
      // setPageNumber(0);
      setInitialList(null);
      let body = {
        PageNumber: PageNumber,
        PageSize: PageSize,
        Employee_Id: SelectedEmployee,
        From_Date: Date_From,
        To_Date: Date_To,
      };
      const response = await MedicalRequests.Get_Medical_Request_List(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
        setisLoading(false);
        // dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }
    },
  });

  const handleDepartmentChange = async (value, InitialRun) => {
    try {
      setisLoading(true);
      setSelectedDepartment(value);
      setSelectedEmployee(null);
      setSelectedOption("");
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const data = response.Data.Details;
        const emp_list = [];
        setSelectedOption("");
        data.map(item => {
          const obj = {
            value: item.Value,
            label: item.Text + " - " + item.EmpCode,
          };
          emp_list.push(obj);
        });
        if (InitialRun) {
          const index = emp_list.findIndex(item => {
            return item.value == user.Employee_Id;
          });
          if (index !== -1) {
            setSelectedOption(emp_list[index]);
          }
        }
        setEmployeeList(emp_list);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  const renderDepartment = () =>
    DepartmentList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderEmployee = () =>
    EmployeeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text + " - " + item.EmpCode}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Medical")}
            breadcrumbItem={props.t("Medical Requests")}
          />
          {user.Role_Id !== 4 ? (
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                {user.Role_Id === 2 ? (
                  <Col lg="3">
                    <div className="mb-3">
                      <Label className="form-label">
                        <span style={{ color: "red" }}>* </span>Department
                      </Label>
                      <Input
                        type="select"
                        name="Department_Id"
                        onChange={e => {
                          validation.handleChange(e);
                          handleDepartmentChange(e.target.value);
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.Department_Id || ""}
                        invalid={
                          validation.touched.Department_Id &&
                          validation.errors.Department_Id
                            ? true
                            : false
                        }
                      >
                        <option key={1} value={0}>
                          Select
                        </option>
                        {DepartmentList == null || DepartmentList == undefined
                          ? ""
                          : renderDepartment()}
                      </Input>
                      {validation.touched.Department_Id &&
                      validation.errors.Department_Id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Department_Id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Employee
                    </Label>
                    <Select
                      defaultValue={EmployeeList !== null && EmployeeList[0]}
                      isDisabled={EmployeeList == null}
                      isSearchable={true}
                      value={SelectedOption}
                      onChange={e => {
                        setSelectedEmployee(e.value);
                        setSelectedOption(e);
                      }}
                      name="color"
                      options={EmployeeList}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          backgroundColor: "white",
                        }),
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Start Date
                    </Label>
                    <Input
                      type="date"
                      name="Start_Date"
                      onChange={e => setDate_From(e.target.value)}
                      required
                      value={Date_From}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>End Date
                    </Label>
                    <Input
                      type="date"
                      name="End_Date"
                      onChange={e => setDate_To(e.target.value)}
                      required
                      value={Date_To}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <button
                    type="submit"
                    className="btn"
                    id="btn-save-event"
                    style={{
                      marginTop: 28,
                      backgroundColor: "#004a8d",
                      color: "white",
                    }}
                    disabled={SelectedEmployee == null}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}
          <br />
          {/* <Table columns={columns} data={data} /> */}
          {user.Role_Id === 4 ? (
            EmployeeanchorTable == null && initialList == null ? (
              ""
            ) : (
              //Medical Request Table
              <MedicalRequestTableContainer
                columns={EmployeeanchorTable}
                initialData={initialList}
                totalRecords={TotalRecords}
                customPageSize={10}
                className="custom-header-css"
                branch={branch}
                EmployeeId={SelectedEmployee}
                pageSize={PageSize}
                PageNumber={PageNumber}
                Date_From={Date_From}
                Date_To={Date_To}
                changePageSize={e => setPageSize(e)}
                changePageNumber={e => {
                  setPageNumber(e);
                }}
                changeTotalRecords={e => setTotalRecords(e)}
              />
            )
          ) : HrManageranchorTable == null && initialList == null ? (
            ""
          ) : (
            //Medical Request Table
            <MedicalRequestTableContainer
              columns={HrManageranchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
              EmployeeId={SelectedEmployee}
              pageSize={PageSize}
              PageNumber={PageNumber}
              Date_From={Date_From}
              Date_To={Date_To}
              changePageSize={e => setPageSize(e)}
              changePageNumber={e => {
                setPageNumber(e);
              }}
              changeTotalRecords={e => setTotalRecords(e)}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {/* <FloatingForm branch={branch} /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(MedicalRequest);
