import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Employee_Family_DD = (value = 0) => {
  let url = "api/Employee_Family/Get_Employee_Family_DropDown";

  return get(url);
};

const Get_Employee_Family_DropDown_ByEmpID = id => {
  let url =
    "api/Employee_Family/Get_Employee_Family_DropDown_ByEmpID?EmpID=" + id;

  return get(url);
};

const Get_Employee_Family_Relation_DropDown_ByEmpID = id => {
  let url =
    "api/Employee_Family/Get_Employee_Family_Relation_DropDown_ByEmpID?EmpID=" +
    id;

  return get(url);
};

const Get_Employee_Family_By_Id = id => {
  let url = "api/Employee_Family/Get_Employee_Family_By_Id?Id=" + id;

  return get(url);
};

const Get_Employee_Family_List = body => {
  let url = "api/Employee_Family/Get_Employee_Family_List";
  return post(url, body);
};

const Save_Employee_Family = body => {
  let url = "api/Employee_Family/Save_Employee_Family";
  return post(url, body);
};

const Edit_Employee_Family = body => {
  let url = "api/Employee_Family/Edit_Employee_Family";
  return post(url, body);
};

const Delete_Employee_Family = body => {
  let url = "api/Employee_Family/Edit_Employee_Family_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Name",
    accessor: "Employee_Family_Name",
    hidden: false,
    width: 300,
  },
  {
    Header: "Relation Name",
    accessor: "Relationship_Name",
    hidden: false,
  },
  {
    Header: "Is Dependent",
    accessor: "IsDependent",
    hidden: true,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
    width: 200,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: true,
    width: 100,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: true,
    width: 100,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Employee_Family_DD,
  Get_Employee_Family_DropDown_ByEmpID,
  Get_Employee_Family_Relation_DropDown_ByEmpID,
  Get_Employee_Family_By_Id,
  Get_Employee_Family_List,
  Save_Employee_Family,
  Edit_Employee_Family,
  Delete_Employee_Family,
  anchorTable,
};
