import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Fab from "@material-ui/core/Fab";
import queryString from "query-string";
import Popover from "@material-ui/core/Popover";
import Encryption from "helpers/Encryption/Encryption";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//authentication
import authservice from "../../helpers/auth.service";
import userService from "../../helpers/user.service";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { login, loginUser, login_fail, logout } from "../../store/actions";
import avatar from "../../assets/images/users/avatar-1.jpg";

// import images
import profile from "assets/images/profile-img.png";
import systech from "assets/images/cropped-systechlogic.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";

const Login = props => {
  //meta title
  document.title = "HR - LockScreen";
  const [isLoading, setisLoading] = useState(false);
  const [IsPasswordVisible, setIsPasswordVisible] = useState(false);
  const { isLoggedIn, notifMsg } = useSelector(state => state.Login);
  const [SessionUser, setSessionUser] = useState(null);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const itemStr = localStorage.getItem("session_timeout");

    let params = queryString.parse(location.search);
    if (params.q !== null && params.q !== undefined) {
      const encryupteUser = params.q;
      try {
        const decryupteUser = Encryption.decrypt_url(encryupteUser);

        const item = JSON.parse(decryupteUser);
        setSessionUser(item.UserInfo);
      } catch (error) {
        window.location.href = "/login";
        console.log(error);
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  if (!localStorage.getItem("MasterAuth")) {
    // window.location.href = "/master_auth";
    navigate("/master_auth");
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      const dataaa = {
        email: SessionUser.Email,
        password: values.password,
      };

      const response = await authservice.login(dataaa);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        const now = new Date();
        userService.Set_User(response.Data, now.getTime() + 7200000);
        const data = {
          ...response.Data,
          notification: [],
        };
        dispatch(login(data));
        setisLoading(false);
      } else if (response.IsSuccess === false && response.Status_Code === 401) {
        setisLoading(false);
        dispatch(login_fail(response.Status_Message));
      } else {
        console.log(response.message);
        dispatch(login_fail(response.message));
        setisLoading(false);
      }
    },
  });

  if (isLoggedIn) {
    // window.location.href = "/dashboard";
    navigate("/dashboard");
  }

  const showPassword = () => {
    setIsPasswordVisible(!IsPasswordVisible);
  };

  const handleLogout = () => {
    authservice.logout().then(
      data => {
        dispatch(logout());
        window.location.href = "/login";
      },
      error => {
        console.log(error);
      }
    );
  };

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              <Card className="overflow-hidden">
                <div
                  className="bg-primary bg-soft"
                  style={{ textAlign: "center", padding: 20 }}
                >
                  <Row>
                    <Typography variant="h4" gutterBottom>
                      Your session has been expired
                    </Typography>

                    <Typography variant="subtitle2" gutterBottom>
                      You're being timed out due to inactivity/long session.
                      <br />
                      Do you want to stay logged in, Please enter your password
                      to continue or to logout.
                      <br />
                      Otherwise, you will logged off automactically.
                    </Typography>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row style={{ marginTop: 30 }}>
                    <Col className="col-5">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          // alt={name}
                          src={avatar}
                          className="avatar-lg rounded-circle"
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "60%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="col-7">
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                          <div className="mb-1">
                            <Col className="col-12">
                              {SessionUser !== null &&
                              SessionUser !== undefined ? (
                                <Label className="form-label">
                                  {SessionUser !== null
                                    ? SessionUser.First_Name
                                    : "" + " " + SessionUser !== null
                                    ? SessionUser.Last_Name
                                    : ""}
                                </Label>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col className="col-12">
                              <Label className="form-label">
                                {SessionUser !== null ? SessionUser.Email : ""}
                              </Label>
                            </Col>
                          </div>

                          <Row>
                            <Col className="col-8">
                              <div className="mb-1">
                                <Label className="form-label">Password</Label>
                                <div
                                  style={{
                                    position: "relative",
                                    marginBottom: 10,
                                    display: "flex",
                                  }}
                                >
                                  <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={
                                      IsPasswordVisible ? "text" : "password"
                                    }
                                    placeholder="Enter Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                      validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  <button
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      width: 40,
                                      minHeight: "100%",
                                      fontFamily: "Open Sans",
                                      display: "flex",
                                      border: "none",
                                      justifyContent: "center",
                                      backgroundColor: "#556ee6",
                                      alignItems: "center",
                                      color: " #fff",
                                      fontSize: 14,
                                    }}
                                    onClick={showPassword}
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col className="col-4">
                              <div style={{ float: "right", marginTop: "27%" }}>
                                <Fab
                                  size="small"
                                  color="secondary"
                                  type="submit"
                                >
                                  <ArrowForward />
                                </Fab>

                                <Fab
                                  size="small"
                                  color="secondary"
                                  type="button"
                                  onClick={handleLogout}
                                  style={{ marginLeft: 10 }}
                                >
                                  <ExitToApp />
                                </Fab>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Systechlogic</p>
              </div>
            </Col>
          </Row>
        </Container>
        <DialogPopup branch={"Login"} message={notifMsg} />
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

// <div className="mt-4 text-center">
//                         <h5 className="font-size-14 mb-3">Sign in with</h5>

//                         <ul className="list-inline">
//                           <li className="list-inline-item">
//                             <FacebookLogin
//                               appId={facebook.APP_ID}
//                               autoLoad={false}
//                               callback={facebookResponse}
//                               render={renderProps => (
//                                 <Link
//                                   to="#"
//                                   className="social-list-item bg-primary text-white border-primary"
//                                   onClick={renderProps.onClick}
//                                 >
//                                   <i className="mdi mdi-facebook" />
//                                 </Link>
//                               )}
//                             />
//                           </li>
//                           {/*<li className="list-inline-item">*/}
//                           {/*  <TwitterLogin*/}
//                           {/*    loginUrl={*/}
//                           {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
//                           {/*    }*/}
//                           {/*    onSuccess={this.twitterResponse}*/}
//                           {/*    onFailure={this.onFailure}*/}
//                           {/*    requestTokenUrl={*/}
//                           {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
//                           {/*    }*/}
//                           {/*    showIcon={false}*/}
//                           {/*    tag={"div"}*/}
//                           {/*  >*/}
//                           {/*    <a*/}
//                           {/*      href=""*/}
//                           {/*      className="social-list-item bg-info text-white border-info"*/}
//                           {/*    >*/}
//                           {/*      <i className="mdi mdi-twitter"/>*/}
//                           {/*    </a>*/}
//                           {/*  </TwitterLogin>*/}
//                           {/*</li>*/}
//                           <li className="list-inline-item">
//                             <GoogleLogin
//                               clientId={google.CLIENT_ID}
//                               render={renderProps => (
//                                 <Link
//                                   to="#"
//                                   className="social-list-item bg-danger text-white border-danger"
//                                   onClick={renderProps.onClick}
//                                 >
//                                   <i className="mdi mdi-google" />
//                                 </Link>
//                               )}
//                               onSuccess={googleResponse}
//                               onFailure={() => {}}
//                             />
//                           </li>
//                         </ul>
//                       </div>
