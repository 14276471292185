import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MedicalRequests from "helpers/Medical/MedicalRequests";
import _Employee from "helpers/Employee_Management/Employee";
import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import _Department from "helpers/MasterData_Management/Department";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import DialogPopup from "components/Common/DialogPopup";
import DynamicDataTable from "./DynamicDataTable";
import FloatingForm from "components/Forms/Medical/MedicalRequests/form";
import {
  addNewItem,
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setFiles,
  setPopupMessageValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Container,
  Button,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Typography } from "@material-ui/core";

const monthsList = [
  {
    Value: 1,
    Text: "January",
  },
  {
    Value: 2,
    Text: "February",
  },
  {
    Value: 3,
    Text: "March",
  },
  {
    Value: 4,
    Text: "April",
  },
  {
    Value: 5,
    Text: "May",
  },
  {
    Value: 6,
    Text: "June",
  },
  {
    Value: 7,
    Text: "July",
  },
  {
    Value: 8,
    Text: "August",
  },
  {
    Value: 9,
    Text: "September",
  },
  {
    Value: 10,
    Text: "October",
  },
  {
    Value: 11,
    Text: "November",
  },
  {
    Value: 12,
    Text: "December",
  },
];

const NewMedicalRequestsListAnchor = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Family Name",
    accessor: "Employee_Family_Id",
    hidden: false,
  },
  {
    Header: "Medical Expense Type",
    accessor: "Medical_Procedure_Id",
    hidden: false,
  },
  {
    Header: "Date",
    accessor: "Procedure_Date",
    hidden: false,
  },
  {
    Header: "Claim Amount",
    accessor: "Claim_Amount",
    hidden: false,
  },
  {
    Header: "Attachement",
    accessor: "File",
    hidden: false,
    width: 100,
  },
  {
    Header: "Action",
    accessor: "Action",
    hidden: false,
    width: 100,
  },
];

const AddMedicalRequest = props => {
  const branch = "crudTbNewMedicalRequests";
  //meta title
  document.title = "HR - Add Medical Request";

  const [isLoading, setisLoading] = useState(false);
  const [TotalClaimAmount, setTotalClaimAmount] = useState(0);
  const [SelectedMonth, setSelectedMonth] = useState(null);
  const [MedicalProcedureList, setMedicalProcedureList] = useState(null);
  const [EmployeeFamilyList, setEmployeeFamilyList] = useState(null);
  const [EmployeeRelationList, setEmployeeRelationList] = useState(null);
  const [NewMedicalRequestsList, setNewMedicalRequestsList] = useState([]);
  const [MonthList, setMonthList] = useState(null);

  const [currentDate, setcurrentDate] = useState(null);

  const popupMessage = useSelector(
    state => state.crudTbNewMedicalRequests.popupMessage
  );
  const formOpen = useSelector(
    state => state.crudTbNewMedicalRequests.formOpen
  );
  const dataTable = useSelector(
    state => state.crudTbNewMedicalRequests.dataTable
  );
  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialData = async () => {
    try {
      setisLoading(true);
      dataTable !== null &&
        dataTable.map(item => {
          dispatch(removeItem(item, branch));
        });
      const response1 = await MedicalProcedure.Get_Medical_Procedure_DD();
      setMedicalProcedureList(response1.Data.Details);
      const response2 =
        await EmployeeFamily.Get_Employee_Family_DropDown_ByEmpID(
          user.Employee_Id
        );
      setEmployeeFamilyList(response2.Data.Details);
      const response4 =
        await EmployeeFamily.Get_Employee_Family_Relation_DropDown_ByEmpID(
          user.Employee_Id
        );
      setEmployeeRelationList(response4.Data.Details);

      // only show current or last month
      // const currentDate = new Date();
      // const day = currentDate.getDate();
      // const month = currentDate.getMonth() + 1;
      // if (day <= 10) {
      //   const newMonthList = monthsList.filter(item => {
      //     return item.Value === month || item.Value === month - 1;
      //   });
      //   setMonthList(newMonthList);
      // } else {
      //   const newMonthList = monthsList.filter(item => {
      //     return item.Value === month;
      //   });
      //   setMonthList(newMonthList);
      // }
      setMonthList(monthsList);
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = Moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  useEffect(() => {
    setNewMedicalRequestsList(dataTable);
    let totalAmount = 0;
    dataTable.map(item => {
      totalAmount = totalAmount + item.Claim_Amount;
    });
    setTotalClaimAmount(totalAmount);
  }, [dataTable, formOpen]);

  const addNewItem = () => {
    if (SelectedMonth !== null) {
      dispatch(toggleForm(true, branch));
    } else {
      dispatch(setPopupMessageValues("Please select a month.", branch));
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Approver_Remarks: "",
      ApprovalStatus_Id: "",
      Refunded_Amount: "",
    },
    validationSchema: Yup.object({
      // Approver_Remarks: Yup.string().required("Please enter remarks"),
      // ApprovalStatus_Id: Yup.number().min(
      //   1,
      //   "Please select an approval status"
      // ),
      // Refunded_Amount: Yup.number()
      //   .required("Please enter remarks")
      //   .min(1, "Refuned Amount cannot be zero."),
    }),
    onSubmit: async values => {
      setisLoading(true);
      const request = [];
      NewMedicalRequestsList.map(item => {
        const newObj = {
          Transaction_Id: item.New_Medical_Request_Id,
          Employee_Family_Id: item.Employee_Family_Id,
          Medical_Procedure_Id: item.Medical_Procedure_Id,
          Claim_Amount: item.Claim_Amount,
          Procedure_Date: item.Procedure_Date,
          Medical_Document_Path:
            item.File !== null
              ? {
                  Name: item.File.Name,
                  File: item.File.File,
                }
              : null,
        };
        request.push(newObj);
      });
      const body = {
        Tr_Med_Id: 0,
        Med_Id: 0,
        Employee_Id: user.Employee_Id,
        Month: SelectedMonth,
        Total_Claim_Amount: TotalClaimAmount,
        // IsActive: true,
        User: user.Employee_Id,
        Report_to: user.Report_To_ID,
        MedicalTransaction: request,
      };
      if (NewMedicalRequestsList.length == 0) {
        dispatch(
          setPopupMessageValues("At least add one medical detail.", branch)
        );
      } else {
        const response = await MedicalRequests.Save_Medical_Request(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          setisLoading(false);
          navigate("/medical/medical_requests");
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }

      setisLoading(false);
    },
  });
  const renderMonthList = () =>
    MonthList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Add Medical Request
        </Typography>
        <Container fluid>
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Employee Name
                  </Label>
                  <Input
                    type="text"
                    name="employee_name"
                    required
                    disabled
                    value={user.First_Name + " " + user.Last_Name}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Employee Code
                  </Label>
                  <Input
                    type="text"
                    name="employee_Code"
                    required
                    disabled
                    value={user.Employee_Code}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Department
                  </Label>
                  <Input
                    type="text"
                    name="employee_department"
                    required
                    disabled
                    value={user.Department_Name}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Designation
                  </Label>
                  <Input
                    type="text"
                    name="employee_Designation"
                    required
                    disabled
                    value={user.Designation_Name}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Total Claim Amount
                  </Label>
                  <Input
                    type="text"
                    name="employee_department"
                    required
                    disabled
                    value={TotalClaimAmount.toLocaleString()}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Month
                  </Label>
                  <Input
                    type="select"
                    name="employee_Designation"
                    disabled={
                      NewMedicalRequestsList !== [] &&
                      NewMedicalRequestsList.length > 0
                    }
                    onChange={e => {
                      setSelectedMonth(e.target.value);
                    }}
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {MonthList == null || MonthList == undefined
                      ? ""
                      : renderMonthList()}
                  </Input>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            {/* Medical Request Tables */}
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={addNewItem}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <DynamicDataTable
                  HeaderData={NewMedicalRequestsListAnchor}
                  BodyData={NewMedicalRequestsList}
                  branch={branch}
                  EmployeeFamilyList={EmployeeFamilyList}
                  EmployeeRelationList={EmployeeRelationList}
                  MedicalProcedureList={MedicalProcedureList}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="col-12 text-start">
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm branch={branch} SelectedMonth={SelectedMonth} />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

AddMedicalRequest.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default AddMedicalRequest;
