import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Floating Form
import FloatingForm from "components/Forms/MasterData_Management/BloodGroup/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "../../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

//API Service
import _BloodGroup from "helpers/MasterData_Management/BloodGroup";
import AuthorizationFragment from "helpers/Unauthorized";

//i18n
import { withTranslation } from "react-i18next";
const BloodGroup = props => {
  //meta title
  document.title = "HR - BloodGroup";
  //Table Column Names
  const anchorTable = _BloodGroup.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbBloodGroup";
  let isSubmenuFound = false;

  const dispatch = useDispatch();
  const location = useLocation();
  //Redux States
  const initData = useSelector(state => state.crudTbBloodGroup.dataTable);
  const formOpen = useSelector(state => state.crudTbBloodGroup.formOpen);
  const popupMessage = useSelector(
    state => state.crudTbBloodGroup.popupMessage
  );
  const menu = useSelector(state => state.Login.Menu);

  const initialData = async () => {
    try {
      setisLoading(true);
      const response = await _BloodGroup.Get_BloodGroup_List_By_Rows(1, 10);
      setInitialList(response.Data.Details);
      setTotalRecords(response.Data.TotalRecords);
      dispatch(setData(response.Data, branch));
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  const isAuthorized = async () => {
    await menu.forEach(values => {
      values.child.forEach(({ link }) => {
        if (link === location.pathname) {
          isSubmenuFound = true;
        }
      });
    });
    console.log(isSubmenuFound);
    if (isSubmenuFound === false) {
      window.location.href = "/page_not_found";
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    // isAuthorized();
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);
  return (
    <AuthorizationFragment>
      {isSubmenuFound === true ? (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Master")}
              breadcrumbItem={props.t("Blood Group")}
            />
            {/* <Table columns={columns} data={data} /> */}
            {anchorTable == null && initialList == null ? (
              ""
            ) : (
              //Dynamic Table
              <TableContainer
                columns={anchorTable}
                initialData={initialList}
                totalRecords={TotalRecords}
                customPageSize={10}
                className="custom-header-css"
                branch={branch}
              />
            )}
          </Container>
        </div>
      ) : (
        ""
      )}

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm branch={branch} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </AuthorizationFragment>
  );
};

export default withTranslation()(BloodGroup);
