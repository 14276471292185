import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  // Table,
} from "reactstrap";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";
import { setDashboardAttendanceId } from "store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import ActivityComp from "./ActivityComp";
import RequestsUser from "./RequestsUser";

import Dashboards from "helpers/Dashboard";
import _EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import FloatingDashboardLeaveApproval from "components/Forms/Employee_Management/EmployeeLeave/DashboardLeaveApproval";
import AttendanceDetailsPopup from "components/Common/AttendanceDetailsPopup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const icons = [
  "bx-calendar-check",
  "bx bx-coffee",
  "bx bx-plus-medical",
  "bx bxs-florist",
];

const Dashboard = props => {
  const [AttendanceData, setAttendanceData] = useState(null);
  const [RequestData, setRequestData] = useState(null);
  // const [CurrentDate, setCurrentDate] = useState(null);
  const [EmployeeRequestData, setEmployeeRequestData] = useState(null);
  const [InnerWidth, setInnerWidth] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [LeaveData, setLeaveData] = useState([
    {
      title: "PL",
      iconClass: icons[0],
      description: "0 Days",
    },
    {
      title: "Casual",
      iconClass: icons[1],
      description: "0 Days",
    },
    {
      title: "Sick",
      iconClass: icons[2],
      description: "0 Days",
    },
    {
      title: "Marraige",
      iconClass: icons[3],
      description: "0 Days",
    },
  ]);

  const theme = useSelector(state => state.Layout.layoutModeType);
  const user = useSelector(state => state.Login.User);
  const formOpen = useSelector(state => state.Dashboard.formOpen);
  const formValues = useSelector(state => state.Dashboard.formValues);
  const popupMsgValues = useSelector(state => state.Dashboard.popupMsgValues);
  const AttendaceID = useSelector(state => state.Dashboard.Attendance_Id);

  const renderTableRows = () => {
    if (AttendanceData !== null) {
      return (
        // {
        AttendanceData.map((item, index) => {
          return (
            <TableRow
              key={index}
              style={{
                border:
                  theme == "light" ? "2px solid white" : "2px solid #2a3042",
              }}
            >
              <TableCell style={{ textAlign: "center" }}>{item.date}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{item.day}</TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color:
                    item.status === "Absent"
                      ? "red"
                      : item.status === "Present"
                      ? "green"
                      : item.status === "Late"
                      ? "#b3b300"
                      : "black",
                  // backgroundColor:
                  //   item.status === "Absent"
                  //     ? "#ffff4b"
                  //     : item.status === "Present"
                  //     ? ""
                  //     : "",
                }}
              >
                {item.status}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {item.timeIn}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {item.timeOut}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {item.leave}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {item.workingHours}
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color:
                    item.approvalStatus === "Completed" ||
                    item.approvalStatus === "Approved"
                      ? "green"
                      : item.approvalStatus === "Pending"
                      ? "orange"
                      : "red",
                }}
              >
                {item.approvalStatus}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {item.Attendance_Id !== null ? (
                  <button
                    className="btn btn-sm"
                    style={{
                      backgroundColor: "#004a8d",
                      color: "white",
                    }}
                    onClick={e => {
                      dispatch(setDashboardAttendanceId(item.Attendance_Id));
                    }}
                  >
                    View
                  </button>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          );
        })
        // }
      );
    }
  };

  const initialData = async () => {
    try {
      setisLoading(true);
      //Remaining Leaves
      const response1 = await Dashboards.Get_Employee_Leave_Count(
        user.Employee_Id
      );
      let data = JSON.parse(response1.Data);
      data = data.filter(item => {
        return item.LeaveTypeId < 5;
      });
      const obj = [];
      data.map((item, index) => {
        let val = {
          title: item.LeaveTypeName,
          iconClass: icons[index],
          description: item.TotalAllowed - item.TotalUsed + " " + "Days",
          description2: `out of ${item.TotalAllowed}`,
          // background: "linear-gradient(to right, #3C9AC1, #79D0D7)",
        };
        obj.push(val);
      });
      setLeaveData(obj);
    } catch (error) {
      console.log(error);
      // return;
    }
    try {
      //Attendance Table
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      let startDate = new Date(currentYear, currentMonth, 1);
      startDate = moment(startDate).format("YYYY-MM-DD");
      let endDate = new Date(currentYear, currentMonth + 1, 0);
      endDate = moment(endDate).format("YYYY-MM-DD");
      const response2 = await Dashboards.GetEmpAttendanceByDateRange(
        user.Employee_Id,
        startDate,
        endDate
      );
      const data2 = JSON.parse(response2.Data);
      const attendanceArray = [];
      //get current day of the month
      const now = new window.Date();
      let currentdate = `${now.getFullYear("YYYY")}-${
        now.getMonth("MM") + 1
      }-${now.getDate("DD")}`;
      currentdate = moment(currentdate).format("DD-MMM-YY");
      const currentDay = now.getDate("DD");
      data2.map((item, index) => {
        let dayName = "";
        const daysMapping = {
          Monday: "Mon",
          Tuesday: "Tue",
          Wednesday: "Wed",
          Thursday: "Thu",
          Friday: "Fri",
          Saturday: "Sat",
          Sunday: "Sun",
        };
        if (item[0] in daysMapping) {
          dayName = daysMapping[item[0]];
        } else {
          dayName = dayName;
        }

        //Check Weather the employee was late
        const isOnTime = checkOnTime(item[7], item[14]);
        //Calculate total working hours of employee
        const totalHours = calculateWorkingHours(item[7], item[8]);

        const obj = {
          date: moment(item[4].split("T")[0]).format("DD-MMM-YY"),
          day: dayName,
          status:
            item[7] !== null && item[7] !== "00:00:00" && isOnTime === true
              ? "Present"
              : item[7] !== null && item[7] !== "00:00:00" && isOnTime === false
              ? "Late"
              : item[12] !== "Working Day"
              ? "Off Day"
              : index + 1 <= currentDay
              ? "Absent"
              : "-",
          timeIn:
            item[7] !== null && item[7] !== "00:00:00"
              ? moment(item[7].split(".")[0], "HH:mm:ss").format("h:mm A")
              : "-",
          timeOut:
            item[8] !== null && item[8] !== "00:00:00"
              ? moment(item[8].split(".")[0], "HH:mm:ss").format("h:mm A")
              : "-",
          leave:
            item[12] !== null && item[12] === "Working Day" && item[9] === null
              ? "-"
              : item[9] !== null
              ? item[9]
              : item[12] === "Week Day Off"
              ? "Off Day"
              : item[12],
          workingHours:
            item[7] !== null &&
            item[7] !== "00:00:00" &&
            item[8] !== null &&
            item[8] !== "00:00:00" &&
            index + 1 <= currentDay
              ? totalHours
              : "-",
          approvalStatus:
            item[13] !== "" && item[13] !== "Cancelled" ? item[13] : "-",
          Attendance_Id: index + 1 <= currentDay ? item[15] : null,
        };
        attendanceArray.push(obj);
      });
      setAttendanceData(attendanceArray);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  function checkOnTime(time1, time2) {
    const [time1Hours, time1Minutes, time1Seconds] = time1
      .split(":")
      .map(Number);
    const [time2Hours, time2Minutes, time2Seconds] = time2
      .split(":")
      .map(Number);

    const time1Timestamp = new Date();
    time1Timestamp.setHours(time1Hours, time1Minutes, time1Seconds);

    const time2Timestamp = new Date();
    time2Timestamp.setHours(time2Hours, time2Minutes, time2Seconds);

    // Add one minute to time2Timestamp
    time2Timestamp.setMinutes(time2Timestamp.getMinutes() + 1);

    return time1Timestamp <= time2Timestamp;
  }

  const calculateWorkingHours = (Time_In, Time_Out) => {
    const timeInParts = Time_In.split(":");
    const timeOutParts = Time_Out.split(":");

    const timeInDate = new Date();
    timeInDate.setHours(timeInParts[0], timeInParts[1], timeInParts[2]);

    const timeOutDate = new Date();
    timeOutDate.setHours(timeOutParts[0], timeOutParts[1], timeOutParts[2]);

    const durationInMillis = timeOutDate.getTime() - timeInDate.getTime();
    const totalWorkingHours = durationInMillis / (1000 * 60 * 60);

    return totalWorkingHours.toFixed(2);
  };

  const getManagerRequest = async () => {
    try {
      setisLoading(true);
      //Requests Table
      const response = await Dashboards.GetLeaveRequestsByManager(
        user.Employee_Id
      );
      let data = JSON.parse(response.Data);
      if (data.length > 0) {
        data = data.filter(item => {
          return item[9] === "Pending";
        });
        if (data.length > 0) setRequestData(data);
        else setRequestData(null);
      } else {
        setRequestData(null);
      }

      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployeeRequest = async () => {
    try {
      setisLoading(true);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      // Get the start date of the current month
      let startDate = new Date(currentYear, currentMonth, 1);
      startDate = moment(startDate).format("YYYY-MM-DD");
      // Get the end date of the current month
      let endDate = new Date(currentYear, currentMonth + 1, 0);
      endDate = moment(endDate).format("YYYY-MM-DD");
      const data = {
        PageNumber: 0,
        PageSize: 10,
        Employee_Id: user.Employee_Id,
        From_Date: startDate,
        To_Date: endDate,
      };
      const response = await _EmployeeLeave.Get_EmployeeLeave_List(data);
      setEmployeeRequestData(response.Data.Details);

      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (user.Role_Id === 4) {
        getEmployeeRequest();
      } else {
        getManagerRequest();
      }
    }, 1500);
  }, [popupMsgValues]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //meta title
  document.title = "HR - Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            // breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="5" md="12" sm="12">
              <Col xl="12">
                <WelcomeComp />
              </Col>

              <Col xl="12">
                {user.Role_Id === 4 ? (
                  <RequestsUser RequestData={EmployeeRequestData} />
                ) : (
                  <ActivityComp RequestData={RequestData} />
                )}
              </Col>
            </Col>
            <Col xl="7" md="12" sm="12">
              <Col xl="12">
                <Row>
                  <div>
                    <h4 style={{ padding: "0px 0px 5px 5px" }}>
                      Leave Balance
                    </h4>
                    <div style={{ display: "flex", marginLeft: -5 }}>
                      <Row>
                        {LeaveData.map((report, key) => (
                          <Col md="6" key={"_col_" + key}>
                            <Card
                              style={{
                                margin: "0px 10px 10px 10px",
                                // background: report.background,
                                // color: "white",
                              }}
                            >
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <h5 className="text-muted fw-medium">
                                      {report.title}
                                    </h5>
                                    <h4 className="mb-0">
                                      {report.description}
                                      <span
                                        style={{
                                          fontSize: 14,
                                          fontWeight: "normal",
                                        }}
                                      >
                                        &nbsp;- {report.description2}
                                      </span>
                                    </h4>
                                  </div>
                                  <div className="avatar-xs rounded-circle align-self-center mini-stat-icon">
                                    <span
                                      className="avatar-title rounded-circle "
                                      style={{ backgroundColor: "#004a8d" }}
                                    >
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-20"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Row>
              </Col>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Col
                xl="12"
                style={{
                  overflowX:
                    InnerWidth !== false && InnerWidth < 500
                      ? "scroll"
                      : "hidden",
                  marginTop: -5,
                }}
              >
                <div className="mini-stats-wid">
                  {/* <h4 style={{ padding: "5px 5px" }}>Daily Attendance</h4> */}
                  <div
                    style={{
                      height: 513,
                      overflowY: "scroll",
                      border:
                        theme == "light"
                          ? "2px solid white"
                          : "2px solid #2a3042",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow
                          style={{
                            position: "sticky",
                            top: 0,
                            background: theme == "light" ? "white" : "#2a3042",
                            zIndex: 1,
                          }}
                        >
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              minWidth: 120,
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              minWidth: 60,
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Day
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              minWidth: 60,
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              minWidth: 70,
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Time In
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              minWidth: 70,
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Time Out
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Leave
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Working Hrs.
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Approval
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <tbody>{renderTableRows()}</tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={"Dashboard"} message={popupMsgValues} />

      {/* AttendanceDetailsPopup */}
      {AttendaceID !== undefined && AttendaceID !== null ? (
        <AttendanceDetailsPopup Id={AttendaceID} />
      ) : (
        ""
      )}

      {/* Form */}
      {formOpen !== undefined && formOpen === true ? (
        <FloatingDashboardLeaveApproval />
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
