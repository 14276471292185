import Department from "helpers/MasterData_Management/Department";
import Designation from "helpers/MasterData_Management/Designation";
import Gender from "helpers/MasterData_Management/Gender";
import LeaveType from "helpers/MasterData_Management/LeaveType";
import HeadCategory from "helpers/MasterData_Management/HeadCategory";
import SalaryHeads from "helpers/MasterData_Management/SalaryHeads";
import Country from "helpers/MasterData_Management/Country";
import State from "helpers/MasterData_Management/State";
import City from "helpers/MasterData_Management/City";
import ContractType from "helpers/MasterData_Management/ContractType";
import Degree from "helpers/MasterData_Management/Degree";
import Days from "helpers/MasterData_Management/Days";
import BloodGroup from "helpers/MasterData_Management/BloodGroup";
import Relationship from "helpers/MasterData_Management/Relationship";
import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import Branch from "helpers/MasterData_Management/Branch";
import AttendanceType from "helpers/MasterData_Management/AttendanceType";
import ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import InsurancePlan from "helpers/MasterData_Management/InsurancePlan";
import MappingDepartmentWithDesignation from "helpers/MasterData_Management/MappingDepartmentWithDesignation";
import BranchHoliday from "helpers/MasterData_Management/BranchHoliday";
import Calendar from "helpers/MasterData_Management/Calendar";
import Role from "helpers/MasterData_Management/Role";
import Function from "helpers/MasterData_Management/Function";

//Profile Management
import Menu from "helpers/Profile_Management/Menu";
import SubMenu from "helpers/Profile_Management/SubMenu";
import Profile from "helpers/Profile_Management/Profile";

//Shift Management
import Shift from "helpers/Shift_Management/Shift";
import ShiftOffDay from "helpers/Shift_Management/ShiftOffDay";

//Employee Management
import EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";

//Salary Management
import SalaryProfile from "helpers/Salary_Management/SalaryProfile";

//Asset Management
import AssetManagementList from "helpers/AssetManagement/AssetManagementList";
import EmployeeAssets from "helpers/AssetManagement/EmployeeAssets";
import ProductTransferHistory from "helpers/AssetManagement/ProductTransferHistory";
import AssetCategory from "helpers/AssetManagement/AssetCategory";
import DemandMaster from "helpers/AssetManagement/DemandMaster";
import ServicesMaster from "helpers/AssetManagement/ServicesMaster";
import ServicesDetails from "helpers/AssetManagement/ServicesDetails";
import DemandDetails from "helpers/AssetManagement/DemandDetails";

//Account
import Currency from "helpers/Account/Currency";
import Tax from "helpers/Account/Tax";
import CurrencyRate from "helpers/Account/CurrencyRate";

//Client Profiles
import Company from "helpers/ClientProfiles/Company";
import Client from "helpers/ClientProfiles/Client";
import Project from "helpers/ClientProfiles/Project";

let User_Id;

const delete_department = async (data, user) => {
  try {
    const body = {
      Department_Id: data.Department_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Department.Delete_Department(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_designation = async (data, user) => {
  try {
    const body = {
      Designation_Id: data.Designation_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Designation.Delete_Designation(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_gender = async (data, user) => {
  try {
    const body = {
      Gender_Id: data.Gender_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Gender.Delete_Gender(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_leaveType = async (data, user) => {
  try {
    const body = {
      LeaveType_Id: data.LeaveType_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await LeaveType.Delete_LeaveType(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_head_category = async (data, user) => {
  try {
    const body = {
      Head_Cat_Id: data.Head_Cat_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await HeadCategory.Delete_HeadCategory(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_salary_head = async (data, user) => {
  try {
    const body = {
      Head_Id: data.Head_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await SalaryHeads.Delete_SalaryHeads(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_country = async (data, user) => {
  try {
    const body = {
      Country_Id: data.Country_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Country.Delete_Country(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_state = async (data, user) => {
  try {
    const body = {
      State_Id: data.State_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await State.Delete_State(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_city = async (data, user) => {
  try {
    const body = {
      City_Id: data.City_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await City.Delete_City(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_contractType = async (data, user) => {
  try {
    const body = {
      ContractType_Id: data.ContractType_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await ContractType.Delete_ContractType(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_degree = async (data, user) => {
  try {
    const body = {
      Degree_Id: data.Degree_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Degree.Delete_Degree(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_days = async (data, user) => {
  try {
    const body = {
      Day_Id: data.Day_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Days.Delete_Day(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_bloodGroup = async (data, user) => {
  try {
    const body = {
      BloodGroup_Id: data.BloodGroup_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await BloodGroup.Delete_BloodGroup(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_relationship = async (data, user) => {
  try {
    const body = {
      Relationship_Id: data.Relationship_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Relationship.Delete_Relationship(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_medical_procedure = async (data, user) => {
  try {
    const body = {
      Medical_Procedure_Id: data.Medical_Procedure_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await MedicalProcedure.Delete_Medical_Procedure(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_employee_family = async (data, user) => {
  try {
    const body = {
      Emp_Fam_Id: data.Emp_Fam_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await EmployeeFamily.Delete_Employee_Family(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_branch = async (data, user) => {
  try {
    const body = {
      Branch_Id: data.Branch_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Branch.Delete_Branch(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_attendanceType = async (data, user) => {
  try {
    const body = {
      AttendanceType_Id: data.AttendanceType_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await AttendanceType.Delete_AttendanceType(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_approvalStatus = async (data, user) => {
  try {
    const body = {
      ApprovalStatus_Id: data.ApprovalStatus_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await ApprovalStatus.Delete_ApprovalStatus(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_insurancePlan = async (data, user) => {
  try {
    const body = {
      InsurancePlan_Id: data.InsurancePlan_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await InsurancePlan.Delete_InsurancePlans(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_mappingDepartmentDesignaiton = async (data, user) => {
  try {
    const body = {
      Dep_Des_Id: data.Dep_Des_Id,
      IsDelete: true,
      User: user,
    };

    const responseee =
      await MappingDepartmentWithDesignation.Delete_MappingDepartmentDesignation(
        body
      );
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_branchHoliday = async (data, user) => {
  try {
    const body = {
      BranchHoliday_Id: data.BranchHoliday_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await BranchHoliday.Delete_BranchHoliday(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_calendar = async (data, user) => {
  try {
    const body = {
      Calendar_Id: data.Calendar_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Calendar.Delete_Calendar(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_role = async (data, user) => {
  try {
    const body = {
      Role_Id: data.Role_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Role.Delete_Role(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_function = async (data, user) => {
  try {
    const body = {
      Function_Id: data.Function_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Function.Delete_Function(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Profile Management------------------------------------
const delete_menu = async (data, user) => {
  try {
    const body = {
      Menu_Id: data.Menu_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Menu.Delete_Menu(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_subMenu = async (data, user) => {
  try {
    const body = {
      SubMenu_Id: data.SubMenu_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await SubMenu.Delete_SubMenu(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_profile = async (data, user) => {
  try {
    const body = {
      Profile_Id: data.Profile_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Profile.Delete_Profile(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Shift Management------------------------------------------------------------------------
const delete_shift = async (data, user) => {
  try {
    const body = {
      Shift_Id: data.Shift_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Shift.Delete_Shift(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_shiftOffDay = async (data, user) => {
  try {
    const body = {
      ShiftOffDay_Id: data.ShiftOffDay_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await ShiftOffDay.Delete_ShiftOffDay(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//EmployeeLeave Management------------------------------------------------------------------------
const delete_EmployeeLeave = async (data, user) => {
  try {
    const body = {
      EmployeeLeave_Id: data.EmployeeLeave_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await EmployeeLeave.Delete_EmployeeLeave(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Salary Management------------------------------------------------------------------------
const delete_salaryProfile = async (data, user) => {
  try {
    const body = {
      Salary_Profile_Id: data.Salary_Profile_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await SalaryProfile.Delete_Salary_Profile_Master(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_salaryDisbursement = async (data, user) => {
  try {
    const body = {
      Salary_Disbursement_Id: data.Salary_Disbursement_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await SalaryDisbursement.Delete_SalaryDisbursement(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Asset Management------------------------------------------------------------------------
const delete_assetManagementList = async (data, user) => {
  try {
    const body = {
      Asset_Management_Id: data.Asset_Management_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await AssetManagementList.Delete_Asset_Management(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_employeeAssets = async (data, user) => {
  try {
    const body = {
      Employee_Asset_Id: data.Employee_Asset_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await EmployeeAssets.Delete_Employee_Assets(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_productTransferHistory = async (data, user) => {
  try {
    const body = {
      Product_Transfer_History_Id: data.Product_Transfer_History_Id,
      IsDelete: true,
      User: user,
    };

    const responseee =
      await ProductTransferHistory.Delete_Product_Transfer_History(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_AssetCategory = async (data, user) => {
  try {
    const body = {
      Asset_Category_Id: data.Asset_Category_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await AssetCategory.Delete_AssetCategory(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_DemandMaster = async (data, user) => {
  try {
    const body = {
      Demand_ID: data.Demand_ID,
      IsDelete: true,
      User: user,
    };

    const responseee = await DemandMaster.Delete_DemandMaster(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_ServicesMaster = async (data, user) => {
  try {
    const body = {
      Service_ID: data.Service_ID,
      IsDelete: true,
      User: user,
    };

    const responseee = await ServicesMaster.Delete_ServicesMaster(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_ServicesDetails = async (data, user) => {
  try {
    const body = {
      Serv_Det_ID: data.Serv_Det_ID,
      IsDelete: true,
      User: user,
    };

    const responseee = await ServicesDetails.Delete_ServicesDetails(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_DemandDetails = async (data, user) => {
  try {
    const body = {
      Dem_Item_ID: data.Dem_Item_ID,
      IsDelete: true,
      User: user,
    };

    const responseee = await DemandDetails.Delete_DemandDetails(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Account--------------------------------------------
const delete_Tax = async (data, user) => {
  try {
    const body = {
      Tax_Id: data.Tax_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Tax.Delete_Tax(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const delete_Currency = async (data, user) => {
  try {
    const body = {
      Currency_Id: data.Currency_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Currency.Delete_Currency(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const delete_Currency_Rate = async (data, user) => {
  try {
    const body = {
      Currency_Id: data.CurrencyRate_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await CurrencyRate.Delete_CurrencyRate(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//Client Profiles------------------------------------
const delete_Company = async (data, user) => {
  try {
    const body = {
      Company_Id: data.Company_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Company.Delete_Company(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const delete_Client = async (data, user) => {
  try {
    const body = {
      Client_Id: data.Client_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Client.Delete_Client(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const delete_Project = async (data, user) => {
  try {
    const body = {
      Project_Id: data.Project_Id,
      IsDelete: true,
      User: user,
    };

    const responseee = await Project.Delete_Project(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delAction = async (branch, item, user) => {
  let save_response = null;

  switch (branch) {
    case "crudTbDepartment": {
      save_response = await delete_department(item, user);
      break;
    }
    case "crudTbDesignation": {
      save_response = await delete_designation(item, user);
      break;
    }
    case "crudTbGender": {
      save_response = await delete_gender(item, user);
      break;
    }
    case "crudTbLeaveType": {
      save_response = await delete_leaveType(item, user);
      break;
    }
    case "crudTbHeadCategory": {
      save_response = await delete_head_category(item, user);
      break;
    }
    case "crudTbSalaryHeads": {
      save_response = await delete_salary_head(item, user);
      break;
    }
    case "crudTbCountry": {
      save_response = await delete_country(item, user);
      break;
    }
    case "crudTbStates": {
      save_response = await delete_state(item, user);
      break;
    }
    case "crudTbCity": {
      save_response = await delete_city(item, user);
      break;
    }
    case "crudTbContractType": {
      save_response = await delete_contractType(item, user);
      break;
    }
    case "crudTbDegree": {
      save_response = await delete_degree(item, user);
      break;
    }
    case "crudTbDays": {
      save_response = await delete_days(item, user);
      break;
    }
    case "crudTbBloodGroup": {
      save_response = await delete_bloodGroup(item, user);
      break;
    }
    case "crudTbRelationship": {
      save_response = await delete_relationship(item, user);
      break;
    }
    case "crudTbMedicalProcedure": {
      save_response = await delete_medical_procedure(item, user);
      break;
    }
    case "crudTbEmployeeFamily": {
      save_response = await delete_employee_family(item, user);
      break;
    }
    case "crudTbBranch": {
      save_response = await delete_branch(item, user);
      break;
    }
    case "crudTbAttendanceType": {
      save_response = await delete_attendanceType(item, user);
      break;
    }
    case "crudTbApprovalStatus": {
      save_response = await delete_approvalStatus(item, user);
      break;
    }
    case "crudTbInsurancePlan": {
      save_response = await delete_insurancePlan(item, user);
      break;
    }
    case "crudTbMappingDepartmentWithDesignation": {
      save_response = await delete_mappingDepartmentDesignaiton(item, user);
      break;
    }
    case "crudTbBranchHoliday": {
      save_response = await delete_branchHoliday(item, user);
      break;
    }
    case "crudTbCalendar": {
      save_response = await delete_calendar(item, user);
      break;
    }
    case "crudTbRole": {
      save_response = await delete_role(item, user);
      break;
    }
    case "crudTbFunction": {
      save_response = await delete_function(item, user);
      break;
    }

    //Profile Management------------------------------------
    case "crudTbMenu": {
      save_response = await delete_menu(item, user);
      break;
    }
    case "crudTbSubMenu": {
      save_response = await delete_subMenu(item, user);
      break;
    }
    case "crudTbProfile": {
      save_response = await delete_profile(item, user);
      break;
    }

    //Shift Management------------------------------------
    case "crudTbShift": {
      save_response = await delete_shift(item, user);
      break;
    }
    case "crudTbShiftOffDay": {
      save_response = await delete_shiftOffDay(item, user);
      break;
    }

    //EmployeeLeave Management------------------------------------
    case "crudTbEmployeeLeave": {
      save_response = await delete_EmployeeLeave(item, user);
      break;
    }

    //Salary Management------------------------------------
    case "crudTbSalaryProfileList": {
      save_response = await delete_salaryProfile(item, user);
      break;
    }
    case "crudTbSalaryDisbursement": {
      save_response = await delete_salaryDisbursement(item, user);
      break;
    }

    //Asset Management------------------------------------
    case "crudTbAssetManagementList": {
      save_response = await delete_assetManagementList(item, user);
      break;
    }
    case "crudTbEmployeeAssets": {
      save_response = await delete_employeeAssets(item, user);
      break;
    }
    case "crudTbProductTransferHistory": {
      save_response = await delete_productTransferHistory(item, user);
      break;
    }
    case "crudTbAssetCategory": {
      save_response = await delete_AssetCategory(item, user);
      break;
    }
    case "crudTbDemandMaster": {
      save_response = await delete_DemandMaster(item, user);
      break;
    }
    case "crudTbServicesMaster": {
      save_response = await delete_ServicesMaster(item, user);
      break;
    }
    case "crudTbServicesDetails": {
      save_response = await delete_ServicesDetails(item, user);
      break;
    }
    case "crudTbDemandDetails": {
      save_response = await delete_DemandDetails(item, user);
      break;
    }

    //Account------------------------------------
    case "crudTbTax": {
      save_response = await delete_Tax(item, user);
      break;
    }
    case "crudTbCurrency": {
      save_response = await delete_Currency(item, user);
      break;
    }
    case "crudTbCurrencyRate": {
      save_response = await delete_Currency_Rate(item, user);
      break;
    }
    //Clinet Profiles------------------------------------
    case "crudTbCompany": {
      save_response = await delete_Company(item, user);
      break;
    }
    case "crudTbClient": {
      save_response = await delete_Client(item, user);
      break;
    }
    case "crudTbProject": {
      save_response = await delete_Project(item, user);
      break;
    }
    default:
      break;
  }

  return save_response;
};

export default {
  delAction,
};
