import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Encryption from "helpers/Encryption/Encryption";
import PropTypes from "prop-types";
import _Department from "helpers/MasterData_Management/Department";
import _Menu from "helpers/Profile_Management/Menu";
import _Profile from "helpers/Profile_Management/Profile";
import Switch from "@material-ui/core/Switch";
import { CheckBox } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const ProfileDetail = props => {
  const { branch } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [ProfileName, setProfileName] = useState(false);
  const [InitialData, setInitialData] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [MenuList, setMenuList] = useState(null);
  const [SwitchList, setSwitchList] = useState([]);
  const [MenuCheck, setMenuCheck] = useState(false);
  const [MenuCheckComplete, setMenuCheckComplete] = useState(false);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      const response2 = await _Menu.Get_Menu_with_Child_Menus();
      setMenuList(response2.Data.Details);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const checkScrenn = async () => {
    setisLoading(true);
    let params = queryString.parse(location.search);
    const encryupteId = params.q;
    if (encryupteId !== undefined && encryupteId !== null) {
      const profileId = Encryption.decrypt_url(encryupteId);
      params = queryString.parse(profileId);
    }
    const profile_ = params.id;
    if (profile_) {
      setisLoading(true);
      const response = await _Profile.Get_Profile_with_Menus_By_Id(profile_);
      const data = response.Data.Details;
      await setInitialData(data);
      await setProfileName(data.Profile_Name);
      setIsEdit(true);
      setisLoading(false);

      if (InitialData != null && SwitchList != null) {
        // const data = InitialData.Menus;
        data.Menus.map(item1 => {
          item1.child.map(item2 => {
            const data1 = SwitchList;
            const findData = data1.findIndex(submenu => {
              return submenu.SubMenu_Id === item2.key;
            });
            if (findData !== -1) {
              data1[findData].Checked = true;
              const submenuId = data1[findData].SubMenu_Id;
              setSwitchList(data1);

              MenuList.map(mainmenu => {
                const abcd = mainmenu.child.findIndex(item => {
                  return item.key === submenuId;
                });
                if (abcd !== -1) {
                  mainmenu.child[abcd] = {
                    ...mainmenu.child[abcd],
                    checked: true,
                  };
                }
              });
            }
          });
        });
      }
      setisLoading(false);
      // setMenuCheckComplete(true);
      // }
    }
    // else {
    //   setMenuCheckComplete(true);
    //   setisLoading(false);
    // }
  };

  useEffect(() => {
    if (MenuList != null) {
      renderMainMenu_();
    }
  }, [MenuList]);

  useEffect(() => {
    checkScrenn();
    setMenuCheckComplete(true);
    setisLoading(false);
  }, [MenuCheck]);

  useEffect(() => {
    initialData();
  }, []);

  const renderMainMenu_ = async () => {
    const Switchdata = [];
    await MenuList.map((item, index) => {
      item.child.map((item1, index, row) => {
        const object = {
          Menu_Id: item.key,
          SubMenu_Id: item1.key,
          Name: "MenuId_" + item.key + "__SubMenuId_" + item1.key,
          Checked: false,
        };

        Switchdata.push(object);
        if (index + 1 === row.length) {
          setSwitchList(Switchdata);
          setMenuCheck(true);
        } else {
          // Not last one.
        }
      });
    });
  };

  const renderMainMenu = () =>
    MenuList.map((item, index) => (
      <div key={index}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              {item.name}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={3}>
            {renderSubMenu(item.key, item.child)}
          </Grid>
        </div>
      </div>
    ));

  const renderSubMenu = (MainMenu, SubMenu) => {
    return SubMenu.map((item, index, row) => {
      const indexVal = SwitchList.findIndex(
        item2 => item2.SubMenu_Id === item.key
      );
      let isChecked = false;
      if (indexVal !== -1) {
        isChecked = SwitchList[indexVal].Checked;
      }
      if (isEdit) {
        return (
          <Grid item xs={6} sm={2} key={index}>
            <FormControlLabel
              control={
                <Switch
                  name={"MenuId_" + MainMenu + "__SubMenuId_" + item.key}
                  // onChange={e => handleSwitch(e)}
                  onClick={e => handleSwitch(e)}
                  id={item.key}
                  checked={isChecked}
                  color="primary"
                />
              }
              label={item.name}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item xs={6} sm={2} key={index}>
            <FormControlLabel
              key={index}
              control={
                <Switch
                  name={"MenuId_" + MainMenu + "__SubMenuId_" + item.key}
                  // onChange={e => handleSwitch(e)}
                  onClick={e => handleSwitch(e)}
                  id={item.key}
                  color="primary"
                />
              }
              label={item.name}
            />
          </Grid>
        );
      }
    });
  };
  const handleSwitch = async e => {
    setisLoading(true);
    dispatch(setPopupMessageValues("changed", branch));
    const data = SwitchList;
    const objectName = e.target.name;
    const findData = data.findIndex(item => item.Name == objectName);
    if (findData !== -1) {
      data[findData].Checked = !data[findData].Checked;
      setSwitchList(data);
    }
    dispatch(setPopupMessageValues(null, branch));
    setisLoading(false);
    ///////
    // const sign = data[findData].Checked;
    // await MenuList.map(menu => {
    //   const childId = menu.child.findIndex(item => item.key === e.target.id);
    //   if (childId !== -1) {
    //     menu.child[childId] = {
    //       ...menu.child[childId],
    //       checked: sign,
    //     };
    //   }
    // });
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Profile_Name: ProfileName ? ProfileName : "",
    },
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        const switchData = SwitchList.filter(item => {
          return item.Checked == true;
        });
        let ProfileDetail = [];
        switchData.map(item => {
          ProfileDetail.push({
            Menu_Id: item.Menu_Id,
            SubMenu_Id: item.SubMenu_Id,
          });
        });
        let body = {
          Profile_Id: InitialData.Profile_Id,
          Profile_Name: values.Profile_Name,
          Profile_Detail: ProfileDetail,
          User: user.Employee_Id,
        };
        const response = await _Profile.Save_Profile(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          setisLoading(false);
          navigate("/profile_management/profile");
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        const switchData = SwitchList.filter(item => {
          return item.Checked == true;
        });
        let ProfileDetail = [];
        switchData.map(item => {
          ProfileDetail.push({
            Menu_Id: item.Menu_Id,
            SubMenu_Id: item.SubMenu_Id,
          });
        });
        let body = {
          Profile_Id: 0,
          Profile_Name: values.Profile_Name,
          Profile_Detail: ProfileDetail,
          User: user.Employee_Id,
        };
        const response = await _Profile.Save_Profile(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          setisLoading(false);
          navigate("/profile_management/profile");
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
    },
  });
  return (
    <>
      <Form
        onSubmit={e => {
          validation.handleSubmit();
          //   handleSubmit(e);
          e.preventDefault();
          return false;
        }}
      >
        <Row>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">Profile Name</Label>
              <Input
                name="Profile_Name"
                type="text"
                maxLength={255}
                onKeyPressCapture={e => _validation.alphabetic(e)}
                // value={isEdit ? InitialFormData.Profile_Name : ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                disabled={ProfileName}
                value={validation.values.Profile_Name || ""}
                invalid={
                  validation.touched.Profile_Name &&
                  validation.errors.Profile_Name
                    ? true
                    : false
                }
              />
              {validation.touched.Profile_Name &&
              validation.errors.Profile_Name ? (
                <FormFeedback type="invalid">
                  {validation.errors.Profile_Name}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            {MenuList !== null && MenuCheckComplete == true
              ? renderMainMenu()
              : ""}
          </div>
        </Row>

        <Row className="mt-2">
          <Col className="col-6 text-end">
            <button
              type="submit"
              className="btn btn-success"
              id="btn-save-event"
            >
              Save
            </button>
          </Col>
        </Row>
      </Form>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

ProfileDetail.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default ProfileDetail;
