import React, { useEffect, useState } from "react";
import moment from "moment";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Floating Form
import FloatingForm from "components/Forms/AssetManagement/ProductTransferHistory/form";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "../../../components/Common/TableContainer";
import ProductTransferHistoryTableContainer from "components/Table/AssetManagement/ProductTransferHistoryTableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues, setFormValues } from "store/actions";

import * as Yup from "yup";
import { useFormik } from "formik";

//API Service
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";
import { RotatingLines } from "react-loader-spinner";
// import Category from "helpers/MasterData_Management/Category";
import ProductTransferHistory from "helpers/AssetManagement/ProductTransferHistory";
import _validation from "helpers/validation";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";

const EmployeeAsset = props => {
  //meta title
  document.title = "HR- Product Transfer History";
  //Table Column Names
  const AnchorTable = ProductTransferHistory.AnchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [currentDate, setcurrentDate] = useState(false);
  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);

  //Data Lists
  const [Department, setDepartment] = useState(null);
  const [EmployeeList, setEmployeeList] = useState(null);
  const [CategoryList, setCategoryList] = useState([]);
  const [SelectedDepartment, setSelectedDepartment] = useState(null);

  //Filter Values
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedCategory, setSelectedCategory] = useState(null);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [PageSize, setPageSize] = useState(10);
  const [PageNumber, setPageNumber] = useState(0);
  const branch = "crudTbProductTransferHistory";

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(
    state => state.crudTbProductTransferHistory.dataTable
  );
  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbProductTransferHistory.popupMessage
  );
  const formValues = useSelector(
    state => state.crudTbProductTransferHistory.formValues
  );

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const initialData = async () => {
    try {
      setisLoading(true);
      const response1 = await _Department.Get_Department_DD();
      setDepartment(response1.Data.Details);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      // dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
    try {
      setisLoading(true);
      const body = {
        PageNumber: 1,
        PageSize: 10,
        Employee_Id: user.Employee_Id,
      };
      const ProductTransferHistory =
        await ProductTransferHistory.Get_Product_Transfer_History_List(body);

      setInitialList(ProductTransferHistory.Data.Details);
      setTotalRecords(ProductTransferHistory.Data.TotalRecords);
      dispatch(setData(ProductTransferHistory.Data, branch));
      setisLoading(false);
    } catch (error) {
      // dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Category_Id: 0,
    },
    validationSchema: Yup.object({
      // Category_Id: Yup.number()
      //   .required("Please Select a Category")
      //   .min(1, "Please Select a Category"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      setPageSize(10);
      setPageNumber(0);
      setInitialList(null);
      let body = {
        PageNumber: 1,
        PageSize: 10,
        Employee_Id: SelectedEmployee,
      };
      const response =
        await ProductTransferHistory.Get_Product_Transfer_History_List(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setInitialList(response.Data.Details);
        setTotalRecords(response.Data.TotalRecords);
        dispatch(setData(response.Data, branch));
        setisLoading(false);
        // dispatch(setPopupMessageValues(response.Status_Message, branch));
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.Status_Message, branch));
      }
    },
  });

  const handleDepartmentChange = async value => {
    setSelectedDepartment(value);
    setisSpinnerLoaderEmployee(true);
    try {
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      const data = response.Data.Details;
      const emp_list = [];
      data.map(item => {
        const obj = {
          value: item.Value,
          label: item.Text + " - " + item.EmpCode,
        };
        emp_list.push(obj);
      });
      setEmployeeList(emp_list);
      setisSpinnerLoaderEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderEmployee(false);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const renderDepartment = () =>
    Department.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderCategory = () =>
    CategoryList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Asset Management")}
            breadcrumbItem={props.t("Product Transfer History")}
          />
          {user.Role_Id !== 4 ? (
            <Form
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Department
                    </Label>
                    <Input
                      type="select"
                      name="Department_Id"
                      onChange={e => handleDepartmentChange(e.target.value)}
                      required
                      value={SelectedDepartment}
                    >
                      <option key={1} value={0}>
                        Select
                      </option>
                      {Department == null || Department == undefined
                        ? ""
                        : renderDepartment()}
                    </Input>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Employee
                      <span style={{ paddingTop: 10, marginLeft: 10 }}>
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="15"
                          visible={isSpinnerLoaderEmployee}
                        />
                      </span>
                    </Label>
                    <Select
                      defaultValue={EmployeeList !== null && EmployeeList[0]}
                      isDisabled={EmployeeList == null}
                      isSearchable={true}
                      name="color"
                      onChange={e => setSelectedEmployee(e.value)}
                      options={EmployeeList}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          backgroundColor: "white",
                        }),
                      }}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Category
                    </Label>
                    <Input
                      type="select"
                      name="Category_Id"
                      onChange={e => {
                        validation.handleChange(e);
                        setSelectedCategory(e.target.value);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.Category_Id || ""}
                      invalid={
                        validation.touched.Category_Id &&
                        validation.errors.Category_Id
                          ? true
                          : false
                      }
                    >
                      <option key={1} value={0}>
                        Select
                      </option>
                      {CategoryList == null || CategoryList == undefined
                        ? ""
                        : renderCategory()}
                    </Input>
                    {validation.touched.Category_Id &&
                    validation.errors.Category_Id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Category_Id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Product Name
                    </Label>
                    <Input
                      name="Product_Name"
                      type="text"
                      maxLength={255}
                      onKeyPress={_validation.alphaNumeric}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.Product_Name || ""}
                      invalid={
                        validation.touched.Product_Name &&
                        validation.errors.Product_Name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.Product_Name &&
                    validation.errors.Product_Name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Product_Name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Start Date
                    </Label>
                    <Input
                      type="date"
                      name="StartDate"
                      max={currentDate}
                      onChange={e => setStartDate(e.target.value)}
                      value={StartDate}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>End Date
                    </Label>
                    <Input
                      type="date"
                      name="EndDate"
                      max={currentDate}
                      onChange={e => setEndDate(e.target.value)}
                      value={EndDate}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <button
                    type="submit"
                    className="btn"
                    id="btn-save-event"
                    style={{
                      marginTop: 28,
                      backgroundColor: "#004a8d",
                      color: "white",
                    }}
                    disabled={SelectedEmployee == null}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}

          {/* <Table columns={columns} data={data} /> */}
          {AnchorTable == null && initialList == null ? (
            ""
          ) : (
            //Product Transfer History Table
            <ProductTransferHistoryTableContainer
              columns={AnchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
              EmployeeId={SelectedEmployee}
              pageSize={PageSize}
              PageNumber={PageNumber}
              changePageSize={e => setPageSize(e)}
              changePageNumber={e => setPageNumber(e)}
              changeTotalRecords={e => setTotalRecords(e)}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      <FloatingForm branch={branch} />
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EmployeeAsset);
