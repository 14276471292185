import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { RotatingLines } from "react-loader-spinner";
import AdvFilter from "../Table/AdvFilter";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";
import Select from "react-select";
import MedicalReport from "helpers/Medical/MedicalReport";
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";

import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  InputGroup,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const MedicalReportDetails = props => {
  const { branch } = props;
  const EmployeeColumns = MedicalReport.EmployeeColumns;
  const ManagerHRColumns = MedicalReport.ManagerHRColumns;
  const [isLoading, setisLoading] = useState(false);
  const [initialList, setInitialList] = useState([]);
  const [currentDate, setcurrentDate] = useState(false);

  const [EmployeeList, setEmployeeList] = useState(null);
  const [Department, setDepartment] = useState(null);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);
  //------------
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [SelectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  //--------------

  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const initialData = async () => {
    try {
      const response1 = await _Department.Get_Department_DD();
      setDepartment(response1.Data.Details);
    } catch (error) {
      // dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
    try {
      var firstDay = new Date();
      var lastDay = new Date();
      firstDay.setDate(1);
      setStartDate(moment(firstDay).format("YYYY-MM-DD"));
      setEndDate(moment(lastDay).format("YYYY-MM-DD"));
      const response1 = await _Employee.Get_Employee_By_Id(user.Employee_Id);
      const data1 = response1.Data.Details.Employee;
      setSelectedEmployeeData(data1);
      const body = {
        fromDate: moment(firstDay).format("YYYY-MM-DD"),
        toDate: moment(lastDay).format("YYYY-MM-DD"),
        EmployeeID: user.Employee_Id,
      };
      const response = await MedicalReport.Attendance_Report(body);
      const data = JSON.parse(response.Data);
      //changing columns order
      const newOrder = [];
      data.map((item, index) => {
        const newValues = [
          index + 1,
          item[2],
          item[3],
          item[4],
          item[5],
          item[6] !== null && item[6] !== ""
            ? moment(item[6]).format("DD-MMM-YYYY")
            : "",
          item[7],
        ];
        newOrder.push(newValues);
      });
      setInitialList(newOrder);
      setSelectedDepartment(user.Department_Id);
      handleDepartmentChange(user.Department_Id, true);
      setSelectedEmployee(user.Employee_Id);
      // setSearchInputEmployee(
      //   user.First_Name + " " + user.Last_Name + " - " + user.Employee_Code
      // );
      setisLoading(false);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      // dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const handleDepartmentChange = async (value, InitialRun) => {
    setisSpinnerLoaderEmployee(true);
    setSelectedDepartment(value);
    setSelectedEmployee(null);
    setSelectedOption("");
    try {
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      const data = response.Data.Details;
      const emp_list = [];
      data.map(item => {
        const obj = {
          value: item.Value,
          label: item.Text + " - " + item.EmpCode,
        };
        emp_list.push(obj);
      });
      if (InitialRun) {
        const index = emp_list.findIndex(item => {
          return item.value == user.Employee_Id;
        });
        if (index !== -1) {
          setSelectedOption(emp_list[index]);
          setSelectedEmployee(user.Employee_Id);
        }
      }
      setEmployeeList(emp_list);
      setisSpinnerLoaderEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderEmployee(false);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const handleGetReport = async () => {
    try {
      setisLoading(true);
      const body = {
        fromDate: StartDate,
        toDate: EndDate,
        EmployeeID: user.Role_Id === 5 ? user.Employee_Id : SelectedEmployee,
      };
      const response2 = await MedicalReport.Attendance_Report(body);
      const data2 = JSON.parse(response2.Data);

      const newOrder = [];
      data2.map((item, index) => {
        const newValues = [
          index + 1,
          item[2],
          item[3],
          item[4],
          item[5],
          item[6] !== null && item[6] !== ""
            ? moment(item[6]).format("DD-MMM-YYYY")
            : "",
          item[7],
        ];
        newOrder.push(newValues);
      });
      setInitialList(newOrder);
      const response = await _Employee.Get_Employee_By_Id(SelectedEmployee);
      const data = response.Data.Details.Employee;
      setSelectedEmployeeData(data);

      setisLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const renderDepartment = () =>
    Department.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  useEffect(() => {
    setisLoading(true);
    initialData();
  }, []);

  return (
    <>
      <Row>
        <Col lg="3">
          <div className="mb-3">
            <Label className="form-label">
              <span style={{ color: "red" }}>* </span>Start Date
            </Label>
            <Input
              type="date"
              name="StartDate"
              max={currentDate}
              onChange={e => setStartDate(e.target.value)}
              value={StartDate}
            />
          </div>
        </Col>
        <Col lg="3">
          <div className="mb-3">
            <Label className="form-label">
              <span style={{ color: "red" }}>* </span>End Date
            </Label>
            <Input
              type="date"
              name="EndDate"
              max={currentDate}
              onChange={e => setEndDate(e.target.value)}
              value={EndDate}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <div className="mb-3">
            <Label className="form-label">
              <span style={{ color: "red" }}>* </span>Department
            </Label>
            <Input
              type="select"
              name="Department_Id"
              onChange={e => handleDepartmentChange(e.target.value)}
              required
              value={SelectedDepartment}
            >
              <option key={1} value={0}>
                Select
              </option>
              {Department == null || Department == undefined
                ? ""
                : renderDepartment()}
            </Input>
          </div>
        </Col>
        <Col lg="3">
          <div className="mb-3">
            <Label className="form-label">
              <span style={{ color: "red" }}>* </span>Employee
              <span style={{ paddingTop: 10, marginLeft: 10 }}>
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="15"
                  visible={isSpinnerLoaderEmployee}
                />
              </span>
            </Label>
            <Select
              defaultValue={EmployeeList !== null && EmployeeList[0]}
              isDisabled={EmployeeList == null}
              isSearchable={true}
              name="color"
              onChange={e => {
                setSelectedEmployee(e.value);
                setSelectedOption(e);
              }}
              options={EmployeeList}
              value={SelectedOption}
              styles={{
                menu: provided => ({
                  ...provided,
                  backgroundColor: "white",
                }),
              }}
            />
          </div>
        </Col>
        <Col lg="3" style={{ marginTop: 28 }}>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#004a8d",
              color: "white",
            }}
            id="btn-save-event"
            disabled={SelectedEmployee === null}
            onClick={() => {
              handleGetReport();
              // GetEmployeeData(SelectedEmployee);
            }}
          >
            Generate Report
          </button>
        </Col>
      </Row>
      <br />
      <Row style={{ marginBottom: 10 }}>
        <Col className="col-12">
          <Label style={{ fontSize: 16, fontWeight: "bold" }}>
            EMPLOYEE DETAILS:
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 150, fontSize: 15 }}>Employee Name:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Full_Name
              : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Department:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Department_Name
              : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Designation:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Designation_Name
              : ""}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <Label style={{ width: 150, fontSize: 15 }}>Date Of Joining:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null ? SelectedEmployeeData.DOJ : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Report To:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Report_To_Name
              : ""}
          </Label>
        </Col>
        {/* <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Month:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedYear !== null &&
            SelectedMonth !== null &&
            MonthList !== null
              ? MonthList[SelectedMonth - 1].Text + " - " + SelectedYear
              : ""}
          </Label>
        </Col> */}
      </Row>
      <br />
      {/* <Row>
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
            />
          )}
        </Row> */}
      <div>
        {initialList !== [] && user.Role_Id === 4 ? (
          <AdvFilter
            columns={EmployeeColumns}
            data={initialList}
            fileName={
              StartDate !== null &&
              EndDate !== null &&
              SelectedEmployeeData !== null
                ? `${SelectedEmployeeData.Full_Name} Medical Rpt for ${
                    StartDate + " To " + EndDate
                  }.csv`
                : "Rpt.csv"
            }
          />
        ) : (
          <AdvFilter
            columns={ManagerHRColumns}
            data={initialList}
            fileName={
              StartDate !== null &&
              EndDate !== null &&
              SelectedEmployeeData !== null
                ? `${SelectedEmployeeData.Full_Name} Medical Rpt for ${
                    StartDate + " To " + EndDate
                  }.csv`
                : "Rpt.csv"
            }
          />
        )}
        {/* <SourceReader componentName={docSrc + "AdvFilter.js"} /> */}
      </div>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

MedicalReportDetails.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default MedicalReportDetails;
