import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_PaymentMode_DD = (value = 0) => {
  let url = "api/Employee_Attendance/Get_PaymentMode_DropDown";

  return get(url);
};

const Get_PaymentMode_List = body => {
  let url = "api/Employee_Attendance/Get_PaymentMode_List?id=0";

  return get(url, body);
};

const Get_PaymentMode_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Employee_Attendance/Get_PaymentMode_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_PaymentMode = body => {
  let url = "api/Employee_Attendance/Save_PaymentMode";
  return post(url, body);
};

const Edit_PaymentMode = body => {
  let url = "api/Employee_Attendance/Edit_PaymentMode";
  return post(url, body);
};

const Delete_PaymentMode = body => {
  let url = "api/Employee_Attendance/Edit_PaymentMode_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Payment Mode ID",
    accessor: "SrNo",
    hidden: false,
  },
  {
    Header: "Payment Mode",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "Date",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "Time_In",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Modified By",
    accessor: "Time_In",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_PaymentMode_DD,
  Get_PaymentMode_List,
  Get_PaymentMode_List_By_Rows,
  Save_PaymentMode,
  Edit_PaymentMode,
  Delete_PaymentMode,
  anchorTable,
};
