import {
  SET_DATA,
  ADD_NEW_ITEM,
  UPDATE_ITEM,
  REMOVE_ITEM,
  TOGGLE_FORM,
  SET_FORM_VALUES,
  SET_POP_MESSAGE_VALUES,
} from "../../crudTbFrmConstant";

const Initial_State = {
  dataTable: [],
  error: {},
  formOpen: false,
  formValues: null,
  popupMessage: null,
};

const City = (state = Initial_State, action) => {
  const { branch } = action;
  switch (action.type) {
    case `${branch}/${SET_DATA}`:
      return {
        ...state,
        dataTable: action.payload.Details,
      };

    case `${branch}/${TOGGLE_FORM}`:
      return {
        ...state,
        formOpen: action.payload,
      };

    case `${branch}/${SET_FORM_VALUES}`: {
      return {
        ...state,
        formValues: action.payload,
      };
    }

    case `${branch}/${SET_POP_MESSAGE_VALUES}`: {
      return {
        ...state,
        popupMessage: action.payload,
      };
    }

    case `${branch}/${ADD_NEW_ITEM}`: {
      let newTable = state.dataTable;
      newTable.push(action.payload);
      return {
        ...state,
        dataTable: newTable,
        formOpen: false,
        formValues: null,
      };
    }

    case `${branch}/${UPDATE_ITEM}`: {
      let DemandDetailsList = state.dataTable;
      const index = DemandDetailsList.findIndex(
        item => item.Dem_Item_ID === action.payload.Dem_Item_ID
      );
      if (index !== -1) {
        DemandDetailsList[index] = action.payload;
        return {
          ...state,
          dataTable: DemandDetailsList,
          formOpen: false,
          formValues: null,
        };
      }
      break;
    }

    case `${branch}/${REMOVE_ITEM}`: {
      const DemandDetailsList = state.dataTable;
      const index = DemandDetailsList.findIndex(
        item => item.Dem_Item_ID === action.payload.Dem_Item_ID
      );
      if (index !== -1) {
        return {
          ...state,
          dataTable: DemandDetailsList.filter(item => {
            return item.Dem_Item_ID !== action.payload.Dem_Item_ID;
          }),
        };
      }
      break;
    }
    default:
      return state;
  }
};

export default City;
