import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Floating Form
import FloatingForm from "components/Forms/MasterData_Management/LeaveType/form";
//Import Breadcrumb
import Breadcrumbs from "./../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "./../../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

//API Service
import _LeaveType from "helpers/MasterData_Management/LeaveType";

//i18n
import { withTranslation } from "react-i18next";
const LeaveType = props => {
  //meta title
  document.title = "HR - Leave Type";
  //Table Column Names
  const anchorTable = _LeaveType.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbLeaveType";

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(state => state.crudTbLeaveType.dataTable);
  const formOpen = useSelector(state => state.crudTbLeaveType.formOpen);
  const popupMessage = useSelector(state => state.crudTbLeaveType.popupMessage);

  const initialData = async () => {
    try {
      setisLoading(true);
      const response = await _LeaveType.Get_LeaveType_List_By_Rows(1, 10);
      setInitialList(response.Data.Details);
      setTotalRecords(response.Data.TotalRecords);
      dispatch(setData(response.Data, branch));
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  //Fetch Initial data on page load
  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Leave Type")}
          />
          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            //Dynamic Table
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm branch={branch} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(LeaveType);
