import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
//Import Alert Dialogue
import DialogPopup from "components/Common/DialogPopup";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Dynamic Table Component
import TableContainer from "../../../components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

//API Service
import _CurrencyRate from "helpers/Account/CurrencyRate";

const CurrencyRate = props => {
  //meta title
  document.title = "HR - CurrencyRate";
  //Table Column Names
  const anchorTable = _CurrencyRate.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbCurrencyRate";

  const dispatch = useDispatch();
  //Redux States
  const initData = useSelector(state => state.crudTbCurrencyRate.dataTable);
  const formOpen = useSelector(state => state.crudTbCurrencyRate.formOpen);
  const popupMessage = useSelector(
    state => state.crudTbCurrencyRate.popupMessage
  );

  // const initialData = async () => {
  //   try {
  //     setisLoading(true);
  //     const response = await _CurrencyRate.Get_CurrencyRate_List_By_Rows(1, 10);
  //     setInitialList(response.Data.Details);
  //     setTotalRecords(response.Data.TotalRecords);
  //     dispatch(setData(response.Data, branch));
  //     setisLoading(false);
  //   } catch (error) {
  //     dispatch(setPopupMessageValues("Opps! Server is down.", branch));
  //     setisLoading(false);
  //   }
  // };

  //Fetch Initial data on page load
  React.useEffect(() => {
    // initialData();
  }, []);

  React.useEffect(() => {
    setInitialList(initData);
  }, [initData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Master"} breadcrumbItem={"CurrencyRate"} />
          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            //Dynamic Table
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default CurrencyRate;
