import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
  openLeaveApprovalForm,
  setDashboardFormValues,
  setDashboardPopupValues,
  setLeaveApprovalStatusValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const formOpen = useSelector(state => state.Dashboard.formOpen);
  const formValues = useSelector(state => state.Dashboard.formValues);
  const leaveApprovalStatus = useSelector(
    state => state.Dashboard.leaveApprovalStatus
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();
  useEffect(() => {
    if (formValues !== undefined && formValues !== null) {
      setInitialFormData(formValues);
    }
  }, [formOpen, formValues]);
  const toggle = () => {
    dispatch(openLeaveApprovalForm(false));
    dispatch(setDashboardFormValues(null));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Leave_Remarks: "",
    },
    validationSchema: Yup.object({
      Leave_Remarks: Yup.string().required("Please Enter Leave Remarks"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      let body = {
        Employee_Leave_Id: InitialFormData[1],
        ApprovalStatus_Id: leaveApprovalStatus,
        ApprovedById: user.Employee_Id,
        ApprovedRemarks: values.Leave_Remarks,
        DOP_User_Id: 0,
      };
      const response = await _EmployeeLeave.UpdateEmployeeLeave_Workflow(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setisLoading(false);
        dispatch(setDashboardPopupValues(response.Status_Message));
        dispatch(setLeaveApprovalStatusValues(null));
        toggle();
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setDashboardPopupValues(response.Status_Message));
        dispatch(setLeaveApprovalStatusValues(null));
        toggle();
      }
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {"Leave Approval/Rejection"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Remarks
                </Label>
                <Input
                  name="Leave_Remarks"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumericPlusMinus(e)}
                  // value={isEdit ? InitialFormData.Leave_Remarks : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Leave_Remarks || ""}
                  invalid={
                    validation.touched.Leave_Remarks &&
                    validation.errors.Leave_Remarks
                      ? true
                      : false
                  }
                />
                {validation.touched.Leave_Remarks &&
                validation.errors.Leave_Remarks ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Leave_Remarks}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
