import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Product_Transfer_History_DD = (value = 0) => {
  let url =
    "api/Product_Transfer_History/Get_Product_Transfer_History_DropDown";

  return get(url);
};

const Get_Product_Transfer_History_By_Id = id => {
  let url =
    "api/Product_Transfer_History/Get_Product_Transfer_History_By_Id?Id=" + id;

  return get(url);
};

const Get_Product_Transfer_History_List = body => {
  let url = "api/Product_Transfer_History/Get_Product_Transfer_History_List";

  return post(url, body);
};

const Get_Product_Transfer_History_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Product_Transfer_History/Get_Product_Transfer_History_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Product_Transfer_History = body => {
  let url = "api/Product_Transfer_History/Save_Product_Transfer_History";
  return post(url, body);
};

const Edit_Product_Transfer_History = body => {
  let url = "api/Product_Transfer_History/Edit_Product_Transfer_History";
  return post(url, body);
};

const Delete_Product_Transfer_History = body => {
  let url = "api/Product_Transfer_History/Edit_Product_Transfer_History_Status";
  return post(url, body);
};
const AnchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
  },
  {
    Header: "Employee Name",
    accessor: "EmployeeName",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "Designation",
    accessor: "Designation_Name",
    hidden: false,
  },
  {
    Header: "Product",
    accessor: "Product_Name",
    hidden: false,
  },
  {
    Header: "Category",
    accessor: "Category_Name",
    hidden: false,
  },
  {
    Header: "Serial No.",
    accessor: "Serial_Number",
    hidden: false,
  },
  {
    Header: "Purchased Date",
    accessor: "purchasedDate",
    hidden: false,
  },
  {
    Header: "Qty",
    accessor: "Quantity",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: false,
    delete: false,
    view: false,
    add: true,
    hidden: true,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Product_Transfer_History_DD,
  Get_Product_Transfer_History_By_Id,
  Get_Product_Transfer_History_List,
  Get_Product_Transfer_History_List_By_Rows,
  Save_Product_Transfer_History,
  Edit_Product_Transfer_History,
  Delete_Product_Transfer_History,
  AnchorTable,
};
