import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import img1 from "../../../assets/images/systechLogo.png";
import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

import _DailyAttendance from "helpers/Employee_Management/DailyAttendance";

//i18n
import { withTranslation } from "react-i18next";
const DailyAttendance = props => {
  //meta title
  document.title = "HR - Daily Attendance";
  const [initialList, setInitialList] = useState(null);
  const [TotalRecords, setTotalRecords] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbDailyAttendance";

  const dispatch = useDispatch();
  const user = useSelector(state => state.Login.User);
  const popupMessage = useSelector(
    state => state.crudTbDailyAttendance.popupMessage
  );

  // React.useEffect(() => {
  //   initialData();
  // }, []);

  // React.useEffect(() => {
  //   setInitialList(initData);
  // }, [initData]);

  const handleCheckIn = async () => {
    setisLoading(true);
    const body = {
      flag: 0,
      Employee_Id: user.Employee_Id,
      User: user.Employee_Id,
    };
    const response = await _DailyAttendance.Save_Employee_Manual_Attendance(
      body
    );
    if (response.IsSuccess === true && response.Status_Code === 200) {
      dispatch(setPopupMessageValues("Checked IN", branch));
    }
    setisLoading(false);
  };
  const handleCheckOut = async () => {
    setisLoading(true);
    const body = {
      flag: 1,
      Employee_Id: user.Employee_Id,
      User: user.Employee_Id,
    };
    const response = await _DailyAttendance.Save_Employee_Manual_Attendance(
      body
    );
    if (response.IsSuccess === true && response.Status_Code === 200) {
      dispatch(setPopupMessageValues("Checked OUT", branch));
    }
    setisLoading(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Daily Attendance")}
          />
        </Container>
      </div>

      <Row>
        <Col lg="3" style={{ margin: "auto", padding: "15px 0px" }}>
          <div
            style={{
              backgroundColor: "#efefef",
              borderRadius: "5%",
              boxShadow: "10px 2px 20px #2a3042",
            }}
          >
            <br />
            <br />
            <Row>
              <h1
                style={{
                  backgroundColor: "white",
                  margin: "auto",
                  color: "black",
                  width: "93%",
                  textAlign: "center",
                }}
              >
                ATTENDANCE
              </h1>
            </Row>
            <br />
            <Row>
              <p style={{ textAlign: "center", color: "black" }}>
                Hi, {user.First_Name}
              </p>
            </Row>
            <Row>
              <Col className="col-6" style={{ margin: "auto" }}>
                <div>
                  <img src={img1} style={{ width: "100%" }} />
                </div>
              </Col>
            </Row>
            {/* <Row>
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                CURRENT TIME & DATE
              </p>
            </Row> */}
            {/* <Row>
              <p
                style={{ textAlign: "center", fontSize: 18, letterSpacing: 2 }}
              >
                11:16 AM | 08-MAY-2023
              </p>
            </Row> */}
            <Row style={{ margin: "10px 0px" }}>
              <Col className="col-12" style={{ margin: "auto" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ margin: "auto" }}>
                    <Button
                      // color="primary"
                      className="btn btn-primary waves-effect waves-light btn-lg"
                      style={{
                        minWidth: 200,
                        backgroundColor: "#327599",
                        borderRadius: 20,
                      }}
                      onClick={handleCheckIn}
                    >
                      CHECK IN
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ margin: "10px 0px" }}>
              <Col className="col-12" style={{ margin: "auto" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ margin: "auto" }}>
                    <Button
                      // color="secondary"
                      className="btn btn-primary waves-effect waves-light btn-lg"
                      style={{
                        minWidth: 200,
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: 20,
                      }}
                      onClick={handleCheckOut}
                    >
                      CHECK OUT
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
          </div>
        </Col>
      </Row>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {/* <FloatingForm branch={branch} /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(DailyAttendance);
