import React from "react";
import TableHead from "@material-ui/core/TableHead";
import Moment from "moment";
import { Table, Row, Col, Button, Input } from "reactstrap";

const ApprovalHistoryTable = props => {
  const { DataList, className } = props;

  const columns = [
    {
      Header: "Sr.No.",
      accessor: "indexing",
      hidden: false,
      width: 30,
    },
    {
      Header: "Approver Name",
      accessor: "Approver_Name",
      hidden: false,
      width: 200,
    },
    {
      Header: "Department",
      accessor: "Approver_department",
      hidden: false,
      width: 150,
    },
    {
      Header: "Designation",
      accessor: "Approver_designation",
      hidden: false,
      width: 150,
    },
    {
      Header: "Approval Status",
      accessor: "Approval_Status_Name",
      hidden: false,
      width: 100,
    },
    {
      Header: "Remarks",
      accessor: "Approved_Remarks",
      hidden: false,
      width: 250,
    },
    {
      Header: "Approval Date",
      accessor: "Approved_On",
      hidden: false,
      width: 100,
    },
  ];

  const renderRows = () =>
    // stableSort(rows, getComparator(order, orderBy))
    DataList.map((row, index1) => {
      return (
        <tr key={index1}>
          {columns.map((cell, index) => {
            return (
              cell.hidden === false && (
                <td
                  key={index}
                  // width={cell.width !== undefined && cell.width}
                  style={{
                    margin: 0,
                    width: cell.width !== undefined ? cell.width : 300,
                  }}
                >
                  {cell.accessor === "IsActive" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          width: 80,
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                          zIndex: 9999,
                        }}
                        onClick={() => {
                          setchangeStatusModal(true);
                          setStatusChangeItem(row);
                        }}
                      >
                        ACTIVE
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          width: 80,
                          borderRadius: 5,
                          padding: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setchangeStatusModal(true);
                          setStatusChangeItem(row);
                        }}
                      >
                        In Active
                      </Button>
                    )
                  ) : cell.accessor === "IsDependent" ? (
                    row[cell.accessor] == true ? (
                      <Button
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 40,
                          margin: "auto",
                          borderRadius: 5,
                          padding: 0,
                          zIndex: 9999,
                        }}
                      >
                        Yes
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          width: 40,
                          margin: "auto",
                          pointerEvents: "none",
                          borderRadius: 5,
                          padding: 0,
                        }}
                      >
                        No
                      </Button>
                    )
                  ) : cell.accessor === "Approval_Status_Name" ? (
                    row[cell.accessor] == "Approved" ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        APPROVED
                      </Button>
                    ) : row[cell.accessor] == "Cancelled" ? (
                      <Button
                        type="button"
                        color="secondary"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        CANCELLED
                      </Button>
                    ) : row[cell.accessor] == "Pending" ? (
                      <Button
                        type="button"
                        color="warning"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        PENDING
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        color="danger"
                        className="btn-rounded  mb-1 me-1"
                        style={{
                          pointerEvents: "none",
                          width: 80,
                          borderRadius: 10,
                          padding: 0,
                        }}
                      >
                        REJECTED
                      </Button>
                    )
                  ) : cell.accessor === "Approved_On" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(row[cell.accessor]).format("MMM DD, YYYY")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "indexing" ? (
                    index1 + 1
                  ) : (
                    row[cell.accessor]
                  )}
                </td>
              )
            );
          })}
        </tr>
      );
    });

  return (
    <div>
      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
          <TableHead className="table-light table-nowrap">
            <tr>
              {columns.map((headerGroup, index) =>
                headerGroup.hidden === false ? (
                  <th key={index}>
                    {/* <TableSortLabel 
                     active={orderBy === headerGroup.Header}
                     direction={orderBy === headerGroup.Header ? order : "asc"}
                   onClick={createSortHandler(headerGroup.Header)}
                    >
                  {headerGroup.Header}
                    </TableSortLabel> */}
                    {headerGroup.Header}
                  </th>
                ) : (
                  ""
                )
              )}
            </tr>
          </TableHead>

          <tbody>
            {DataList !== null && DataList[0] !== null ? (
              renderRows()
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ApprovalHistoryTable;
