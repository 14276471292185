import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Shift_DD = (value = 0) => {
  let url = "api/Shift/Get_Shift_DropDown";

  return get(url);
};

const Get_Shift_List = body => {
  let url = "api/Shift/Get_Shift_List?id=0";

  return get(url, body);
};

const Get_Shift_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Shift/Get_Shift_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Shift = body => {
  let url = "api/Shift/Save_Shift";
  return post(url, body);
};

const Edit_Shift = body => {
  let url = "api/Shift/Edit_Shift";
  return post(url, body);
};

const Delete_Shift = body => {
  let url = "api/Shift/Edit_Shift_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Shift Name",
    accessor: "Shift_Name",
    hidden: false,
  },
  {
    Header: "Branch Name",
    accessor: "Branch_Name",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Shift_DD,
  Get_Shift_List,
  Get_Shift_List_By_Rows,
  Save_Shift,
  Edit_Shift,
  Delete_Shift,
  anchorTable,
};
