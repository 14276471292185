import React from "react";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import Image from "@material-ui/icons/Image";
import Description from "@material-ui/icons/Description";
import Moment from "moment";
import { Table, Row, Col, Button, Input } from "reactstrap";

const ApprovalHistoryTable = props => {
  const { DataList, className } = props;

  const MedicalRequestTable = [
    {
      Header: "Sr.No.",
      accessor: "indexing",
      hidden: false,
      width: 30,
    },
    {
      Header: "Family Name",
      accessor: "Employee_Family_Name",
      hidden: false,
      width: 200,
    },
    {
      Header: "Relationship",
      accessor: "relationship_name",
      hidden: false,
      width: 200,
    },
    {
      Header: "Medical Expense Type",
      accessor: "Medical_Procedure_name",
      hidden: false,
      width: 200,
    },
    {
      Header: "Date",
      accessor: "Procedure_Date",
      hidden: false,
      width: 200,
    },
    {
      Header: "Claim Amount",
      accessor: "Claim_Amount",
      hidden: false,
      width: 200,
    },
    {
      Header: "Attachment",
      accessor: "Attachment",
      hidden: false,
      width: 50,
    },
  ];

  const renderRows = () =>
    // stableSort(rows, getComparator(order, orderBy))
    DataList.map((row, index1) => {
      return (
        <tr key={index1}>
          {MedicalRequestTable.map((cell, index) => {
            return (
              cell.hidden === false && (
                <td
                  key={index}
                  // width={cell.width !== undefined && cell.width}
                  style={{
                    margin: 0,
                    width: cell.width !== undefined ? cell.width : 300,
                  }}
                >
                  {cell.accessor === "Procedure_Date" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor] !== "" &&
                    row[cell.accessor] !== undefined ? (
                      Moment(row[cell.accessor]).format("MMM DD, YYYY")
                    ) : (
                      ""
                    )
                  ) : cell.accessor === "Claim_Amount" ? (
                    row[cell.accessor] !== null &&
                    row[cell.accessor].toLocaleString()
                  ) : cell.accessor === "Attachment" ? (
                    <IconButton
                      aria-label="View"
                      onClick={() => Gotoview(row[cell.accessor])}
                    >
                      <Description
                        style={{ fontSize: 22, padding: 0, margin: -20 }}
                      />
                    </IconButton>
                  ) : cell.accessor === "indexing" ? (
                    index1 + 1
                  ) : (
                    row[cell.accessor]
                  )}
                </td>
              )
            );
          })}
        </tr>
      );
    });

  const Gotoview = async URL => {
    window.open(URL, "_blank");
  };

  return (
    <div>
      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
          <TableHead className="table-light table-nowrap">
            <tr>
              {MedicalRequestTable.map((headerGroup, index) =>
                headerGroup.hidden === false ? (
                  <th key={index}>
                    {/* <TableSortLabel 
                     active={orderBy === headerGroup.Header}
                     direction={orderBy === headerGroup.Header ? order : "asc"}
                   onClick={createSortHandler(headerGroup.Header)}
                    >
                  {headerGroup.Header}
                    </TableSortLabel> */}
                    {headerGroup.Header}
                  </th>
                ) : (
                  ""
                )
              )}
            </tr>
          </TableHead>

          <tbody>
            {DataList !== null && DataList[0] !== null ? (
              renderRows()
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ApprovalHistoryTable;
