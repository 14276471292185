import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import PropTypes, { string } from "prop-types";
import _Shift from "helpers/Shift_Management/Shift";
import _Country from "helpers/MasterData_Management/Country";
import _State from "helpers/MasterData_Management/State";
import _City from "helpers/MasterData_Management/City";
import _Branch from "helpers/MasterData_Management/Branch";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _validation from "helpers/validation";
import { RotatingLines } from "react-loader-spinner";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Loading from "components/Loading";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  FormGroup,
  Form,
  FormFeedback,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [CountryList, setCountryList] = useState(null);
  const [StateList, setStateList] = useState(null);
  const [CityList, setCityList] = useState(null);
  const [BranchList, setBranchList] = useState(null);
  const [isSpinnerLoaderState, setisSpinnerLoaderState] = useState(false);
  const [isSpinnerLoaderCity, setisSpinnerLoaderCity] = useState(false);
  const [isSpinnerLoaderBranch, setisSpinnerLoaderBranch] = useState(false);

  const [Start_In, setStart_In] = useState(null);
  const [Start_Out, setStart_Out] = useState(null);
  const [Break_In, setBreak_In] = useState(null);
  const [Break_Out, setBreak_Out] = useState(null);
  const [Grace_Time, setGrace_Time] = useState(null);
  const [Half_Day_Time, setHalf_Day_Time] = useState(null);

  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedStae, setselectedStae] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);

  const formOpen = useSelector(state => state.crudTbShift.formOpen);
  const ShiftList = useSelector(state => state.crudTbShift.dataTable);
  const formValues = useSelector(state => state.crudTbShift.formValues);
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const initialData = async () => {
    try {
      const response1 = await _Country.Get_Country_DD();
      setCountryList(response1.Data.Details);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const fetchStateList = async countryId => {
    try {
      setisSpinnerLoaderState(true);
      const response2 = await _State.Get_State_DD(countryId);
      setStateList(response2.Data.Details);
      setCityList(null);
      setBranchList(null);
      setisSpinnerLoaderState(false);
    } catch (error) {
      console.log(err);
      setisSpinnerLoaderState(false);
    }
  };
  const fetchCityList = async state_Id => {
    try {
      setisSpinnerLoaderCity(true);
      const response3 = await _City.Get_City_DD(state_Id);
      setCityList(response3.Data.Details);
      setBranchList(null);
      setisSpinnerLoaderCity(false);
    } catch (error) {
      console.log(err);
      setisSpinnerLoaderCity(false);
    }
  };
  const fetchBranchList = async city_Id => {
    try {
      setisSpinnerLoaderBranch(true);
      const response4 = await _Branch.Get_Branch_DD(city_Id);
      setBranchList(response4.Data.Details);
      setisSpinnerLoaderBranch(false);
    } catch (error) {
      console.log(err);
      setisSpinnerLoaderBranch(false);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    if (formValues != null) {
      const index = ShiftList.findIndex(
        item => item.Shift_Id == formValues.Shift_Id
      );
      setIsEdit(true);
      setInitialFormData(ShiftList[index]);
      setIsActiveCheck(ShiftList[index].IsActive);
      setStart_In(ShiftList[index].Start_In);
      setStart_Out(ShiftList[index].Start_Out);
      setBreak_In(ShiftList[index].Break_In);
      setBreak_Out(ShiftList[index].Break_Out);
      setGrace_Time(ShiftList[index].Grace_Time);
      setHalf_Day_Time(ShiftList[index].Half_Day_Time);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Shift_Name: isEdit ? InitialFormData.Shift_Name : "",
      Branch_Id: isEdit ? InitialFormData.Branch_Id : 0,
      // Start_In: isEdit ? InitialFormData.Start_In : "",
      Start_In: isEdit ? Start_In : "",
      Start_Out: isEdit ? Start_Out : "",
      Break_In: isEdit ? Break_In : "",
      Break_Out: isEdit ? Break_Out : "",
      Grace_Time: isEdit ? Grace_Time : "",
      Half_Day_Time: isEdit ? Half_Day_Time : "",
      IsActive: isEdit ? IsActiveCheck : "",
    },
    validationSchema: Yup.object({
      Shift_Name: Yup.string().required("Please Enter Shift Name"),
      // Country_Id: Yup.number().required("Please select a Country").min(1),
      // State_Id: Yup.number().required("Please select a State").min(1),
      // City_Id: Yup.number().required("Please select a City").min(1),
      Branch_Id: Yup.number()
        .required("Please select a Branch")
        .min(1, "Please select a Branch"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          Shift_Id: InitialFormData.Shift_Id,
          Shift_Name: values.Shift_Name,
          Branch_Id: InitialFormData.Branch_Id,
          Start_In: Start_In,
          Start_Out: Start_Out,
          Break_In: Break_In,
          Break_Out: Break_Out,
          Grace_Time: Grace_Time,
          Half_Day_Time: Half_Day_Time,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _Shift.Edit_Shift(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          Shift_Id: 0,
          Shift_Name: values.Shift_Name,
          Branch_Id: values.Branch_Id,
          Start_In: Start_In,
          Start_Out: Start_Out,
          Break_In: Break_In,
          Break_Out: Break_Out,
          Grace_Time: Grace_Time,
          Half_Day_Time: Half_Day_Time,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _Shift.Save_Shift(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });
  const renderCountry = () =>
    CountryList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderState = () =>
    StateList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderCity = () =>
    CityList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderBranch = () =>
    BranchList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  // const handleTimeIn = {
  //   onChange: (selectedDates, dateStr, instance) => console.log(dateStr),
  // };
  const handleValidate = e => {
    _validation.alphabetic(e);
  };

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Shift" : "Add Shift"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Shift Name
                </Label>
                <Input
                  name="Shift_Name"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => handleValidate(e)}
                  // value={isEdit ? InitialFormData.Shift_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Shift_Name || ""}
                  invalid={
                    validation.touched.Shift_Name &&
                    validation.errors.Shift_Name
                      ? true
                      : false
                  }
                />
                {validation.touched.Shift_Name &&
                validation.errors.Shift_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Shift_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          {isEdit ? (
            ""
          ) : (
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Country
                  </Label>
                  <Input
                    type="select"
                    name="Country_Id"
                    onChange={e => fetchStateList(e.target.value)}
                  >
                    <option key={1}>Select</option>
                    {CountryList == null || CountryList == undefined
                      ? ""
                      : renderCountry()}
                  </Input>
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>State
                    <span style={{ paddingTop: 10, marginLeft: 10 }}>
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="15"
                        visible={isSpinnerLoaderState}
                      />
                    </span>
                  </Label>
                  <Input
                    type="select"
                    name="State_Id"
                    onChange={e => fetchCityList(e.target.value)}
                    disabled={isSpinnerLoaderState}
                  >
                    <option key={1}>Select</option>
                    {StateList == null || StateList == undefined
                      ? ""
                      : renderState()}
                  </Input>
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>City
                    <span style={{ paddingTop: 10, marginLeft: 10 }}>
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="15"
                        visible={isSpinnerLoaderCity}
                      />
                    </span>
                  </Label>
                  <Input
                    type="select"
                    name="City_Id"
                    onChange={e => fetchBranchList(e.target.value)}
                    disabled={isSpinnerLoaderCity}
                  >
                    <option key={1}>Select</option>
                    {CityList == null || CityList == undefined
                      ? ""
                      : renderCity()}
                  </Input>
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Branch
                    <span style={{ paddingTop: 10, marginLeft: 10 }}>
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="15"
                        visible={isSpinnerLoaderBranch}
                      />
                    </span>
                  </Label>
                  <Input
                    type="select"
                    name="Branch_Id"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    disabled={isSpinnerLoaderBranch}
                    value={validation.values.Branch_Id || ""}
                    invalid={
                      validation.touched.Branch_Id &&
                      validation.errors.Branch_Id
                        ? true
                        : false
                    }
                  >
                    <option key={1}>Select</option>
                    {BranchList == null || BranchList == undefined
                      ? ""
                      : renderBranch()}
                  </Input>
                  {validation.touched.Branch_Id &&
                  validation.errors.Branch_Id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Branch_Id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Time In
                </Label>
                <Input
                  type="time"
                  name="Start_In"
                  step="1"
                  onChange={e => setStart_In(e.target.value)}
                  value={Start_In}
                  // value={validation.values.Start_In || ""}
                  required
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Time Out
                </Label>
                <Input
                  type="time"
                  name="Start_Out"
                  step="1"
                  onChange={e => setStart_Out(e.target.value)}
                  // value={validation.values.Start_Out || ""}
                  value={Start_Out}
                  required
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Break In
                </Label>
                <Input
                  type="time"
                  name="Break_In"
                  step="1"
                  onChange={e => setBreak_In(e.target.value)}
                  // value={validation.values.Break_In || ""}
                  value={Break_In}
                  required
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Break Out
                </Label>
                <Input
                  type="time"
                  name="Break_Out"
                  step="1"
                  onChange={e => setBreak_Out(e.target.value)}
                  // value={validation.values.Break_Out || ""}
                  value={Break_Out}
                  required
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Grace Time
                </Label>
                <Input
                  type="time"
                  name="Grace_Time"
                  step="1"
                  onChange={e => setGrace_Time(e.target.value)}
                  // value={validation.values.Grace_Time || ""}
                  value={Grace_Time}
                  required
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Half Day Time
                </Label>
                <Input
                  type="time"
                  name="Half_Day_Time"
                  step="1"
                  onChange={e => setHalf_Day_Time(e.target.value)}
                  // value={validation.values.Half_Day_Time || ""}
                  value={Half_Day_Time}
                  required
                />
              </div>
            </Col>
            {/* <Col className="col-6">
              <FormGroup className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Time In
                </Label>
                <InputGroup>
                  <Flatpickr
                    name="Start_In"
                    id="Start_In"
                    className="form-control d-block"
                    placeholder="Select time"
                    value={validation.values.Start_In || ""}
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i:S",
                      onChange: (selectedDates, dateStr, instance) =>
                        setStart_In(dateStr),
                    }}
                  />
                </InputGroup>
                 {validation.touched.Time_In && validation.errors.Time_In ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Time_In}
                  </FormFeedback>
                ) : null} 
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Time Out
                </Label>
                <InputGroup>
                  <Flatpickr
                    name="Start_Out"
                    id="Start_Out"
                    className="form-control d-block"
                    placeholder="Select Time Out"
                    value={validation.values.Start_Out || ""}
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i:S",
                      onChange: (selectedDates, dateStr, instance) =>
                        setStart_Out(dateStr),
                    }}
                  />
                </InputGroup>
                {validation.touched.Time_In && validation.errors.Time_In ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Time_In}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Break In
                </Label>
                <InputGroup>
                  <Flatpickr
                    name="Break_In"
                    id="Break_In"
                    className="form-control d-block"
                    placeholder="Select Break In"
                    value={validation.values.Break_In || ""}
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i:S",
                      onChange: (selectedDates, dateStr, instance) =>
                        setBreak_In(dateStr),
                    }}
                  />
                </InputGroup>
                {validation.touched.Time_In && validation.errors.Time_In ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Time_In}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Break Out
                </Label>
                <InputGroup>
                  <Flatpickr
                    name="Break_Out"
                    id="Break_Out"
                    className="form-control d-block"
                    placeholder="Select Time Out"
                    value={validation.values.Break_Out || ""}
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i:S",
                      onChange: (selectedDates, dateStr, instance) =>
                        setBreak_Out(dateStr),
                    }}
                  />
                </InputGroup>
                {validation.touched.Time_In && validation.errors.Time_In ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Time_In}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Grace Time
                </Label>
                <InputGroup>
                  <Flatpickr
                    name="Grace_Time"
                    id="Grace_Time"
                    className="form-control d-block"
                    placeholder="Select Grace Time"
                    value={validation.values.Grace_Time || ""}
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i:S",
                      onChange: (selectedDates, dateStr, instance) =>
                        setGrace_Time(dateStr),
                    }}
                  />
                </InputGroup>
                {validation.touched.Time_In && validation.errors.Time_In ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Time_In}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Half Day Time
                </Label>
                <InputGroup>
                  <Flatpickr
                    name="Half_Day_Time"
                    id="Half_Day_Time"
                    className="form-control d-block"
                    placeholder="Select Half Day Time"
                    value={validation.values.Half_Day_Time || ""}
                    required
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i:S",
                      onChange: (selectedDates, dateStr, instance) =>
                        setHalf_Day_Time(dateStr),
                    }}
                  />
                </InputGroup>
                {validation.touched.Time_In && validation.errors.Time_In ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Time_In}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
