import React, { Fragment, useState, useEffect } from "react";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _MedicalRequests from "helpers/Medical/MedicalRequests";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";

const floatingForm = props => {
  const { branch, SelectedMonth } = props;

  const [isEdit, setIsEdit] = useState(false);
  // const [previewUrl, setPreviewUrl] = useState("");
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [ProcedureDate, setProcedureDate] = useState(null);
  const [File, setFile] = useState(null);

  const [MedicalProcedureList, setMedicalProcedureList] = useState(null);
  // const [EmployeeFamilyName, setEmployeeFamilyName] = useState(null);
  // const [EmployeeRelationList, setEmployeeRelationList] = useState(null);
  const [EmployeeFamilyList, setEmployeeFamilyList] = useState(null);

  const formOpen = useSelector(
    state => state.crudTbNewMedicalRequests.formOpen
  );
  const formValues = useSelector(
    state => state.crudTbNewMedicalRequests.formValues
  );
  const dataTable = useSelector(
    state => state.crudTbNewMedicalRequests.dataTable
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
    setisLoading(true);
    if (formValues != null) {
      setIsEdit(true);
      setInitialFormData(formValues);
      setFile(formValues.File);
      setProcedureDate(formValues.Procedure_Date.split("T")[0]);
      setIsEdit(true);
    } else {
      setIsEdit(false);
      setisLoading(false);
    }
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  const initialData = async () => {
    try {
      setisLoading(true);
      const response1 = await MedicalProcedure.Get_Medical_Procedure_DD();
      setMedicalProcedureList(response1.Data.Details);
      const response2 =
        await EmployeeFamily.Get_Employee_Family_DropDown_ByEmpID(
          user.Employee_Id
        );
      setEmployeeFamilyList(response2.Data.Details);
      // const response3 =
      //   await EmployeeFamily.Get_Employee_Family_Relation_DropDown_ByEmpID(
      //     user.Employee_Id
      //   );
      // setEmployeeRelationList(response3.Data.Details);
      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Employee_Family_Id: isEdit ? InitialFormData.Employee_Family_Id : "",
      // Employee_Relation_Id: isEdit ? InitialFormData.Employee_Relation_Id : "",
      Medical_Procedure_Id: isEdit ? InitialFormData.Medical_Procedure_Id : "",
      Claim_Amount: isEdit ? InitialFormData.Claim_Amount : "",
    },
    validationSchema: Yup.object({
      Employee_Family_Id: Yup.number()
        .required("Please Select an Employee Family")
        .min(1, "Please Select an Employee Family"),
      // Employee_Relation_Id: Yup.number()
      //   .required("Please Select an Relationship")
      //   .min(1, "Please Select an Relationship"),
      Medical_Procedure_Id: Yup.number()
        .required("Please Select an Medical Procedure")
        .min(1, "Please Select an Medical Procedure"),
      Claim_Amount: Yup.string().required("Please enter a claim amount"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          New_Medical_Request_Id: InitialFormData.New_Medical_Request_Id,
          Employee_Family_Id: values.Employee_Family_Id,
          // Employee_Relation_Id: values.Employee_Relation_Id,
          Medical_Procedure_Id: values.Medical_Procedure_Id,
          Procedure_Date: ProcedureDate,
          Claim_Amount: parseInt(values.Claim_Amount),
          File: File,
        };

        dispatch(updateItem(body, branch));
        setisLoading(false);
      } else {
        //While Adding new Data
        let body = {
          New_Medical_Request_Id: dataTable.length + 1,
          Employee_Family_Id: values.Employee_Family_Id,
          // Employee_Relation_Id: values.Employee_Relation_Id,
          Medical_Procedure_Id: values.Medical_Procedure_Id,
          Claim_Amount: parseInt(values.Claim_Amount),
          Procedure_Date: ProcedureDate,
          File: File,
        };

        dispatch(addNewItem(body, branch));
        setisLoading(false);
      }
      toggle();
    },
  });

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadFiles = (files, previewUrl) => {
    if (files.length === 1) {
      files.map(async file => {
        const fileSize = files[0].size;
        if (fileSize > 1048576) {
          setFile(null);
          alert(`Maximum file size is 1 Mb.`);
          return;
        } else if (
          file.type === "application/pdf" ||
          file.type === "image/png" ||
          file.type === "image/jpeg"
        ) {
          await convertBase64(file, result => {
            const newItem = {
              Name: file.name,
              fileType: file.type,
              File: result,
              previewUrl: previewUrl,
            };
            // dispatch(setFiles(newItem, branch));
            setFile(newItem);
          });
        } else {
          setFile(null);
          alert(`Only Images and Pdfs are allowed.`);
        }
      });
    }
  };

  const handleFileEvent = e => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    // dispatch(setFiles(null, branch));
    const file = e.target.files[0];
    const reader = new FileReader();
    let previewUrl;
    reader.onload = () => {
      previewUrl = reader.result;
      setFile(null);
      handleUploadFiles(chosenFiles, previewUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //-------------------

  const renderMedicalProcedure = () =>
    MedicalProcedureList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  // const renderEmployeeRelationList = () =>
  //   EmployeeRelationList.map((item, index) => (
  //     <option key={index} value={item.Value}>
  //       {item.Text}
  //     </option>
  //   ));

  const renderEmployeeFamily = () =>
    EmployeeFamilyList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const getMinDate = () => {
    const year = new Date().getFullYear();
    const month = SelectedMonth.toString().padStart(2, "0");
    return `${year}-${month}-01`;
  };

  const getMaxDate = () => {
    const year = new Date().getFullYear();
    const month = SelectedMonth.toString().padStart(2, "0");
    const lastDay = new Date(year, SelectedMonth, 0).getDate();
    return `${year}-${month}-${lastDay}`;
  };

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Medical Request" : "Add Medical Request"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee Family Name
                </Label>
                <Input
                  type="select"
                  name="Employee_Family_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.Employee_Family_Id || ""}
                  invalid={
                    validation.touched.Employee_Family_Id &&
                    validation.errors.Employee_Family_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {EmployeeFamilyList == null || EmployeeFamilyList == undefined
                    ? ""
                    : renderEmployeeFamily()}
                </Input>
                {validation.touched.Employee_Family_Id &&
                validation.errors.Employee_Family_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Employee_Family_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {/* <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Relationship
                </Label>
                <Input
                  type="select"
                  name="Employee_Relation_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.Employee_Relation_Id || ""}
                  invalid={
                    validation.touched.Employee_Relation_Id &&
                    validation.errors.Employee_Relation_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {EmployeeRelationList == null ||
                  EmployeeRelationList == undefined
                    ? ""
                    : renderEmployeeRelationList()}
                </Input>
                {validation.touched.Employee_Relation_Id &&
                validation.errors.Employee_Relation_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Employee_Relation_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col> */}
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Medical Procedure
                </Label>
                <Input
                  type="select"
                  name="Medical_Procedure_Id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.Medical_Procedure_Id || ""}
                  invalid={
                    validation.touched.Medical_Procedure_Id &&
                    validation.errors.Medical_Procedure_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {MedicalProcedureList == null ||
                  MedicalProcedureList == undefined
                    ? ""
                    : renderMedicalProcedure()}
                </Input>
                {validation.touched.Medical_Procedure_Id &&
                validation.errors.Medical_Procedure_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Medical_Procedure_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date
                </Label>
                <Input
                  type="date"
                  name="Start_Date"
                  onChange={e => setProcedureDate(e.target.value)}
                  required
                  min={getMinDate()}
                  max={getMaxDate()}
                  value={ProcedureDate}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Claim Amount
                </Label>
                <Input
                  name="Claim_Amount"
                  type="text"
                  maxLength={255}
                  onKeyPress={_validation.Number}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Claim_Amount || ""}
                  invalid={
                    validation.touched.Claim_Amount &&
                    validation.errors.Claim_Amount
                      ? true
                      : false
                  }
                />
                {validation.touched.Claim_Amount &&
                validation.errors.Claim_Amount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Claim_Amount}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label for="basicpill-email-input4">
                  Attachment (Pdf/Image)
                </Label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileEvent}
                  id="fileUpload"
                  // disabled={IsTicketClose}
                  accept="image/jpeg, image/png,application/pdf"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
