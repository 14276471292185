import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { RotatingLines } from "react-loader-spinner";
import AdvFilter from "../Table/AdvFilter";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";
import Select from "react-select";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import _LeaveReport from "helpers/Employee_Management/LeaveReport";
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";
import LeaveType from "helpers/MasterData_Management/LeaveType";

import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  InputGroup,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const LeaveReport_table = props => {
  const { branch } = props;
  const columns = _LeaveReport.columns;
  const [isLoading, setisLoading] = useState(false);
  const [initialList, setInitialList] = useState([]);
  const [currentDate, setcurrentDate] = useState(false);

  const [EmployeeList, setEmployeeList] = useState(null);
  const [LeaveTypeList, setLeaveTypeList] = useState(null);
  const [Department, setDepartment] = useState(null);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [SelectedLeaveType, setSelectedLeaveType] = useState(0);
  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);
  //------------
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [SelectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  //--------------

  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const initialData = async () => {
    try {
      const response1 = await _Department.Get_Department_DD();
      setDepartment(response1.Data.Details);
      const response2 = await LeaveType.Get_LeaveType_DD();
      setLeaveTypeList(response2.Data.Details);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
    try {
      var firstDay = new Date();
      var lastDay = new Date();
      firstDay.setDate(1);
      setStartDate(moment(firstDay).format("YYYY-MM-DD"));
      setEndDate(moment(lastDay).format("YYYY-MM-DD"));
      setSelectedDepartment(user.Department_Id);
      handleDepartmentChange(user.Department_Id, true);
      setSelectedEmployee(user.Employee_Id);
      const response1 = await _Employee.Get_Employee_By_Id(user.Employee_Id);
      const data1 = response1.Data.Details.Employee;
      setSelectedEmployeeData(data1);
      const body = {
        fromDate: moment(firstDay).format("YYYY-MM-DD"),
        toDate: moment(lastDay).format("YYYY-MM-DD"),
        EmployeeID: user.Employee_Id,
        leaveType: SelectedLeaveType == 0 ? null : SelectedLeaveType,
      };
      const response2 = await _LeaveReport.Leave_Report(body);
      const data2 = JSON.parse(response2.Data);
      //changing columns order
      const newOrder = [];
      data2.map(item => {
        const newValues = [
          item[1],
          item[0],
          moment(item[4].split(" ")[0]).format("DD-MMM-YYYY"),
          moment(item[5].split(" ")[0]).format("DD-MMM-YYYY"),
          item[6],
          item[7],
          item[11],
          item[9],
          item[10] !== null && item[10] !== ""
            ? moment(item[10].split(" ")[0]).format("DD-MMM-YYYY")
            : "",
          item[2],
          item[3],
        ];
        newOrder.push(newValues);
      });
      setInitialList(newOrder);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const handleDepartmentChange = async (value, InitialRun) => {
    setisSpinnerLoaderEmployee(true);
    setSelectedDepartment(value);
    setSelectedEmployee(null);
    setSelectedOption("");
    try {
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      const data = response.Data.Details;
      const emp_list = [];
      data.map(item => {
        const obj = {
          value: item.Value,
          label: item.Text + " - " + item.EmpCode,
        };
        emp_list.push(obj);
      });
      if (InitialRun) {
        const index = emp_list.findIndex(item => {
          return item.value == user.Employee_Id;
        });
        if (index !== -1) {
          setSelectedOption(emp_list[index]);
          setSelectedEmployee(user.Employee_Id);
        }
      }
      setEmployeeList(emp_list);
      setisSpinnerLoaderEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderEmployee(false);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const handleGetReport = async () => {
    try {
      setisLoading(true);
      if (user.Role_Id !== 2) {
        const body = {
          fromDate: StartDate,
          toDate: EndDate,
          EmployeeID: user.Employee_Id,
          leaveType: SelectedLeaveType == 0 ? null : SelectedLeaveType,
        };
        const response2 = await _LeaveReport.Leave_Report(body);
        const data2 = JSON.parse(response2.Data);
        //changing columns order
        const newOrder = [];
        data2.map(item => {
          const newValues = [
            item[1],
            item[0],
            moment(item[4].split(" ")[0]).format("DD-MMM-YYYY"),
            moment(item[5].split(" ")[0]).format("DD-MMM-YYYY"),
            item[6],
            item[7],
            item[11],
            item[9],
            item[10] !== null && item[10] !== ""
              ? moment(item[10].split(" ")[0]).format("DD-MMM-YYYY")
              : "",
            item[2],
            item[3],
          ];
          newOrder.push(newValues);
        });
        setInitialList(newOrder);
      } else {
        const body = {
          fromDate: StartDate,
          toDate: EndDate,
          EmployeeID: SelectedEmployee,
          leaveType: SelectedLeaveType == 0 ? null : SelectedLeaveType,
        };
        const response = await _LeaveReport.Leave_Report(body);
        const data2 = JSON.parse(response.Data);
        const newOrder = [];
        data2.map(item => {
          const newValues = [
            item[1],
            item[0],
            moment(item[4].split(" ")[0]).format("DD-MMM-YYYY"),
            moment(item[5].split(" ")[0]).format("DD-MMM-YYYY"),
            item[6],
            item[7],
            item[11],
            item[9],
            item[10] !== null && item[10] !== ""
              ? moment(item[10].split(" ")[0]).format("DD-MMM-YYYY")
              : "",
            item[2],
            item[3],
          ];
          newOrder.push(newValues);
        });
        setInitialList(newOrder);
      }
      const response = await _Employee.Get_Employee_By_Id(SelectedEmployee);
      const data = response.Data.Details.Employee;
      setSelectedEmployeeData(data);

      setisLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const renderDepartment = () =>
    Department.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderLeaveType = () =>
    LeaveTypeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  useEffect(() => {
    setisLoading(true);
    initialData();
  }, []);

  return (
    <>
      {user.Role_Id === 2 ? (
        <>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Start Date
                </Label>
                <Input
                  type="date"
                  name="StartDate"
                  max={currentDate}
                  onChange={e => setStartDate(e.target.value)}
                  value={StartDate}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>End Date
                </Label>
                <Input
                  type="date"
                  name="EndDate"
                  max={currentDate}
                  onChange={e => setEndDate(e.target.value)}
                  value={EndDate}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>LeaveType
                </Label>
                <Input
                  type="select"
                  name="LeaveType_Id"
                  onChange={e => setSelectedLeaveType(e.target.value)}
                  required
                  value={SelectedLeaveType}
                >
                  <option key={1} value={0}>
                    All
                  </option>
                  {LeaveTypeList == null || LeaveTypeList == undefined
                    ? ""
                    : renderLeaveType()}
                </Input>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Department
                </Label>
                <Input
                  type="select"
                  name="Department_Id"
                  onChange={e => handleDepartmentChange(e.target.value)}
                  required
                  value={SelectedDepartment}
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {Department == null || Department == undefined
                    ? ""
                    : renderDepartment()}
                </Input>
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee
                  <span style={{ paddingTop: 10, marginLeft: 10 }}>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={isSpinnerLoaderEmployee}
                    />
                  </span>
                </Label>
                <Select
                  defaultValue={EmployeeList !== null && EmployeeList[0]}
                  isDisabled={EmployeeList == null || isSpinnerLoaderEmployee}
                  isSearchable={true}
                  name="color"
                  onChange={e => {
                    setSelectedEmployee(e.value);
                    setSelectedOption(e);
                  }}
                  options={EmployeeList}
                  value={SelectedOption}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                  }}
                />
              </div>
            </Col>
            <Col lg="3" style={{ marginTop: 28 }}>
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#004a8d",
                  color: "white",
                }}
                disabled={SelectedEmployee == null}
                id="btn-save-event"
                onClick={() => {
                  handleGetReport();
                }}
              >
                Generate Report
              </button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Start Date
                </Label>
                <Input
                  type="date"
                  name="StartDate"
                  max={currentDate}
                  onChange={e => setStartDate(e.target.value)}
                  value={StartDate}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>End Date
                </Label>
                <Input
                  type="date"
                  name="EndDate"
                  max={currentDate}
                  onChange={e => setEndDate(e.target.value)}
                  value={EndDate}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>LeaveType
                </Label>
                <Input
                  type="select"
                  name="LeaveType_Id"
                  onChange={e => setSelectedLeaveType(e.target.value)}
                  required
                  value={SelectedLeaveType}
                >
                  <option key={1} value={0}>
                    All
                  </option>
                  {LeaveTypeList == null || LeaveTypeList == undefined
                    ? ""
                    : renderLeaveType()}
                </Input>
              </div>
            </Col>
            <Col lg="3" style={{ marginTop: 28 }}>
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#004a8d",
                  color: "white",
                }}
                id="btn-save-event"
                disabled={SelectedEmployee == null}
                onClick={() => {
                  handleGetReport();
                }}
              >
                Generate Report
              </button>
            </Col>
          </Row>
        </>
      )}
      <br />
      <Row style={{ marginBottom: 10 }}>
        <Col className="col-12">
          <Label style={{ fontSize: 16, fontWeight: "bold" }}>
            EMPLOYEE DETAILS:
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 150, fontSize: 15 }}>Employee Name:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Full_Name
              : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Department:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Department_Name
              : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Designation:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Designation_Name
              : ""}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <Label style={{ width: 150, fontSize: 15 }}>Date Of Joining:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null ? SelectedEmployeeData.DOJ : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Report To:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Report_To_Name
              : ""}
          </Label>
        </Col>
      </Row>
      <br />
      <div>
        {initialList !== [] ? (
          <AdvFilter
            columns={columns}
            data={initialList}
            fileName={
              StartDate !== null &&
              EndDate !== null &&
              SelectedEmployeeData !== null
                ? `${SelectedEmployeeData.Full_Name} Leave Rpt for ${
                    StartDate + " To " + EndDate
                  }.csv`
                : "Rpt.csv"
            }
          />
        ) : (
          ""
        )}
      </div>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

LeaveReport_table.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default LeaveReport_table;
