import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Attendance_Report = body => {
  let url = "api/Reports/Attendance_Report";
  return post(url, body);
};

const Attendance_Report_ByHR = body => {
  let url = "api/Reports/Attendance_Report_ByHR";
  return post(url, body);
};

const columns = [
  {
    name: "Date",
    options: {
      filter: false,
    },
  },
  {
    name: "Day",
    options: {
      filter: false,
    },
  },
  {
    name: "Shift",
    options: {
      filter: true,
    },
  },
  {
    name: "Date In",
    options: {
      filter: true,
    },
  },
  {
    name: "Time In",
    options: {
      filter: true,
    },
  },
  {
    name: "Date Out",
    options: {
      filter: true,
    },
  },
  {
    name: "Time Out",
    options: {
      filter: true,
    },
  },
  {
    name: "Late Hours",
    options: {
      filter: true,
    },
  },
  {
    name: "Early Hours",
    options: {
      filter: false,
    },
  },
  {
    name: "Breaks",
    options: {
      filter: false,
    },
  },
  {
    name: "Overtime",
    options: {
      filter: false,
    },
  },
  {
    name: "Work Hours",
    options: {
      filter: false,
    },
  },
  {
    name: "Remarks",
    options: {
      filter: false,
    },
  },
];
const anchorTable = [
  {
    Header: "Date",
    accessor: "Date",
    hidden: false,
  },
  {
    Header: "Day",
    accessor: "Day",
    hidden: false,
  },
  {
    Header: "Shift",
    accessor: "Shift",
    hidden: false,
  },
  {
    Header: "Date In",
    accessor: "Date_In",
    hidden: false,
  },
  {
    Header: "Time In",
    accessor: "Time_In",
    hidden: false,
  },
  {
    Header: "Date Out",
    accessor: "Date_Out",
    hidden: false,
  },
  {
    Header: "Time Out",
    accessor: "Time_Out",
    hidden: false,
  },
  {
    Header: "Late Hours",
    accessor: "Late_Hours",
    hidden: false,
  },
  {
    Header: "Early Hours",
    accessor: "Early_Hours",
    hidden: false,
  },
  {
    Header: "Breaks",
    accessor: "Breaks",
    hidden: false,
  },
  {
    Header: "Overtime",
    accessor: "Overtime",
    hidden: false,
  },
  {
    Header: "Work Hours",
    accessor: "Work_Hours",
    hidden: false,
  },
  {
    Header: "Remarks",
    accessor: "Remarks",
    hidden: false,
  },
];
export default {
  getLoggedInUser,
  isUserAuthenticated,
  Attendance_Report,
  Attendance_Report_ByHR,
  columns,
};
