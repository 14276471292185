import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { RotatingLines } from "react-loader-spinner";
import AdvFilter from "../Table/AdvFilter";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";
import Select from "react-select";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import _AttendanceReport from "helpers/Employee_Management/AttendanceReport";
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";
import Country from "helpers/MasterData_Management/Country";
import _Calendar from "helpers/MasterData_Management/Calendar";

import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  InputGroup,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const AttendanceDetail = props => {
  const { branch } = props;
  const anchorTable = Country.anchorTable;
  const columns = _AttendanceReport.columns;
  const [isLoading, setisLoading] = useState(false);
  const [initialList, setInitialList] = useState([]);
  const [currentDate, setcurrentDate] = useState(false);

  const [EmployeeList, setEmployeeList] = useState(null);
  const [Department, setDepartment] = useState(null);
  const [YearList, setYearList] = useState(null);
  const [MonthList, setMonthList] = useState(null);
  const [SelectedYear, setSelectedYear] = useState(null);
  const [SelectedMonth, setSelectedMonth] = useState(null);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);
  const [isSpinnerLoaderMonth, setisSpinnerLoaderMonth] = useState(false);
  //------------
  const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [SelectedDepartment, setSelectedDepartment] = useState(null);
  const [SelectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [SelectedOption, setSelectedOption] = useState("");
  //--------------

  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
    const now = new window.Date();
    let currentdate = `${now.getFullYear("YYYY")}-${
      now.getMonth("MM") + 1
    }-${now.getDate("DD")}`;
    currentdate = moment(currentdate).format("YYYY-MM-DD");
    setcurrentDate(currentdate);
  }, []);

  const initialData = async () => {
    try {
      const response1 = await _Department.Get_Department_DD();
      setDepartment(response1.Data.Details);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
    try {
      if (user.Role_Id !== 2) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        getMonths(currentYear);
        setSelectedYear(currentYear.toString());
        setSelectedMonth(currentMonth + 1);
        const years = await _Calendar.Get_Calendar_Years();
        const yearsList = years.Data.Details;
        setYearList(yearsList);
        const response1 = await _Employee.Get_Employee_By_Id(user.Employee_Id);
        const data1 = response1.Data.Details.Employee;
        setSelectedEmployeeData(data1);
        const body = {
          Year: currentYear.toString(),
          Month: currentMonth + 1,
          Employee_Id: user.Employee_Id,
        };
        const response = await _AttendanceReport.Attendance_Report(body);
        const data = JSON.parse(response.Data);
        console.log(data);
        //changing columns order
        const newOrder = [];
        data.map(item => {
          const breakStart =
            item[14] == null
              ? ""
              : moment(item[14].split("-")[0], "HH:mm:ss").format("h:mm A");
          const breakEnd =
            item[14] == null
              ? ""
              : moment(item[14].split("-")[1], "HH:mm:ss").format("h:mm A");
          const newValues = [
            moment(item[6]).format("YYYY-MM-DD"),
            item[8],
            item[13],
            item[9] == null || item[9] == "0001-01-01T00:00:00"
              ? "-"
              : moment(item[9]).format("YYYY-MM-DD"),
            item[10] == null || item[10] == "00:00:00"
              ? "-"
              : moment(item[10], "HH:mm:ss").format("h:mm A"),
            item[11] == null || item[11] == "0001-01-01T00:00:00"
              ? "-"
              : moment(item[11]).format("YYYY-MM-DD"),
            item[12] == null || item[12] == "00:00:00"
              ? "-"
              : moment(item[12], "HH:mm:ss").format("h:mm A"),
            "",
            "",
            breakStart + " - " + breakEnd,
          ];
          newOrder.push(newValues);
        });
        setInitialList(newOrder);
        // handleDepartmentChange(user.Department_Id);
        setSelectedDepartment(user.Department_Id);
        setSelectedEmployee(user.Employee_Id);
        // setSearchInputEmployee(
        //   user.First_Name + " " + user.Last_Name + " - " + user.Employee_Code
        // );
        setisLoading(false);
      } else {
        var firstDay = new Date();
        var lastDay = new Date();
        firstDay.setDate(1);
        setStartDate(moment(firstDay).format("YYYY-MM-DD"));
        setEndDate(moment(lastDay).format("YYYY-MM-DD"));
        const body = {
          fromDate: firstDay,
          toDate: lastDay,
          EmployeeID: user.Employee_Id,
        };
        const response = await _AttendanceReport.Attendance_Report_ByHR(body);
        const data2 = JSON.parse(response.Data);
        //changing columns order
        const newOrder = [];
        data2.map(item => {
          const breakStart =
            item[14] == ""
              ? ""
              : moment(item[14].split("-")[0], "HH:mm:ss").format("h:mm A");
          const breakEnd =
            item[14] == ""
              ? ""
              : moment(item[14].split("-")[1], "HH:mm:ss").format("h:mm A");
          const newValues = [
            moment(item[6]).format("YYYY-MM-DD"),
            item[8],
            item[13],
            item[9] == "" || item[9] == "1/1/0001 12:00:00 AM"
              ? "-"
              : moment(item[9]).format("YYYY-MM-DD"),
            item[10] == "" || item[10] == "00:00:00"
              ? "-"
              : moment(item[10], "HH:mm:ss").format("h:mm A"),
            item[11] == "" || item[11] == "1/1/0001 12:00:00 AM"
              ? "-"
              : moment(item[11]).format("YYYY-MM-DD"),
            item[12] == "" || item[12] == "00:00:00"
              ? "-"
              : moment(item[12], "HH:mm:ss").format("h:mm A"),
            "",
            "",
            breakStart + " - " + breakEnd,
          ];
          newOrder.push(newValues);
        });
        setInitialList(newOrder);
        setSelectedDepartment(user.Department_Id);
        handleDepartmentChange(user.Department_Id, true);
        setSelectedEmployee(user.Employee_Id);
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
    }
  };

  const handleDepartmentChange = async (value, InitialRun) => {
    setisSpinnerLoaderEmployee(true);
    setSelectedDepartment(value);
    setSelectedEmployee(null);
    setSelectedOption("");
    try {
      const response = await _Employee.Get_Employee_By_Department_DD(value);
      const data = response.Data.Details;
      const emp_list = [];
      data.map(item => {
        const obj = {
          value: item.Value,
          label: item.Text + " - " + item.EmpCode,
        };
        emp_list.push(obj);
      });
      if (InitialRun) {
        const index = emp_list.findIndex(item => {
          return item.value == user.Employee_Id;
        });
        if (index !== -1) {
          setSelectedOption(emp_list[index]);
          setSelectedEmployee(user.Employee_Id);
        }
      }
      setEmployeeList(emp_list);
      setisSpinnerLoaderEmployee(false);
    } catch (error) {
      console.log(error);
      setisSpinnerLoaderEmployee(false);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const handleGetReport = async () => {
    try {
      setisLoading(true);
      if (user.Role_Id !== 2) {
        const body = {
          Year: SelectedYear,
          Month: SelectedMonth,
          Employee_Id: user.Employee_Id,
        };
        const response2 = await _AttendanceReport.Attendance_Report(body);
        const data2 = JSON.parse(response2.Data); //changing columns order
        const newOrder = [];
        data2.map(item => {
          const breakStart =
            item[14] == null
              ? ""
              : moment(item[14].split("-")[0], "HH:mm:ss").format("h:mm A");
          const breakEnd =
            item[14] == null
              ? ""
              : moment(item[14].split("-")[1], "HH:mm:ss").format("h:mm A");
          const newValues = [
            moment(item[6]).format("YYYY-MM-DD"),
            item[8],
            item[13],
            item[9] == null || item[9] == "0001-01-01T00:00:00"
              ? "-"
              : moment(item[9]).format("YYYY-MM-DD"),
            item[10] == null || item[10] == "00:00:00"
              ? "-"
              : moment(item[10], "HH:mm:ss").format("h:mm A"),
            item[11] == null || item[11] == "0001-01-01T00:00:00"
              ? "-"
              : moment(item[11]).format("YYYY-MM-DD"),
            item[12] == null || item[12] == "00:00:00"
              ? "-"
              : moment(item[12], "HH:mm:ss").format("h:mm A"),
            "",
            "",
            breakStart + " - " + breakEnd,
          ];
          newOrder.push(newValues);
        });
        setInitialList(newOrder);
      } else {
        const body = {
          fromDate: StartDate,
          toDate: EndDate,
          EmployeeID: SelectedEmployee,
        };
        const response = await _AttendanceReport.Attendance_Report_ByHR(body);
        const data2 = JSON.parse(response.Data);
        //changing columns order
        const newOrder = [];
        data2.map(item => {
          const breakStart =
            item[14] == ""
              ? ""
              : moment(item[14].split("-")[0], "HH:mm:ss").format("h:mm A");
          const breakEnd =
            item[14] == ""
              ? ""
              : moment(item[14].split("-")[1], "HH:mm:ss").format("h:mm A");
          const newValues = [
            moment(item[6]).format("YYYY-MM-DD"),
            item[8],
            item[13],
            item[9] == "" || item[9] == "1/1/0001 12:00:00 AM"
              ? "-"
              : moment(item[9]).format("YYYY-MM-DD"),
            item[10] == "" || item[10] == "00:00:00"
              ? "-"
              : moment(item[10], "HH:mm:ss").format("h:mm A"),
            item[11] == "" || item[11] == "1/1/0001 12:00:00 AM"
              ? "-"
              : moment(item[11]).format("YYYY-MM-DD"),
            item[12] == "" || item[12] == "00:00:00"
              ? "-"
              : moment(item[12], "HH:mm:ss").format("h:mm A"),
            "",
            "",
            breakStart + " - " + breakEnd,
          ];
          newOrder.push(newValues);
        });
        setInitialList(newOrder);
      }
      const response = await _Employee.Get_Employee_By_Id(SelectedEmployee);
      const data = response.Data.Details.Employee;
      setSelectedEmployeeData(data);

      setisLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(setPopupMessageValues(error, branch));
    }
  };

  const renderDepartment = () =>
    Department.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  useEffect(() => {
    setisLoading(true);
    initialData();
  }, []);

  const getMonths = async value => {
    const year = value;
    if (value !== 0) {
      setisSpinnerLoaderMonth(true);
      setSelectedYear(year);
      try {
        const months = await _Calendar.Get_Calendar_Months_By_Year(year);
        if (months.IsSuccess === true && months.Status_Code === 200) {
          setMonthList(months.Data.Details);
          setisSpinnerLoaderMonth(false);
        } else {
          setMonthList(null);
          setisSpinnerLoaderMonth(false);
        }
      } catch (error) {
        dispatch(setPopupMessageValues("Opps! Server is down.", branch));
        setisSpinnerLoaderMonth(false);
      }
    }
  };

  const renderYear = () =>
    YearList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));
  const renderMonth = () =>
    MonthList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  return (
    <>
      {user.Role_Id !== 2 ? (
        <Row>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">
                <span style={{ color: "red" }}>* </span>Year
              </Label>
              <Input
                type="select"
                name="Year"
                onChange={e => getMonths(e.target.value)}
                value={SelectedYear}
              >
                <option key={1} value={0}>
                  Select
                </option>
                {YearList == null || YearList == undefined ? "" : renderYear()}
              </Input>
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">
                <span style={{ color: "red" }}>* </span>Month
                <span style={{ paddingTop: 10, marginLeft: 10 }}>
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="15"
                    visible={isSpinnerLoaderMonth}
                  />
                </span>
              </Label>
              <Input
                type="select"
                name="Month"
                onChange={e => setSelectedMonth(e.target.value)}
                value={SelectedMonth}
                disabled={isSpinnerLoaderMonth}
              >
                <option key={1} value={0}>
                  Select
                </option>
                {MonthList == null || MonthList == undefined
                  ? ""
                  : renderMonth()}
              </Input>
            </div>
          </Col>
          <Col lg="3" style={{ marginTop: 28 }}>
            <button
              type="button"
              className="btn btn-success"
              id="btn-save-event"
              onClick={() => {
                handleGetReport();
              }}
            >
              Generate Report
            </button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">
                <span style={{ color: "red" }}>* </span>Start Date
              </Label>
              <Input
                type="date"
                name="StartDate"
                max={currentDate}
                onChange={e => setStartDate(e.target.value)}
                value={StartDate}
              />
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">
                <span style={{ color: "red" }}>* </span>End Date
              </Label>
              <Input
                type="date"
                name="EndDate"
                max={currentDate}
                onChange={e => setEndDate(e.target.value)}
                value={EndDate}
              />
            </div>
          </Col>
        </Row>
      )}
      {user.Role_Id === 2 ? (
        <Row>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">
                <span style={{ color: "red" }}>* </span>Department
              </Label>
              <Input
                type="select"
                name="Department_Id"
                onChange={e => handleDepartmentChange(e.target.value)}
                required
                value={SelectedDepartment}
              >
                <option key={1} value={0}>
                  Select
                </option>
                {Department == null || Department == undefined
                  ? ""
                  : renderDepartment()}
              </Input>
            </div>
          </Col>
          <Col lg="3">
            <div className="mb-3">
              <Label className="form-label">
                <span style={{ color: "red" }}>* </span>Employee
                <span style={{ paddingTop: 10, marginLeft: 10 }}>
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="15"
                    visible={isSpinnerLoaderEmployee}
                  />
                </span>
              </Label>
              <Select
                defaultValue={EmployeeList !== null && EmployeeList[0]}
                isDisabled={EmployeeList == null || isSpinnerLoaderEmployee}
                isSearchable={true}
                name="color"
                onChange={e => {
                  setSelectedEmployee(e.value);
                  setSelectedOption(e);
                }}
                options={EmployeeList}
                value={SelectedOption}
                styles={{
                  menu: provided => ({
                    ...provided,
                    backgroundColor: "white",
                  }),
                }}
              />
            </div>
          </Col>
          <Col lg="3" style={{ marginTop: 28 }}>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#004a8d",
                color: "white",
              }}
              id="btn-save-event"
              disabled={SelectedEmployee === null}
              onClick={() => {
                handleGetReport();
                // GetEmployeeData(SelectedEmployee);
              }}
            >
              Generate Report
            </button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <br />
      <Row style={{ marginBottom: 10 }}>
        <Col className="col-12">
          <Label style={{ fontSize: 16, fontWeight: "bold" }}>
            EMPLOYEE DETAILS:
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 150, fontSize: 15 }}>Employee Name:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Full_Name
              : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Department:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Department_Name
              : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Designation:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Designation_Name
              : ""}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <Label style={{ width: 150, fontSize: 15 }}>Date Of Joining:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null ? SelectedEmployeeData.DOJ : ""}
          </Label>
        </Col>
        <Col lg="3">
          <Label style={{ width: 130, fontSize: 15 }}>Report To:</Label>
          <Label style={{ fontWeight: "normal", fontSize: 14 }}>
            {SelectedEmployeeData !== null
              ? SelectedEmployeeData.Report_To_Name
              : ""}
          </Label>
        </Col>
        {user.Role_Id !== 2 ? (
          <Col lg="3">
            <Label style={{ width: 130, fontSize: 15 }}>Month:</Label>
            <Label style={{ fontWeight: "normal", fontSize: 14 }}>
              {SelectedYear !== null &&
              SelectedMonth !== null &&
              MonthList !== null
                ? MonthList[SelectedMonth - 1].Text + " - " + SelectedYear
                : ""}
            </Label>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <br />
      {/* <Row>
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              totalRecords={TotalRecords}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
            />
          )}
        </Row> */}
      <div>
        {initialList !== [] && user.Role_Id !== 2 ? (
          <AdvFilter
            columns={columns}
            data={initialList}
            fileName={
              SelectedYear !== null &&
              SelectedMonth !== null &&
              SelectedEmployeeData !== null &&
              MonthList !== null
                ? `${SelectedEmployeeData.Full_Name} Attendance Rpt for ${
                    MonthList[SelectedMonth - 1].Text + " - " + SelectedYear
                  }.csv`
                : "Rpt.csv"
            }
          />
        ) : initialList !== [] && user.Role_Id == 2 ? (
          <AdvFilter
            columns={columns}
            data={initialList}
            fileName={
              StartDate !== null &&
              EndDate !== null &&
              SelectedEmployeeData !== null
                ? `${SelectedEmployeeData.Full_Name} Attendance Rpt for ${
                    StartDate + " To " + EndDate
                  }.csv`
                : "Rpt.csv"
            }
          />
        ) : (
          ""
        )}
        {/* <SourceReader componentName={docSrc + "AdvFilter.js"} /> */}
      </div>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

AttendanceDetail.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default AttendanceDetail;
