import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_BloodGroup_DD = (value = 0) => {
  let url = "api/Blood_Group/Get_BloodGroup_DropDown";

  return get(url);
};

const Get_BloodGroup_List = body => {
  let url = "api/Blood_Group/Get_BloodGroup_List?id=0";

  return get(url, body);
};

const Get_BloodGroup_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Blood_Group/Get_BloodGroup_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_BloodGroup = body => {
  let url = "api/Blood_Group/Save_BloodGroup";
  return post(url, body);
};

const Edit_BloodGroup = body => {
  let url = "api/Blood_Group/Edit_BloodGroup";
  return post(url, body);
};

const Delete_BloodGroup = body => {
  let url = "api/Blood_Group/Edit_BloodGroup_Status";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
  },
  {
    Header: "Name",
    accessor: "BloodGroup_Name",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_BloodGroup_DD,
  Get_BloodGroup_List,
  Get_BloodGroup_List_By_Rows,
  Save_BloodGroup,
  Edit_BloodGroup,
  Delete_BloodGroup,
  anchorTable,
};
