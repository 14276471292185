import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Get_Asset_Management_DD = (value = 0) => {
  let url = "api/Asset_Management/Get_Asset_Management_DropDown";

  return get(url);
};

const Get_Asset_Management_By_Id = id => {
  let url = "api/Asset_Management/Get_Asset_Management_By_Id?Id=" + id;

  return get(url);
};

const Get_Asset_Management_List = body => {
  let url = "api/Asset_Management/Get_Asset_Management_List";

  return post(url, body);
};

const Get_Asset_Management_List_By_Rows = (PageNumber, PageSize) => {
  let url = `api/Asset_Management/Get_Asset_Management_List?PageNumber=${PageNumber}&PageSize=${PageSize}`;

  return get(url);
};

const Save_Asset_Management = body => {
  let url = "api/Asset_Management/Save_Asset_Management";
  return post(url, body);
};

const Edit_Asset_Management = body => {
  let url = "api/Asset_Management/Edit_Asset_Management";
  return post(url, body);
};

const Delete_Asset_Management = body => {
  let url = "api/Asset_Management/Edit_Asset_Management_Status";
  return post(url, body);
};
const AnchorTable = [
  {
    Header: "Sr.No.",
    accessor: "indexing",
    hidden: false,
  },
  {
    Header: "Product Name",
    accessor: "ProductName",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "Department_Name",
    hidden: false,
  },
  {
    Header: "Category",
    accessor: "Category_Name",
    hidden: false,
  },
  {
    Header: "Serial No.",
    accessor: "Serial_Number",
    hidden: false,
  },
  {
    Header: "Purchased Date",
    accessor: "purchasedDate",
    hidden: false,
  },
  {
    Header: "Qty",
    accessor: "Quantity",
    hidden: false,
  },
  {
    Header: "Cost",
    accessor: "Cost",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "Status",
    hidden: false,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
  },
];

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Get_Asset_Management_DD,
  Get_Asset_Management_By_Id,
  Get_Asset_Management_List,
  Get_Asset_Management_List_By_Rows,
  Save_Asset_Management,
  Edit_Asset_Management,
  Delete_Asset_Management,
  AnchorTable,
};
