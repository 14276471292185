import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import _Degree from "helpers/MasterData_Management/Degree";
import _City from "helpers/MasterData_Management/City";
import _validation from "helpers/validation";

const floatingForm = props => {
  const { branch } = props;

  const [DegreeList, setDegreeList] = useState(null);
  const [CityList, setCityList] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [YearList, setYearList] = useState(null);

  const formOpen = useSelector(
    state => state.crudTbEmployeeAcademicInformation.formOpen
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  React.useEffect(() => {
    initialData();
  }, []);
  React.useEffect(() => {
    const currentYear = new Date().getFullYear();
    let DateArray = [];
    for (let i = 0; i < 70; i++) {
      DateArray.push({
        Text: currentYear - i,
        Value: currentYear - i,
      });
    }
    setYearList(DateArray);
  }, []);

  const initialData = async () => {
    try {
      setisLoading(true);

      const response_degree = await _Degree.Get_Degree_DD();
      setDegreeList(response_degree.Data.Details);

      const response_city = await _City.Get_City_All_DD();
      setCityList(response_city.Data.Details);

      setisLoading(false);
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  const renderCity = () =>
    CityList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderDegree = () =>
    DegreeList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const renderYear = () =>
    YearList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Institute_Name: "",
      Degree_Id: "",
      GPA: "",
      Grade: "",
      Awarding_Year: "",
      City_Id: "",
    },
    validationSchema: Yup.object({
      Institute_Name: Yup.string().required("Please Enter Institution Name"),
      Degree_Id: Yup.number()
        .required("Please Select Degree")
        .min(1, "Please Select Degree"),
      GPA: Yup.string().required("Please Enter GPA"),
      Grade: Yup.string().required("Please Enter Grade"),
      Awarding_Year: Yup.number()
        .required("Please Enter Awarding Year")
        .min(1, "Please Enter Awarding Year"),
      City_Id: Yup.number()
        .required("Please Select City")
        .min(1, "Please Select City"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      let Degree_Name = "";
      let City_Name = "";

      const findCityData = await CityList.findIndex(submenu => {
        return submenu.Value + "" === values.City_Id + "";
      });

      if (findCityData !== -1) {
        City_Name = CityList[findCityData].Text;
      }

      const findDegreeData = await DegreeList.findIndex(submenu => {
        return submenu.Value + "" === values.Degree_Id + "";
      });

      if (findDegreeData !== -1) {
        Degree_Name = DegreeList[findDegreeData].Text;
      }
      let str = values.GPA.toString();
      values = {
        ...values,
        City_Name: City_Name,
        Degree_Name: Degree_Name,
        GPA: str,
      };
      dispatch(addNewItem(values, branch));
      setisLoading(false);
      dispatch(setPopupMessageValues("Added", branch));
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {"Add Institution Information"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Institution Name</Label>
                <Input
                  name="Institute_Name"
                  type="text"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  maxLength={255}
                  value={validation.values.Institute_Name || ""}
                  invalid={
                    validation.touched.Institute_Name &&
                    validation.errors.Institute_Name
                      ? true
                      : false
                  }
                  onKeyPress={event => {
                    _validation.alphaNumeric(event);
                  }}
                />
                {validation.touched.Institute_Name &&
                validation.errors.Institute_Name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Institute_Name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Degree</Label>
                <Input
                  name="Degree_Id"
                  type="select"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Degree_Id || ""}
                  invalid={
                    validation.touched.Degree_Id && validation.errors.Degree_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {DegreeList == null || DegreeList == undefined
                    ? ""
                    : renderDegree()}
                </Input>

                {validation.touched.Degree_Id && validation.errors.Degree_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Degree_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-3">
              <div className="mb-3">
                <Label className="form-label">GPA</Label>
                <Input
                  name="GPA"
                  type="number"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  maxLength={3}
                  value={validation.values.GPA || ""}
                  invalid={
                    validation.touched.GPA && validation.errors.GPA
                      ? true
                      : false
                  }
                  onKeyPress={event => {
                    _validation.Number2(event);
                  }}
                />
                {validation.touched.GPA && validation.errors.GPA ? (
                  <FormFeedback type="invalid">
                    {validation.errors.GPA}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-3">
              <div className="mb-3">
                <Label className="form-label">Grade</Label>
                <Input
                  name="Grade"
                  type="text"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Grade || ""}
                  invalid={
                    validation.touched.Grade && validation.errors.Grade
                      ? true
                      : false
                  }
                  onKeyPress={event => {
                    _validation.alphaNumericPlusMinusNoSpace(event);
                  }}
                  maxLength={2}
                />
                {validation.touched.Grade && validation.errors.Grade ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Grade}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <div className="mb-3">
                <Label className="form-label">Awarding Year</Label>
                <Input
                  name="Awarding_Year"
                  type="select"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Awarding_Year || ""}
                  invalid={
                    validation.touched.Awarding_Year &&
                    validation.errors.Awarding_Year
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {YearList == null || YearList == undefined
                    ? ""
                    : renderYear()}
                </Input>

                {validation.touched.Awarding_Year &&
                validation.errors.Awarding_Year ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Awarding_Year}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-8">
              <div className="mb-3">
                <Label className="form-label">City</Label>
                <Input
                  name="City_Id"
                  type="select"
                  // value={isEdit ? InitialFormData.ApprovalStatus_Name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.City_Id || ""}
                  invalid={
                    validation.touched.City_Id && validation.errors.City_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {CityList == null || CityList == undefined
                    ? ""
                    : renderCity()}
                </Input>

                {validation.touched.City_Id && validation.errors.City_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.City_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
