import axios from "axios";
import React, { useMemo } from "react";
import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const Save_Employee_Manual_Attendance = body => {
  let url = "api/Employee_Attendance/Save_Employee_Manual_Attendance";
  return post(url, body);
};

export default {
  getLoggedInUser,
  isUserAuthenticated,
  Save_Employee_Manual_Attendance,
};
