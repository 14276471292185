import Department from "helpers/MasterData_Management/Department";
import Designation from "helpers/MasterData_Management/Designation";
import Gender from "helpers/MasterData_Management/Gender";
import LeaveType from "helpers/MasterData_Management/LeaveType";
import HeadCategory from "helpers/MasterData_Management/HeadCategory";
import SalaryHeads from "helpers/MasterData_Management/SalaryHeads";
import Country from "helpers/MasterData_Management/Country";
import State from "helpers/MasterData_Management/State";
import City from "helpers/MasterData_Management/City";
import ContractType from "helpers/MasterData_Management/ContractType";
import Degree from "helpers/MasterData_Management/Degree";
import Days from "helpers/MasterData_Management/Days";
import BloodGroup from "helpers/MasterData_Management/BloodGroup";
import Relationship from "helpers/MasterData_Management/Relationship";
import MedicalProcedure from "helpers/MasterData_Management/MedicalProcedure";
import EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import Branch from "helpers/MasterData_Management/Branch";
import AttendanceType from "helpers/MasterData_Management/AttendanceType";
import ApprovalStatus from "helpers/MasterData_Management/ApprovalStatus";
import InsurancePlan from "helpers/MasterData_Management/InsurancePlan";
import MappingDepartmentWithDesignation from "helpers/MasterData_Management/MappingDepartmentWithDesignation";
import BranchHoliday from "helpers/MasterData_Management/BranchHoliday";
import Calendar from "helpers/MasterData_Management/Calendar";
import Role from "helpers/MasterData_Management/Role";
import Function from "helpers/MasterData_Management/Function";

//Profile Management
import Menu from "helpers/Profile_Management/Menu";
import SubMenu from "helpers/Profile_Management/SubMenu";
import Profile from "helpers/Profile_Management/Profile";

//Shift Management
import Shift from "helpers/Shift_Management/Shift";
import ShiftOffDay from "helpers/Shift_Management/ShiftOffDay";

//Employee Management
import Employee from "helpers/Employee_Management/Employee";
import EmployeeLeave from "helpers/Employee_Management/EmployeeLeave";
import HREmployeeAttendance from "helpers/Employee_Management/HREmployeeAttendance";
import Attendance from "helpers/Employee_Management/Attendance";

//Salary Management
import SalaryProfile from "helpers/Salary_Management/SalaryProfile";
import SalaryDisbursement from "helpers/Salary_Management/SalaryDisbursement";

//Medical
import MedicalRequests from "helpers/Medical/MedicalRequests";

//Asset Management
import AssetManagementList from "helpers/AssetManagement/AssetManagementList";
import EmployeeAssets from "helpers/AssetManagement/EmployeeAssets";
import ProductTransferHistory from "helpers/AssetManagement/ProductTransferHistory";
import AssetCategory from "helpers/AssetManagement/AssetCategory";
import DemandMaster from "helpers/AssetManagement/DemandMaster";
import ServicesMaster from "helpers/AssetManagement/ServicesMaster";
import ServicesDetails from "helpers/AssetManagement/ServicesDetails";
import DemandDetails from "helpers/AssetManagement/DemandDetails";

//Account
import Currency from "helpers/Account/Currency";
import Tax from "helpers/Account/Tax";
import CurrencyRate from "helpers/Account/CurrencyRate";

//Client Profiles
import Company from "helpers/ClientProfiles/Company";
import Client from "helpers/ClientProfiles/Client";
import Project from "helpers/ClientProfiles/Project";

const get_department_data = async (page, rows) => {
  try {
    const responseee = await Department.Get_Department_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_designation_data = async (page, rows) => {
  try {
    const responseee = await Designation.Get_Designation_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_gender_data = async (page, rows) => {
  try {
    const responseee = await Gender.Get_Gender_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_leaveType_data = async (page, rows) => {
  try {
    const responseee = await LeaveType.Get_LeaveType_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_head_category_data = async (page, rows) => {
  try {
    const responseee = await HeadCategory.Get_HeadCategory_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_salary_head_data = async (page, rows) => {
  try {
    const responseee = await SalaryHeads.Get_SalaryHeads_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_country_data = async (page, rows) => {
  try {
    const responseee = await Country.Get_Country_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_state_data = async (page, rows) => {
  try {
    const responseee = await State.Get_State_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_city_data = async (page, rows) => {
  try {
    const responseee = await City.Get_City_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_contractType_data = async (page, rows) => {
  try {
    const responseee = await ContractType.Get_ContractType_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_degree_data = async (page, rows) => {
  try {
    const responseee = await Degree.Get_Degree_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_days_data = async (page, rows) => {
  try {
    const responseee = await Days.Get_Days_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_bloodgroup_data = async (page, rows) => {
  try {
    const responseee = await BloodGroup.Get_BloodGroup_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_relationship_data = async (page, rows) => {
  try {
    const responseee = await Relationship.Get_Relationship_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_medical_procedure_data = async (page, rows) => {
  try {
    const responseee =
      await MedicalProcedure.Get_Medical_Procedure_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_employee_family_data = async (page, rows, EmpID) => {
  try {
    const body = {
      PageNumber: page,
      PageSize: rows,
      EmployeeID: EmpID,
    };
    const responseee = await EmployeeFamily.Get_Employee_Family_List(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_branch_data = async (page, rows) => {
  try {
    const responseee = await Branch.Get_Branch_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_attendanceType_data = async (page, rows) => {
  try {
    const responseee = await AttendanceType.Get_AttendanceType_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_approvalstatus_data = async (page, rows) => {
  try {
    const responseee = await ApprovalStatus.Get_ApprovalStatus_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_insurancePlan_data = async (page, rows) => {
  try {
    const responseee = await InsurancePlan.Get_InsurancePlans_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_mappingDepartmentDesignation_data = async (page, rows) => {
  try {
    const responseee =
      await MappingDepartmentWithDesignation.Get_MappingDepartmentDesignation_List_By_Rows(
        page,
        rows
      );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_branchHoliday_data = async (page, rows) => {
  try {
    const responseee = await BranchHoliday.Get_BranchHoliday_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_calendar_data = async (page, rows) => {
  try {
    const responseee = await Calendar.Get_Calendar_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_role_data = async (page, rows) => {
  try {
    const responseee = await Role.Get_Role_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_function_data = async (page, rows) => {
  try {
    const responseee = await Function.Get_Function_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Profile Management------------------------------------------------------------------------
const get_menu_data = async (page, rows) => {
  try {
    const responseee = await Menu.Get_Menu_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_subMenu_data = async (page, rows) => {
  try {
    const responseee = await SubMenu.Get_SubMenu_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_profile_data = async (page, rows) => {
  try {
    const responseee = await Profile.Get_Profile_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Shift Management------------------------------------------------------------------------
const get_shift_data = async (page, rows) => {
  try {
    const responseee = await Shift.Get_Shift_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_shiftOffDay_data = async (page, rows) => {
  try {
    const responseee = await ShiftOffDay.Get_ShiftOffDay_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//EmployeeLeave Management------------------------------------------------------------------------
const get_EmployeeLeave_data = async (
  currentPage,
  totalRows,
  EmployeeId,
  Date_From,
  Date_To
) => {
  try {
    const body = {
      PageNumber: currentPage,
      PageSize: totalRows,
      Employee_Id: EmployeeId,
      From_Date: Date_From,
      To_Date: Date_To,
    };
    const responseee = await EmployeeLeave.Get_EmployeeLeave_List(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_HREmployeeAttendance_data = async (
  currentPage,
  totalRows,
  EmployeeId,
  SelectedDepartment,
  Date_From,
  Date_To
) => {
  try {
    let body;
    if (EmployeeId !== null) {
      body = {
        PageNumber: currentPage,
        PageSize: totalRows,
        Employee_Id: EmployeeId,
        Department_Id: SelectedDepartment,
        From_Date: Date_From,
        To_Date: Date_To,
      };
    } else {
      body = {
        PageNumber: currentPage,
        PageSize: totalRows,
        Department_Id: SelectedDepartment,
        From_Date: Date_From,
        To_Date: Date_To,
      };
    }

    const responseee = await HREmployeeAttendance.Get_EmployeeAttendance_List(
      body
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_EmployeeList_data = async (
  currentPage,
  totalRows,
  EmployeeListBranchId,
  EmployeeListDepartmentId,
  EmployeeListIsAcitve,
  EmployeeListSearchScreen
) => {
  try {
    let body = {
      PageNumber: currentPage,
      PageSize: totalRows,
      BranchId: EmployeeListBranchId,
      Department: EmployeeListDepartmentId,
      IsActive: EmployeeListIsAcitve,
      Emp_Payrol: EmployeeListSearchScreen,
    };
    const responseee = await Employee.Get_Employee_List_By_Rows(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_Attendance_data = async (page, rows) => {
  try {
    const responseee = await Attendance.Get_EmployeeAttendance_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Salary Management--------------------------------------------
const get_salaryprofile_data = async (page, rows) => {
  try {
    const responseee = await SalaryProfile.Get_SalaryProfile_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const get_salaryDisbursement_data = async (page, rows) => {
  try {
    const responseee =
      await SalaryDisbursement.Get_SalaryDisbursement_List_By_Rows(page, rows);
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Medical Request--------------------------------------------
const get_medical_requests_data = async (
  page,
  rows,
  employeeId,
  Date_From,
  Date_To
) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
      Employee_Id: employeeId,
      From_Date: Date_From,
      To_Date: Date_To,
    };
    const response = await MedicalRequests.Get_Medical_Request_List(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Asset Management--------------------------------------------
const get_asset_management_list_data = async (page, rows, employeeId) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
      Employee_Id: employeeId,
    };
    const response = await AssetManagementList.Get_Asset_Management_List(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_employee_assets_data = async (page, rows, employeeId) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
      Employee_Id: employeeId,
    };
    const response = await EmployeeAssets.Get_Employee_Assets_List(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_product_transfer_history_data = async (page, rows, employeeId) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
      Employee_Id: employeeId,
    };
    const response =
      await ProductTransferHistory.Get_Product_Transfer_History_List(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_AssetCategory_data = async (page, rows) => {
  try {
    const responseee = await AssetCategory.Get_AssetCategory_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_DemandMaster_data = async (page, rows) => {
  try {
    const responseee = await DemandMaster.Get_DemandMaster_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_ServicesMaster_data = async (page, rows) => {
  try {
    const responseee = await ServicesMaster.Get_ServicesMaster_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_ServicesDetails_data = async (page, rows) => {
  try {
    const responseee = await ServicesDetails.Get_ServicesDetails_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const get_DemandDetails_data = async (page, rows) => {
  try {
    const responseee = await DemandDetails.Get_DemandDetails_List_By_Rows(
      page,
      rows
    );
    return responseee;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Account--------------------------------------------
const get_currency_list_data = async (page, rows) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
    };
    const response = await Currency.Get_Currency_List_By_Rows(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const get_tax_list_data = async (page, rows) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
    };
    const response = await Tax.Get_Tax_List_By_Rows(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const get_currency_rate_data = async (page, rows) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
    };
    const response = await CurrencyRate.Get_CurrencyRate_List_By_Rows(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//Client Profiles------------------------------------
const get_company_data = async (page, rows) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
    };
    const response = await Company.Get_Company_List_By_Rows(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const get_client_data = async (page, rows) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
    };
    const response = await Client.Get_Client_List_By_Rows(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const get_project_data = async (page, rows) => {
  try {
    let body = {
      PageNumber: page,
      PageSize: rows,
    };
    const response = await Project.Get_Project_List_By_Rows(body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const tableData = async (branch, page, rows) => {
  let save_response = null;

  switch (branch) {
    case "crudTbDepartment": {
      save_response = await get_department_data(page, rows);
      break;
    }
    case "crudTbDesignation": {
      save_response = await get_designation_data(page, rows);
      break;
    }
    case "crudTbGender": {
      save_response = await get_gender_data(page, rows);
      break;
    }
    case "crudTbLeaveType": {
      save_response = await get_leaveType_data(page, rows);
      break;
    }
    case "crudTbHeadCategory": {
      save_response = await get_head_category_data(page, rows);
      break;
    }
    case "crudTbSalaryHeads": {
      save_response = await get_salary_head_data(page, rows);
      break;
    }
    case "crudTbCountry": {
      save_response = await get_country_data(page, rows);
      break;
    }
    case "crudTbStates": {
      save_response = await get_state_data(page, rows);
      break;
    }
    case "crudTbCity": {
      save_response = await get_city_data(page, rows);
      break;
    }
    case "crudTbContractType": {
      save_response = await get_contractType_data(page, rows);
      break;
    }
    case "crudTbDegree": {
      save_response = await get_degree_data(page, rows);
      break;
    }
    case "crudTbDays": {
      save_response = await get_days_data(page, rows);
      break;
    }
    case "crudTbBloodGroup": {
      save_response = await get_bloodgroup_data(page, rows);
      break;
    }
    case "crudTbRelationship": {
      save_response = await get_relationship_data(page, rows);
      break;
    }
    case "crudTbMedicalProcedure": {
      save_response = await get_medical_procedure_data(page, rows);
      break;
    }
    case "crudTbEmployeeFamily": {
      save_response = await get_employee_family_data(page, rows);
      break;
    }
    case "crudTbBranch": {
      save_response = await get_branch_data(page, rows);
      break;
    }
    case "crudTbAttendanceType": {
      save_response = await get_attendanceType_data(page, rows);
      break;
    }
    case "crudTbApprovalStatus": {
      save_response = await get_approvalstatus_data(page, rows);
      break;
    }
    case "crudTbInsurancePlan": {
      save_response = await get_insurancePlan_data(page, rows);
      break;
    }
    case "crudTbMappingDepartmentWithDesignation": {
      save_response = await get_mappingDepartmentDesignation_data(page, rows);
      break;
    }
    case "crudTbBranchHoliday": {
      save_response = await get_branchHoliday_data(page, rows);
      break;
    }
    case "crudTbCalendar": {
      save_response = await get_calendar_data(page, rows);
      break;
    }
    case "crudTbRole": {
      save_response = await get_role_data(page, rows);
      break;
    }
    case "crudTbFunction": {
      save_response = await get_function_data(page, rows);
      break;
    }

    //Profile Management------------------------------------
    case "crudTbMenu": {
      save_response = await get_menu_data(page, rows);
      break;
    }
    case "crudTbSubMenu": {
      save_response = await get_subMenu_data(page, rows);
      break;
    }
    case "crudTbProfile": {
      save_response = await get_profile_data(page, rows);
      break;
    }

    //Shift Management------------------------------------
    case "crudTbShift": {
      save_response = await get_shift_data(page, rows);
      break;
    }
    case "crudTbShiftOffDay": {
      save_response = await get_shiftOffDay_data(page, rows);
      break;
    }

    //EmployeeLeave Management------------------------------------
    // case "crudTbEmployeeLeave": {
    //   save_response = await get_EmployeeLeave_data(page, rows);
    //   break;
    // }
    case "crudTbEmployeeList": {
      save_response = await get_EmployeeList_data(
        page,
        rows,
        EmployeeListBranchId,
        EmployeeListDepartmentId,
        EmployeeListIsAcitve,
        EmployeeListSearchString
      );
      break;
    }
    // case "crudTbHREmployeeAttendance": {
    //   save_response = await get_HREmployeeAttendance_data(page, rows);
    //   break;
    // }
    case "crudTbAttendance": {
      save_response = await get_Attendance_data(page, rows);
      break;
    }

    //Salary Management---------------------------------------
    case "crudTbSalaryProfileList": {
      save_response = await get_salaryprofile_data(page, rows);
      break;
    }
    case "crudTbSalaryDisbursement": {
      save_response = await get_salaryDisbursement_data(page, rows);
      break;
    }

    //Asset Management---------------------------------------
    case "crudTbAssetCategory": {
      save_response = await get_AssetCategory_data(page, rows);
      break;
    }
    case "crudTbDemandMaster": {
      save_response = await get_DemandMaster_data(page, rows);
      break;
    }
    case "crudTbServicesMaster": {
      save_response = await get_ServicesMaster_data(page, rows);
      break;
    }
    case "crudTbServicesDetails": {
      save_response = await get_ServicesDetails_data(page, rows);
      break;
    }
    case "crudTbDemandDetails": {
      save_response = await get_DemandDetails_data(page, rows);
      break;
    }

    //Profile Management------------------------------------
    case "crudTbCurrency": {
      save_response = await get_currency_list_data(page, rows);
      break;
    }
    case "crudTbTax": {
      save_response = await get_tax_list_data(page, rows);
      break;
    }
    case "crudTbCurrencyRate": {
      save_response = await get_currency_rate_data(page, rows);
      break;
    }
    //Clinet Profiles------------------------------------
    case "crudTbCompany": {
      save_response = await get_company_data(item, user);
      break;
    }
    case "crudTbClient": {
      save_response = await get_client_data(item, user);
      break;
    }
    case "crudTbProject": {
      save_response = await get_project_data(item, user);
      break;
    }
    default:
      break;
  }

  return save_response;
};

export default {
  tableData,
  get_EmployeeLeave_data,
  get_HREmployeeAttendance_data,
  get_EmployeeList_data,
  get_medical_requests_data,
  get_employee_family_data,
  get_asset_management_list_data,
  get_employee_assets_data,
  get_product_transfer_history_data,
};
