import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _EmployeeFamily from "helpers/MasterData_Management/EmployeeFamily";
import _Relationship from "helpers/MasterData_Management/Relationship";
import _Department from "helpers/MasterData_Management/Department";
import _Employee from "helpers/Employee_Management/Employee";
import { RotatingLines } from "react-loader-spinner";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogPopup from "components/Common/DialogPopup";
import Loading from "components/Loading";
import FamilyRelationTable from "./FamilyRelationTable";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
  removeItem,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch, SelectedEmployee, SelectedEmployeeName } = props;
  // const relationBranch = "crudTbFamilyRelations";

  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [IsDependentCheck, setIsDependentCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [RelationList, setRelationList] = useState(null);
  // const [DepartmentList, setDepartmentList] = useState(null);
  // const [EmployeeList, setEmployeeList] = useState(null);
  // const [FamilyRelationList, setFamilyRelationList] = useState([]);
  // const [SelectedEmployee, setSelectedEmployee] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  // const [isSpinnerLoaderEmployee, setisSpinnerLoaderEmployee] = useState(false);

  const formOpen = useSelector(state => state.crudTbEmployeeFamily.formOpen);
  const EmployeeFamilyList = useSelector(
    state => state.crudTbEmployeeFamily.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbEmployeeFamily.formValues
  );
  // const FamilyRelationDataTable = useSelector(
  //   state => state.crudTbFamilyRelations.dataTable
  // );
  // const popupMessage = useSelector(
  //   state => state.crudTbFamilyRelations.popupMessage
  // );
  const user = useSelector(state => state.Login.User);

  useEffect(() => {
    // FamilyRelationDataTable !== null &&
    //   FamilyRelationDataTable.map(item => {
    //     dispatch(removeItem(item, relationBranch));
    //   });
    if (formValues != null) {
      // GetEmployeeById(formValues.Employee_Id);
      const index = EmployeeFamilyList.findIndex(
        item => item.Emp_Fam_Id == formValues.Emp_Fam_Id
      );
      setIsEdit(true);
      setInitialFormData(EmployeeFamilyList[index]);
      setIsActiveCheck(EmployeeFamilyList[index].IsActive);
      setIsDependentCheck(EmployeeFamilyList[index].IsDependent);
    } else {
      setIsEdit(false);
    }
  }, [formOpen]);

  // const GetEmployeeById = async value => {
  //   try {
  //     const response = await _EmployeeFamily.Get_Employee_Family_By_Id(value);
  //     const data = response.Data.Details;
  //     setIsEdit(true);
  //     setInitialFormData(data);
  //     if (data.RelationShip.length > 0) {
  //       data.RelationShip.map(item => {
  //         const newObj = {
  //           Value: item.relationship_Id,
  //           Text: item.relationship_name,
  //         };
  //         dispatch(addNewItem(newObj, relationBranch));
  //       });
  //     }
  //     onDepartmentChange(data.Department_Id);
  //     setIsActiveCheck(true);
  //     setIsDependentCheck(data.IsDependent);
  //   } catch (error) {
  //     dispatch(popupMessage("Ops! Server is down.", branch));
  //     console.log(error);
  //     return;
  //   }
  // };
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  // useEffect(() => {
  //   setFamilyRelationList(FamilyRelationDataTable);
  // }, [FamilyRelationDataTable]);

  const initialData = async () => {
    try {
      setisLoading(true);
      const relation = await _Relationship.Get_Relationship_DD();
      setRelationList(relation.Data.Details);

      // const department = await _Department.Get_Department_DD();
      // setDepartmentList(department.Data.Details);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  // const onDepartmentChange = async value => {
  //   setisSpinnerLoaderEmployee(true);
  //   const employees = await _Employee.Get_Employee_By_Department_DD(value);
  //   setEmployeeList(employees.Data.Details);
  //   setisSpinnerLoaderEmployee(false);
  // };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // Department_Id: isEdit ? InitialFormData.Department_Id : "",
      // Employee_Id: isEdit ? InitialFormData.Employee_Id : "",
      Employee_Family_name: isEdit ? InitialFormData.Employee_Family_Name : "",
      Relationship_Id: isEdit ? InitialFormData.Relationship_Id : "",
      IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      // Department_Id: Yup.string()
      //   .required("Please Select a Department")
      //   .min(1, "Please Select a Department"),
      // Employee_Id: Yup.string()
      //   .required("Please Select a Employee")
      //   .min(1, "Please Select a Employee"),
      Employee_Family_name: Yup.string().required(
        "Please Enter Employee Family Name"
      ),
      Relationship_Id: Yup.string()
        .required("Please Select a Relation")
        .min(1, "Please Select a Relation"),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        // const RelationArray = [];
        // FamilyRelationDataTable !== null &&
        //   FamilyRelationDataTable.map(item => {
        //     RelationArray.push(item.Value);
        //   });
        let body = {
          Emp_Fam_Id: InitialFormData.Emp_Fam_Id,
          Employee_Family_name: values.Employee_Family_name,
          Employee_Id: InitialFormData.Employee_Id,
          Relationship_Id: values.Relationship_Id,
          IsDependent: IsDependentCheck,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _EmployeeFamily.Edit_Employee_Family(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      } else {
        //While Adding new Data
        // const RelationArray = [];
        // FamilyRelationDataTable !== null &&
        //   FamilyRelationDataTable.map(item => {
        //     RelationArray.push(item.Value);
        //   });
        let body = {
          Emp_Fam_Id: 0,
          Employee_Family_name: values.Employee_Family_name,
          Employee_Id:
            SelectedEmployee !== null ? SelectedEmployee : user.Employee_Id,
          Relationship_Id: values.Relationship_Id,
          IsDependent: IsDependentCheck,
          IsActive: IsActiveCheck,
          User: user.Employee_Id,
        };
        const response = await _EmployeeFamily.Save_Employee_Family(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data.Details[0], branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        } else {
          console.log(response.Status_Message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.Status_Message, branch));
        }
      }
      toggle();
    },
  });

  // const handleRelationChange = value => {
  //   const index = RelationList.findIndex(item => {
  //     return item.Value == value;
  //   });
  //   let newObj;
  //   if (index !== -1) {
  //     newObj = RelationList[index];
  //   }
  //   dispatch(addNewItem(newObj, relationBranch));
  // };

  const renderRelation = () =>
    RelationList.map((item, index) => (
      <option key={index} value={item.Value}>
        {item.Text}
      </option>
    ));

  // const renderDepartment = () =>
  //   DepartmentList.map((item, index) => (
  //     <option key={index} value={item.Value}>
  //       {item.Text}
  //     </option>
  //   ));

  // const renderEmployee = () =>
  //   EmployeeList.map((item, index) => (
  //     <option key={index} value={item.Value}>
  //       {item.Text}
  //     </option>
  //   ));

  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit Employee Family" : "Add Employee Family"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            {isEdit == false ? (
              <>
                {/* <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Department
                    </Label>
                    <Input
                      type="select"
                      name="Department_Id"
                      onChange={e => {
                        validation.handleChange(e);
                        onDepartmentChange(e.target.value);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.Department_Id || ""}
                      invalid={
                        validation.touched.Department_Id &&
                        validation.errors.Department_Id
                          ? true
                          : false
                      }
                    >
                      <option key={1} value={0}>
                        Select
                      </option>
                      {DepartmentList == null || DepartmentList == undefined
                        ? ""
                        : renderDepartment()}
                    </Input>
                    {validation.touched.Department_Id &&
                    validation.errors.Department_Id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Department_Id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Employee
                      <span style={{ paddingTop: 10, marginLeft: 10 }}>
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="15"
                          visible={isSpinnerLoaderEmployee}
                        />
                      </span>
                    </Label>
                    <Input
                      type="select"
                      name="Employee_Id"
                      disabled={isSpinnerLoaderEmployee}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.Employee_Id || ""}
                      invalid={
                        validation.touched.Employee_Id &&
                        validation.errors.Employee_Id
                          ? true
                          : false
                      }
                    >
                      <option key={1} value={0}>
                        Select
                      </option>
                      {EmployeeList == null || EmployeeList == undefined
                        ? ""
                        : renderEmployee()}
                    </Input>
                    {validation.touched.Employee_Id &&
                    validation.errors.Employee_Id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.Employee_Id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col> */}
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">
                      <span style={{ color: "red" }}>* </span>Employee Name
                    </Label>
                    <Input
                      type="text"
                      name="Employee_Name"
                      disabled
                      value={
                        SelectedEmployeeName !== null
                          ? SelectedEmployeeName
                          : user.First_Name +
                            " " +
                            user.Last_Name +
                            " - " +
                            user.Employee_Code
                      }
                    />
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Employee Family Name
                </Label>
                <Input
                  name="Employee_Family_name"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphabetic(e)}
                  // value={isEdit ? InitialFormData.Employee_Family_name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Employee_Family_name || ""}
                  invalid={
                    validation.touched.Employee_Family_name &&
                    validation.errors.Employee_Family_name
                      ? true
                      : false
                  }
                />
                {validation.touched.Employee_Family_name &&
                validation.errors.Employee_Family_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Employee_Family_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Relation
                </Label>
                <Input
                  type="select"
                  name="Relationship_Id"
                  onChange={e => {
                    validation.handleChange(e);
                    // handleRelationChange(e.target.value);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.Relationship_Id || ""}
                  invalid={
                    validation.touched.Relationship_Id &&
                    validation.errors.Relationship_Id
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {RelationList == null || RelationList == undefined
                    ? ""
                    : renderRelation()}
                </Input>
                {validation.touched.Relationship_Id &&
                validation.errors.Relationship_Id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.Relationship_Id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {/* <Col lg="12">
              <FamilyRelationTable
                DataList={FamilyRelationList}
                branch={relationBranch}
              />
            </Col> */}
            <Col className="col-12">
              <FormControlLabel
                control={
                  <Switch
                    name="IsDependent"
                    onChange={e => setIsDependentCheck(e.target.checked)}
                    checked={IsDependentCheck}
                    color="primary"
                  />
                }
                label="Is Dependent"
              />
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>

      {/* DialogPopup */}
      {/* <DialogPopup branch={branch} message={popupMessage} /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
