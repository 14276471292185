import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Favorite from "@material-ui/icons/Favorite";
import NaturePeople from "@material-ui/icons/NaturePeople";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import About from "components/Profile/About";
import Education_EmployerRecord from "components/Profile/Education_EmployerRecord";
import Connection from "components/Profile/Connection";
import UserFunction from "components/Profile/UserFunction";
import Loading from "components/Loading";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Employee from "helpers/Employee_Management/Employee";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

function TabContainer(props) {
  const { children } = props;
  return <div style={{ paddingTop: 8 * 3 }}>{children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const UserProfile = () => {
  //meta title
  document.title = "HR - User Profile";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [value, setValue] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [UserData, setUserData] = useState(null);

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const User = useSelector(state => state.Login.User);
  const isCopied = useSelector(state => state.Dashboard.isCopied);

  const getInitialData = async () => {
    setisLoading(true);
    const response = await Employee.Get_Employee_By_Id(User.Employee_Id);
    if (response.IsSuccess === true && response.Status_Code === 200) {
      let data = response.Data.Details;
      data = {
        ...data,
        Subordinates: [],
        Employer_Information: [],
      };
      setUserData(data);
      setisLoading(false);
    } else {
      console.log("Error from User Login API");
      setisLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const handleChange = (event, val) => {
    setValue(val);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="HR" breadcrumbItem="Profile" /> */}

          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody style={{ backgroundColor: "#2a3042f0", height: 200 }}>
                  <div className="d-flex">
                    <div style={{ margin: "auto", marginTop: 15 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt={name}
                          src={avatar}
                          className="avatar-lg rounded-circle"
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "30%",
                            height: "30%",
                          }}
                        />
                      </div>
                      <Typography variant="h4" gutterBottom>
                        {name}
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "inherit",
                            color: "white",
                          }}
                        >
                          {UserData !== null ? UserData.Employee.Full_Name : ""}
                        </span>
                      </Typography>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <AppBar position="static" style={{ backgroundColor: "#004a8d" }}>
            <Hidden mdUp>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab icon={<AccountCircle />} />
                <Tab icon={<SupervisorAccount />} />
                <Tab icon={<Favorite />} />
                <Tab icon={<PhotoLibrary />} />
              </Tabs>
            </Hidden>
            <Hidden smDown>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="inherit"
                centered
              >
                <Tab icon={<AccountCircle />} label="ABOUT" />
                <Tab
                  icon={<SupervisorAccount />}
                  label={
                    UserData !== null
                      ? UserData.Employee_Subordinates.length + " SUBORDINATES"
                      : "0 SUBORDINATES"
                  }
                />

                <Tab
                  icon={<NaturePeople />}
                  label="EDUCATION & PREV. EMPLOYER"
                />
                <Tab icon={<PhotoLibrary />} label="FUNCTION" />
              </Tabs>
            </Hidden>
          </AppBar>
          {UserData !== null && (
            <>
              {value === 0 && (
                <TabContainer>
                  {UserData !== null ? (
                    <About
                      dataa={UserData.Employee}
                      User_Photo={UserData.Employee_Pic}
                    />
                  ) : (
                    ""
                  )}
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  {UserData !== null ? (
                    <Connection Connections={UserData.Employee_Subordinates} />
                  ) : (
                    ""
                  )}
                </TabContainer>
              )}
              {value === 2 && (
                // <TabContainer>
                //   <Favorites />
                // </TabContainer>
                <TabContainer>
                  {UserData !== null ? (
                    <Education_EmployerRecord
                      AcedamicData={UserData.Employee_Academics}
                      EmployerData={UserData.Employee_Record}
                    />
                  ) : (
                    ""
                  )}
                </TabContainer>
              )}
              {value === 3 && (
                // <TabContainer>
                //   <Albums />
                // </TabContainer>
                <TabContainer>
                  {UserData !== null ? (
                    <UserFunction data={UserData.Employee_Functions} />
                  ) : (
                    ""
                  )}
                </TabContainer>
              )}
            </>
          )}
        </Container>
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
        {isCopied ? (
          <div
            style={{
              position: "fixed",
              bottom: 20,
              left: "50%",
              backgroundColor: "black",
              // width: 200,
              marginBottom: 0,
              textAlign: "center",
              color: "white",
              zIndex: 999,
              // height: 30,
              padding: "10px 10px 0px 10px",
            }}
          >
            <p>Text copied to clipboard.</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
