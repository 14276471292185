import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
//Dashboard
import Dashboard from "./dashboard/reducer";
//Calendar
import calendar from "./calendar/reducer";

//Master Data Management
import CrudTbDepartment from "./MasterData_Management/Department/reducer";
import crudTbDesignation from "./MasterData_Management/Designation/reducer";
import crudTbGender from "./MasterData_Management/Gender/reducer";
import crudTbLeaveType from "./MasterData_Management/LeaveType/reducer";
import crudTbHeadCategory from "./MasterData_Management/HeadCategory/reducer";
import crudTbSalaryHeads from "./MasterData_Management/SalaryHeads/reducer";
import crudTbCountry from "./MasterData_Management/Country/reducer";
import crudTbStates from "./MasterData_Management/State/reducer";
import crudTbCity from "./MasterData_Management/City/reducer";
import crudTbContractType from "./MasterData_Management/ContractType/reducer";
import crudTbDegree from "./MasterData_Management/Degree/reducer";
import crudTbDays from "./MasterData_Management/Days/reducer";
import crudTbBloodGroup from "./MasterData_Management/BloodGroup/reducer";
import crudTbRelationship from "./MasterData_Management/Relationship/reducer";
import crudTbMedicalProcedure from "./MasterData_Management/MedicalProcedure/reducer";
import crudTbEmployeeFamily from "./MasterData_Management/EmployeeFamily/reducer";
import crudTbFamilyRelations from "./MasterData_Management/EmployeeFamily/FamilyRelationReducer";
import crudTbBranch from "./MasterData_Management/Branch/reducer";
import crudTbAttendanceType from "./MasterData_Management/AttendanceType/reducer";
import crudTbApprovalStatus from "./MasterData_Management/ApprovalStatus/reducer";
import crudTbInsurancePlan from "./MasterData_Management/InsurancePlan/reducer";
import crudTbMappingDepartmentWithDesignation from "./MasterData_Management/MappingDepartmentWithDesignation/reducer";
import crudTbBranchHoliday from "./MasterData_Management/BranchHoliday/reducer";
import crudTbCalendar from "./MasterData_Management/Calendar/reducer";
import crudTbRole from "./MasterData_Management/Role/reducer";
import crudTbFunction from "./MasterData_Management/Function/reducer";

//Profile Management-----------------------------------
import crudTbMenu from "./Profile_Management/Menu/reducer";
import crudTbSubMenu from "./Profile_Management/SubMenu/reducer";
import crudTbProfile from "./Profile_Management/Profile/reducer";

//Shift Management-----------------------------------
import crudTbShift from "./Shift_Management/Shift/reducer";
import crudTbShiftOffDay from "./Shift_Management/ShiftOffDay/reducer";

//Employee Management-----------------------------------
import crudTbEmployeeList from "./Employee_Management/Employee_List/reducer";
import crudTbEmployee from "./Employee_Management/Employee_Crud/reducer";
import crudTbEmployeeAcademicInformation from "./Employee_Management/Employee_AcademicInformation/reducer";
import crudTbEmployeeFunctionInformation from "./Employee_Management/Employee_FunctionInformation/reducer";
import crudTbEmployeeEmployerRecord from "./Employee_Management/Employer_Record/reducer";
import crudTbHREmployeeAttendance from "./Employee_Management/HREmployeeAttendance/reducer";
import crudTbAttendanceReport from "./Employee_Management/AttendanceReport/reducer";
import crudTbLeaveReport from "./Employee_Management/LeaveReport/reducer";
import crudTbAttendance from "./Employee_Management/Attendance/reducer";
import crudTbEmployeeLeave from "./Employee_Management/EmployeeLeave/reducer";
import crudTbDailyAttendance from "./Employee_Management/DailyAttendance/reducer";
import crudTbEmployeeBulkUploader from "./Employee_Management/EmployeeBulkUploader/reducer";

//Salary Management-------------------------------------
import crudTbSalaryProfileList from "./Salary_Management/SalaryProfile/reducer";
import crudTbSalaryDetail from "./Salary_Management/SalaryDetail/reducer";
import crudTbSalaryProfile from "./Salary_Management/SalaryProfile/reducer";
import crudTbSalaryDisbursement from "./Salary_Management/SalaryDisbursement/reducer";

//Account Management-------------------------------------
import crudTbLedgerHead from "./Account/LedgerHead/reducer";
import crudTbPaymentMode from "./Account/PaymentMode/reducer";
import crudTbTransaction from "./Account/Transaction/reducer";
import crudTbTax from "./Account/Tax/reducer";
import crudTbCurrency from "./Account/Currency/reducer";
import crudTbCurrencyRate from "./Account/CurrencyRate/reducer";
import crudTbNewCurrencyRate from "./Account/NewCurrencyRate/reducer";

//Medical -------------------------------------
import crudTbMedicalRequests from "./Medical/MedicalRequests/reducer";
import crudTbMedicalReport from "./Medical/MedicalReport/reducer";
import crudTbNewMedicalRequests from "./Medical/NewMedicalRequests/reducer";

//Asset Management --------------------------------
import crudTbAssetManagementList from "./AssetManagement/AssetManagementList/reducer";
import crudTbEmployeeAssets from "./AssetManagement/EmployeeAssets/reducer";
import crudTbProductTransferHistory from "./AssetManagement/ProductTransferHistory/reducer";
import crudTbAssetCategory from "./AssetManagement/AssetCategory/reducer";
import crudTbDemandMaster from "./AssetManagement/DemandMaster/reducer";
import crudTbServicesMaster from "./AssetManagement/ServicesMaster/reducer";
import crudTbServicesDetails from "./AssetManagement/ServicesDetails/reducer";
import crudTbDemandDetails from "./AssetManagement/DemandDetails/reducer";

//Client Profiles-----------------------------------
import crudTbCompany from "./ClientProfiles/Company/reducer";
import crudTbClient from "./ClientProfiles/Client/reducer";
import crudTbProject from "./ClientProfiles/Project/reducer";

/**
 * Branching reducers to use one reducer for many components
 */

function branchReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { branch } = action;
    const isInitializationCall = state === undefined;
    if (branch !== reducerName && !isInitializationCall) {
      return state;
    }
    return reducerFunction(state, action);
  };
}

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  calendar,
  Profile,
  Dashboard,
  //Master Data
  crudTbDepartment: branchReducer(CrudTbDepartment, "crudTbDepartment"),
  crudTbDesignation: branchReducer(crudTbDesignation, "crudTbDesignation"),
  crudTbGender: branchReducer(crudTbGender, "crudTbGender"),
  crudTbLeaveType: branchReducer(crudTbLeaveType, "crudTbLeaveType"),
  crudTbHeadCategory: branchReducer(crudTbHeadCategory, "crudTbHeadCategory"),
  crudTbSalaryHeads: branchReducer(crudTbSalaryHeads, "crudTbSalaryHeads"),
  crudTbCountry: branchReducer(crudTbCountry, "crudTbCountry"),
  crudTbStates: branchReducer(crudTbStates, "crudTbStates"),
  crudTbCity: branchReducer(crudTbCity, "crudTbCity"),
  crudTbContractType: branchReducer(crudTbContractType, "crudTbContractType"),
  crudTbDegree: branchReducer(crudTbDegree, "crudTbDegree"),
  crudTbDays: branchReducer(crudTbDays, "crudTbDays"),
  crudTbBloodGroup: branchReducer(crudTbBloodGroup, "crudTbBloodGroup"),
  crudTbRelationship: branchReducer(crudTbRelationship, "crudTbRelationship"),
  crudTbMedicalProcedure: branchReducer(
    crudTbMedicalProcedure,
    "crudTbMedicalProcedure"
  ),
  crudTbEmployeeFamily: branchReducer(
    crudTbEmployeeFamily,
    "crudTbEmployeeFamily"
  ),
  crudTbFamilyRelations: branchReducer(
    crudTbFamilyRelations,
    "crudTbFamilyRelations"
  ),
  crudTbBranch: branchReducer(crudTbBranch, "crudTbBranch"),
  crudTbAttendanceType: branchReducer(
    crudTbAttendanceType,
    "crudTbAttendanceType"
  ),
  crudTbApprovalStatus: branchReducer(
    crudTbApprovalStatus,
    "crudTbApprovalStatus"
  ),
  crudTbInsurancePlan: branchReducer(
    crudTbInsurancePlan,
    "crudTbInsurancePlan"
  ),
  crudTbMappingDepartmentWithDesignation: branchReducer(
    crudTbMappingDepartmentWithDesignation,
    "crudTbMappingDepartmentWithDesignation"
  ),
  crudTbBranchHoliday: branchReducer(
    crudTbBranchHoliday,
    "crudTbBranchHoliday"
  ),
  crudTbCalendar: branchReducer(crudTbCalendar, "crudTbCalendar"),
  crudTbRole: branchReducer(crudTbRole, "crudTbRole"),
  crudTbFunction: branchReducer(crudTbFunction, "crudTbFunction"),

  //Profile Management-----------------
  crudTbMenu: branchReducer(crudTbMenu, "crudTbMenu"),
  crudTbSubMenu: branchReducer(crudTbSubMenu, "crudTbSubMenu"),
  crudTbProfile: branchReducer(crudTbProfile, "crudTbProfile"),

  //Shift Management--------------------
  crudTbShift: branchReducer(crudTbShift, "crudTbShift"),
  crudTbShiftOffDay: branchReducer(crudTbShiftOffDay, "crudTbShiftOffDay"),

  //Employee Management-----------------
  crudTbEmployeeList: branchReducer(crudTbEmployeeList, "crudTbEmployeeList"),
  crudTbEmployee: branchReducer(crudTbEmployee, "crudTbEmployee"),
  crudTbDailyAttendance: branchReducer(
    crudTbDailyAttendance,
    "crudTbDailyAttendance"
  ),
  crudTbEmployeeBulkUploader: branchReducer(
    crudTbEmployeeBulkUploader,
    "crudTbEmployeeBulkUploader"
  ),
  crudTbEmployeeAcademicInformation: branchReducer(
    crudTbEmployeeAcademicInformation,
    "crudTbEmployeeAcademicInformation"
  ),
  crudTbEmployeeFunctionInformation: branchReducer(
    crudTbEmployeeFunctionInformation,
    "crudTbEmployeeFunctionInformation"
  ),
  crudTbEmployeeEmployerRecord: branchReducer(
    crudTbEmployeeEmployerRecord,
    "crudTbEmployeeEmployerRecord"
  ),
  crudTbHREmployeeAttendance: branchReducer(
    crudTbHREmployeeAttendance,
    "crudTbHREmployeeAttendance"
  ),
  crudTbAttendance: branchReducer(crudTbAttendance, "crudTbAttendance"),
  crudTbAttendanceReport: branchReducer(
    crudTbAttendanceReport,
    "crudTbAttendanceReport"
  ),
  crudTbLeaveReport: branchReducer(crudTbLeaveReport, "crudTbLeaveReport"),
  crudTbEmployeeLeave: branchReducer(
    crudTbEmployeeLeave,
    "crudTbEmployeeLeave"
  ),

  //Salary Management-------------------------
  crudTbSalaryDetail: branchReducer(crudTbSalaryDetail, "crudTbSalaryDetail"),
  crudTbSalaryProfile: branchReducer(
    crudTbSalaryProfile,
    "crudTbSalaryProfile"
  ),
  crudTbSalaryProfileList: branchReducer(
    crudTbSalaryProfileList,
    "crudTbSalaryProfileList"
  ),
  crudTbSalaryDisbursement: branchReducer(
    crudTbSalaryDisbursement,
    "crudTbSalaryDisbursement"
  ),

  //Account Management-------------------------
  crudTbLedgerHead: branchReducer(crudTbLedgerHead, "crudTbLedgerHead"),
  crudTbPaymentMode: branchReducer(crudTbPaymentMode, "crudTbPaymentMode"),
  crudTbTransaction: branchReducer(crudTbTransaction, "crudTbTransaction"),
  crudTbTax: branchReducer(crudTbTax, "crudTbTax"),
  crudTbCurrency: branchReducer(crudTbCurrency, "crudTbCurrency"),
  crudTbCurrencyRate: branchReducer(crudTbCurrencyRate, "crudTbCurrencyRate"),
  crudTbNewCurrencyRate: branchReducer(
    crudTbNewCurrencyRate,
    "crudTbNewCurrencyRate"
  ),

  //Medical-----------------
  crudTbMedicalRequests: branchReducer(
    crudTbMedicalRequests,
    "crudTbMedicalRequests"
  ),
  crudTbMedicalReport: branchReducer(
    crudTbMedicalReport,
    "crudTbMedicalReport"
  ),
  crudTbNewMedicalRequests: branchReducer(
    crudTbNewMedicalRequests,
    "crudTbNewMedicalRequests"
  ),

  //Asset Management-------------
  crudTbAssetManagementList: branchReducer(
    crudTbAssetManagementList,
    "crudTbAssetManagementList"
  ),
  crudTbEmployeeAssets: branchReducer(
    crudTbEmployeeAssets,
    "crudTbEmployeeAssets"
  ),
  crudTbProductTransferHistory: branchReducer(
    crudTbProductTransferHistory,
    "crudTbProductTransferHistory"
  ),
  crudTbAssetCategory: branchReducer(
    crudTbAssetCategory,
    "crudTbAssetCategory"
  ),
  crudTbDemandMaster: branchReducer(crudTbDemandMaster, "crudTbDemandMaster"),
  crudTbServicesMaster: branchReducer(
    crudTbServicesMaster,
    "crudTbServicesMaster"
  ),
  crudTbServicesDetails: branchReducer(
    crudTbServicesDetails,
    "crudTbServicesDetails"
  ),
  crudTbDemandDetails: branchReducer(
    crudTbDemandDetails,
    "crudTbDemandDetails"
  ),

  //Client Profiles-----------------
  crudTbCompany: branchReducer(crudTbCompany, "crudTbCompany"),
  crudTbClient: branchReducer(crudTbClient, "crudTbClient"),
  crudTbProject: branchReducer(crudTbProject, "crudTbProject"),
});

export default rootReducer;
